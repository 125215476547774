import { Country, Sport, Language } from './';

export enum Month {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}

export interface Settings {
  country: Country;
  sports: Sport;
  language: Language;
  darkMode: boolean;
  defaultVideoStartingTime: number;
  defaultVideoEndingTime: number;
  statisticsFrom: Month;
  statisticsTo: Month;
}
