import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MenuItem } from '../MenuItem';
import { setSidePanelVisibility } from '../../actions';

export const SubMenu: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const setSidePanelVisible = () => dispatch(setSidePanelVisibility(false));
  return (
    <div>
      <MenuItem
        onClick={setSidePanelVisible}
        label={t('settings')}
        iconName="settings"
        to="/settings"
      />
      <MenuItem
        onClick={setSidePanelVisible}
        label={t('competitionsAndCategories')}
        iconName="cup"
        to="/competitions"
      />
      <MenuItem onClick={setSidePanelVisible} label={t('about')} iconName="info" to="/about" />
    </div>
  );
};
