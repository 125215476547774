import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Title } from '../../components/Title';
import {
  getApplicationBreakpointState,
  getApplicationModeState,
  getMatchDetailStatus,
  getMatches,
  getMatchesStatus,
  getMatchesTotalCount,
  getUser,
} from '../../selectors';
import { MatchTable, MatchTableType } from '../../components/MatchTable';
import {
  ApplicationBreakpoint,
  ApplicationMode,
  Match,
  Status,
  MatchTab,
  MatchTabLocationState,
} from '../../types';
import { MatchCard, MatchCardType } from '../../components/MatchCard';
import { setMatches } from '../../actions';
import { ContentLoader } from '../../components/ContentLoader';
import { FloatingAddButton } from '../../components/FloatingAddButton';
import { MobileTitle } from '../../components/MobileTItle';

import './MatchList.scss';
import {
  setMatchTabToLocalStorage,
  getMatchTabFromLocalStorage,
  setMatchDetailTabToLocalStorage,
} from '../../utils';
import { NoMatches } from '../../components/NoMatches';

export const MatchListPage: FunctionComponent<{}> = () => {
  const UPCOMING_MATCHES_TAB_ID = 0;
  const PREVIOUS_MATCHES_TAB_ID = 1;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<MatchTabLocationState>();

  useEffect(() => {
    setMatchDetailTabToLocalStorage({ id: 0, name: '' });
  }, []);

  // const [currentScrolled, setCurrentScrolled] = useState<number>(0);
  // const [maxScrolled, setMaxScrolled] = useState<number>(0);

  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const applicationMode = useSelector(getApplicationModeState);
  const matches = useSelector(getMatches);
  const totalCount = useSelector(getMatchesTotalCount);
  const matchesStatus = useSelector(getMatchesStatus);
  const user = useSelector(getUser);
  const matchDetailStatus = useSelector(getMatchDetailStatus);

  const matchListClasses = ['MatchList'];
  if (applicationMode === ApplicationMode.dark) matchListClasses.push('MatchList--dark');

  // useEffect(() => {
  //   const handleScroll = (event: any) => {
  //     setCurrentScrolled(event.target.scrollTop);
  //     setMaxScrolled(event.target.scrollTopMax);
  //
  //     const percentage = (currentScrolled / maxScrolled) * 100;
  //     console.log(percentage);
  //   };
  //
  //   matchListRef.current.addEventListener('scroll', handleScroll);
  //
  //   return () => matchListRef.current.removeEventListener('scroll', handleScroll);
  // });

  const tabs: MatchTab[] = [
    {
      id: UPCOMING_MATCHES_TAB_ID,
      name: t('upMatches'),
      filter: `date_gt=${new Date().toISOString()}`,
      sort: 'ASC',
      matchCardType: MatchCardType.upcoming,
      matchTableType: MatchTableType.upcoming,
    },
    {
      id: PREVIOUS_MATCHES_TAB_ID,
      name: t('prevMatches'),
      filter: `date_lt=${new Date().toISOString()}`,
      sort: 'DESC',
      matchCardType: MatchCardType.previous,
      matchTableType: MatchTableType.previous,
    },
  ];

  const [activeTab, setActiveTab] = useState<MatchTab>(
    getMatchTabFromLocalStorage(tabs) || tabs[0]
  );

  const loadMore = useCallback(() => {
    if (user && matches.length < totalCount && matchesStatus !== Status.requestingMore) {
      const start = matches.length;
      dispatch(setMatches(activeTab.filter, start, activeTab.sort));
    }
  }, [user, matches.length, activeTab.filter, activeTab.sort, dispatch, matchesStatus, totalCount]);

  const observer = useRef<any>();
  const lastMatchRef = useCallback(
    node => {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [loadMore]
  );

  const redirectToNewMatch = () => {
    history.replace(location.pathname, {
      matchTab: activeTab.id,
    });
    history.push(`/match-detail?isNew=true`);
  };

  const redirectToMatch = (match: Match) => {
    history.replace(location.pathname, {
      matchTab: activeTab.id,
    });
    history.push(`/match-detail?id=${match.id}`);
  };

  useEffect(() => {
    const matchTab = location.state?.matchTab;
    if (matchTab) {
      setActiveTab(tabs[matchTab]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setMatchTabToLocalStorage(activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (user || matchDetailStatus === Status.success) {
      const start = 0;
      dispatch(setMatches(activeTab.filter, start, activeTab.sort));
    }
  }, [dispatch, user, activeTab, t, matchDetailStatus]);

  const renderMatchCards = matches.map((match: Match, index: number) => {
    const redirect = () => {
      redirectToMatch(match);
    };
    return (
      <MatchCard
        type={activeTab.matchCardType}
        key={index}
        match={match}
        redirectToMatch={redirect}
      />
    );
  });

  const renderIsLoadingMore = () =>
    matchesStatus === Status.requestingMore && (
      <div style={{ paddingBlock: '1rem' }}>
        <ContentLoader />
      </div>
    );

  return (
    <div className={matchListClasses.join(' ')}>
      {matchesStatus !== Status.requesting ? (
        <React.Fragment>
          {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
            <Title
              title={t('matches')}
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          ) : (
            <MobileTitle
              title={t('matches')}
              setActiveTab={setActiveTab}
              tabs={tabs}
              activeTab={activeTab}
            />
          )}
          <div className="MatchList__scrollable">
            <div className="MatchList__content">
              {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
                <div style={{ minWidth: 'fit-content', width: '100%' }}>
                  <div>
                    {matches.length > 0 && (
                      <>
                        <MatchTable
                          type={activeTab.matchTableType}
                          matches={matches}
                          redirectToMatch={redirectToMatch}
                        />
                        <div ref={lastMatchRef}>&nbsp;</div>
                        {renderIsLoadingMore()}
                      </>
                    )}
                    {matches.length === 0 && (
                      <NoMatches
                        type={activeTab.matchTableType}
                        redirectToNewMatch={redirectToNewMatch}
                        withMatchtable
                      />
                    )}
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  {matches.length > 0 && (
                    <>
                      {renderMatchCards}
                      <div ref={lastMatchRef}>&nbsp;</div>
                      {renderIsLoadingMore()}
                    </>
                  )}
                  {matches.length === 0 && (
                    <NoMatches
                      type={activeTab.matchTableType}
                      redirectToNewMatch={redirectToNewMatch}
                    />
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
          {matches.length > 0 && <FloatingAddButton handleClick={redirectToNewMatch} />}
        </React.Fragment>
      ) : (
        <ContentLoader />
      )}
    </div>
  );
};
