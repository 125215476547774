import { Category, Competition, CompetitionsResponse, Gender } from '../types';
import {
  DELETE_CATEGORY_URL,
  DELETE_COMPETITION_URL,
  GET_COMPETITIONS_URL,
  POST_CATEGORY_URL,
  POST_COMPETITION_URL,
  PUT_CATEGORY_URL,
  PUT_COMPETITION_URL,
} from './index';
import { getJwtFromLocalStorage, request } from '../utils';

export const _getCompetitions = async (userId: number): Promise<CompetitionsResponse> => {
  const response = await request(GET_COMPETITIONS_URL(userId), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });

  return response.json();
};

export const putCompetition = async (
  competition: Competition,
  currentSport: number | undefined,
  currentCountry: number | undefined
): Promise<any> => {
  const response = await request(PUT_COMPETITION_URL(competition?.id || 0), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
    body: JSON.stringify({
      name: competition.name,
      sport: currentSport,
      country: currentCountry,
      gender: competition.gender === Gender.men ? 'male' : 'female',
    }),
  });

  return response.json();
};

export const postCompetition = async (
  competition: Competition,
  currentSport: number | undefined,
  currentCountry: number | undefined
): Promise<any> => {
  const response = await request(POST_COMPETITION_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
    body: JSON.stringify({
      name: competition.name,
      sport: currentSport,
      country: currentCountry,
      gender: competition.gender === Gender.men ? 'male' : 'female',
    }),
  });

  return response.json();
};

export const deleteCompetition = async (competitionId: number | undefined): Promise<any> => {
  const response = await request(DELETE_COMPETITION_URL(competitionId || 0), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });

  return response.json();
};

export const putCategory = async (category: Category | undefined): Promise<any> => {
  if (category) {
    const response = await request(PUT_CATEGORY_URL(category?.id || 0), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${getJwtFromLocalStorage()}`,
      },
      body: JSON.stringify({
        name: category.name,
        reward4AR: category.rewards.type4AR,
        rewardAAR: category.rewards.typeAAR,
        rewardAR: category.rewards.typeAR,
        rewardD: category.rewards.typeD,
        rewardR: category.rewards.typeR,
      }),
    });

    return response.json();
  }
};

export const postCategory = async (
  category: Category,
  competitionId: number | undefined
): Promise<any> => {
  if (category) {
    const response = await request(POST_CATEGORY_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${getJwtFromLocalStorage()}`,
      },
      body: JSON.stringify({
        name: category.name,
        reward4AR: category.rewards.type4AR,
        rewardAAR: category.rewards.typeAAR,
        rewardAR: category.rewards.typeAR,
        rewardD: category.rewards.typeD,
        rewardR: category.rewards.typeR,
        competition: competitionId,
      }),
    });

    return response.json();
  }
};

export const deleteCategory = async (categoryId: number | undefined): Promise<any> => {
  const response = await request(DELETE_CATEGORY_URL(categoryId || 0), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });

  return response.json();
};
