import { AnyAction } from 'redux';
import { SET_TIPS_ERROR, SET_TIPS_REQUESTING, SET_TIPS_SUCCESS } from '../constants';
import { Status, TipsState } from '../types';

const initTipsState: TipsState = { status: Status.idle, errorMsg: null, tips: [] };

export const tipsReducer = (state: TipsState = initTipsState, action: AnyAction): TipsState => {
  switch (action.type) {
    case SET_TIPS_REQUESTING: {
      return { status: Status.requesting, errorMsg: null, tips: [] };
    }
    case SET_TIPS_ERROR: {
      const { errorMsg } = action.payload;

      return { status: Status.error, errorMsg, tips: [] };
    }
    case SET_TIPS_SUCCESS: {
      const { tips } = action.payload;

      return { status: Status.success, errorMsg: null, tips };
    }
    default:
      return state;
  }
};
