import React, { FunctionComponent, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationMode, Clip, ClipCategory, ClipType, CommunityClip } from '../../types';
import { Icon, IconType } from '../Icon';
import { getApplicationModeState, getIsPremium } from '../../selectors';

import './ClipCard.scss';
import { useTranslation } from 'react-i18next';

import LockIcon from '@material-ui/icons/Lock';
import { setPremiumModalVisibility } from '../../actions';
import { joinWithDot, printTeams } from '../../utils';

type ClipCardProps = {
  clip: Clip;
  clipType: ClipType;
  premiumLock?: boolean;
  isFirst: boolean;
  isLast: boolean;
  redirectToVideoDetail: (path: string) => void;
  clipRef: React.RefObject<HTMLDivElement>;
  clipGroupId?: number;
  seePrivateNote: boolean;
};

export const ClipCard: FunctionComponent<ClipCardProps> = ({
  clip,
  clipType,
  premiumLock,
  isFirst,
  isLast,
  redirectToVideoDetail,
  clipRef,
  clipGroupId,
  seePrivateNote,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const applicationMode = useSelector(getApplicationModeState);
  const userIsPremium = useSelector(getIsPremium);
  const clipsAreLockedIfNotPremium = Boolean(premiumLock);
  const featureDisabled = !userIsPremium && clipsAreLockedIfNotPremium;

  const iconType = applicationMode === ApplicationMode.light ? IconType.default : IconType.white;
  const clipCardClasses = ['ClipCard'];
  if (applicationMode === ApplicationMode.dark) clipCardClasses.push('ClipCard--dark');
  if (isFirst) clipCardClasses.push('ClipCard__first');
  if (isLast) clipCardClasses.push('ClipCard__last');

  const path = `/video-detail?id=${clip.id}${
    clipGroupId !== undefined ? '&clipgroup=' + clipGroupId : ''
  }${clipType === ClipType.favourite ? '&t=' + ClipType.favourite : ''}`;

  const redirectToDetail = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // cmd || ctr click opens in new window, so we don't need to handle this
    if (!event.metaKey && !event.ctrlKey) {
      event.preventDefault();
      redirectToVideoDetail(path);
    }
  };

  const openPremiumModal = () => dispatch(setPremiumModalVisibility(true));

  const myClipCardInfo = () => (
    <Fragment>
      <div className="ClipCard__label">
        <Icon size="12px" name="pen" type={iconType} />
        <div className="ClipCard__labelText">{clip.name || '-'}</div>
      </div>
      <div className="ClipCard__label">
        <Icon size="12px" name="folder" type={iconType} />
        <div className="ClipCard__labelText">
          {joinWithDot(...clip.categories.map((category: ClipCategory) => t(category.value))) ||
            '-'}
        </div>
      </div>
      <div className="ClipCard__label">
        <Icon size="12px" name="comment" type={iconType} />
        <div className="ClipCard__labelText">{clip.publicNote || '-'}</div>
      </div>
      {seePrivateNote && (
        <div className="ClipCard__label">
          <Icon size="12px" name="comment" type={iconType} />
          <div className="ClipCard__labelText">{clip.note || '-'}</div>
        </div>
      )}
    </Fragment>
  );

  const communityClipCardInfo = () => {
    const communityClip = clip as CommunityClip;
    const { location, gender, competition, category } = communityClip;
    const teams = printTeams(communityClip.teams);
    const labelText = joinWithDot(location, gender, competition, category, teams);
    return (
      <Fragment>
        {myClipCardInfo()}
        <div className="ClipCard__label">
          <Icon size="12px" name="cup" type={iconType} />
          <div className="ClipCard__labelText">{labelText || '-'}</div>
        </div>
      </Fragment>
    );
  };

  const favouriteClipCardInfo = () => {
    return myClipCardInfo();
  };

  const getClipCardInfo = () => {
    if (clipType === ClipType.myVideo) {
      return myClipCardInfo();
    }
    if (clipType === ClipType.community) {
      return communityClipCardInfo();
    }
    if (clipType === ClipType.favourite) {
      return favouriteClipCardInfo();
    }
    if (clipType === ClipType.closedCommunity) {
      return communityClipCardInfo();
    }
  };

  const clipCard = (
    <div ref={clipRef} className={clipCardClasses.join(' ')}>
      <img
        className="ClipCard__thumbnail"
        alt="clip"
        src={clip.imageUrl || `${process.env.PUBLIC_URL}/preview.png`}
      />
      {featureDisabled && (
        <div className="ClipCard__thumbnail ClipCard__thumbnail__premium">
          <div className="ClipCard__iconWrapper">
            <LockIcon className="ClipCard__iconWrapper__icon" />
            <span className="ClipCard__iconWrapper__content">{t('premiumFeatureAvailable')}</span>
          </div>
        </div>
      )}
      <div className="ClipCard__info">{getClipCardInfo()}</div>
    </div>
  );

  if (featureDisabled) {
    return <div onClick={openPremiumModal}>{clipCard}</div>;
  }

  return (
    <a href={path} onClick={redirectToDetail}>
      {clipCard}
    </a>
  );
};
