import { isUndefined } from 'lodash';
import { ApplicationMode } from '../types';

export const classHandler = (
  baseClass: string,
  applicationMode: ApplicationMode,
  isActive?: boolean,
  isVisible?: boolean,
  classNames?: string
): string => {
  const resultClasses = [classNames, baseClass];

  resultClasses.push(applicationMode === ApplicationMode.dark ? `${baseClass}--dark` : '');
  resultClasses.push(isActive ? `${baseClass}--active` : '');
  resultClasses.push(isVisible ? `${baseClass}--visible` : '');

  return resultClasses.filter(className => !isUndefined(className) && className !== '').join(' ');
};
