import { store } from '../';
import { setUserSuccess } from '../actions';
import { removeJwtFromLocalStorage } from './localStorageHandlers';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const logoutCheck = (response: Response | AxiosResponse): void => {
  if (response.status === 401) {
    removeJwtFromLocalStorage();
    store.dispatch(setUserSuccess(null));
  }
};

export const request = (url: RequestInfo, options?: RequestInit | undefined): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(url, options);

      logoutCheck(res);

      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const requestWithProgress = (
  config: AxiosRequestConfig,
  onUploadProgress: ((progressEvent: ProgressEvent<EventTarget>) => void) | undefined
): Promise<AxiosResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await axios.request({
        ...config,
        onUploadProgress,
      });

      logoutCheck(data);

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
