import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import './NoNotes.scss';
import { useSelector } from 'react-redux';
import { getApplicationModeState } from '../../selectors';
import { classHandler } from '../../utils';
import { useHistory } from 'react-router-dom';
import { Button } from '../Button';

export const NoNotes: FunctionComponent<{}> = () => {
  const applicationMode = useSelector(getApplicationModeState);
  const { t } = useTranslation();
  const history = useHistory();

  const redirectToMatches = () => {
    history.push(`/`);
  };

  return (
    <div className={classHandler('NoNotes', applicationMode)}>
      <img
        alt="no notes"
        className="NoNotes__image"
        src={`${process.env.PUBLIC_URL}/no_notes.png`}
      />
      <span className="NoNotes__text">
        <Trans i18nKey="noNotesMessage">
          You don't have <strong>{{ notes: t('noNotes').toLowerCase() }}</strong>. Use a tab Notes
          in Match detail page.
        </Trans>
      </span>
      {/* <div onClick={redirectToMatches} className="NoNotes__button">
        {t('addNotesEmpty')}
      </div> */}
      <Button label={t('addNotesEmpty')} onClick={redirectToMatches} type="primary" />
    </div>
  );
};
