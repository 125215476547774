import React, { FunctionComponent, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Icon, IconType } from '../Icon';
import { getApplicationBreakpointState, getApplicationModeState } from '../../selectors';
import { ApplicationBreakpoint, ApplicationMode, Tab } from '../../types';
import { classHandler, iconTypeHandler } from '../../utils';
import { setFilterPanelVisibility } from '../../actions';

import './Title.scss';
import { Button } from '../Button';

type TitleProps = {
  title: string;
  tabs?: Tab[];
  activeTab?: Tab;
  withFilter?: boolean;
  withIcons?: { name: string; onClick: MouseEventHandler; visible: boolean }[];
  backFunction?: boolean;
  handleHeadingClick?: React.MouseEventHandler<HTMLDivElement>;
  setActiveTab?: any;
  withPrevTab?: boolean;
  goBackTab?: React.MouseEventHandler<HTMLDivElement>;
  isNewMatch?: boolean;
  activeFilters?: number;
};

export const Title: FunctionComponent<TitleProps> = ({
  title,
  tabs,
  activeTab,
  withFilter,
  withIcons,
  backFunction,
  handleHeadingClick,
  setActiveTab,
  withPrevTab,
  goBackTab,
  isNewMatch,
  activeFilters,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const applicationMode = useSelector(getApplicationModeState);
  const goBack = () => history.goBack();
  const isActiveFilters = activeFilters !== undefined && activeFilters > 0;
  const openFilterPanel = () => {
    dispatch(setFilterPanelVisibility(true));
  };
  const getLabelFromIconName = (name: string): string => {
    switch (name) {
      case 'trash':
        return 'delete';
      case 'edit':
        return 'editCompetition';
      default:
        return '';
    }
  };
  const showFilter = withFilter && applicationBreakpoint === ApplicationBreakpoint.desktop;
  const renderTabs = tabs?.map((tab: Tab, index: number) => {
    const activateTab = () => setActiveTab(tab);

    const isActive = activeTab?.id === tab.id;
    const isLast = tabs.length - index === 1;

    return isNewMatch ? (
      <div
        key={tab.id}
        onClick={activateTab}
        className={classHandler('Title__tab', applicationMode, isActive)}
      >
        <div className="Title__arrowItem">
          <span style={{ padding: '0 25px' }}>{tab.name}</span>
          {!isLast && (
            <Icon
              size="18px"
              name="arrowForward"
              type={iconTypeHandler(applicationMode, IconType.white, IconType.red, isActive)}
            />
          )}
        </div>
      </div>
    ) : (
      <div
        key={tab.id}
        onClick={activateTab}
        className={classHandler('Title__tab', applicationMode, isActive)}
      >
        {tab.name}
      </div>
    );
  });

  return (
    <div className={classHandler('Title', applicationMode)}>
      <div
        style={{ display: 'flex', justifyContent: 'flex-start', alignContent: 'center', flex: '1' }}
      >
        {backFunction && (
          <div className="Title__backButton" onClick={withPrevTab ? goBackTab : goBack}>
            <Icon
              size="20px"
              name="arrowBack"
              type={iconTypeHandler(applicationMode, IconType.white)}
            />
          </div>
        )}
        {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
          <h2
            style={{
              userSelect: 'none',
              whiteSpace: 'nowrap',
              cursor: handleHeadingClick ? 'pointer' : undefined,
            }}
            onClick={handleHeadingClick}
          >
            {title}
          </h2>
        ) : (
          ''
        )}
      </div>
      <div className="Title__tabs">{renderTabs}</div>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'center', flex: '1' }}
      >
        {showFilter && (
          <Button type={'basic'} onClick={openFilterPanel}>
            <div className="Title__filterText">{t('filter')}</div>
            <Icon
              type={applicationMode === ApplicationMode.dark ? IconType.white : IconType.default}
              name="filter"
            />
            {isActiveFilters && <div className="Title__filterBadge">{activeFilters}</div>}
          </Button>
        )}

        {withIcons && withIcons?.length > 0 && (
          <div style={{ display: 'flex' }}>
            {withIcons
              .filter(withIcon => withIcon.visible)
              .map((withIcon, key) => (
                <Button
                  key={key}
                  className={'Title__buttonWithBoldText'}
                  label={t(getLabelFromIconName(withIcon.name))}
                  type="basic"
                  onClick={withIcon.onClick}
                  disabled={false}
                  iconName={withIcon.name}
                  iconPosition="right"
                  iconType={iconTypeHandler(applicationMode, IconType.white)}
                  premiumFeature={false}
                  theme={applicationMode}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};
