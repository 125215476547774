import { Category, Competition, Gender, RootState, Status } from '../types';

export const getCompetitionsStatus = (state: RootState): Status => state.competitions.status;

export const getCompetitionsErrorMsg = (state: RootState): string | null =>
  state.competitions.errorMsg;

export const getCompetitions = (gender?: Gender) => (state: RootState): Competition[] => {
  if (gender) {
    return state.competitions.competitions.filter(
      (competition: Competition) => competition.gender === gender
    );
  }
  return state.competitions.competitions;
};

export const getCompetition = (id: number) => (state: RootState): Competition | null =>
  state.competitions.competitions.find((competition: Competition) => competition.id === id) || null;

export const getCategories = (currentCompetitionId: number) => (state: RootState): Category[] => {
  const currentCompetition = state.competitions.competitions.find(
    (competition: Competition) => competition.id === currentCompetitionId
  );

  return currentCompetition?.categories || [];
};
