import LockIcon from '@material-ui/icons/Lock';
import {
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getClipCategories, getIsPremium } from '../../selectors';
import { ClipCategory } from '../../types';
import { timePresentation } from '../../utils';
import { Modal } from '../Modal';
import _ from 'lodash';

import { Button } from '../Button';
import { CustomCheckbox } from '../VideoFilterPanel';
import { getRecorderSituations } from '../../selectors/recorderSituations';
import { updateRecorderSituation } from '../../actions/recorderSituationsActions';

import './DigiRecorderSituations.scss';
import { PREMIUM_MODAL_CONTENT_LOC_KEY, SITUATIONS_LIMIT } from '../DigiRecorderContent/constants';
import { setPremiumModalVisibility } from '../../actions';

const DigiRecorderSituations: React.FC<{}> = () => {
  const { t } = useTranslation();
  const [isCategoryPickerVisible, setCategoryPickerVisible] = useState(false);
  const clipCategories = useSelector(getClipCategories);
  const [currentSituationId, setCurrentSituationId] = useState<string>();
  const { situations } = useSelector(getRecorderSituations);
  const reverseSituations = [...situations].reverse();
  const dispatch = useDispatch();
  const isPremium = useSelector(getIsPremium);
  const openPremiumModal = useCallback(
    () => dispatch(setPremiumModalVisibility(true, PREMIUM_MODAL_CONTENT_LOC_KEY)),
    [dispatch]
  );

  const handleCategoryOnClick = (situationId: string) => () => {
    setCurrentSituationId(situationId);
    setCategoryPickerVisible(true);
  };

  const renderClipCategoriesContent = useCallback(() => {
    const currentSituation = situations.find(({ id }) => id === currentSituationId);

    if (!currentSituation) {
      return null;
    }

    const categories = _.groupBy(clipCategories, clip => clip.type);
    return (
      <div className="DigiRecorderSituations__categories">
        {Object.keys(categories)
          .sort()
          .map(category => {
            return (
              <div className="DigiRecorderSituations__categoryGroup" key={category}>
                <div className="DigiRecorderSituations__categoryTitle">{t(category)}</div>
                <div className="DigiRecorderSituations__categoryList">
                  {(categories[category] as ClipCategory[]).map(c => {
                    const checked = !!currentSituation.categories.find(ctg => ctg.id === c.id);
                    let newCategories = [...currentSituation.categories];
                    if (checked) {
                      newCategories = newCategories.filter(ctg => ctg.id !== c.id);
                    } else {
                      newCategories = [...newCategories, c];
                    }
                    return (
                      <div className="DigiRecorderSituations__categoryItem" key={c.id}>
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={checked}
                              onChange={() => {
                                dispatch(
                                  updateRecorderSituation(currentSituation.id, {
                                    categories: newCategories,
                                  })
                                );
                              }}
                            />
                          }
                          label={t(c.value)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
    );
  }, [clipCategories, t, currentSituationId, dispatch, situations]);

  return (
    <div className="DigiRecorderSituations">
      <Modal
        isVisible={isCategoryPickerVisible}
        width={'min(calc(100% - 20px), 500px)'}
        height={'min(calc(100% - 40px), 600px)'}
        handleClose={() => setCategoryPickerVisible(false)}
      >
        <div className="DigiRecorderSituations__categoriesContainer">
          {renderClipCategoriesContent()}
          <Button
            label={`${t('close')}`}
            type={'primary'}
            className={'DigiRecorderSituations__closeButton'}
            onClick={() => setCategoryPickerVisible(false)}
          />
        </div>
      </Modal>
      <div className="DigiRecorderSituations__heading_container">
        <div className="DigiRecorderSituations__heading">{t('listOfSituations')}</div>
        {!isPremium && (
          <div className="DigiRecorderSituations__premium" onClick={openPremiumModal}>
            <LockIcon className="DigiRecorderContent__container_title_icon" />
            <span className="DigiRecorderContent__container_title_text">
              {t('premiumFeatureSituationsTitle', { situationsLimit: SITUATIONS_LIMIT })}
            </span>
          </div>
        )}
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="DigiRecorderSituations__table_head_item">{t('time')}</TableCell>
            <TableCell className="DigiRecorderSituations__table_head_item">
              {t('clipName')}
            </TableCell>
            <TableCell className="DigiRecorderSituations__table_head_item">
              {t('category')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reverseSituations.length === 0 && (
            <TableRow>
              <TableCell colSpan={3} className="DigiRecorderSituations__table_empty_row">
                {t('noSituationRow')}
              </TableCell>
            </TableRow>
          )}
          {reverseSituations.map(({ timeSeconds, videoName, categories, id }) => (
            <TableRow key={id}>
              <TableCell>{timePresentation(timeSeconds, false, true)}</TableCell>
              <TableCell>
                <TextField
                  type="text"
                  value={videoName}
                  onChange={event =>
                    dispatch(updateRecorderSituation(id, { videoName: event.target.value }))
                  }
                />
              </TableCell>
              <TableCell>
                <div className="DigiRecorderSituations__addCategory">
                  <div>{categories.length || ''}</div>
                  <div
                    className="DigiRecorderSituations__addCategory__button"
                    onClick={handleCategoryOnClick(id)}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default DigiRecorderSituations;
