import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TopSegment } from '../TopSegment';
import { ProfileSegment } from '../ProfileSegment';
import { Divider } from '../Divider';
import { MainMenu } from '../MainMenu';
import { SubMenu } from '../SubMenu';
import { TipBar } from '../TipBar';
import { ApplicationBreakpoint } from '../../types';
import {
  getApplicationBreakpointState,
  getApplicationModeState,
  getIsPremium,
  getUser,
} from '../../selectors';
import { classHandler } from '../../utils';

import './SidePanel.scss';
import { setSidePanelVisibility } from '../../actions';
import { GetPremiumButton } from '../GetPremiumButton';

type SidePanelProps = {
  isVisible?: boolean;
};

export const SidePanel: FunctionComponent<SidePanelProps> = ({ isVisible }) => {
  const dispatch = useDispatch();

  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const applicationMode = useSelector(getApplicationModeState);
  const isPremium = useSelector(getIsPremium);
  const user = useSelector(getUser);
  const isLoggedIn = user ? true : false;

  const wrapperRef = useRef(null);

  const setInvisible = () => dispatch(setSidePanelVisibility(false));

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setInvisible();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef} className={classHandler('SidePanel', applicationMode, false, isVisible)}>
      <TopSegment
        handleClose={setInvisible}
        isOpened={isVisible || false}
        isLoggedIn={isLoggedIn}
      />
      <div className="SidePanel__content">
        <ProfileSegment />
        {applicationBreakpoint === ApplicationBreakpoint.desktop && (
          <React.Fragment>
            <Divider />
            <MainMenu />
          </React.Fragment>
        )}
        <Divider />
        <SubMenu />
        {!isPremium && <Divider />}
        {!isPremium && <TipBar />}
        {!isPremium && <GetPremiumButton />}
      </div>
    </div>
  );
};
