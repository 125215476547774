import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { classHandler, iconTypeHandler } from '../../utils';
import { getApplicationModeState, getClipDetail, getUser } from '../../selectors';
import { Icon, IconType } from '../Icon';

import './UnavailableVideoNotification.scss';
import { Button } from '../Button';
import { useHistory } from 'react-router-dom';

export type NotificationTextProps = {
  timeOverlap?: boolean;
  isProcessing?: boolean;
  isError?: boolean;
  isLoadingError?: boolean;
  videoOwnerId?: number;
};

export const UnavailableVideoNotification: FunctionComponent<NotificationTextProps> = ({
  timeOverlap,
  isProcessing,
  isError,
  isLoadingError,
  videoOwnerId,
}) => {
  const { t } = useTranslation();
  const applicationMode = useSelector(getApplicationModeState);
  const history = useHistory();
  const matchClip = useSelector(getClipDetail);
  const user = useSelector(getUser);
  const isOwner = user?.id === videoOwnerId;
  const isMatchDetail = history.location.pathname === '/match-detail';

  const getAlertMessage = () => {
    if (isProcessing) {
      return <h4>{t('processing')}</h4>;
    }

    if (isError) {
      return <h4>{t('videoError')}</h4>;
    }

    if (isLoadingError) {
      return <p>{isOwner || isMatchDetail ? t('changeUrl') : t('matchVideoUnavailableInfo')}</p>;
    }

    if (timeOverlap) {
      return <p>{isOwner ? t('changeTime') : t('matchVideoUnavailableInfo')}</p>;
    }

    return <p>{isOwner ? t('changeUrl') : t('matchVideoUnavailableInfo')}</p>;
  };

  return (
    <div className={classHandler('UnavailableVideoNotification', applicationMode)}>
      <div className="UnavailableVideoNotification__content">
        <div className="UnavailableVideoNotification__alertIcon">
          <Icon name="alert" type={iconTypeHandler(applicationMode, IconType.white)} />
        </div>
        <div className="messageContainer">
          <h4>{t('unavailable')}</h4>
          {getAlertMessage()}
          {isOwner && (
            <Button
              className="timeOverlapButton"
              type="secondary"
              onClick={() => history.push(`/match-detail?id=${matchClip?.match.id}&tab=video`)}
            >
              {t('checkMatchVideo')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
