import { Dispatch } from 'redux';

import {
  SET_MATCHES_ERROR,
  SET_MATCHES_REQUESTING,
  SET_MATCHES_REQUESTING_MORE,
  SET_MATCHES_SUCCESS,
} from '../constants';
import { Gender, Match, MatchesResponse } from '../types';
import { _getMatches } from '../api';
import { store } from '../index';

/**
 * Action for matches setting (REQUESTING)
 *
 */
export const setMatchesRequesting = () => ({ type: SET_MATCHES_REQUESTING });

/**
 * Action for matches setting (REQUESTING MORE)
 *
 */
export const setMatchesRequestingMore = () => ({ type: SET_MATCHES_REQUESTING_MORE });

/**
 * Action for matches setting (ERROR)
 *
 * @params errorMsg Error message
 */
export const setMatchesError = (errorMsg: string) => ({
  type: SET_MATCHES_ERROR,
  payload: { errorMsg },
});

/**
 * Action for matches setting (SUCCESS)
 *
 * @params matches list of matches
 */
export const setMatchesSuccess = (matches: Match[], totalCount: number) => ({
  type: SET_MATCHES_SUCCESS,
  payload: { matches, totalCount },
});

/**
 * Action for fetch of Matches
 *
 */
export const setMatches = (filter: string, start: number, sort: 'ASC' | 'DESC') => (
  dispatch: Dispatch
) => {
  if (start === 0) dispatch(setMatchesRequesting());
  else dispatch(setMatchesRequestingMore());

  _getMatches(filter, { start, limit: 50 }, sort).then((response: MatchesResponse) => {
    if (response.matches) {
      if (start === 0)
        dispatch(setMatchesSuccess(transformMatches(response.matches), response.count));
      else
        dispatch(
          setMatchesSuccess(
            [...store.getState().matches.matches, ...transformMatches(response.matches)],
            response.count
          )
        );
    }
  });
};

const transformMatches = (matches: any): Match[] => {
  return matches.map((match: any) => {
    const date = new Date(match.date);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const time = `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;

    return {
      id: match.id,
      date,
      time,
      location: match.location,
      gender: match.gender === 'male' ? Gender.men : Gender.women,
      competition: match.competition,
      category: match.categorie,
      teams: { firstTeam: match.homeTeam, secondTeam: match.guestTeam },
      referees: match.referees ? Object.values(match.referees) : [],
      refereeCoach: match.supevisor || '',
      videoCount: match.videoCount || 0,
      noteCount: match.noteCount || 0,
    };
  });
};
