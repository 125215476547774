import { Dispatch } from 'redux';

import {
  SET_REFEREE_TEAM_TYPES_REQUESTING,
  SET_REFEREE_TEAM_TYPES_ERROR,
  SET_REFEREE_TEAM_TYPES_SUCCESS,
} from '../constants';
import { ErrorResponse, RefereeTeamType, RefereeTeamTypesResponse } from '../types';
import { getRefereeTeamTypes } from '../api';
import { isErrorResponse } from '../utils';

export const setRefereeTeamTypesRequesting = () => ({ type: SET_REFEREE_TEAM_TYPES_REQUESTING });

export const setRefereeTeamTypesError = (errorMsg: string) => ({
  type: SET_REFEREE_TEAM_TYPES_ERROR,
  payload: { errorMsg },
});

export const setRefereeTeamTypesSuccess = (refereeTeamTypes: RefereeTeamType[]) => ({
  type: SET_REFEREE_TEAM_TYPES_SUCCESS,
  payload: { refereeTeamTypes },
});

export const setRefereeTeamTypes = (sportId: number) => (dispatch: Dispatch) => {
  dispatch(setRefereeTeamTypesRequesting());

  getRefereeTeamTypes(sportId).then((response: RefereeTeamTypesResponse | ErrorResponse) => {
    if (isErrorResponse(response)) {
      const errorResponse = response as ErrorResponse;

      setRefereeTeamTypesError(errorResponse.message[0].messages[0].message);
    } else {
      const refereeTeamTypesResponse = response as RefereeTeamTypesResponse;

      const transformedRefereeTeamTypes: RefereeTeamType[] = refereeTeamTypesResponse.refereeTeamTypes.map(
        (refereeTeamType: any) => {
          return {
            id: refereeTeamType.id,
            name: refereeTeamType.name,
            roles: refereeTeamType.roles.split(', '),
          };
        }
      );

      dispatch(setRefereeTeamTypesSuccess(transformedRefereeTeamTypes));
    }
  });
};
