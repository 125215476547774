import { AppInfo, Credentials, ErrorResponse, SuccessResponse, UserResponse } from '../types';
import {
  GET_IS_PREMIUM_UNTIL,
  GET_USER_URL,
  POST_LOGIN_URL,
  POST_REGISTRATION_URL,
  POST_USER_APP_INFO,
} from './';
import { RegistrationData } from '../containers/RegisterPage';
import { request } from '../utils';

export const getUser = async (jwt: string): Promise<UserResponse | ErrorResponse> => {
  const response = await request(GET_USER_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${jwt}`,
    },
  });

  return response.json();
};

export const postLogin = async (
  credentials: Credentials
): Promise<UserResponse | ErrorResponse> => {
  const response = await request(POST_LOGIN_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      identifier: credentials.email,
      password: credentials.password,
      platform: credentials.platform,
      appVersion: credentials.appVersion,
    }),
  });

  return response.json();
};

export const postRegistration = async (
  registrationData: RegistrationData
): Promise<UserResponse | ErrorResponse> => {
  const response = await request(POST_REGISTRATION_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: registrationData.email,
      password: registrationData.password,
      firstName: registrationData.firstName,
      lastName: registrationData.lastName,
      mgmEmail: registrationData.mgmEmail,
      country: registrationData.country,
      sports: registrationData.sport,
      appLanguage: 1,
    }),
  });

  return response.json();
};

export const postAppInfo = async (
  userId: number,
  jwt: string,
  appInfo: AppInfo
): Promise<SuccessResponse | ErrorResponse> => {
  const response = await request(POST_USER_APP_INFO, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify({
      userId,
      platform: appInfo.platform,
      appVersion: appInfo.appVersion,
    }),
  });

  return response.json();
};

export const getIsPremiumUntil = async (jwt: string, id: number) => {
  const response = await request(GET_IS_PREMIUM_UNTIL(id), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${jwt}`,
    },
  });

  return response.json();
};
