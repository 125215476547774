import { HIDE_NOTIFICATION, SHOW_NOTIFICATION, UPDATE_NOTIFICATION } from '../constants';
import { NotificationSeverity } from '../types';

export const showNotification = (
  message: string,
  severity: NotificationSeverity,
  hideAfter?: number
) => ({
  type: SHOW_NOTIFICATION,
  payload: { message, severity, hideAfter },
});

export const updateNotification = (message: string, severity?: string) => {
  let payload: { message: string } | { message: string; severity: string } = { message };
  if (severity) {
    payload = { ...payload, severity };
  }

  return {
    type: UPDATE_NOTIFICATION,
    payload,
  };
};

export const hideNotification = () => ({
  type: HIDE_NOTIFICATION,
});
