import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getApplicationModeState,
  getApplicationBreakpointState,
  getAccountingFilter,
  getAccountingsStatus,
} from '../../selectors';
import { ApplicationMode, ApplicationBreakpoint, Tab, Status } from '../../types';
import { Title } from '../../components/Title';
import { MobileTitle } from '../../components/MobileTItle';

import './AccountingPage.scss';
// import { getNoteTabFromLocalStorage } from '../../utils';
import { AccountingYearTab } from '../../components/AccountingYearTab/AccountingYearTab';
import { getAccountings } from '../../selectors';

import { setAccounting } from '../../actions';
import { NoMatches } from '../../components/NoMatches';

import { useHistory, useLocation } from 'react-router-dom';
import { MatchTabLocationState } from '../../types';

import { MatchTableType } from '../../components/MatchTable';
import { ContentLoader } from '../../components/ContentLoader';

export const AccountingPage: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const history = useHistory();
  const location = useLocation<MatchTabLocationState>();
  const accountingFilter = useSelector(getAccountingFilter);
  const accountingPageClasses = ['AccountingPage'];
  if (applicationMode === ApplicationMode.dark) accountingPageClasses.push('AccountingPage--dark');
  const dispatch = useDispatch();

  const seasons = useSelector(getAccountings);
  const status = useSelector(getAccountingsStatus);

  const redirectToNewMatch = () => {
    history.replace(location.pathname);
    history.push(`/match-detail?isNew=true`);
  };

  useEffect(() => {
    dispatch(setAccounting());
  }, [dispatch]);

  const tabs: Tab[] = [];

  seasons.sort((a, b) => {
    let yearA = a.season.length > 4 ? Number(a.season.substring(0, 4)) : Number(a.season);
    let yearB = b.season.length > 4 ? Number(b.season.substring(0, 4)) : Number(b.season);
    yearA = isNaN(yearA) ? -1 : yearA;
    yearB = isNaN(yearB) ? -1 : yearB;

    return yearB - yearA;
  });

  seasons.forEach((s, i) => {
    if (s.season === 'All seasons' && seasons.length <= 2) return;

    tabs.push({
      id: i,
      name: t(s.season),
    });
  });

  const getActiveFilter = (): number => {
    let count = 0;
    if (accountingFilter.dateTo !== null || accountingFilter.dateFrom !== null) count++;
    if (accountingFilter.type !== '') count++;
    return count;
  };

  const [activeTab, setActiveTab] = useState<Tab>(tabs[0]); // getNoteTabFromLocalStorage(tabs)
  if (!activeTab && tabs[0]) setActiveTab(tabs[0]);

  return (
    <div className={accountingPageClasses.join(' ')}>
      {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
        <Title
          title={t('accounting')}
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          activeFilters={getActiveFilter()}
          withFilter
        />
      ) : (
        <MobileTitle
          title={t('accounting')}
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          activeFilters={getActiveFilter()}
          withFilters
        />
      )}
      <div className="AccountingPage__content">
        {seasons && activeTab && seasons[activeTab.id] && (
          <AccountingYearTab
            year={seasons[activeTab.id].season}
            link={seasons[activeTab.id].link}
          />
        )}
        {seasons && seasons.length === 0 && status === Status.success && (
          <div style={{ margin: '0px auto' }}>
            <NoMatches type={MatchTableType.noData} redirectToNewMatch={redirectToNewMatch} />
          </div>
        )}

        {status === Status.requesting && <ContentLoader />}
      </div>
    </div>
  );
};
