import React, { FunctionComponent } from 'react';
import './FeatureToggle.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useState } from 'react';
// import { useTranslation } from 'react-i18next';

type FeatureToggleProps = {
  title: string;
  content: string[];
};

export const FeatureToggle: FunctionComponent<FeatureToggleProps> = ({ title, content }) => {
  // const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const features: JSX.Element[] = [];
  content.forEach((feature, index) => {
    features.push(<div key={index}>{feature}</div>);
  });

  return (
    <div className="FeatureToggle">
      <div className="FeatureToggle__title" onClick={handleExpand}>
        <span>{title}</span>
        <span className="FeatureToggle__arrow">
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </span>
      </div>
      {expanded && <div className="FeatureToggle__content">{features}</div>}
    </div>
  );
};
