import {
  SET_SHARED_MATCH_ERROR,
  SET_SHARED_MATCH_REQUESTING,
  SET_SHARED_MATCH_SUCCESS,
} from '../constants';
import { Dispatch } from 'redux';
import { getMatchClips } from '../api';
import { ClipFilters, MyClipGroup } from '../types';

export const setSharedMatchError = (errorMsg: string) => ({
  type: SET_SHARED_MATCH_ERROR,
  payload: errorMsg,
});
export const setSharedMatchRequesting = () => ({ type: SET_SHARED_MATCH_REQUESTING });
export const setSharedMatchSuccess = (res: MyClipGroup) => ({
  type: SET_SHARED_MATCH_SUCCESS,
  payload: res,
});

export const setSharedMatch = (shareToken: string, filters: ClipFilters) => (
  dispatch: Dispatch
) => {
  dispatch(setSharedMatchRequesting());

  const _filters = filters.clipCategories
    .map(({ id }) => `&clips.clipCategories.id=${id}`)
    .join('');

  getMatchClips(shareToken, _filters)
    .then(res => {
      if (res?.count === 0) {
        dispatch(setSharedMatchError('deletedMatchMessage'));
      } else {
        dispatch(setSharedMatchSuccess(res.clipGroups[0]));
      }
    })
    .catch(e => {
      dispatch(setSharedMatchError(e.message || ''));
    });
};
