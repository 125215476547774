import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MatchDetail, VideoStatus } from '../../types';
import { Modal } from '../Modal';
import { VideoPlayer } from '../VideoPlayer';
import './MatchDetailVideoPlayVideoModal.scss';
import { MatchButton } from '../MatchButton';
import { Button } from '../Button';
import { getApplicationModeState } from '../../selectors';
import { useSelector } from 'react-redux';
import { useModalKeyboardShortcuts } from '../../utils';
import setProxy from '../../utils/setProxy';
import { uploadPercentageCompleted } from '../../selectors/job';

type MatchDetailVideoPlayVideoModalProps = {
  newMatch: MatchDetail;
};

type Context = {
  className: string;
  label: string;
  loader: boolean;
  playTheMatch: boolean;
};

export const MatchDetailVideoPlayVideoModal: FunctionComponent<MatchDetailVideoPlayVideoModalProps> = ({
  newMatch,
}) => {
  const { t } = useTranslation();

  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const applicationMode = useSelector(getApplicationModeState);
  const uploadPercentage = useSelector(uploadPercentageCompleted(newMatch.id));

  const handleModalVisibilityChange = () => {
    setModalVisible(!isModalVisible);
  };

  const getContext = (): Context => {
    const videoUrlPresent = newMatch.videoUrl.length > 0;
    const videoAdded = videoUrlPresent || newMatch.videoLink;
    const isUploading = newMatch.videoStatus === VideoStatus.uploading;

    if (isUploading) {
      return {
        className:
          'MatchDetailVideoContent__fileInputButton MatchDetailVideoContent__fileInputButton--gray',
        label: `${uploadPercentage}% - ${t('videoUploading')}`,
        loader: true,
        playTheMatch: false,
      };
    }

    if (videoAdded) {
      return {
        className:
          'MatchDetailVideoContent__fileInputButton MatchDetailVideoContent__fileInputButton--green',
        label: t('added'),
        loader: false,
        playTheMatch: videoUrlPresent,
      };
    }

    return {
      className: 'MatchDetailVideoContent__fileInputButton',
      label: `+ ${t('addVideo')}`,
      loader: false,
      playTheMatch: false,
    };
  };

  const context = getContext();
  useModalKeyboardShortcuts(
    isModalVisible,
    handleModalVisibilityChange,
    handleModalVisibilityChange
  );
  return (
    <div className="MatchDetailVideoContent__fileInputWrapper MatchDetailVideoContent__videoSetupPageButtonRow">
      <div className="MatchDetailVideoContent__containerHeading ">{t('originalVideo')}</div>
      {context.playTheMatch && (
        <MatchButton
          onClick={handleModalVisibilityChange}
          label={t('playTheMatch')}
          type="secondary"
          iconRotation="0deg"
          theme={applicationMode}
        />
      )}
      <Modal
        isVisible={isModalVisible}
        width="unset"
        height="unset"
        handleClose={handleModalVisibilityChange}
      >
        <div className="MatchDetailVideoContent__playVideoModalHeader">{t('playTheMatch')}</div>
        <div className="MatchDetailVideoContent__playVideoWrapperModal">
          {<VideoPlayer videoSrc={setProxy(newMatch.videoUrl)} />}
        </div>
        <div className="MatchDetailVideoContent__centered">
          <Button label={t('back')} type="secondary" onClick={handleModalVisibilityChange} />
        </div>
      </Modal>
    </div>
  );
};
