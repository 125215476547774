import { AnyAction } from 'redux';
import {
  SET_MATCH_DETAIL_ASYNC_REQUESTING,
  SET_MATCH_DETAIL_ERROR,
  SET_MATCH_DETAIL_REQUESTING,
  SET_MATCH_DETAIL_SUCCESS,
} from '../constants';
import { MatchDetailState, Status } from '../types';
import { sortMatchDetailClips } from '../utils';

const initMatchDetailState = { status: Status.idle, errorMsg: null, matchDetail: null };

/**
 * Match detail reducer
 *
 * @params state State of matches
 */
export const matchDetailReducer = (
  state: MatchDetailState = initMatchDetailState,
  action: AnyAction
): MatchDetailState => {
  switch (action.type) {
    case SET_MATCH_DETAIL_REQUESTING: {
      return { status: Status.requesting, errorMsg: null, matchDetail: null };
    }
    case SET_MATCH_DETAIL_ASYNC_REQUESTING: {
      return { status: Status.asyncRequesting, errorMsg: null, matchDetail: state.matchDetail };
    }
    case SET_MATCH_DETAIL_ERROR: {
      const { errorMsg } = action.payload;

      return { status: Status.error, errorMsg, matchDetail: null };
    }
    case SET_MATCH_DETAIL_SUCCESS: {
      const { matchDetail } = action.payload;

      return {
        status: Status.success,
        errorMsg: null,
        matchDetail: sortMatchDetailClips(matchDetail),
      };
    }
    default:
      return state;
  }
};
