import { RootState } from '../types';
import { Job } from '../types/job';

export const getJobs = (state: RootState): Job[] => state.job.jobs;

export const uploadPercentageCompleted = (matchId: number) => (state: RootState) => {
  const wantedJob = state.job.jobs.find((job: Job) => job.metadata.matchId === matchId);

  if (!wantedJob) return null;

  return wantedJob.metadata.percentUploaded;
};
