import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCompetitions,
  setMatchDetail,
  setNewMatchDetail,
  setRefereeTeamTypes,
} from '../actions';
import { getMatchDetail, getSettings, getUser } from '../selectors';
import useIsNewMatch from './useIsNewMatch';
import { useQuery } from './useQuery';
import { getDefaultNewMatch } from '../constants';

/**
 * use new match
 */
export default () => {
  const isNew = useIsNewMatch();
  const match = useSelector(getMatchDetail);
  const [newMatch, setNewMatch] = useState(getDefaultNewMatch());
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const settings = useSelector(getSettings);
  const matchId = parseInt(useQuery().get('id') || '0', 0);

  useEffect(() => {
    if (match) {
      setNewMatch(match);
    }
  }, [match]);

  useEffect(() => {
    if (isNew && matchId === 0) {
      dispatch(setNewMatchDetail());
    } else {
      dispatch(setMatchDetail(matchId));
    }

    if (user) {
      dispatch(setCompetitions(user.id));
      dispatch(setRefereeTeamTypes(settings?.sports.id || 0));
    }
  }, [dispatch, matchId, isNew, user, settings]);

  return { newMatch, setNewMatch };
};
