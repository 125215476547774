import React, { FunctionComponent, MouseEventHandler, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Tab } from '../../types';
import { Icon, IconType } from '../Icon';
import { classHandler, iconTypeHandler } from '../../utils';
import { getApplicationModeState } from '../../selectors';
import { setFilterPanelVisibility, setSidePanelVisibility } from '../../actions';

import './MobileTitle.scss';

export type IconProps = {
  name?: string;
  onClick: MouseEventHandler;
  visible: boolean;
  type?: IconType;
  icon?: ReactNode;
};

type MobileTitleProps = {
  title?: string;
  withFilters?: boolean;
  withIcons?: IconProps[];
  tabs?: Tab[];
  activeTab?: Tab;
  setActiveTab?: any;
  backFunction?: boolean;
  withPrevTab?: boolean;
  goBackTab?: React.MouseEventHandler<HTMLDivElement>;
  isNewMatch?: boolean;
  activeFilters?: number;
  renderPopper?: any;
};

export const MobileTitle: FunctionComponent<MobileTitleProps> = ({
  title,
  withFilters,
  withIcons,
  tabs,
  activeTab,
  setActiveTab,
  backFunction,
  withPrevTab,
  goBackTab,
  isNewMatch,
  activeFilters,
  renderPopper,
}) => {
  const dispatch = useDispatch();

  const applicationMode = useSelector(getApplicationModeState);
  const history = useHistory();

  const setFilterPanelVisible = () => dispatch(setFilterPanelVisibility(true));
  const setSidePanelVisible = () => dispatch(setSidePanelVisibility(true));
  const isActiveFilters = activeFilters !== undefined && activeFilters > 0;

  const renderTabs = (tabs || []).map((tab: Tab, index: number) => {
    const activateTab = () => {
      setActiveTab(tab);
    };

    const isActive = activeTab?.id === tab.id;
    const isLast = (tabs as Tab[]).length - index === 1;

    if (isNewMatch) {
      return (
        <div
          onClick={activateTab}
          key={tab.id}
          className={classHandler('MobileTitle__tab', applicationMode, isActive)}
          style={{ width: `${100 / (tabs?.length || 0)}%`, minWidth: '80px' }}
        >
          <div className="MobileTitle__arrowItem">
            <span />
            <span
              style={{
                padding: '0 10px',
                overflow: 'hidden',
              }}
            >
              {tab.name}
            </span>
            <span className="MobileTitle__arrow">
              {!isLast && (
                <Icon
                  size="16px"
                  name="arrowForward"
                  type={iconTypeHandler(applicationMode, IconType.white, IconType.red, isActive)}
                />
              )}
            </span>
          </div>
        </div>
      );
    }

    return (
      <div
        onClick={activateTab}
        key={tab.id}
        className={classHandler('MobileTitle__tab', applicationMode, isActive)}
        style={{ width: `${100 / (tabs?.length || 0)}%`, minWidth: '80px' }}
      >
        {tab.name}
      </div>
    );
  });

  const goBack = () => history.goBack();

  return (
    <div className={classHandler('MobileTitle', applicationMode)}>
      <div className="MobileTitle__topSegment">
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignContent: 'center',
            flex: '1',
          }}
        >
          {backFunction ? (
            <div className="MobileTitle__backButton" onClick={withPrevTab ? goBackTab : goBack}>
              <Icon
                size="20px"
                name="arrowBack"
                type={iconTypeHandler(applicationMode, IconType.white)}
              />
            </div>
          ) : (
            <div onClick={setSidePanelVisible} className="MobileTitle__sidePanelButton">
              <Icon
                size="14px"
                name="menu"
                type={iconTypeHandler(applicationMode, IconType.white)}
                style={{ marginRight: '34px' }}
              />
            </div>
          )}
        </div>
        {title && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              textAlign: 'center',
            }}
          >
            <h3>{title}</h3>
          </div>
        )}
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'center', flex: '1' }}
        >
          {withFilters && (
            <div onClick={setFilterPanelVisible} className="MobileTitle__filterPanelButton">
              <Icon
                size="14px"
                name="filter"
                type={iconTypeHandler(applicationMode, IconType.white)}
              />
              {isActiveFilters && <div className="MobileTitle__filterBadge">{activeFilters}</div>}
            </div>
          )}
          {withIcons && withIcons.length > 0 && (
            <div className="MobileTitle__actionButtons">
              {withIcons
                .filter(withIcon => withIcon.visible)
                .map((withIcon, key) => (
                  <div
                    onClick={withIcon.onClick}
                    className="Title__actionButton MobileTitle__actionButton"
                    key={key}
                  >
                    {withIcon.name && (
                      <Icon
                        type={
                          withIcon.type
                            ? withIcon.type
                            : iconTypeHandler(applicationMode, IconType.white)
                        }
                        name={withIcon.name}
                        size="20px"
                      />
                    )}
                    {withIcon.icon && withIcon.icon}
                  </div>
                ))}
            </div>
          )}
          {renderPopper && renderPopper()}
        </div>
      </div>
      {(tabs?.length || 0) > 0 && <div className="MobileTitle__tabs">{renderTabs}</div>}
    </div>
  );
};
