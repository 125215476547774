import { AnyAction } from 'redux';

import { AccountingDataState, Status } from '../types';
import {
  SET_ACCOUNTING_DATA_ERROR,
  SET_ACCOUNTING_DATA_REQUESTING,
  SET_ACCOUNTING_DATA_SUCCESS,
} from '../constants';

const initAccountingDataState: AccountingDataState = {
  status: Status.idle,
  errorMsg: null,
  accountingData: {
    taxable: [],
    nonTaxable: [],
    total: [],
    competitions: [],
  },
};

export const accountingDataReducer = (
  state: AccountingDataState = initAccountingDataState,
  action: AnyAction
): AccountingDataState => {
  switch (action.type) {
    case SET_ACCOUNTING_DATA_REQUESTING: {
      return {
        status: Status.requesting,
        errorMsg: null,
        accountingData: {
          taxable: [],
          nonTaxable: [],
          total: [],
          competitions: [],
        },
      };
    }
    case SET_ACCOUNTING_DATA_ERROR: {
      const { errorMsg } = action.payload;

      return {
        status: Status.error,
        errorMsg,
        accountingData: {
          taxable: [],
          nonTaxable: [],
          total: [],
          competitions: [],
        },
      };
    }
    case SET_ACCOUNTING_DATA_SUCCESS: {
      const { accountingData } = action.payload;
      return { status: Status.success, errorMsg: null, accountingData };
    }
    default:
      return state;
  }
};
