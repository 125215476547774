import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';

import { getApplicationModeState } from '../../selectors';
import { MatchDetail } from '../../types';
import { InputWrapper } from '../InputWrapper';
import { classHandler } from '../../utils';

import './MatchDetailNotesContent.scss';
import { Note } from '../../types/notes';

type MatchDetailNotesContentProps = {
  newMatch: MatchDetail | null;
  handleNewMatch: React.Dispatch<React.SetStateAction<MatchDetail>>; // TODO-refactor: nahradit raději dispatchem v komponentě
};

export const MatchDetailNotesContent: FunctionComponent<MatchDetailNotesContentProps> = ({
  newMatch,
  handleNewMatch,
}) => {
  const { t } = useTranslation();
  const applicationMode = useSelector(getApplicationModeState);

  if (!newMatch) return null;

  const addNote = () => {
    const newNotes = [...newMatch.notes];

    const newId = Math.max(0, ...newNotes.map(note => note.id)) + 1;

    newNotes.push({ id: newId, text: '' });

    handleNewMatch({ ...newMatch, notes: newNotes });
  };

  const renderNotes = newMatch.notes.map((note: Note) => {
    const removeNote = () => {
      handleNewMatch({
        ...newMatch,
        notes: newMatch.notes.filter((_note: Note) => _note.id !== note.id),
      });
    };

    const handleNoteChange = (event: any) => {
      const value = event.target.value;

      const newNotes = newMatch.notes.map((_note: Note) => {
        if (_note.id === note.id) {
          return { ..._note, text: value };
        }

        return _note;
      });

      handleNewMatch({ ...newMatch, notes: newNotes });
    };

    return (
      <InputWrapper key={note.id} iconSize={'0'} withDelete={true} handleDelete={removeNote}>
        <TextField
          className="MatchDetailInfoContent__textFiled"
          value={note.text}
          onChange={handleNoteChange}
          autoComplete="off"
        />
      </InputWrapper>
    );
  });

  return (
    <div className={classHandler('MatchDetailNotesContent', applicationMode)}>
      <div className="MatchDetailNotesContent__container">
        <div className="MatchDetailNotesContent__containerHeading">{t('notesHeading')}</div>
        <div className="MatchDetailNotesContent__centeredContainer">
          {renderNotes}
          <div
            onClick={addNote}
            className="MatchDetailNotesContent__addButton MatchDetailNotesContent__addButton--dynamic-width"
          >
            {t('addNotes')}
          </div>
        </div>
      </div>
    </div>
  );
};
