import { useEffect, useState } from 'react';

const getOnlineStatus = (): boolean => {
  return typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;
};

// const refreshPage = () => {
//   window.location.reload();
// };

const useOnlineStatus = (): boolean => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(getOnlineStatus());

  const goOnline = () => {
    setOnlineStatus(true);
    // refreshPage();
  };

  const goOffline = () => {
    setOnlineStatus(false);
  };

  useEffect(() => {
    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  return onlineStatus;
};

export default useOnlineStatus;
