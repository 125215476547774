import React, { Fragment, FunctionComponent, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setFavouriteClips, setMoreFavouriteClips } from '../../actions/clipsActions';
import { getClipsStatus, getUser, getFavouriteClipGroups, getClipFilters } from '../../selectors';
import {
  Status,
  ClipType,
  FavouriteClipGroup,
  VideoTabLocationState,
  SetClipsType,
} from '../../types';
import { ContentLoader } from '../ContentLoader';
import { NoVideos, NoVideosType } from '../NoVideos';
import { VideoSwimLine } from '../VideoSwimLine';
import './VideoListFavouriteTab.scss';

export const FAVOURITE_TAB_ID = 2;

type VideoListFavouriteTabProps = {
  redirectToVideoDetail: (path: string, verticalScroll: number, horizontalScroll: number) => void;
  redirectToMatchDetail: (path: string, verticalScroll: number) => void;
};

export const VideoListFavouriteTab: FunctionComponent<VideoListFavouriteTabProps> = ({
  redirectToVideoDetail,
  redirectToMatchDetail,
}) => {
  const dispatch = useDispatch();
  const location = useLocation<VideoTabLocationState>();

  const clipsStatus = useSelector(getClipsStatus);
  const user = useSelector(getUser);
  const favouriteClipGroups = useSelector(getFavouriteClipGroups);
  const clipFilters = useSelector(getClipFilters);

  const clipLineRefs = Array.from({
    length: favouriteClipGroups.length,
  }).map(() => React.createRef<HTMLDivElement>());

  const ownerId = user?.id;

  const clipsCount = favouriteClipGroups
    .map(clipGroup => clipGroup.count)
    .reduce((prev, cur) => prev + cur, 0);

  const getInitialClipGroupsLimit = useCallback(() => {
    if (location.state?.horizontalScroll !== undefined && location.state?.horizontalScroll > 6) {
      return location.state.horizontalScroll + 5;
    }
    return 10;
  }, [location.state]);

  useEffect(() => {
    const start = 0;
    const limit = getInitialClipGroupsLimit();
    dispatch(setFavouriteClips(start, limit, clipFilters, ownerId));
  }, [dispatch, getInitialClipGroupsLimit, clipFilters, ownerId]);

  const renderVideoSwimLines = favouriteClipGroups.map(
    (clipGroup: FavouriteClipGroup, index: number) => {
      const _redirectToVideoDetail = (path: string, horizontalScroll: number) => {
        const verticalScroll = index;
        redirectToVideoDetail(path, verticalScroll, horizontalScroll);
      };

      const _redirectToMatchDetail = (path: string) => {
        const verticalScroll = index;
        redirectToMatchDetail(path, verticalScroll);
      };

      const loadMoreClips = () => {
        const type = index === 0 ? SetClipsType.myFavourites : SetClipsType.otherFavourites;
        const start = clipGroup.clips.length;
        const totalCount = clipGroup.count;
        if (start < totalCount && clipGroup.status !== Status.requestingMore)
          dispatch(setMoreFavouriteClips(start, type, clipFilters, ownerId));
      };

      const clipLineIndex = location.state?.verticalScroll;
      const shouldScroll =
        location.state?.videoTab === FAVOURITE_TAB_ID &&
        clipLineIndex === index &&
        !!clipLineRefs[clipLineIndex];

      return (
        <VideoSwimLine
          key={index}
          clipGroup={clipGroup}
          clipType={ClipType.favourite}
          redirectToMatchDetail={_redirectToMatchDetail}
          redirectToVideoDetail={_redirectToVideoDetail}
          loadMore={loadMoreClips}
          clipLineRef={clipLineRefs[index]}
          shouldScroll={shouldScroll}
          seePrivateNote={index === 0}
        />
      );
    }
  );

  return (
    <Fragment>
      {clipsStatus === Status.success ? (
        <Fragment>
          <div className="VideoListPage__swimLines">
            {clipsCount > 0 && renderVideoSwimLines}
            {clipsCount === 0 && <NoVideos type={NoVideosType.favourite} />}
          </div>
        </Fragment>
      ) : (
        <ContentLoader />
      )}
    </Fragment>
  );
};
