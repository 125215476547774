import { WebSocketEventType, WebSocketMessage } from './types/webSocketProvider';

export default class WebSocketProvider {
  private static socket: WebSocket;
  private static jwt: string;
  private static waitingForArchives: Set<number> = new Set<number>();
  private constructor() {}
  public static init(userId: number, jwt: string): void {
    if (this.socket == null || this.socket.readyState === WebSocket.CLOSED) {
      this.socket = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET_URI}/${userId}` || '');
      this.setupWebsocket();
      this.jwt = jwt;
    }
  }

  public static close(): void {
    if (!this.socket) {
      return;
    }

    this.socket.close();
  }

  public static registerOnArchiveCreationActionOnce(
    matchId: number,
    onready: () => any,
    onerror: () => any
  ): void {
    matchId = +matchId; // matchId may be string sometimes
    if (!this.socket || this.waitingForArchives.has(matchId)) {
      return;
    }

    this.waitingForArchives.add(matchId);
    const cb = (e: MessageEvent) => {
      try {
        const { data, event, error } = JSON.parse(e.data) as WebSocketMessage;
        if (error) {
          onerror();
          return;
        }

        switch (event) {
          case WebSocketEventType.createMissingClips: {
            if (+data.matchId === matchId && this.waitingForArchives.has(matchId)) {
              onready();
            }
            break;
          }
        }
      } catch (e) {
        onerror();
      } finally {
        this.socket.removeEventListener('message', cb);
        this.waitingForArchives.delete(matchId);
      }
    };
    this.socket.addEventListener('message', cb);
  }

  private static setupWebsocket(): void {
    if (!this.socket) {
      return;
    }

    this.socket.addEventListener('close', _ => {
      this.waitingForArchives.clear();
    });

    // this.socket.addEventListener('error', e => {
    //   console.log('socket error', e);
    // });

    this.socket.addEventListener('open', () => {
      const message: WebSocketMessage = {
        event: WebSocketEventType.authorization,
        data: {
          jwt: this.jwt,
        },
        error: false,
      };
      this.socket.send(JSON.stringify(message));
    });
  }
}
