import React, { FunctionComponent, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ProfilePicture } from '../ProfilePicture';
import { getApplicationModeState } from '../../selectors';
import { classHandler, useOpenBrowserHandler } from '../../utils';
import { getUser, getIsPremium } from '../../selectors/user';

import './ProfileSegment.scss';
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import { setInfoModalText, setInfoModalVisibility } from '../../actions';

export const ProfileSegment: FunctionComponent<{}> = () => {
  const applicationMode = useSelector(getApplicationModeState);
  const user = useSelector(getUser);
  const isPremium = useSelector(getIsPremium);
  const { t } = useTranslation();

  // different behavior for iOS
  const iOS = (window as any).webkit;
  const iOSOpenBrowserHandler = useOpenBrowserHandler;
  const dispatch = useDispatch();

  const handleLinkOpen = () => {
    if (iOS)
      iOSOpenBrowserHandler('https://www.refereerecorder.com/my-account', (result: any) => {
        if (result.status === 1) {
          dispatch(setInfoModalText(result.error));
          dispatch(setInfoModalVisibility(true));
        }
      });
  };

  const userInfo = (
    <Fragment>
      {!iOS && (
        <a
          href="https://www.refereerecorder.com/my-account"
          target="_blank"
          rel="noopener noreferrer"
          className="ProfileSegment__link"
        >
          <ProfilePicture />
          <div className="ProfileSegment__name">
            {user?.firstName} {user?.lastName}
          </div>
          {isPremium && (
            <div className="ProfileSegment__stats ProfileSegment__stats__highlight">
              {t('premium')}
            </div>
          )}
          <div className="ProfileSegment__stats">
            {user?.matchesCount} {t('matchesCount')}
          </div>
        </a>
      )}
      {iOS && (
        <span onClick={handleLinkOpen} className="ProfileSegment__link">
          <ProfilePicture />
          <div className="ProfileSegment__name">
            {user?.firstName} {user?.lastName}
          </div>
          {isPremium && (
            <div className="ProfileSegment__stats ProfileSegment__stats__highlight">
              {t('premium')}
            </div>
          )}
          <div className="ProfileSegment__stats">
            {user?.matchesCount} {t('matchesCount')}
          </div>
        </span>
      )}
    </Fragment>
  );

  const guestInfo = (
    <Fragment>
      <div className="ProfileSegment__loginWrapper">
        <Link to={'/login'}>
          <Button type="secondary" label={`${t('login')}`} />
        </Link>
      </div>
    </Fragment>
  );

  return (
    <div className={classHandler('ProfileSegment', applicationMode)}>
      {user ? userInfo : guestInfo}
    </div>
  );
};
