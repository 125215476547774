import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotesByMatches as getNotesByMatchesAction } from '../../actions';
import { getNotesStatus, getNotesByMatches, getApplicationModeState } from '../../selectors';
import { Status } from '../../types';
import { MatchWithNotes, Note } from '../../types/notes';
import { classHandler, joinWithDot, printTeams } from '../../utils';
import { ContentLoader } from '../ContentLoader';
import { NoNotes } from '../NoNotes';
import { Icon, IconType } from '../../components/Icon';
import { useTranslation } from 'react-i18next';

import './NotesByMatchesTab.scss';
import { useHistory } from 'react-router-dom';

const NoteOfMatch: FunctionComponent<Note> = props => (
  <div className="NotesByMatchesTab__note">{props.text}</div>
);

const NotesByMatch: FunctionComponent<MatchWithNotes> = props => {
  const history = useHistory();
  const { t } = useTranslation();

  const teams = printTeams(props.teams);
  const { date, gender, competition, category, notes } = props;
  const fullDate = `${date.getDate()}. ${date.getMonth() + 1}. ${date.getFullYear()}`;

  const goToMatchDetail = () => {
    history.push(`/match-detail?id=${props.matchId}`);
  };

  return (
    <div className="NotesByMatchesTab__card">
      <div className="NotesByMatchesTab__cardTitle" onClick={goToMatchDetail}>
        <span>{joinWithDot(fullDate, t(gender), competition, category, teams)}</span>
        <Icon size="18px" name="arrowForward" type={IconType.red} />
      </div>
      <div className="NotesByMatchesTab__notes">
        {notes.map((note: Note) => (
          <NoteOfMatch key={note.id} {...note} />
        ))}
      </div>
    </div>
  );
};

export const NotesByMatchesTab: FunctionComponent<{}> = () => {
  const dispatch = useDispatch();

  const applicationMode = useSelector(getApplicationModeState);
  const notesByMatches = useSelector(getNotesByMatches);
  const notesStatus = useSelector(getNotesStatus);

  useEffect(() => {
    dispatch(getNotesByMatchesAction());
  }, [dispatch]);

  notesByMatches.sort((a, b) => {
    if (a.date < b.date) return 1;
    if (a.date > b.date) return -1;

    return 0;
  });

  const notesCount = notesByMatches
    .map(item => item.notes.length)
    .reduce((acc, current) => acc + current, 0);

  return (
    <div className={classHandler('NotesByMatchesTab', applicationMode)}>
      <div className="NotesByMatchesTab__container">
        <div className="NotesByMatchesTab__content">
          {notesStatus === Status.success ? (
            <Fragment>
              {notesCount > 0 &&
                notesByMatches
                  .filter((notesByMatch: MatchWithNotes) => notesByMatch.notes.length)
                  .map((notesByMatch: MatchWithNotes) => (
                    <NotesByMatch key={notesByMatch.matchId} {...notesByMatch} />
                  ))}
              {notesCount === 0 && <NoNotes />}
            </Fragment>
          ) : (
            <ContentLoader />
          )}
        </div>
      </div>
    </div>
  );
};
