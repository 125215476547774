import { CREATE_JOB, FINISH_JOB, UPDATE_JOB } from '../constants';
import { Job } from '../types/job';

export const createJob = (job: Job) => ({
  type: CREATE_JOB,
  payload: { job },
});

export const updateJob = (job: Job) => ({
  type: UPDATE_JOB,
  payload: { job },
});

export const finishJob = (job: Job) => ({
  type: FINISH_JOB,
  payload: { job },
});
