import { POST_PREMIUM_RECEIPT_URL } from './routes';

import { getJwtFromLocalStorage, request } from '../utils';

export const postReceipt = async (receiptData: string): Promise<any> => {
  if (receiptData) {
    const response = await request(POST_PREMIUM_RECEIPT_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${getJwtFromLocalStorage()}`,
      },
      body: JSON.stringify({
        receiptData,
      }),
    });

    return response.json();
  }
};
