import { ApplicationBreakpoint, ApplicationMode, ApplicationState, RootState } from '../types';

/**
 * Application State Selector
 *
 * @params state State of application
 */
export const getApplicationState = (state: RootState): ApplicationState => state.application;

/**
 * Application Mode State Selector
 *
 * @params state State of application
 */
export const getApplicationModeState = (state: RootState): ApplicationMode =>
  state.application.applicationMode;

/**
 * Application Breakpoint State Selector
 *
 * @params state State of application
 */
export const getApplicationBreakpointState = (state: RootState): ApplicationBreakpoint =>
  state.application.applicationBreakpoint;

/**
 * Filter panel visibility Selector
 *
 * @params state State of application
 */
export const getFilterPanelVisibility = (state: RootState): boolean =>
  state.application.filterPanelVisibility;

/**
 * Side panel visibility Selector
 *
 * @params state State of application
 */
export const getSidePanelVisibility = (state: RootState): boolean =>
  state.application.sidePanelVisibility;

/**
 * Premium modal visibility Selector
 *
 * @params state State of application
 */
export const getPremiumModalVisibility = (state: RootState): boolean =>
  state.application.premiumModalVisibility;

/**
 * Premium modal text Selector
 *
 * @params state State of application
 */
export const getPremiumModalContentLocKey = (state: RootState): string | null =>
  state.application.premiumModalContentLocKey;

/**
 * Login required modal visibility Selector
 *
 * @params state State of application
 */
export const getLoginRequiredModalVisibility = (state: RootState): boolean =>
  state.application.loginRequiredModalVisibility;

/**
 * Application currency
 *
 * @params state State of application
 */
export const getApplicationCurrency = (state: RootState): string => state.application.currency;

/**
 * Info modal visibility Selector
 *
 * @params state State of application
 */
export const getInfoModalVisibility = (state: RootState): boolean =>
  state.application.infoModalVisibility;

/**
 * Info modal text Selector
 *
 * @params state State of application
 */
export const getInfoModalText = (state: RootState): string => state.application.infoModalText;
