import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertModal } from '../../components/AlertModal';
import { MatchDetailInfoContent } from '../../components/MatchDetailInfoContent';
import MatchDetailOthersTab from '../../components/MatchDetailOthersTab/MatchDetailOthersTab';
import MatchDetailSituationsContent from '../../components/MatchDetailSituationsContent/MatchDetailSituationsContent';
import { MatchDetailVideoContent } from '../../components/MatchDetailVideoContent';
import { MatchDetail, SaveButtonState, TimeStamp } from '../../types';

import useSyncMap from '../../utils/useSyncMap';
import useValueChanged from '../../utils/useValueChanged';

interface MatchDetailRoutesProps {
  tabName: string;
  handleSave: any;
  newMatch: MatchDetail;
  setNewMatch: React.Dispatch<React.SetStateAction<MatchDetail>>;
  setSaving: React.Dispatch<React.SetStateAction<SaveButtonState>>;
}

const MatchDetailRoutes: React.FC<MatchDetailRoutesProps> = ({
  tabName,
  handleSave,
  newMatch,
  setNewMatch,
  setSaving,
}) => {
  const { t } = useTranslation();
  const [init, setInit] = useState(false);

  const [syncEntry, setSyncEntry] = useState<number>(newMatch?.syncTime || 0);
  const [previousSyncEntry, setPreviousSyncEntry] = useState<number>(syncEntry);

  const hasSyncChanged = useValueChanged(syncEntry);

  const { syncMap, setSyncMap } = useSyncMap(newMatch);

  const [addSrcModalvisible, setAddSrcModalVisible] = useState(false);

  useEffect(() => {
    if (init && hasSyncChanged) {
      const changedBySyncTimeStamps: TimeStamp[] =
        newMatch?.clips.map((timeStamp: TimeStamp) => {
          const initTime = timeStamp.time;
          const changedTime = +syncEntry - +previousSyncEntry;
          const time = initTime + changedTime;

          return {
            ...timeStamp,
            time: time >= 0 ? time : 0,
          };
        }) || [];

      if (!newMatch?.id) {
        return;
      }

      setNewMatch({
        ...newMatch,
        syncTime: syncEntry,
        clips: changedBySyncTimeStamps,
      });
    }
    setInit(true);
  }, [newMatch, setNewMatch, init, hasSyncChanged, syncEntry, previousSyncEntry]);

  const renderContent = useCallback(() => {
    switch (tabName) {
      case t('info'): {
        return <MatchDetailInfoContent newMatch={newMatch} handleNewMatch={setNewMatch} />;
      }
      case t('tabSituations'): {
        return (
          <MatchDetailSituationsContent
            setSaving={setSaving}
            newMatch={newMatch}
            handleNewMatch={setNewMatch}
            syncEntry={syncEntry}
            setSyncEntry={setSyncEntry}
            previousSyncEntry={previousSyncEntry}
            setPreviousSyncEntry={setPreviousSyncEntry}
            setAddSrcModalVisible={setAddSrcModalVisible}
            handleSave={handleSave}
            syncMap={syncMap}
            setSyncMap={setSyncMap}
          />
        );
      }
      case t('video'): {
        return (
          <MatchDetailVideoContent
            newMatch={newMatch}
            handleNewMatch={setNewMatch}
            syncEntry={syncEntry}
            setSyncEntry={setSyncEntry}
            setPreviousSyncEntry={setPreviousSyncEntry}
            previousSyncEntry={previousSyncEntry}
            setAddSrcModalVisible={setAddSrcModalVisible}
          />
        );
      }
      case t('tabOther'): {
        return <MatchDetailOthersTab newMatch={newMatch} handleNewMatch={setNewMatch} />;
      }

      default: {
        return null;
      }
    }
  }, [
    tabName,
    handleSave,
    newMatch,
    previousSyncEntry,
    setNewMatch,
    setSyncMap,
    syncEntry,
    syncMap,
    t,
    setSaving,
  ]);

  return (
    <>
      <AlertModal
        isVisible={addSrcModalvisible}
        handleClose={() => setAddSrcModalVisible(false)}
        text={t('AddSourceVideoFirst')}
        confirmBtn
        cancelBtn={false}
      />
      {renderContent()}
    </>
  );
};

export default MatchDetailRoutes;
