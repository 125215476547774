import { AnyAction } from 'redux';

import { StatisticsDataState, Status } from '../types';
import {
  SET_STATISTICS_DATA_ERROR,
  SET_STATISTICS_DATA_REQUESTING,
  SET_STATISTICS_DATA_SUCCESS,
} from '../constants';

const initStatisticsDataState: StatisticsDataState = {
  status: Status.idle,
  errorMsg: null,
  statisticsData: {
    matches: [],
    matchesInCompetitions: [],
    // competitionsWithCategories: [];
    // coligas: [];
    colleagues: [],
    rolesInTwoPersons: [],
    rolesInThreePersons: [],
    fromNotes: [],
    numOfTechnicalFouls: [],
    numOfUnsportsmenlikeFouls: [],
    countriesContest: [],
    others: [],
  },
};

export const statisticsDataReducer = (
  state: StatisticsDataState = initStatisticsDataState,
  action: AnyAction
): StatisticsDataState => {
  switch (action.type) {
    case SET_STATISTICS_DATA_REQUESTING: {
      return {
        status: Status.requesting,
        errorMsg: null,
        statisticsData: {
          matches: [],
          matchesInCompetitions: [],
          // competitionsWithCategories: [];
          // coligas: [];
          colleagues: [],
          rolesInTwoPersons: [],
          rolesInThreePersons: [],
          fromNotes: [],
          numOfTechnicalFouls: [],
          numOfUnsportsmenlikeFouls: [],
          countriesContest: [],
          others: [],
        },
      };
    }
    case SET_STATISTICS_DATA_ERROR: {
      const { errorMsg } = action.payload;

      return {
        status: Status.error,
        errorMsg,
        statisticsData: {
          matches: [],
          matchesInCompetitions: [],
          // competitionsWithCategories: [];
          // coligas: [];
          colleagues: [],
          rolesInTwoPersons: [],
          rolesInThreePersons: [],
          fromNotes: [],
          numOfTechnicalFouls: [],
          numOfUnsportsmenlikeFouls: [],
          countriesContest: [],
          others: [],
        },
      };
    }
    case SET_STATISTICS_DATA_SUCCESS: {
      const { statisticsData } = action.payload;
      return { status: Status.success, errorMsg: null, statisticsData };
    }
    default:
      return state;
  }
};
