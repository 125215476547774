import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { classHandler, getLocaleInfo, iconTypeHandler } from '../../utils';
import {
  getApplicationModeState,
  getSettings,
  getApplicationBreakpointState,
  getIsPremium,
  getAccountingFilter,
  getAccountings,
} from '../../selectors';
import {
  setAccountingData,
  setAccountingFilter,
  setFilterPanelVisibility,
  setPremiumModalVisibility,
} from '../../actions';
import './AccountingFilterPanel.scss';
import { useTranslation } from 'react-i18next';
import LockIcon from '@material-ui/icons/Lock';
import { Icon, IconType } from '../Icon';
import { SaveButtonsWrapper } from '../SaveButtonsWrapper';
import { ApplicationBreakpoint, AccountingFilter, Tab } from '../../types';
import { FormControlLabel, withStyles, Checkbox } from '@material-ui/core';
import { InputWrapper } from '../InputWrapper';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { initAccountingFilter } from '../../reducers/accountingReducer';

type FilterPanelProps = {
  isVisible: boolean;
};

const checkBoxStyles = () => ({
  root: {
    '&$checked': {
      fill: '#FFFFFF',
      color: '#de1919',
    },
  },
  checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const areFiltersTheSame = (a: AccountingFilter, b: AccountingFilter) => {
  return _.isEqual(a, b);
};

export const AccountingFilterPanel: FunctionComponent<FilterPanelProps> = ({ isVisible }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const settings = useSelector(getSettings);
  const isPremium = useSelector(getIsPremium);
  const { locale } = getLocaleInfo(settings);
  const actualFilter = useSelector(getAccountingFilter);
  const [filter, setFilter] = useState<AccountingFilter>(initAccountingFilter);
  const seasons = useSelector(getAccountings);
  const tabs: Tab[] = [];
  const isDesktop = applicationBreakpoint === ApplicationBreakpoint.desktop;

  seasons.sort((a, b) => {
    let yearA = a.season.length > 4 ? Number(a.season.substring(0, 4)) : Number(a.season);
    let yearB = b.season.length > 4 ? Number(b.season.substring(0, 4)) : Number(b.season);
    yearA = isNaN(yearA) ? -1 : yearA;
    yearB = isNaN(yearB) ? -1 : yearB;

    return yearB - yearA;
  });

  seasons.forEach((s, i) => {
    if (s.season === 'All seasons' && seasons.length <= 2) return;

    tabs.push({
      id: i,
      name: t(s.season),
    });
  });

  const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);
  if (!activeTab && tabs[0]) setActiveTab(tabs[0]);

  const handleChange = (name: string, value: any) => {
    setFilter({ ...filter, [name]: value });
  };

  const handleTypeChange = (e: any) => {
    let value: string = '';

    if (!e.target.checked) {
      value = filter.type.replace(`${e.target.value},`, '');
    } else {
      value = filter.type.concat(e.target.value, ',');
    }

    setFilter({ ...filter, type: value });
  };

  const setInvisible = () => dispatch(setFilterPanelVisibility(false));

  const openPremiumModal = () => dispatch(setPremiumModalVisibility(true));

  const resetFilter = () => {
    setInvisible();
    setFilter(initAccountingFilter);
    dispatch(setAccountingFilter(initAccountingFilter));
    dispatch(setAccountingData(seasons[activeTab.id].link, initAccountingFilter));
  };

  const applyFilter = () => {
    setInvisible();
    dispatch(setAccountingFilter(filter));
    dispatch(setAccountingData(seasons[activeTab.id].link, filter));
  };

  return (
    <div className={classHandler('FilterPanel', applicationMode, false, isVisible)}>
      <div className="FilterPanel__headingWrapper">
        <div className="FilterPanel__heading">
          {t('filter')}
          {!isPremium && (
            <div className="FilterPanel__heading__premium" onClick={openPremiumModal}>
              <LockIcon className="FilterPanel__heading__premium__icon" />
              {t('premiumFeatureAvailable')}
            </div>
          )}
        </div>

        <div className="FilterPanel__close" onClick={setInvisible}>
          <Icon type={iconTypeHandler(applicationMode, IconType.white)} name="close" size="16px" />
        </div>
      </div>
      <div className={`FilterPanel__content ${!isPremium ? 'FilterPanel__content__premium' : ''}`}>
        <div className="FilterPanel__segment">
          <div className="FilterPanel__segmentHeading">{t('date')}</div>
          <InputWrapper iconName="calendar">
            <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
              <DatePicker
                label={t('dateFrom')}
                className="MatchDetailInfoContent__datePicker"
                disableToolbar={true}
                value={filter?.dateFrom}
                onChange={value => handleChange('dateFrom', value)}
                okLabel={t('save')}
                cancelLabel={t('cancel')}
                disabled={!isPremium}
              />
            </MuiPickersUtilsProvider>
          </InputWrapper>
          <InputWrapper iconName="calendar">
            <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
              <DatePicker
                label={t('dateTo')}
                className="MatchDetailInfoContent__datePicker"
                disableToolbar={true}
                value={filter?.dateTo}
                onChange={value => handleChange('dateTo', value)}
                okLabel={t('save')}
                cancelLabel={t('cancel')}
                disabled={!isPremium}
              />
            </MuiPickersUtilsProvider>
          </InputWrapper>
          <div className="FilterPanel__segmentHeading" style={{ marginTop: '1em' }}>
            {t('receivedAs')}
          </div>
          <div className="checkboxWrapper">
            <FormControlLabel
              control={
                <CustomCheckbox
                  onChange={handleTypeChange}
                  value="bankAccount"
                  checked={filter.type.includes('bankAccount')}
                  disabled={!isPremium}
                />
              }
              label={t('bankAccount')}
            />
            <FormControlLabel
              control={
                <CustomCheckbox
                  onChange={handleTypeChange}
                  value="cash"
                  checked={filter.type.includes('cash')}
                  disabled={!isPremium}
                />
              }
              label={t('cash')}
            />
          </div>
        </div>
      </div>
      <SaveButtonsWrapper
        primaryButton={{
          onClick: applyFilter,
          label: t('applyFilter'),
          disabled: areFiltersTheSame(actualFilter, filter) || !isPremium,
        }}
        secondaryButton={{
          onClick: resetFilter,
          label: t('resetFilter'),
          disabled: areFiltersTheSame(initAccountingFilter, actualFilter) || !isPremium,
        }}
        style={{
          margin: '0 1em',
          padding: isDesktop ? '20px' : '10px',
          borderTop: '1px solid #c5c5c5',
        }}
      />
    </div>
  );
};
