import React, {
  Fragment,
  FunctionComponent,
  useEffect,
  createRef,
  useCallback,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setMyClips } from '../../actions/clipsActions';
import {
  getClipsStatus,
  getMyClipGroups,
  getMyClipGroupCount,
  getClipFilters,
} from '../../selectors';
import { ClipGroup, Status, ClipType, VideoTabLocationState } from '../../types';
import { ContentLoader } from '../ContentLoader';
import { VideoSwimLine } from '../VideoSwimLine';
import './VideoListMyVideosTab.scss';
import { ClipCardLoader } from '../ClipCardLoader';
import { NoVideos } from '../NoVideos';

export const MY_VIDEOS_TAB_ID = 0;

type VideoListMyVideosTabProps = {
  redirectToVideoDetail: (path: string, verticalScroll: number, horizontalScroll: number) => void;
  redirectToMatchDetail: (path: string, verticalScroll: number) => void;
  scrollRef: HTMLDivElement | null;
};

export const VideoListMyVideosTab: FunctionComponent<VideoListMyVideosTabProps> = ({
  redirectToVideoDetail,
  redirectToMatchDetail,
  scrollRef,
}) => {
  const dispatch = useDispatch();
  const location = useLocation<VideoTabLocationState>();

  const clipsStatus = useSelector(getClipsStatus);
  const clipGroups = useSelector(getMyClipGroups);
  const totalCount = useSelector(getMyClipGroupCount);
  const clipFilters = useSelector(getClipFilters);

  const [loadingMore, setLoadingMore] = useState(false);

  const clipLineRefs = Array.from({ length: clipGroups.length }).map(() =>
    createRef<HTMLDivElement>()
  );

  const getInitialClipGroupsLimit = useCallback(() => {
    if (location.state?.verticalScroll !== undefined && location.state?.verticalScroll > 6) {
      return location.state.verticalScroll + 5;
    }
    return 10;
  }, [location.state]);

  const loadMore = useCallback(() => {
    if (clipGroups.length < totalCount) {
      const start = clipGroups.length;

      dispatch(setMyClips(start, clipFilters));
    }
  }, [clipGroups.length, totalCount, dispatch, clipFilters]);

  useEffect(() => {
    const handleScroll = (event: any) => {
      const max =
        event.target.scrollTop > event.target.scrollHeight - event.target.clientHeight - 300;
      if (max && !loadingMore) {
        setLoadingMore(true);
        loadMore();
      }
    };

    scrollRef?.addEventListener('scroll', handleScroll);

    return () => scrollRef?.removeEventListener('scroll', handleScroll);
  }, [loadMore, loadingMore, scrollRef]);

  useEffect(() => {
    setLoadingMore(clipsStatus === Status.requestingMore);
  }, [clipsStatus]);

  useEffect(() => {
    const limit = getInitialClipGroupsLimit();
    dispatch(setMyClips(0, clipFilters, limit));
  }, [dispatch, clipFilters, getInitialClipGroupsLimit]);

  const renderVideoSwimLines = clipGroups.map((clipGroup: ClipGroup, index: number) => {
    const _redirectToVideoDetail = (path: string, horizontalScroll: number) => {
      const verticalScroll = index;
      redirectToVideoDetail(path, verticalScroll, horizontalScroll);
    };

    const _redirectToMatchDetail = (path: string) => {
      const verticalScroll = index;
      redirectToMatchDetail(path, verticalScroll);
    };

    const clipIndex = location.state?.verticalScroll;
    const shouldScroll =
      location.state?.videoTab === MY_VIDEOS_TAB_ID &&
      clipIndex === index &&
      !!clipLineRefs[clipIndex];

    return (
      <VideoSwimLine
        key={index}
        clipType={ClipType.myVideo}
        clipGroup={clipGroup}
        redirectToMatchDetail={_redirectToMatchDetail}
        redirectToVideoDetail={_redirectToVideoDetail}
        clipLineRef={clipLineRefs[index]}
        shouldScroll={shouldScroll}
        seePrivateNote={true}
      />
    );
  });

  const requestingMoreLoader = (
    <div className="VideoSwimLine__wrapper">
      <div className="VideoSwimLine">
        <div className="VideoSwimLine__clips">
          <ClipCardLoader clipType={ClipType.myVideo} />
          <ClipCardLoader clipType={ClipType.myVideo} />
          <ClipCardLoader clipType={ClipType.myVideo} />
        </div>
      </div>
    </div>
  );

  return (
    <Fragment>
      {clipsStatus !== Status.requesting ? (
        <div className="VideoListPage__swimLines">
          {clipGroups.length > 0 && renderVideoSwimLines}
          {clipGroups.length === 0 && <NoVideos />}
          {clipsStatus === Status.requestingMore && requestingMoreLoader}
        </div>
      ) : (
        <ContentLoader />
      )}
    </Fragment>
  );
};
