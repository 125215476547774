import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginRequiredModalVisibility } from '../../selectors';
import { setLoginRequiredModalVisibility } from '../../actions';

export const LoginRequiredModal: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isVisible = useSelector(getLoginRequiredModalVisibility);

  const goToLogin = () => {
    closeModalHandler();
    history.push('/login');
  };
  const closeModalHandler = () => dispatch(setLoginRequiredModalVisibility(false));

  return (
    <Modal
      className="modal"
      isVisible={isVisible}
      width="600px"
      height="fit-content"
      handleClose={closeModalHandler}
    >
      <div className="MatchDetailResyncClipsModal__confirmation-modal">
        <div>{t('loginToRateClips')}</div>
      </div>
      <div className=" MatchDetailResyncClipsModal__buttons">
        <Button label={t('cancel')} onClick={closeModalHandler} type="secondary" />
        <Button
          label={t('signUp')}
          onClick={() => window.open('https://www.refereerecorder.com/my-account', '_self')}
          type="secondary"
        />
        <Button className="mx-1" label={t('login')} onClick={goToLogin} type="primary" />
      </div>
    </Modal>
  );
};
