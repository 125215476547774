import { TextField } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  setMatchDetail,
  setPremiumModalVisibility,
  showNotification,
  waitForMatchToProcess,
} from '../../actions';
import { getApplicationModeState, getIsPremium } from '../../selectors';
import { getJobs } from '../../selectors/job';
import { MatchDetail, Status, VideoStatus } from '../../types';
import { Job, JobType, VideoUploadJob } from '../../types/job';
import { iconTypeHandler, useModalKeyboardShortcuts } from '../../utils';
import { AlertModal } from '../AlertModal';
import { Button } from '../Button';
import { Icon, IconType } from '../Icon';
import { Modal } from '../Modal';
import { useValidateUrl } from '../../utils';
import './MatchDetailVideoVideoSrcModal.scss';
import { cancelUpload, postFile } from '../../api';

type MatchDetailVideoVideoSrcModalProps = {
  newMatch: MatchDetail;
  handleNewMatch: any;
};

type Context = {
  className: string;
  label: string;
  loader: boolean;
  playTheMatch: boolean;
  uploadingThis: boolean;
};

export const MatchDetailVideoVideoSrcModal: FunctionComponent<MatchDetailVideoVideoSrcModalProps> = ({
  newMatch,
  handleNewMatch,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [videoUrlCheckStatus, setVideoUrlCheckStatus] = useState<Status>(Status.idle);
  const applicationMode = useSelector(getApplicationModeState);
  const handleValidateUrl = useValidateUrl(
    _ => setVideoUrlCheckStatus(Status.success),
    () => {
      setVideoUrlCheckStatus(Status.error);
    }
  );
  const isPremium = useSelector(getIsPremium);
  const jobs = useSelector(getJobs);

  const videoFileInputRef = useRef<HTMLInputElement>(null);
  const isFile = new RegExp('^(http|https)://storage');

  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [isCancelUploadVisible, setCancelUploadVisible] = useState(false);
  const [isCancelUploadEnabled, setCancelUploadEnabled] = useState(true);

  const [videoType, setVideoType] = useState<string>('');

  const handleModalVisibilityChange = () => {
    if (videoUrlCheckStatus !== Status.idle && videoUrlCheckStatus !== Status.success) {
      handleNewMatch({ ...newMatch, videoUrl: '' });
    }

    if (newMatch.videoLink) {
      handleNewMatch({ ...newMatch, VideoStatus: VideoStatus.uploading });
      postFile(newMatch.id, newMatch.videoLink)
        .catch(() => {
          return;
        })
        .finally(() => dispatch(waitForMatchToProcess(newMatch.id)));
    }
    setModalVisible(!isModalVisible);
  };

  const openPremiumModal = () => {
    handleModalVisibilityChange();
    dispatch(setPremiumModalVisibility(true));
  };

  const triggerVideoFileInput = () => {
    videoFileInputRef?.current?.click();
  };

  const handleVideoInputChange = (e: any) => {
    const videoLink = e.target.files[0];
    e.persist();
    handleNewMatch({
      ...newMatch,
      videoUrl: '',
      videoLink,
      fileName: e.target.files[0].name,
    });
  };

  const handleVideoLink = (e: any) => {
    setVideoUrlCheckStatus(Status.requesting);
    handleValidateUrl(e.target.value);
    handleNewMatch({ ...newMatch, videoUrl: e.target.value });
  };

  const handleConfirmVisibilityChange = (type: string) => {
    setVideoType(type);
    setConfirmVisible(!isConfirmVisible);
  };

  const handleDelete = (result: boolean) => {
    handleConfirmVisibilityChange('');
    if (result) {
      videoType === 'link'
        ? handleNewMatch({ ...newMatch, videoLink: '' })
        : handleNewMatch({ ...newMatch, videoUrl: '' });
    }
  };

  const urlInputRow = (type: string) => {
    return (
      <div className="MatchDetailVideoContent__linkButtonModal" style={{ marginBottom: '10px' }}>
        <div className="MatchDetailVideoContent__fullWidth">
          <Icon
            name="link"
            size="20px"
            style={{ height: '20px' }}
            type={iconTypeHandler(applicationMode, IconType.white)}
          />
          <div style={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1 }}>
            <div style={{ flexWrap: 'nowrap' }} className={'MatchDetailVideoContent__column'}>
              <TextField
                className="MatchDetailVideoContent__input"
                label={t('pasteWebAddress')}
                value={newMatch.videoUrl}
                onChange={isPremium ? handleVideoLink : undefined}
                style={{ width: 'min(100%, 520px)' }}
                autoComplete="off"
                disabled={!isPremium}
                InputLabelProps={{ style: { whiteSpace: 'nowrap' } }}
              />

              {type === 'empty' ? (
                ''
              ) : (
                <div
                  className="Title__deleteButton"
                  // tslint:disable-next-line
                  onClick={() => handleConfirmVisibilityChange('url')}
                  style={{ padding: '0 0 0 24px', display: 'flex' }}
                >
                  <Icon
                    name="trash"
                    type={iconTypeHandler(applicationMode, IconType.white)}
                    style={{ height: '24px' }}
                    size="24px"
                  />
                </div>
              )}
            </div>
            {!isPremium && (
              <div
                className="MatchDetailVideoContent__premium"
                onClick={openPremiumModal}
                style={{ marginTop: '1em', width: 'min(100%, 200px)' }}
              >
                <LockIcon className="MatchDetailVideoContent__premium__icon" />
                {t('premiumFeatureAvailable')}
              </div>
            )}
          </div>
        </div>
        <div style={{ textAlign: 'center', width: '100%', marginTop: '20px' }}>
          {videoUrlCheckStatus === Status.error && newMatch.videoUrl !== ''
            ? t('invalidVideoUrlSrc')
            : '\u00a0'}
        </div>
      </div>
    );
  };

  const uploadBtn = (
    <div className="MatchDetailVideoContent__fileInputWrapper">
      <div className="MatchDetailVideoContent__fullWidth">
        <Icon name="upload" size="26px" type={iconTypeHandler(applicationMode, IconType.white)} />
        <div style={{ padding: '12px 6px' }}>{t('uploadFile')}</div>
      </div>
      <div
        onClick={triggerVideoFileInput}
        className="MatchDetailVideoContent__fileInputButton MatchDetailVideoContent__playVideoButton"
      >
        {t('chooseFile')}
      </div>
    </div>
  );

  const uploadInputRow = (type: string) => {
    return (
      <div style={{ width: '100%' }}>
        <div className="MatchDetailVideoContent__fullWidth">
          <div style={{ padding: '15px 0 20px' }}>{t('uploadFile')}</div>
        </div>
        <div className="MatchDetailVideoContent__uploadRow">
          <TextField
            className="MatchDetailVideoContent__input"
            disabled={true}
            value={type === 'videoLink' ? newMatch.fileName : newMatch.videoUrl}
            style={{ width: '100%' }}
            InputProps={{ disableUnderline: true }}
            autoComplete="off"
          />
          <div
            className="Title__deleteButton"
            // tslint:disable-next-line
            onClick={() => handleConfirmVisibilityChange(type === 'videoLink' ? 'link' : 'url')}
            style={{ padding: '0 30px' }}
          >
            <Icon
              name="trash"
              type={iconTypeHandler(applicationMode, IconType.white)}
              size="30px"
            />
          </div>
        </div>
      </div>
    );
  };

  const showUrlInput = () => {
    const videoUrlIsUpload = isFile.test(newMatch.videoUrl) && !(newMatch.id === 0);
    const hideUrlInput = newMatch.videoLink || videoUrlIsUpload;

    if (hideUrlInput) {
      return '';
    }

    return urlInputRow(newMatch.videoUrl.length > 0 ? 'filled' : 'empty');
  };

  const showUploadInput = () => {
    if (newMatch.videoUrl) {
      if (isFile.test(newMatch.videoUrl) && !(newMatch.id === 0)) {
        return uploadInputRow('videoUrl');
      }

      return '';
    }

    if (newMatch.videoLink) {
      return uploadInputRow('videoLink');
    }

    return uploadBtn;
  };

  const handleClickCancelUpload = () => {
    if (!isCancelUploadEnabled) {
      return;
    }

    setCancelUploadVisible(true);
  };

  const handleCloseCancelUploadModal = (v: boolean) => {
    setCancelUploadVisible(false);
    if (!v) {
      return;
    }
    // user confirmed cancel...

    setCancelUploadEnabled(false);
    cancelUpload(newMatch.id).then(refetchMatch => {
      if (refetchMatch) {
        dispatch(setMatchDetail(newMatch.id));
        dispatch(showNotification('The upload was cancelled.', 'error'));
      }
    });
    newMatch.videoLink = '';
  };

  const getContext = (): Context => {
    const videoUrlPresent = newMatch.videoUrl.length > 0;
    const videoAdded = videoUrlPresent || newMatch.videoLink;
    const postProcessing = newMatch.videoStatus === VideoStatus.uploadPostProcessing;
    const jobResponsible = jobs
      .filter((job: Job) => job.type === JobType.VideoUploadJob)
      .find((job: Job) => parseInt(job.metadata.matchId, 10) === newMatch.id) as
      | VideoUploadJob
      | undefined;
    const isUploading = newMatch.videoStatus === VideoStatus.uploading || jobResponsible;
    const isUploadingOther =
      jobs.filter((job: Job) => job.type === JobType.VideoUploadJob).length > 0;
    const uploadingPercentage = jobResponsible?.metadata.percentUploaded || 0;

    if (postProcessing) {
      return {
        className:
          'MatchDetailVideoContent__fileInputButton MatchDetailVideoContent__fileInputButton--gray',
        label: t('processing'),
        loader: true,
        playTheMatch: false,
        uploadingThis: false,
      };
    }

    if (isUploading) {
      return {
        className:
          'MatchDetailVideoContent__fileInputButton MatchDetailVideoContent__fileInputButton--gray',
        label: isCancelUploadEnabled
          ? `${uploadingPercentage}% - ${t('videoUploading')}`
          : t('videoUploadCancelling'),
        loader: true,
        playTheMatch: false,
        uploadingThis: true,
      };
    }

    if (isUploadingOther) {
      return {
        className:
          'MatchDetailVideoContent__fileInputButton MatchDetailVideoContent__fileInputButton--gray',
        label: t('videoUploadingOther'),
        loader: true,
        playTheMatch: videoUrlPresent,
        uploadingThis: false,
      };
    }

    if (videoAdded) {
      return {
        className:
          'MatchDetailVideoContent__fileInputButton MatchDetailVideoContent__fileInputButton--green',
        label: t('added'),
        loader: false,
        playTheMatch: videoUrlPresent,
        uploadingThis: false,
      };
    }

    return {
      className: 'MatchDetailVideoContent__fileInputButton',
      label: t('addVideo'),
      loader: false,
      playTheMatch: false,
      uploadingThis: false,
    };
  };

  const context = getContext();

  useModalKeyboardShortcuts(
    isModalVisible,
    handleModalVisibilityChange,
    handleModalVisibilityChange
  );

  return (
    <div className="MatchDetailVideoContent__fileInputWrapper MatchDetailVideoContent__videoSetupPageButtonRow">
      <div>
        <div className="MatchDetailVideoContent__fileInputLabel">{t('longVideoSrc')}</div>

        <div className="MatchDetailVideoContent__fileInputDescription">
          {t('longVideoSrcDescription')}
        </div>
      </div>
      {context.loader && (
        <div
          className="MatchDetailVideoContent__fileInputButton__spinner__wrapper"
          style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'end' }}
        >
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <div className={context.className} style={{ position: 'relative' }}>
              <div className="MatchDetailVideoContent__fileInputButton__spinner" />
              <span>{context.label}</span>
            </div>
          </span>

          {context.uploadingThis && isCancelUploadEnabled && (
            <div
              className="MatchDetailVideoContent__fileInputButton"
              onClick={handleClickCancelUpload}
            >
              {t('cancel')}
            </div>
          )}
        </div>
      )}
      {!context.loader && (
        <div onClick={handleModalVisibilityChange} className={context.className}>
          {context.label}
        </div>
      )}
      <Modal
        isVisible={isModalVisible}
        width="800px"
        height="fit-content"
        handleClose={handleModalVisibilityChange}
      >
        <div className="MatchDetailVideoContent__fileInputModalHeader">{t('setVideoSource')}</div>
        <div className="MatchDetailVideoContent__fileInputWrapperModal">
          {showUrlInput()}
          <div className="MatchDetailVideoContent__fileInputWrapperModalUploadFromDevice">
            {showUploadInput()}
          </div>
        </div>
        <div className="MatchDetailVideoContent__centered">
          {/*{' '}
           <div
            onClick={handleModalVisibilityChange}
            className="MatchDetailVideoContent__fileInputButton"
          >
            {t('save')}
          </div>{' '} */}
          <Button
            label={t('save')}
            onClick={handleModalVisibilityChange}
            loading={videoUrlCheckStatus === Status.requesting}
            disabled={
              videoUrlCheckStatus !== Status.idle &&
              videoUrlCheckStatus !== Status.success &&
              newMatch.videoUrl !== ''
            }
            type="primary"
          />
        </div>
      </Modal>
      <AlertModal
        isVisible={isConfirmVisible}
        handleClose={handleDelete}
        confirmBtn={true}
        cancelBtn={true}
        text={t('deleteSourceVideo')}
        confirmText={t('delete')}
      />
      <AlertModal
        isVisible={isCancelUploadVisible}
        handleClose={handleCloseCancelUploadModal}
        confirmBtn={true}
        cancelBtn={true}
        text={t('videoBeingUploaded')}
        confirmText={t('abortUpload')}
        cancelText={t('continue')}
      />
      <input
        ref={videoFileInputRef}
        onChange={handleVideoInputChange}
        className="MatchDetailVideoContent__fileInput"
        type="file"
        accept=".mp4, .mov, .3gp"
      />
    </div>
  );
};
