import { createMuiTheme, Theme } from '@material-ui/core';

import { ApplicationMode } from '../types';

const lightTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#de1919',
      main: '#000',
      dark: '#de1919',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiInput: {
      root: {
        fontWeight: 500,
      },
    },
    MuiMenuItem: {
      root: {
        fontWeight: 500,
      },
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 30px #FFF inset !important',
          '-webkit-text-fill-color': '#000 !important',
        },
      },
    },
  },
});

const darkTheme = createMuiTheme({
  overrides: {
    // @ts-ignore
    MuiPickersDay: {
      daySelected: {
        backgroundColor: 'grey',
        '&:hover': {
          color: 'black',
        },
      },
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 30px #212121 inset !important',
          '-webkit-text-fill-color': '#FFF !important',
        },
      },
    },
    MuiPickersClockPointer: {
      thumb: {
        opacity: 0.6,
      },
    },
    MuiInput: {
      root: {
        fontWeight: 500,
      },
    },
    MuiMenuItem: {
      root: {
        fontWeight: 500,
      },
    },
    MuiButton: {
      root: {
        color: 'blue',
      },
    },
  },
  palette: {
    primary: {
      light: '#de1919',
      main: '#FFF',
      dark: '#de1919',
      contrastText: '#fff',
    },
    type: 'dark',
    background: {
      paper: '#212121',
      default: '#191919',
    },
  },
});

export const themeHandler = (applicationMode: ApplicationMode): Theme =>
  applicationMode === ApplicationMode.light ? lightTheme : darkTheme;

export const setBodyBackground = (applicationMode: ApplicationMode) => {
  document.body.style.backgroundColor =
    applicationMode === ApplicationMode.light ? '#f9f9f9' : '#191919';
};
