import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '@material-ui/core';

import { ClipCategory, ClipDetail } from '../../types';
import { Modal } from '../Modal';
import { AddCategoryContent } from '../AddCategoryContent';
import { getClipCategoryLabel, useModalKeyboardShortcuts } from '../../utils';

import './ClipCategoriesContent.scss';
import { Button } from '../Button';
import { AlertModal } from '../AlertModal';

type ClipCategoriesContentProps = {
  newClipDetail: ClipDetail | null;
  handleNewClipDetail?: any;
  defaultClipCategories: ClipCategory[];
  plusHidden?: boolean;
  deleteHidden?: boolean;
};

export const ClipCategoriesContent: FunctionComponent<ClipCategoriesContentProps> = ({
  newClipDetail,
  handleNewClipDetail,
  defaultClipCategories,
  plusHidden,
  deleteHidden,
}) => {
  const { t } = useTranslation();
  const [currentlyDeletedId, setCurrentlyDeletedId] = useState<number | null>(null);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false);

  const handleConfirmVisibilityChange = () => {
    setConfirmVisible(!isConfirmVisible);
  };

  const handleModalVisibilityChange = () => {
    setModalVisible(prev => !prev);
  };

  useModalKeyboardShortcuts(
    isModalVisible,
    handleModalVisibilityChange,
    handleModalVisibilityChange
  );

  if (!newClipDetail) return null;

  const renderClipCategories = () => {
    return newClipDetail.clipCategories.map((clipCategory: ClipCategory) => {
      const handleDelete = (result: boolean) => {
        handleConfirmVisibilityChange();
        if (!result) return;

        const filteredClipCategories = newClipDetail.clipCategories.filter(
          (_clipCategory: ClipCategory) => _clipCategory.id !== currentlyDeletedId
        );

        if (handleNewClipDetail) {
          handleNewClipDetail({ ...newClipDetail, clipCategories: filteredClipCategories });
        }
      };

      clipCategory.value = t(clipCategory.value);

      if (deleteHidden) {
        return (
          <Chip
            style={{ margin: '3px' }}
            key={clipCategory.id}
            label={getClipCategoryLabel(clipCategory)}
          />
        );
      }
      return (
        <span key={clipCategory.id}>
          <AlertModal
            isVisible={isConfirmVisible}
            handleClose={handleDelete}
            confirmBtn={true}
            cancelBtn={true}
            text={t('deleteCategory')}
            confirmText={t('delete')}
          />

          <Chip
            style={{ margin: '3px' }}
            key={clipCategory.id}
            label={getClipCategoryLabel(clipCategory)}
            onDelete={() => {
              setCurrentlyDeletedId(clipCategory.id);
              handleConfirmVisibilityChange();
            }}
          />
        </span>
      );
    });
  };

  return (
    <div style={{ marginTop: '25px' }}>
      {renderClipCategories()}
      {!plusHidden && (
        <Chip style={{ margin: '3px' }} label="+" onClick={handleModalVisibilityChange} />
      )}
      <Modal
        isVisible={isModalVisible}
        width="600px"
        height="85vh"
        handleClose={handleModalVisibilityChange}
      >
        <div
          style={{
            padding: '0 10px',
            marginBottom: '2em',
            overflow: 'auto',
            maxHeight: 'calc(100% - 5em)',
          }}
        >
          <h2>{t('clipCategories')}</h2>
          <AddCategoryContent
            newClipDetail={newClipDetail}
            handleNewClipDetail={handleNewClipDetail}
            defaultClipCategories={defaultClipCategories}
          />
        </div>

        <Button
          label={`${t('close')}`}
          type={'primary'}
          className={'ClipCategoriesContent__saveButton'}
          onClick={handleModalVisibilityChange}
        />
      </Modal>
    </div>
  );
};
