export enum ShareContentType {
  json = 'json',
  html = 'html',
  rtf = 'rtf',
  plainText = 'plainText',
  jpg = 'jpg',
  png = 'png',
  gif = 'gif',
  mp4 = 'mp4',
  threeGp = 'threeGp',
  pdf = 'pdf',
}

export type GetAppVersionResult = {
  version?: string;
};
