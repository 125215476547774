import { AnyAction } from 'redux';

import { ClipDetailState, ClipReaction, Status } from '../types';
import {
  SET_CLIP_DETAIL_REQUESTING,
  SET_CLIP_DETAIL_ERROR,
  SET_CLIP_DETAIL_SUCCESS,
  SET_CLIP_REACTION,
  SET_REPORTED_CLIP,
} from '../constants';

const initClipDetailState = { status: Status.idle, errorMsg: null, clipDetail: null };

/**
 * Clip detail reducer
 *
 * @params state State of matches
 */
export const clipDetailReducer = (
  state: ClipDetailState = initClipDetailState,
  action: AnyAction
): ClipDetailState => {
  switch (action.type) {
    case SET_CLIP_DETAIL_REQUESTING: {
      return { status: Status.requesting, errorMsg: null, clipDetail: null };
    }
    case SET_CLIP_DETAIL_ERROR: {
      const { errorMsg } = action.payload;

      return { status: Status.error, errorMsg, clipDetail: null };
    }
    case SET_CLIP_DETAIL_SUCCESS: {
      const { clipDetail } = action.payload;

      return { status: Status.success, errorMsg: null, clipDetail };
    }

    case SET_CLIP_REACTION: {
      if (!state.clipDetail?.ratings) return state;

      const ratings = state.clipDetail.ratings;

      let likeDiff = 0;
      let dislikeDiff = 0;

      if (action.payload.reaction === ClipReaction.like) {
        likeDiff = 1;
        if (ratings.userReaction === 'disliked') {
          dislikeDiff = -1;
        }
      }

      if (action.payload.reaction === ClipReaction.dislike) {
        dislikeDiff = 1;
        if (ratings.userReaction === 'liked') {
          likeDiff = -1;
        }
      }

      if (action.payload.reaction === ClipReaction.unreact) {
        if (ratings.userReaction === 'liked') {
          likeDiff = -1;
        }

        if (ratings.userReaction === 'disliked') {
          dislikeDiff = -1;
        }
      }

      let newReaction: 'liked' | 'disliked' | null = null;
      switch (action.payload.reaction) {
        case ClipReaction.like:
          newReaction = 'liked' as 'liked';
          break;

        case ClipReaction.dislike:
          newReaction = 'disliked' as 'disliked';
          break;
      }

      return {
        ...state,
        clipDetail: {
          ...state.clipDetail,
          ratings: {
            ...state.clipDetail.ratings,
            userReaction: newReaction,
            liked: ratings.liked + likeDiff,
            disliked: ratings.disliked + dislikeDiff,
          },
        },
      };
    }
    case SET_REPORTED_CLIP: {
      if (!state.clipDetail) return state;
      return { ...state, clipDetail: { ...state.clipDetail, reportDisabled: true } };
    }

    default:
      return state;
  }
};
