import React, { Fragment, FunctionComponent } from 'react';
import { Icon, IconType } from '../Icon';
import './MatchButton.scss';

export interface MatchButtonProps {
  label: string;
  onClick?: (e: any) => void | undefined;
  disabled?: boolean;
  type?: 'primary' | 'secondary' | 'done';
  iconRotation?: string;
  iconName?: string;
  iconType?: IconType;
  iconPosition?: 'left' | 'right';
  iconStyle?: object;
  premiumFeature?: boolean;
  theme?: string;
  externalLink?: string;
}

export const MatchButton: FunctionComponent<MatchButtonProps> = ({
  label,
  onClick,
  disabled,
  type,
  iconName,
  iconType,
  iconPosition,
  iconStyle,
  iconRotation,
  premiumFeature,
  externalLink,
  theme,
}) => {
  return (
    <Fragment>
      <div
        onClick={!disabled || premiumFeature ? onClick : undefined}
        className={`MatchButton ${type === 'primary' ? 'MatchButton__primary' : ''} 
            ${type === 'secondary' ? 'MatchButton__secondary' : ''}
            ${type === 'done' ? 'MatchButton__done' : ''}
            ${disabled ? 'MatchButton--disabled' : ''}
            ${theme === 'dark' && disabled ? 'MatchButton--dark--disabled' : ''}
            ${theme === 'dark' ? 'MatchButton__basic--dark' : ''}
            `}
      >
        {iconPosition !== 'right' && iconName && (
          <span className="left-icon">
            <Icon
              size="20px"
              name={iconName}
              type={iconType || IconType.default}
              style={iconStyle}
              rotation={iconRotation}
            />
          </span>
        )}

        <Fragment>{externalLink ? <a href={externalLink}>{label}</a> : label}</Fragment>

        {iconPosition === 'right' && iconName && (
          <span className="right-icon">
            <Icon size="20px" name={iconName} type={iconType || IconType.default} />
          </span>
        )}
      </div>
    </Fragment>
  );
};
