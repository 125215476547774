import React, { FunctionComponent } from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getUser } from '../../selectors/user';

interface PrivateRouteProps extends RouteProps {
  component: any;
}

export const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const user = useSelector(getUser);

  const handleRender = (props: RouteComponentProps) => {
    if (user) {
      return <Component {...props} />;
    } else {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }
  };

  return <Route {...rest} render={handleRender} />;
};
