import { AnyAction } from 'redux';
import { CREATE_JOB, FINISH_JOB, UPDATE_JOB } from '../constants';
import { JobState } from '../types';
import { Job } from '../types/job';

const initJobState: JobState = {
  jobs: [],
};

export const jobsReducer = (state: JobState = initJobState, action: AnyAction): JobState => {
  switch (action.type) {
    case CREATE_JOB: {
      const { job } = action.payload;

      return {
        ...state,
        jobs: [...state.jobs, job],
      };
    }
    case UPDATE_JOB: {
      const { job } = action.payload;

      return {
        ...state,
        jobs: state.jobs.map((j: Job) => {
          if (j.id === job.id) {
            return job;
          }

          return j;
        }),
      };
    }
    case FINISH_JOB: {
      const { job } = action.payload;

      return {
        ...state,
        jobs: state.jobs.filter((j: Job) => j.id !== job.id),
      };
    }
    default:
      return state;
  }
};
