export interface WebSocketMessage {
  event: string;
  error: any;
  data: any;
}

export enum WebSocketEventType {
  createMissingClips = 'createMissingClips',
  authorization = 'authorization',
}
