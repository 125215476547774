import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MatchDetail, VideoStatus } from '../../types';
import { Modal } from '../Modal';
import { VideoPlayer } from '../VideoPlayer';
import './MatchDetailVideoAddTimestampModal.scss';
import { TextField } from '@material-ui/core';
import { TimeInput } from '../TimeInput';
import { getApplicationModeState, getSettings } from '../../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { MatchButton } from '../MatchButton';
import { Button } from '../Button';
import { setMatchDetailSuccess, showNotification } from '../../actions';
import { transformMatchDetail, useModalKeyboardShortcuts } from '../../utils';
import { postMatchDetail } from '../../api';

type MatchDetailVideoAddTimestampModalProps = {
  newMatch: MatchDetail;
  handleNewMatch: any;
  syncEntry: number;
  setProxy: (videoUrl: string) => string;
  handleNoSourceVideoModalVisibility?: () => void;
};

export const MatchDetailVideoAddTimestampModal: FunctionComponent<MatchDetailVideoAddTimestampModalProps> = ({
  newMatch,
  handleNewMatch,
  syncEntry,
  setProxy,
  handleNoSourceVideoModalVisibility,
}) => {
  const { t } = useTranslation();
  const settings = useSelector(getSettings);
  const applicationMode = useSelector(getApplicationModeState);
  const dispatch = useDispatch();

  const [stampName, setName] = useState('');
  const [stampTime, setStampTime] = useState<number>(newMatch?.syncTime || 0);

  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [keyboardShortcutDisabled, setKeyboardShortcutDisabled] = useState<boolean>(false);

  const handleModalVisibilityChange = () => {
    setModalVisible(!isModalVisible);
  };

  const enableKeyboardShortcut = () => {
    setKeyboardShortcutDisabled(false);
  };

  const disableKeyboardShortcut = () => {
    setKeyboardShortcutDisabled(true);
  };

  const addSyncTime = async () => {
    const newSyncTimes = [...newMatch.clips];

    newSyncTimes.push({
      name: stampName ? stampName : `${t('videoInputPlaceHolder')} ${newMatch.clips.length + 1}`,
      time: stampTime,
      startTime: settings?.defaultVideoStartingTime || 0,
      endTime: settings?.defaultVideoEndingTime || 0,
    });
    setName('');
    dispatch(showNotification(t('VideoclipSuccessfullyCreated'), 'success', 2000));
    const response = await postMatchDetail(
      { ...newMatch, clips: newSyncTimes },
      settings?.sports.id
    );
    const matchDetail = {
      ...response.matchDetail,
      matchAccounting: response.matchDetail.accounting,
    };
    const transformedMatchDetail = transformMatchDetail({ matchDetail });
    handleNewMatch(transformedMatchDetail);
    dispatch(setMatchDetailSuccess(transformedMatchDetail));
  };

  const handleStampCancel = () => {
    handleModalVisibilityChange();
    setName('');
    setStampTime(syncEntry);
  };

  useEffect(() => {
    setStampTime(syncEntry);
  }, [syncEntry]);

  const renderStampInput = () => {
    const onChangeName = (e: any) => {
      const value = e.target.value as string;
      setName(value);
    };

    const onChangeStampTime = (e: any) => {
      const value = e.target.value as number;
      setStampTime(value);
    };

    /* value={stampName || `${t('videoInputPlaceHolder')} ${newMatch.clips.length + 1}`} */
    return (
      <div className="MatchDetailVideoContent__syncTime">
        <TextField
          name="name"
          label={t('clipName')}
          className="MatchDetailVideoContent__timeNameInput"
          value={stampName}
          onChange={onChangeName}
          autoComplete="off"
          onFocus={disableKeyboardShortcut}
          onBlur={enableKeyboardShortcut}
          placeholder={`${t('videoInputPlaceHolder')} ${newMatch.clips.length + 1}`}
          style={{ width: '300px' }}
        />
        {!newMatch.videoUrl && (
          <TimeInput
            time={stampTime || syncEntry || 0}
            handleChange={onChangeStampTime}
            withLabels
          />
        )}
      </div>
    );
  };

  const getMatchButtonContext = () => {
    if (newMatch.videoUrl) {
      return {
        disabled: false,
        onClick: handleModalVisibilityChange,
      };
    }

    return {
      disabled: true,
      onClick: handleNoSourceVideoModalVisibility,
    };
  };

  const context = getMatchButtonContext();

  useModalKeyboardShortcuts(isModalVisible, handleStampCancel, addSyncTime);

  return (
    <div className="MatchDetailVideoContent__fileInputWrapper MatchDetailVideoContent__videoSetupPageButtonRow">
      <div className="MatchDetailVideoContent__fileInputLabel">
        {t('addTimeStamps')}
        <div className="MatchDetailVideoContent__fileInputDescription">{t('afterTheGame')}</div>
      </div>

      <MatchButton
        label={t('addTimeStamp')}
        type="secondary"
        onClick={context.onClick}
        theme={applicationMode}
        disabled={context.disabled}
        premiumFeature
      />

      <Modal
        isVisible={isModalVisible}
        width="fit-content"
        height="fit-content"
        handleClose={handleModalVisibilityChange}
      >
        <div className="MatchDetailVideoContent__playVideoModalHeader">{t('addTimeStamps')}</div>
        <div className="MatchDetailVideoContent__playVideoSyncTimeWrapperModal">
          {(newMatch.videoUrl || newMatch.videoStatus === VideoStatus.done) && (
            <VideoPlayer
              videoSrc={setProxy(newMatch.videoUrl)}
              keyboardShortcutDisabled={keyboardShortcutDisabled}
              setProgressTime={setStampTime}
              startPlayingAt={syncEntry}
            />
          )}
        </div>
        <div className="MatchDetailVideoContent__centered MatchDetailVideoContent__gapBetween">
          <div className="MatchDetailVideoContent__playVideoContainer">
            <div className="MatchDetailVideoContent__fileInputWrapper">{renderStampInput()}</div>
          </div>
          <div className="MatchDetailVideoContent__saveCancelButonsWrapper">
            <Button label={t('close')} onClick={handleStampCancel} type="secondary" />
            <Button
              label={t('createVideo')}
              onClick={addSyncTime}
              type="primary"
              adaptable={false}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
