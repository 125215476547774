import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { ClickAwayListener, Popper } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import { setClipCategories } from '../../actions/clipCategoriesActions';
import { setClipDetail, setSharedClipDetail } from '../../actions/clipDetailActions';
import {
  setInfoModalText,
  setInfoModalVisibility,
  setLoginRequiredModalVisibility,
  setMatchDetail,
  showNotification,
} from '../../actions';
import {
  deleteClipDetail,
  postClipDetail,
  postClipDetailFavourite,
  shareClip,
  shareToClosedCommunity,
} from '../../api';
import { ClipController } from '../../components/ClipController';
import { ClipDetailTitle } from '../../components/ClipDetailTitle';
import { ClipInformation } from '../../components/ClipInformation';
import { ClipSubController } from '../../components/ClipSubController';
import { IconProps, MobileTitle } from '../../components/MobileTItle';
import { UnavailableVideoNotification } from '../../components/UnavailableVideoNotification';
import { VideoPlayer } from '../../components/VideoPlayer';
import { Icon, IconType } from '../../components/Icon';
import { MatchDetailVideoResyncTimeModal } from '../../components/MatchDetailVideoResyncTimeModal';

import {
  getApplicationBreakpointState,
  getApplicationModeState,
  getClipDetail,
  getClipDetailStatus,
  getCommunityClipGroups,
  getFavouriteClipGroups,
  getMatchDetail,
  getMyClipGroups,
  getSettings,
  getSharedMatchClipGroup,
  getUser,
} from '../../selectors';
import {
  ApplicationBreakpoint,
  ApplicationMode,
  ClipDetail,
  ClipDetailWithMatch,
  ClipType,
  ShareContentType,
  ShareTokenResponse,
  Status,
  VideoStatus,
} from '../../types';
import {
  classHandler,
  containsOnlyNumbers,
  iconTypeHandler,
  isErrorResponse,
  useQuery,
  useShareLinkHandler,
} from '../../utils';
import './ClipDetailPage.scss';
import { SaveButtonsWrapper } from '../../components/SaveButtonsWrapper';
import { AlertModal } from '../../components/AlertModal';
import { isAndroidApp, isIOSApp, isMobileEdge } from '../../utils/getPlatform';
import useDownloadClip from '../../utils/useDownloadClip';
import { NoVideos, NoVideosType } from '../../components/NoVideos';
import { DeleteAndNextModal } from '../../components/DeleteAndNextModal';
import ReportVideoModal from '../../components/ReportVideoModal/ReportVideoModal';

enum SaveButtonState {
  default = '',
  loading = 'loading',
}

export const ClipDetailPage: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const shareLinkHandler = useShareLinkHandler;

  const clipIdQuery = useQuery().get('id') || '0';
  const clipGroupQuery = useQuery().get('clipgroup') || undefined;
  const shareToken = useQuery().get('share');
  const clipId = containsOnlyNumbers(clipIdQuery) ? parseInt(clipIdQuery, 0) : 0;
  const clipType = useQuery().get('t');
  const clipGroup = clipGroupQuery
    ? containsOnlyNumbers(clipGroupQuery)
      ? parseInt(clipGroupQuery, 0)
      : undefined
    : undefined;
  const iOS = isIOSApp();
  const android = isAndroidApp();
  const isWeb = !iOS && !android;
  const isEdgeOnMobile = isMobileEdge();

  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const clipDetail = useSelector(getClipDetail);
  const clipDetailStatus = useSelector(getClipDetailStatus);
  const settings = useSelector(getSettings);
  const user = useSelector(getUser);
  const matchDetail = useSelector(getMatchDetail);

  const myClipGroups = useSelector(getMyClipGroups);
  const communityClipGroups = useSelector(getCommunityClipGroups);
  const favouriteClipGroups = useSelector(getFavouriteClipGroups);
  const sharedMatchClipGroup = useSelector(getSharedMatchClipGroup);

  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [tooltipVisibility, setTooltipVisible] = useState<boolean>(false);
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [syncEntry] = useState<number>(0);
  const handleTooltipVisibility = () => {
    setTooltipVisible(!tooltipVisibility);
  };

  const [newClipDetail, setNewClipDetail] = useState<ClipDetailWithMatch | null>(clipDetail);
  const [saveButtonState, setSaveButtonState] = useState<SaveButtonState>(SaveButtonState.default);
  const [saveAndNextButtonState, setSaveAndNextButtonState] = useState<SaveButtonState>(
    SaveButtonState.default
  );

  const canEdit =
    !shareToken &&
    Boolean(user?.id && user?.id === (newClipDetail as ClipDetailWithMatch)?.ownerId);

  const [keyboardShortcutDisabled, setKeyboardShortcutDisabled] = useState(false);
  const [isReportModalVisible, setReportModalVisible] = useState(false);

  const [clipSyncTime, setClipSyncTime] = useState<number>(0);
  const [forceStopPlaying, setForceStopPlaying] = useState(false);
  const [isClosedCommunityShared, setIsClosedCommunityShared] = useState(false);
  const moreButtonRef = useRef<HTMLDivElement>(null);

  const { handleDownload } = useDownloadClip(newClipDetail);

  const handleReporModalVisibility = () => {
    if (user) {
      setReportModalVisible(prev => !prev);
    } else {
      dispatch(setLoginRequiredModalVisibility(true));
    }
  };

  useEffect(() => {
    if (shareToken) {
      dispatch(setSharedClipDetail(clipId, shareToken));
    } else {
      dispatch(setClipDetail(clipId));
    }
  }, [dispatch, user, clipId, shareToken]);

  useEffect(() => {
    if (canEdit && newClipDetail && !(myClipGroups && matchDetail?.clips)) {
      dispatch(setMatchDetail(newClipDetail?.match.id));
    }
  }, [dispatch, newClipDetail, myClipGroups, matchDetail, canEdit]);

  useEffect(() => {
    if (user) {
      dispatch(setClipCategories(settings?.sports.id || 0));
    }
  }, [settings, user, dispatch]);

  useEffect(() => {
    setNewClipDetail(clipDetail);
    setClipSyncTime(clipDetail?.syncTime ?? 0);
    setIsClosedCommunityShared(clipDetail?.isClosedCommunityShared || false);
  }, [clipDetail]);

  const timeSegment = newClipDetail
    ? {
        start: newClipDetail.time + newClipDetail.startTime + clipSyncTime - newClipDetail.syncTime,
        end: newClipDetail.time + newClipDetail.endTime + clipSyncTime - newClipDetail.syncTime,
      }
    : { start: 0, end: 10 };

  const isClipBeforeSync = newClipDetail && newClipDetail.time < 0 ? true : false;

  const handleModalVisibilityChange = () => {
    setModalVisible(!isModalVisible);
  };

  const getTargetClips = (): { id: number }[] | undefined => {
    if (clipType === ClipType.favourite) {
      return favouriteClipGroups[canEdit ? 0 : 1].clips;
    }

    if (clipType === 'fromshared') {
      return sharedMatchClipGroup?.clips;
    }

    if (clipGroup === undefined && newClipDetail && (myClipGroups || matchDetail?.clips)) {
      const matchClips = myClipGroups.find(({ id }) => id === newClipDetail.match.id);
      if (matchClips) {
        return matchClips.clips;
      }
      return matchDetail?.clips as { id: number }[];
    }
    if (newClipDetail && communityClipGroups) {
      return communityClipGroups.find(({ id }) => id === clipGroup)?.clips;
    }
  };

  const getNextClipId = (): number | undefined => {
    const targetClips = getTargetClips();

    if (!targetClips) return;

    const clipIndex = targetClips.findIndex(({ id }) => id === clipId);
    return clipIndex < targetClips.length - 1 ? targetClips[clipIndex + 1].id : undefined;
  };

  const nextClipId = getNextClipId();
  const nextClipPath = useMemo(() => {
    const newClipGroup = clipGroup !== undefined ? `&clipgroup=${clipGroup}` : '';
    const newClipType = clipType ? `&t=${clipType}` : '';
    const newShare = shareToken ? `&share=${shareToken}` : '';

    return `/video-detail?id=${nextClipId + newShare + newClipGroup + newClipType}`;
  }, [clipGroup, nextClipId, clipType, shareToken]);

  const handleSave = () => {
    const clip = { ...newClipDetail!, syncTime: clipSyncTime };

    if (saveButtonState === SaveButtonState.default && newClipDetail) {
      if (newClipDetail && !newClipDetail.isPrivate && newClipDetail.clipCategories.length === 0) {
        handleModalVisibilityChange();
        return;
      }
      setSaveButtonState(SaveButtonState.loading);
      if (clip.closedCommunityName) {
        shareToClosedCommunity(clip.id, isClosedCommunityShared, user?.id);
      }
      postClipDetail(clip as ClipDetail).then((response: any) => {
        setSaveButtonState(SaveButtonState.default);
        if (!isErrorResponse(response)) {
          goBackToPrevTab();
          setNewClipDetail({ ...newClipDetail!, syncTime: clipSyncTime });
        }
      });
    }
  };

  const handleSaveAndNext = () => {
    const clip = { ...newClipDetail!, syncTime: clipSyncTime };

    if (saveButtonState === SaveButtonState.default && newClipDetail && nextClipId !== undefined) {
      if (newClipDetail && !newClipDetail.isPrivate && newClipDetail.clipCategories.length === 0) {
        handleModalVisibilityChange();
        return;
      }

      setSaveAndNextButtonState(SaveButtonState.loading);
      if (clip.closedCommunityName) {
        shareToClosedCommunity(clip.id, isClosedCommunityShared, user?.id);
      }
      postClipDetail(clip as ClipDetail).then((response: any) => {
        setSaveAndNextButtonState(SaveButtonState.default);
        if (!isErrorResponse(response)) {
          handleNext();
        }
      });
    }
  };

  const handleFavourite = () => {
    if (!newClipDetail) return;

    if (!user) {
      dispatch(setLoginRequiredModalVisibility(true));
      return;
    }
    const newIsFavorite = !newClipDetail.isFavorite;

    setNewClipDetail({ ...newClipDetail, isFavorite: newIsFavorite });

    postClipDetailFavourite({ id: newClipDetail.id, isFavorite: newIsFavorite });
  };

  const handleShare = () => {
    if (!user) {
      if (isWeb) {
        copy(window.location.href);
        dispatch(showNotification(t('videoLinkCopiedToClipboard'), 'success', 3000));
      } else {
        shareLinkHandler(
          window.location.href,
          ShareContentType.plainText,
          (result: any) => {
            if (result.status === 1) {
              dispatch(setInfoModalText(result.error));
              dispatch(setInfoModalVisibility(true));
            }
          },
          () => {
            copy(window.location.href);
            dispatch(showNotification(t('videoLinkCopiedToClipboard'), 'success', 3000));
          }
        );
      }
    }
    shareClip(clipId).then((response: ShareTokenResponse) => {
      if (!isErrorResponse(response)) {
        const sharePath = `/video-detail?id=${clipId}&share=${response.shareToken}`;
        const shareUrl = `${window.location.origin}${sharePath}`;
        shareLinkHandler(
          shareUrl,
          ShareContentType.plainText,
          (result: any) => {
            if (result.status === 1) {
              dispatch(setInfoModalText(result.error));
              dispatch(setInfoModalVisibility(true));
            }
          },
          () => {
            copy(shareUrl);
            dispatch(showNotification(t('videoLinkCopiedToClipboard'), 'success', 3000));
          }
        );
        if (isWeb) {
          copy(shareUrl);
          dispatch(showNotification(t('videoLinkCopiedToClipboard'), 'success', 3000));
        }
      }
    });
  };

  const redirectToMatchDetail = () => {
    if (newClipDetail) {
      history.push(`/match-detail?id=${newClipDetail.match.id}`);
    }
  };

  const goBackToPrevTab = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.replace('/videos');
    }
  };

  const handleNext = () => {
    setNewClipDetail({ ...newClipDetail!, syncTime: clipSyncTime });
    history.replace(nextClipPath);
    history.goForward();
  };

  const disableKeyboardShortcut = () => {
    setKeyboardShortcutDisabled(true);
  };

  const enableKeyboardShortcut = () => {
    setKeyboardShortcutDisabled(false);
  };

  const handleConfirmVisibilityChange = () => {
    setConfirmVisible(!isConfirmVisible);
  };

  const setClipResync = (syncTime: number) => {
    setClipSyncTime(syncTime);
  };

  const setProxy = (videoUrl: string): string => {
    const tvCom = 'http://static.tvcom.cz/';
    const shouldUseProxy = RegExp(tvCom);
    if (shouldUseProxy.test(videoUrl)) {
      const proxy = 'https://dev.refrec.proxy.koala42.com/tvcom/';
      return videoUrl.replace(tvCom, proxy);
    }
    return videoUrl;
  };

  const handleDelete = (goToNext: boolean) => {
    if (newClipDetail) {
      deleteClipDetail(newClipDetail.id).then((response: any) => {
        if (!isErrorResponse(response)) {
          goToNext && handleNext ? handleNext() : goBackToPrevTab();
        }
      });
    }
  };

  const renderPopper = () => {
    if (canEdit || (clipDetail && !clipDetail.reportDisabled)) {
      return (
        <>
          <ReportVideoModal
            handleClose={handleReporModalVisibility}
            isVisible={isReportModalVisible}
          />
          <div className="ClipDetailPage__moreButton">
            <div
              onClick={handleTooltipVisibility}
              className="ClipController__moreButton"
              ref={moreButtonRef}
            >
              <Icon
                size="15px"
                name="more"
                type={iconTypeHandler(applicationMode, IconType.white)}
              />
            </div>
            <Popper
              open={tooltipVisibility}
              anchorEl={moreButtonRef.current}
              role={undefined}
              placement="left-start"
              onClick={handleTooltipVisibility}
              modifiers={{
                computeStyle: {
                  gpuAcceleration: false,
                  adaptive: false,
                },
              }}
              style={{ zIndex: 1000 }}
            >
              <div className={classHandler('PopperShadow', applicationMode)}>
                {
                  <ClickAwayListener onClickAway={handleTooltipVisibility}>
                    <div
                      className={classHandler(
                        'ClipController__moreTooltip',
                        applicationMode,
                        false,
                        tooltipVisibility
                      )}
                    >
                      {!canEdit && clipDetail && !clipDetail.reportDisabled && (
                        <div
                          onClick={handleReporModalVisibility}
                          className="ClipController__tooltipButton"
                          style={{ paddingLeft: 6 }}
                        >
                          <Icon
                            size="15px"
                            name={'flag'}
                            type={iconTypeHandler(applicationMode, IconType.white)}
                          />
                          <div
                            style={{ marginLeft: 12 }}
                            className="ClipController__tooltipButtonLabel"
                          >
                            {t('reportVideo')}
                          </div>
                        </div>
                      )}
                      {canEdit && (
                        <MatchDetailVideoResyncTimeModal
                          newMatch={clipDetail}
                          sync={clipDetail}
                          setClipResync={setClipResync}
                          syncEntry={syncEntry}
                          setProxy={setProxy}
                          showConfirmation
                          type="button"
                          clipSyncTime={clipSyncTime}
                          setForceStopPlaying={setForceStopPlaying}
                        />
                      )}
                      {canEdit && (
                        <div
                          className="ClipController__tooltipButton"
                          onClick={redirectToMatchDetail}
                        >
                          <Icon
                            size="20px"
                            name="arrowForward"
                            type={iconTypeHandler(applicationMode, IconType.white)}
                          />
                          <div className="ClipController__tooltipButtonLabel">
                            {t('matchDetail')}
                          </div>
                        </div>
                      )}
                    </div>
                  </ClickAwayListener>
                }
              </div>
            </Popper>
          </div>
        </>
      );
    }
  };

  const mobileActionButtons: IconProps[] = [
    {
      name: newClipDetail?.isFavorite ? 'heartFull' : 'heart',
      onClick: handleFavourite,
      visible: true,
      type: newClipDetail?.isFavorite
        ? IconType.default
        : iconTypeHandler(applicationMode, IconType.white),
    },
    {
      name: 'share',
      onClick: handleShare,
      visible: true,
    },
    {
      onClick: handleDownload,
      visible: true,
      icon: (
        <Icon
          size={applicationMode === ApplicationMode.dark ? '24px' : '35px'}
          name={'downloadOutline'}
          type={iconTypeHandler(applicationMode, IconType.white)}
        />
      ),
    },
    {
      name: 'trash',
      onClick: handleConfirmVisibilityChange,
      visible: canEdit,
    },
  ];

  return (
    <div className={classHandler('ClipDetailPage', applicationMode)}>
      <AlertModal
        isVisible={isModalVisible}
        handleClose={handleModalVisibilityChange}
        confirmBtn={true}
        cancelBtn={false}
        text={t('categoryBeforeShare')}
      />
      <DeleteAndNextModal
        isConfirmVisible={isConfirmVisible}
        handleDelete={handleDelete}
        showNext={nextClipId !== undefined}
        handleClose={handleConfirmVisibilityChange}
      />
      {clipDetailStatus === Status.success && (
        <React.Fragment>
          {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
            <ClipDetailTitle
              newClipDetail={newClipDetail}
              handleFavourite={handleFavourite}
              goBack={goBackToPrevTab}
              hideDelete={!canEdit}
              handleShare={handleShare}
              withRedirect={canEdit}
              setClipDetail={setNewClipDetail}
              redirectToMatchDetail={redirectToMatchDetail}
              clipSyncTime={clipSyncTime}
              setClipSyncTime={setClipSyncTime}
              setForceStopPlaying={setForceStopPlaying}
              showDeleteNext={nextClipId !== undefined}
              handleDelete={handleDelete}
              keyboardShortcutDisabled={keyboardShortcutDisabled}
            />
          ) : (
            <MobileTitle
              withIcons={mobileActionButtons}
              withPrevTab={true}
              goBackTab={goBackToPrevTab}
              backFunction={true}
              renderPopper={renderPopper}
            />
          )}
          <div
            className={`ClipDetailPage__content ${
              canEdit ? '' : 'ClipDetailPage__content__withoutSaveButtons'
            }`}
          >
            <div className="ClipDetailPage__contentWrapper">
              {isEdgeOnMobile && (
                <div className="ClipDetailPage__edgeWarning">
                  <Trans i18nKey="edgeWarning" />
                </div>
              )}
              {canEdit ? (
                <ClipController
                  clipDetail={newClipDetail}
                  goBack={goBackToPrevTab}
                  setClipDetail={setNewClipDetail}
                  handleFavourite={handleFavourite}
                  disableKeyboardShortcut={disableKeyboardShortcut}
                  enableKeyboardShortcut={enableKeyboardShortcut}
                  handleShare={handleShare}
                  redirect={redirectToMatchDetail}
                  clipSyncTime={clipSyncTime}
                  setClipSyncTime={setClipSyncTime}
                  setForceStopPlaying={setForceStopPlaying}
                />
              ) : (
                <ClipInformation
                  clipDetail={newClipDetail as ClipDetailWithMatch}
                  handleFavourite={handleFavourite}
                  handleShare={handleShare}
                />
              )}

              {applicationBreakpoint === ApplicationBreakpoint.desktop && canEdit && (
                <ClipSubController
                  clipDetail={newClipDetail}
                  setClipDetail={setNewClipDetail}
                  disableKeyboardShortcut={disableKeyboardShortcut}
                  enableKeyboardShortcut={enableKeyboardShortcut}
                  {...{ setIsClosedCommunityShared, isClosedCommunityShared }}
                />
              )}
            </div>
            <div style={{ width: '100%' }}>
              {newClipDetail?.videoUrl !== '' ? (
                isClipBeforeSync ? (
                  <UnavailableVideoNotification
                    timeOverlap={true}
                    videoOwnerId={clipDetail?.ownerId}
                  />
                ) : newClipDetail?.videoStatus === VideoStatus.uploading ? (
                  <UnavailableVideoNotification
                    isProcessing={true}
                    videoOwnerId={clipDetail?.ownerId}
                  />
                ) : newClipDetail?.videoStatus === VideoStatus.error ? (
                  <UnavailableVideoNotification isError={true} videoOwnerId={clipDetail?.ownerId} />
                ) : (
                  <VideoPlayer
                    videoSrc={newClipDetail?.videoUrl || ''}
                    videoOwnerId={clipDetail?.ownerId}
                    timeSegment={timeSegment}
                    keyboardShortcutDisabled={keyboardShortcutDisabled}
                    forceStopPlaying={forceStopPlaying}
                  />
                )
              ) : (
                <UnavailableVideoNotification videoOwnerId={clipDetail?.ownerId} />
              )}
            </div>
          </div>
          {canEdit ? (
            <SaveButtonsWrapper
              className="ClipDetailPage__buttons"
              secondaryButton={
                nextClipId !== undefined
                  ? {
                      onClick: handleSave,
                      label: t('save'),
                      loading: saveButtonState === SaveButtonState.loading,
                    }
                  : undefined
              }
              primaryButton={
                nextClipId !== undefined
                  ? {
                      onClick: handleSaveAndNext,
                      label: t('saveAndNext'),
                      loading: saveAndNextButtonState === SaveButtonState.loading,
                    }
                  : {
                      onClick: handleSave,
                      label: t('save'),
                      loading: saveButtonState === SaveButtonState.loading,
                    }
              }
            />
          ) : (
            nextClipId !== undefined && (
              <SaveButtonsWrapper
                primaryButton={{
                  onClick: handleNext,
                  label: t('watchNext'),
                }}
              />
            )
          )}
        </React.Fragment>
      )}

      {clipDetailStatus === Status.error && shareToken && (
        <NoVideos type={NoVideosType.sharedClip} />
      )}
    </div>
  );
};
