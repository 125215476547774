import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { MenuItem, MenuItemProps } from '../MenuItem';

export const MainMenu: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isVideoDetailActive = pathname === '/video-detail';
  const isMatchDetailActive = pathname === '/match-detail';

  const items: MenuItemProps[] = [
    { label: t('matches'), iconName: 'calendar', to: '/' },
    { label: t('videos'), iconName: 'camera', to: '/videos' },
    { label: t('notes'), iconName: 'note', to: '/notes' },
    { label: t('accounting'), iconName: 'bank', to: '/accounting' },
    { label: t('statistics'), iconName: 'stat', to: '/statistics' },
  ];

  const renderItems = items.map((item: MenuItemProps, index: number) => {
    return <MenuItem label={item.label} iconName={item.iconName} to={item.to} key={index} />;
  });

  const renderItemsDetail = items.map((item: MenuItemProps, index: number) => {
    return item.label === t('videos') ? (
      <MenuItem
        label={item.label}
        iconName={item.iconName}
        to={item.to}
        key={index}
        active={isVideoDetailActive}
      />
    ) : item.label === t('matches') ? (
      <MenuItem
        label={item.label}
        iconName={item.iconName}
        to={item.to}
        key={index}
        active={isMatchDetailActive}
      />
    ) : (
      <MenuItem label={item.label} iconName={item.iconName} to={item.to} key={index} />
    );
  });

  return (
    <div>
      {pathname === '/video-detail'
        ? renderItemsDetail
        : pathname === '/match-detail'
        ? renderItemsDetail
        : renderItems}
    </div>
  );
};
