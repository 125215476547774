import {
  Category,
  ClipCategory,
  Competition,
  Gender,
  MatchDetail,
  Month,
  RefereeTeamType,
  Settings,
  Tip,
  VideoStatus,
  MatchAccountingReceivedAs,
} from '../types';

export const mockedTips: Tip[] = [
  {
    id: 0,
    heading: 'promoTextOne',
    text: 'promoTextOne',
  },
  {
    id: 1,
    heading: 'promoTextTwo',
    text: 'promoTextTwo',
  },
  {
    id: 2,
    heading: 'promoTextThree',
    text: 'promoTextThree',
  },
  {
    id: 3,
    heading: 'promoTextFour',
    text: 'promoTextFour',
  },
  {
    id: 4,
    heading: 'promoTextFive',
    text: 'promoTextFive',
  },
  {
    id: 5,
    heading: 'promoTextSix',
    text: 'promoTextSix',
  },
];

export const mockedClipCategories: ClipCategory[] = [];

export const mockedSettings: Settings = {
  country: { id: 1, name: 'Name', currency: 'Currency' },
  sports: { id: 1, name: 'Name' },
  language: { id: 1, name: 'Name' },
  darkMode: false,
  defaultVideoStartingTime: 30,
  defaultVideoEndingTime: 5,
  statisticsFrom: Month.June,
  statisticsTo: Month.July,
};

export const mockedRefereeTeamTypes: RefereeTeamType[] = [
  { id: 1, name: 'Prvni type', roles: ['one', 'two', 'three'] },
  { id: 2, name: 'Druhy type', roles: ['jedna', 'dva', 'tri'] },
];

export const getDefaultNewMatch = (): MatchDetail => ({
  id: 0,
  date: new Date(),
  time: new Date(),
  location: '',
  gender: Gender.men,
  competition: 0,
  category: 0,
  teams: { firstTeam: '', secondTeam: '' },
  refereeTeamType: 0,
  referees: [],
  myRole: '',
  sport: 0,
  matchAccounting: {
    id: 0,
    reward: 0,
    workdayBonus: 0,
    travelCost: 0,
    dietary: 0,
    receivedAs: MatchAccountingReceivedAs.bankAccount,
  },
  videoUrl: '',
  videoStatus: VideoStatus.noVideo,
  syncTime: 0,
  clips: [],
  notes: [],
  chiefReferee: '',
});

export const defaultNewCompetition: Competition = {
  id: 0,
  name: '',
  sport: 0,
  country: 0,
  gender: Gender.men,
  categories: [],
};

export const defaultNewCategory: Category = {
  id: 0,
  name: '',
  rewards: {
    type4AR: 0,
    typeAAR: 0,
    typeAR: 0,
    typeD: 0,
    typeR: 0,
  },
};
