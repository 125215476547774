import { uniqueId } from 'lodash';
import { AnyAction } from 'redux';
import {
  ADD_RECORDER_SITUATION,
  CLEAR_RECORDER_SITUATIONS,
  REMOVE_RECORDER_SITUATION,
  SET_MATCH_ID_FOR_SITUATIONS,
  SET_RECORDER_SITUATIONS,
  UPDATE_RECORDER_SITUATION,
} from '../constants';
import { RecorderSituationsState } from '../types';

const initialState: RecorderSituationsState = {
  situations: [],
  forMatchId: null,
};

export const recorderSituationsReducer = (
  state: RecorderSituationsState = initialState,
  action: AnyAction
): RecorderSituationsState => {
  switch (action.type) {
    case SET_RECORDER_SITUATIONS:
      return { ...state, situations: action.payload };
    case REMOVE_RECORDER_SITUATION:
      return {
        ...state,
        situations: state.situations.filter(({ id }) => id !== action.payload.id),
      };
    case UPDATE_RECORDER_SITUATION:
      return {
        ...state,
        situations: state.situations.map(situation => {
          if (situation.id === action.payload.id) {
            return { ...situation, ...action.payload.data, id: situation.id };
          } else {
            return situation;
          }
        }),
      };
    case ADD_RECORDER_SITUATION:
      return {
        ...state,
        situations: [
          ...state.situations,
          {
            timeSeconds: action.payload.timeSeconds,
            id: uniqueId(),
            videoName: `Video ${state.situations.length + 1}`,
            categories: [],
          },
        ],
      };
    case CLEAR_RECORDER_SITUATIONS:
      return { ...state, situations: [] };
    case SET_MATCH_ID_FOR_SITUATIONS:
      return { forMatchId: action.payload.id, situations: [] };
    default:
      return state;
  }
};
