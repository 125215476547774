import { combineReducers } from 'redux';

import { applicationReducer } from './applicationReducer';
import { matchesReducer } from './matchesReducer';
import { matchDetailReducer } from './matchDetailReducer';
import { competitionsReducer } from './competitionsReducer';
import { clipsReducer } from './clipsReducer';
import { clipDetailReducer } from './clipDetailReducer';
import { clipCategoriesReducer } from './clipCategoriesReducer';
import { userReducer } from './userReducer';
import { jwtReducer } from './jwtReducer';
import { countriesReducer } from './countriesReducer';
import { sportsReducer } from './sportsReducer';
import { settingsReducer } from './settingsReducer';
import { languagesReducer } from './languagesReducer';
import { refereeTeamTypesReducer } from './refereeTeamTypes';
import { tipsReducer } from './tipsReducer';
import { notificationReducer } from './notificationReducer';
import { jobsReducer } from './jobsReducer';
import { notesReducer } from './notesReducer';
import { accountingDataReducer } from './acountingDataReducer';
import { accountingReducer } from './accountingReducer';
import { statisticsDataReducer } from './statisticsDataReducer';
import { statisticsReducer } from './statisticsReducer';
import { sharedMatchReducer } from './sharedMatchReducer';
import { recorderSituationsReducer } from './recorderSituationsReducer';

export const rootReducer = combineReducers({
  application: applicationReducer,
  matches: matchesReducer,
  matchDetail: matchDetailReducer,
  sharedMatch: sharedMatchReducer,
  competitions: competitionsReducer,
  clips: clipsReducer,
  clipDetail: clipDetailReducer,
  clipCategories: clipCategoriesReducer,
  user: userReducer,
  jwt: jwtReducer,
  countries: countriesReducer,
  sports: sportsReducer,
  tips: tipsReducer,
  settings: settingsReducer,
  languages: languagesReducer,
  refereeTeamTypes: refereeTeamTypesReducer,
  notification: notificationReducer,
  job: jobsReducer,
  notes: notesReducer,
  accounting: accountingReducer,
  accountingData: accountingDataReducer,
  statistics: statisticsReducer,
  statisticsData: statisticsDataReducer,
  recorderSituations: recorderSituationsReducer,
});
