import React, { useEffect, useRef } from 'react';
import { useQuery } from '../../utils';
import { useTranslation } from 'react-i18next';
import { ClipType, Status } from '../../types';
import './SharedMatchPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  getApplicationBreakpointState,
  getApplicationModeState,
  getClipFilters,
  getSharedMatchClipGroup,
  getSharedMatchInfo,
} from '../../selectors';
import { ApplicationBreakpoint, ApplicationMode } from '../../types';
import { Title } from '../../components/Title';
import { MobileTitle } from '../../components/MobileTItle';

import { setSharedMatch } from '../../actions/sharedMatchActions';
import { VideoSwimLine } from '../../components/VideoSwimLine';
import { setClipCategories } from '../../actions/clipCategoriesActions';
import { NoVideos, NoVideosType } from '../../components/NoVideos';

export const SharedMatchPage: React.FC<{}> = () => {
  const shareToken = useQuery().get('share') || '0';
  const clipLineRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const { clipCategories } = useSelector(getClipFilters);
  const clipGroup = useSelector(getSharedMatchClipGroup);
  const { errorMsg, status, sport } = useSelector(getSharedMatchInfo);
  const filters = useSelector(getClipFilters);

  useEffect(() => {
    dispatch(setSharedMatch(shareToken, filters));
  }, [dispatch, shareToken, filters]);

  useEffect(() => {
    if (sport === null) return;
    dispatch(setClipCategories(sport));
  }, [dispatch, sport]);

  const redirectToMatchDetail = (_: string) => {
    return;
  };

  const redirectToVideoDetail = (path: string) => {
    history.push(`${path}&share=${shareToken}&t=fromshared`);
  };

  const videoListPageClasses: string[] = ['VideoListPage', 'SharedMatchPage'];
  if (applicationMode === ApplicationMode.dark) videoListPageClasses.push('VideoListPage--dark');

  return status === Status.error && errorMsg === 'deletedMatchMessage' ? (
    <div className={videoListPageClasses.join(' ')}>
      <NoVideos type={NoVideosType.sharedMatch} />
    </div>
  ) : (
    <div className={videoListPageClasses.join(' ')}>
      {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
        <Title title={t('sharedMatchClips')} activeFilters={clipCategories.length} withFilter />
      ) : (
        <MobileTitle
          title={t('sharedMatchClips')}
          activeFilters={clipCategories.length}
          withFilters
        />
      )}

      {status === Status.success && (
        <div className="VideoListPage__swimLinesWrapper">
          <div className="VideoListPage__swimLines">
            {clipGroup && (
              <VideoSwimLine
                clipGroup={clipGroup}
                clipType={ClipType.myVideo}
                redirectToMatchDetail={redirectToMatchDetail}
                redirectToVideoDetail={redirectToVideoDetail}
                clipLineRef={clipLineRef}
                shouldScroll={false}
                seePrivateNote={false}
                uncollapsed
              />
            )}

            {!clipGroup?.clips.length && <NoVideos type={NoVideosType.community} />}
          </div>
        </div>
      )}
    </div>
  );
};
