import { useEffect } from 'react';

/**
 * binds `ENTER` keypress to the `onConfirm` method
 * and `ESC` keypress to the `onClose` method
 * @param isModalVisible
 * @param onClose will execute when `ESC` is pressed
 * @param onConfirm will execute when `ENTER` is pressed
 */
export const useModalKeyboardShortcuts = (
  isModalVisible: boolean,
  onClose: () => void,
  onConfirm: () => void
) => {
  useEffect(() => {
    if (!isModalVisible) return;

    const handleKeyPress = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Enter':
          onConfirm();
          break;
        case 'Escape':
          onClose();
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isModalVisible, onClose, onConfirm]);
};
