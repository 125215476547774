import React, { FunctionComponent } from 'react';

import './FloatingAddButton.scss';

type FloatingAddButtonProps = {
  handleClick: React.MouseEventHandler<HTMLDivElement>;
};

export const FloatingAddButton: FunctionComponent<FloatingAddButtonProps> = ({ handleClick }) => (
  <div onClick={handleClick} className="FloatingAddButton" />
);
