import csLocale from 'date-fns/locale/cs';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import skLocale from 'date-fns/locale/sk';

export const getLocaleInfo = (settings: any): { locale: Locale; ampm: boolean } => {
  switch (settings?.language.value) {
    case 'cs_CZ':
      return {
        locale: csLocale,
        ampm: false,
      };
    case 'en_US':
      return {
        locale: enLocale,
        ampm: true,
      };
    case 'es_ES':
      return {
        locale: esLocale,
        ampm: false,
      };
    case 'sk_SK':
      return {
        locale: skLocale,
        ampm: false,
      };
    default:
      return {
        locale: enLocale,
        ampm: true,
      };
  }
};
