import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getApplicationModeState } from '../../selectors';
import { TimeStamp } from '../../types';
import { iconTypeHandler, timePresentation, useModalKeyboardShortcuts } from '../../utils';
import { Button } from '../Button';
import { Icon, IconType } from '../Icon';
import { Modal } from '../Modal';
import { TimeInput } from '../TimeInput';
import { VideoPlayer } from '../VideoPlayer';
import './MatchDetailVideoResyncTimeModal.scss';

type MatchDetailVideoResyncTimeModalProps = {
  newMatch: any; // MatchDetail, ClipDetail
  syncEntry: number;
  setClipResync: any;
  setProxy: (videoUrl: string) => string;
  sync: TimeStamp | any;
  showConfirmation?: boolean;
  type?: 'time' | 'button';
  isVisible?: boolean;
  syncMap?: Map<number, number>;
  setSyncMap?: (id: number, syncTime: number) => void;
  _setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  clipSyncTime?: number;
  setForceStopPlaying?: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenedByDefault?: boolean;
  handleOpenedByDefault?: () => void;
};

export const MatchDetailVideoResyncTimeModal: FunctionComponent<MatchDetailVideoResyncTimeModalProps> = ({
  newMatch,
  syncEntry,
  setProxy,
  setClipResync,
  sync,
  type,
  isVisible,
  _setModalVisible,
  syncMap,
  setSyncMap,
  showConfirmation = false,
  clipSyncTime,
  setForceStopPlaying,
  isOpenedByDefault = false,
  handleOpenedByDefault,
}) => {
  const { t } = useTranslation();

  const [isConfirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false);
  const [videoProgressTime, setVideoProgressTime] = useState<number>(0);
  const [isSecondModalVisible, setSecondModalVisible] = useState<boolean>(false);

  useEffect(() => {
    setSecondModalVisible(isOpenedByDefault);
  }, [isOpenedByDefault]);

  const [clipSyncInfo, setClipSyncInfo] = useState(0);

  const handleModalVisibilityChange = (e: any, fromChangeModals = false) => {
    if (_setModalVisible) {
      _setModalVisible(false);
    } else {
      setConfirmationModalVisible(!isConfirmationModalVisible);
    }
    if (setForceStopPlaying && !fromChangeModals) setForceStopPlaying(false);
    if (e && e.stopPropagation) e.stopPropagation();
  };

  const handleSecondModalVisibilityChange = (e?: any, fromChangeModals = false) => {
    setSecondModalVisible(!isSecondModalVisible);
    if (setForceStopPlaying && !fromChangeModals) setForceStopPlaying(false);
    if (e && e.stopPropagation) e.stopPropagation();
    if (handleOpenedByDefault) handleOpenedByDefault();
  };

  const changeModals = (e: React.SyntheticEvent) => {
    handleModalVisibilityChange(e, true);
    handleSecondModalVisibilityChange(e, true);
  };

  useEffect(() => {
    if (!syncMap) {
      setClipSyncInfo(clipSyncTime ?? 0);
    } else {
      setClipSyncInfo(syncMap?.get(sync.id!) ?? 0);
    }
  }, [clipSyncInfo, clipSyncTime, sync.id, syncMap]);

  const setSyncTimeFromVideo = () => {
    if (!sync.id) return;
    const timeDelta = videoProgressTime - sync.time;
    if (setSyncMap && syncMap) {
      setSyncMap(sync.id, timeDelta);
      // time delta + previous, saved, sync - previous, unsaved resync. See syncMap in MatchDetailVideoContent
      setClipResync(timeDelta + (sync.syncTime ?? 0) - clipSyncInfo, sync.id);
    } else {
      // This gets called only from ClipDetailTitle
      setClipResync(timeDelta + (sync.syncTime ?? 0));
      setClipSyncInfo(clipSyncTime ?? 0);
    }

    handleSecondModalVisibilityChange();
  };

  const applicationMode = useSelector(getApplicationModeState);

  const getContext = () => {
    const synchronizationSet = syncEntry !== 0;
    const noVideoSet = (!newMatch.videoUrl && !newMatch.videoLink) || !sync.id;
    if (noVideoSet) {
      return {
        className:
          'MatchDetailVideoContent__fileInputButton MatchDetailVideoContent__fileInputButton--gray',
        label: t('setResyncMoment'),
        onClick: undefined,
        state: 'unactive',
      };
    }

    if (synchronizationSet) {
      return {
        className:
          'MatchDetailVideoContent__fileInputButton MatchDetailVideoContent__fileInputButton--green',
        label: t('syncTimeSet'),
        onClick: showConfirmation ? handleModalVisibilityChange : handleSecondModalVisibilityChange,
        state: 'done',
      };
    }

    return {
      className: 'MatchDetailVideoContent__fileInputButton',
      label: t('setResyncMoment'),
      onClick: showConfirmation ? handleModalVisibilityChange : handleSecondModalVisibilityChange,
      state: null,
    };
  };

  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const context = getContext();

  useModalKeyboardShortcuts(
    (isConfirmationModalVisible || isVisible) ?? false,
    () => handleModalVisibilityChange(undefined),
    () => {
      handleModalVisibilityChange(undefined, true);
      handleSecondModalVisibilityChange(undefined, true);
    }
  );

  useModalKeyboardShortcuts(isSecondModalVisible, handleSecondModalVisibilityChange, () => {
    if (newMatch.videoUrl) {
      return setSyncTimeFromVideo;
    }
    return () => {
      return;
    };
  });

  return (
    <div
      className={type === 'button' ? '' : 'MatchDetailVideoContent__fileInputWrapper'}
      style={{ justifyContent: type !== 'button' ? 'flex-end' : '' }}
    >
      {type === 'time' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={context.onClick}
        >
          <TimeInput time={sync.time + clipSyncInfo} passive />
          <div
            onClick={context.onClick}
            style={{
              opacity: 1,
              textAlign: 'right',
              marginLeft: '1em',
              userSelect: 'none',
            }}
          >
            <Icon
              size="18px"
              name="arrowForward"
              type={iconTypeHandler(applicationMode, IconType.red)}
            />
          </div>
        </div>
      )}

      {type === 'button' && (
        <div onClick={context.onClick} className="ClipController__tooltipButton">
          <Icon
            size="20px"
            type={iconTypeHandler(applicationMode, IconType.white)}
            name="synchronization"
          />
          <div className="ClipController__tooltipButtonLabel" style={{ maxWidth: '65vw' }}>
            {t('useClipForResync')}
          </div>
        </div>
      )}

      {/* Displays only if prop showConfirmation is true */}
      <Modal
        isVisible={(isConfirmationModalVisible || isVisible) ?? false}
        width="600px"
        height="fit-content"
        handleClose={handleModalVisibilityChange}
      >
        <div className="MatchDetailResyncClipsModal__confirmation-modal">
          <div>{t('resync-explanation')}</div>
        </div>

        <div className="MatchDetailResyncClipsModal__buttons">
          <Button label={t('cancel')} onClick={handleModalVisibilityChange} type="secondary" />

          <Button label={t('resync')} onClick={changeModals} type="primary" />
        </div>
      </Modal>

      <Modal
        isVisible={isSecondModalVisible}
        width="fit-content"
        height="fit-content"
        handleClose={handleSecondModalVisibilityChange}
      >
        <div className="MatchDetailVideoContent__playVideoModalHeader">{t('setResyncMoment')}</div>
        <div
          className="MatchDetailVideoContent__playVideoSyncTimeWrapperModal"
          onClick={stopPropagation}
        >
          {newMatch.videoUrl && !syncMap ? (
            <VideoPlayer
              videoSrc={setProxy(newMatch.videoUrl)}
              setProgressTime={setVideoProgressTime}
              startPlayingAt={sync.time + clipSyncInfo - (sync.syncTime ?? 0)}
            />
          ) : (
            <VideoPlayer
              videoSrc={setProxy(newMatch.videoUrl)}
              setProgressTime={setVideoProgressTime}
              startPlayingAt={sync.time + clipSyncInfo}
            />
          )}
        </div>
        <div className="MatchDetailVideoContent__centered">
          {!newMatch.videoUrl && (
            <div className="MatchDetailVideoContent__playVideoContainer">
              <div className="MatchDetailVideoContent__fileInputWrapper">
                <TimeInput time={syncEntry} withLabels />
              </div>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              label={t('back')}
              onClick={handleSecondModalVisibilityChange}
              type="secondary"
            />

            {newMatch.videoUrl && (
              <Button
                label={`${t('setSyncTimeNow')} ${timePresentation(videoProgressTime, true)}`}
                type="primary"
                onClick={setSyncTimeFromVideo}
                adaptable={true}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
