import React, { useCallback } from 'react';
import { MatchDetail } from '../../types/match';
import { MatchDetailAccountingContent } from '../MatchDetailAccountingContent';
import { MatchDetailNotesContent } from '../MatchDetailNotesContent';
import { useSelector } from 'react-redux';
import { getApplicationBreakpointState } from '../../selectors';
import { ApplicationBreakpoint } from '../../types';

import './MatchDetailOthersTab.scss';

type MatDetailOthersTabProps = {
  newMatch: MatchDetail;
  handleNewMatch: React.Dispatch<React.SetStateAction<MatchDetail>>; // TODO-refactor: nahradit raději dispatchem v daných komponentach
};

const MatchDetailOthersTab: React.FC<MatDetailOthersTabProps> = ({ newMatch, handleNewMatch }) => {
  const breakpoint = useSelector(getApplicationBreakpointState);

  const classHandler = useCallback(
    (baseClass: string) => {
      let classes = [baseClass];
      if (breakpoint !== ApplicationBreakpoint.desktop) {
        classes = [...classes, `${baseClass}--mobile`];
      }
      return classes.join(' ');
    },
    [breakpoint]
  );

  return (
    <div className={`${classHandler('MatchDetailOthersTab__container')}`}>
      <MatchDetailAccountingContent newMatch={newMatch} handleNewMatch={handleNewMatch} />
      <MatchDetailNotesContent newMatch={newMatch} handleNewMatch={handleNewMatch} />
    </div>
  );
};

export default MatchDetailOthersTab;
