import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';

import { InputWrapper } from '../InputWrapper';
import {
  getApplicationModeState,
  getCategories,
  getCompetitions,
  getSettings,
} from '../../selectors';
import {
  Category,
  Competition,
  Gender,
  MatchDetail,
  Referee,
  RefereeTeamType,
  Teams,
} from '../../types';
import { classHandler, getLocaleInfo } from '../../utils';

import './MatchDetailInfoContent.scss';
import { getOtherRoles, getRefereeTeamTypes, getRoles } from '../../selectors/refereeTeamTypes';

type MatchDetailInfoContentProps = {
  newMatch: MatchDetail | null;
  handleNewMatch: any;
};

export const MatchDetailInfoContent: FunctionComponent<MatchDetailInfoContentProps> = ({
  newMatch,
  handleNewMatch,
}) => {
  const { t } = useTranslation();
  const applicationMode = useSelector(getApplicationModeState);
  const categories = useSelector(getCategories(newMatch?.competition || 0));
  const refereeTeamTypes = useSelector(getRefereeTeamTypes);
  const roles = useSelector(getRoles(newMatch?.refereeTeamType || 0));
  const otherRoles = useSelector(
    getOtherRoles(newMatch?.refereeTeamType || 0, newMatch?.myRole || '')
  );
  const settings = useSelector(getSettings);

  const competitions = useSelector(getCompetitions(newMatch?.gender || Gender.men));

  if (!newMatch) return null;

  const handleDateChange = (date: any) => {
    handleNewMatch({ ...newMatch, date });
  };

  const handleTimeChange = (time: any) => {
    handleNewMatch({ ...newMatch, time });
  };

  const handleChange = (e: any) => {
    const name: keyof Teams | keyof MatchDetail = e.target.name;
    const value = e.target.value;
    const editedMatch = { ...newMatch };

    if (name === 'firstTeam' || name === 'secondTeam') {
      editedMatch.teams[name] = value;
    } else if (name === 'refereeTeamType') {
      editedMatch[name] = value || 0;
    } else {
      // @ts-ignore
      editedMatch[name] = value;
    }

    if (name === 'competition') {
      editedMatch.category = 0;
    }

    if (name === 'refereeTeamType') {
      editedMatch.myRole = '';
      const newReferees: Referee[] = otherRoles.map((role: string, index: number) => {
        return { id: index, name: '', role };
      });

      editedMatch.referees = newReferees;
    } else if (name === 'myRole') {
      const newReferees: Referee[] = roles
        .filter((role: string) => role !== value)
        .map((role: string, index: number) => {
          return { id: index, name: '', role };
        });

      editedMatch.referees = newReferees;
    }

    handleNewMatch(editedMatch);
  };

  const { locale, ampm } = getLocaleInfo(settings);

  const renderCompetitions = competitions
    .filter((com: any) => com.sport === settings?.sports.id)
    .map((competition: Competition) => {
      return (
        <MenuItem key={competition.id} value={competition.id}>
          {t(competition.name)}
        </MenuItem>
      );
    });

  const renderCategories = categories.map((category: Category) => {
    return (
      <MenuItem key={category.id} value={category.id}>
        {t(category.name)}
      </MenuItem>
    );
  });

  const renderRefereeTeamTypes = refereeTeamTypes.map((refereeTeamType: RefereeTeamType) => {
    return (
      <MenuItem key={refereeTeamType.id} value={refereeTeamType.id}>
        {`${t(refereeTeamType.name)}`}
      </MenuItem>
    );
  });

  const renderMyRoles = roles.map((role: string, index: number) => {
    return (
      <MenuItem key={index} value={role}>
        {`${t(role)}`}
      </MenuItem>
    );
  });

  const renderReferees = newMatch.referees.map((referee: Referee) => {
    const handleRefereeChange = (event: any) => {
      const newReferees = newMatch.referees.map((_referee: Referee) => {
        if (_referee.id === referee.id) {
          return { ..._referee, name: event.target.value };
        }
        return _referee;
      });

      handleNewMatch({ ...newMatch, referees: newReferees });
    };

    return (
      <InputWrapper key={referee.id} iconName="handshake">
        <TextField
          label={t(referee.role)}
          className="MatchDetailInfoContent__textFiled"
          value={referee.name}
          onChange={handleRefereeChange}
        />
      </InputWrapper>
    );
  });

  return (
    <div className={classHandler('MatchDetailInfoContent', applicationMode)}>
      <div className="MatchDetailInfoContent__container">
        <div className="MatchDetailInfoContent__containerHeading">{t('gameDetails')}</div>
        <InputWrapper iconName="calendar">
          <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            <DatePicker
              label={t('date')}
              className="MatchDetailInfoContent__datePicker"
              disableToolbar={true}
              value={newMatch.date}
              onChange={handleDateChange}
              okLabel={t('save')}
              cancelLabel={t('cancel')}
            />
          </MuiPickersUtilsProvider>
        </InputWrapper>

        <InputWrapper iconName="clock">
          <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            <TimePicker
              label={t('time')}
              ampm={ampm}
              className="MatchDetailInfoContent__timePicker"
              value={newMatch.time}
              // tslint:disable-next-line
              onChange={date => handleTimeChange(date)}
              okLabel={t('save')}
              cancelLabel={t('cancel')}
            />
          </MuiPickersUtilsProvider>
        </InputWrapper>

        <InputWrapper iconName="location">
          <TextField
            name="location"
            label={t('location')}
            className="MatchDetailInfoContent__textFiled"
            value={newMatch.location}
            onChange={handleChange}
          />
        </InputWrapper>

        <InputWrapper iconName="gender" formControl>
          <InputLabel id="gender-label">{t('gender')}</InputLabel>
          <Select
            labelId="gender-label"
            name="gender"
            className="MatchDetailInfoContent__select"
            value={newMatch.gender}
            onChange={handleChange}
          >
            <MenuItem value={Gender.men}>{t('men')}</MenuItem>
            <MenuItem value={Gender.women}>{t('women')}</MenuItem>
          </Select>
        </InputWrapper>

        <InputWrapper iconName="cup" formControl>
          <InputLabel id="competition-label">{t('competition')}</InputLabel>
          <Select
            name="competition"
            labelId="competition-label"
            className="MatchDetailInfoContent__select"
            value={newMatch.competition || ''}
            onChange={handleChange}
          >
            <MenuItem key="" value="">
              <em>{t('none')}</em>
            </MenuItem>
            {renderCompetitions}
          </Select>
        </InputWrapper>
        {newMatch.competition !== 0 && (
          <InputWrapper iconName="category" formControl>
            <InputLabel id="category-label">{t('category')}</InputLabel>
            <Select
              name="category"
              labelId="category-label"
              className="MatchDetailInfoContent__select"
              value={newMatch.category || ''}
              onChange={handleChange}
            >
              <MenuItem key="" value="">
                <em>{t('none')}</em>
              </MenuItem>
              {renderCategories}
            </Select>
          </InputWrapper>
        )}

        <InputWrapper iconName="team">
          <div className="MatchDetailInfoContent__teamsWrapper">
            <TextField
              name="firstTeam"
              label={t('teamA')}
              className="MatchDetailInfoContent__textFiled"
              value={newMatch.teams.firstTeam}
              onChange={handleChange}
            />
            <div className="MatchDetailInfoContent__textFiledDivider">vs.</div>
            <TextField
              name="secondTeam"
              label={t('teamB')}
              className="MatchDetailInfoContent__textFiled"
              value={newMatch.teams.secondTeam}
              onChange={handleChange}
            />
          </div>
        </InputWrapper>
      </div>
      <div className="MatchDetailInfoContent__container">
        <div className="MatchDetailInfoContent__containerHeading">{t('refereeCrew')}</div>
        <InputWrapper iconName="handshake" formControl>
          <InputLabel id="refereeTeamType-label">{t('refTeamType')}</InputLabel>
          <Select
            name="refereeTeamType"
            labelId="refereeTeamType-label"
            className="MatchDetailInfoContent__select"
            value={newMatch.refereeTeamType || ''}
            onChange={handleChange}
          >
            <MenuItem key="" value="">
              <em>{t('none')}</em>
            </MenuItem>
            {renderRefereeTeamTypes}
          </Select>
        </InputWrapper>

        {newMatch.refereeTeamType !== 0 && (
          <InputWrapper iconName="handshake" formControl>
            <InputLabel id="myRole-label">{t('myRole')}</InputLabel>
            <Select
              name="myRole"
              labelId="myRole-label"
              className="MatchDetailInfoContent__select"
              value={newMatch.myRole}
              onChange={handleChange}
            >
              <MenuItem key="" value="">
                <em>{t('none')}</em>
              </MenuItem>
              {renderMyRoles}
            </Select>
          </InputWrapper>
        )}

        {newMatch.refereeTeamType !== 0 && newMatch.myRole !== '' && renderReferees}

        <InputWrapper iconName="eye">
          <TextField
            label={t('supervisor')}
            className="MatchDetailInfoContent__textFiled"
            value={newMatch.chiefReferee}
            name="chiefReferee"
            onChange={handleChange}
          />
        </InputWrapper>
      </div>
    </div>
  );
};
