import { AnyAction } from 'redux';

import { StatisticsState, Status } from '../types';
import {
  SET_STATISTICS_ERROR,
  SET_STATISTICS_REQUESTING,
  SET_STATISTICS_SUCCESS,
} from '../constants';

const initStatisticsState: StatisticsState = {
  status: Status.idle,
  errorMsg: null,
  statistics: [],
};

export const statisticsReducer = (
  state: StatisticsState = initStatisticsState,
  action: AnyAction
): StatisticsState => {
  switch (action.type) {
    case SET_STATISTICS_REQUESTING: {
      return { status: Status.requesting, errorMsg: null, statistics: [] };
    }
    case SET_STATISTICS_ERROR: {
      const { errorMsg } = action.payload;

      return { status: Status.error, errorMsg, statistics: [] };
    }
    case SET_STATISTICS_SUCCESS: {
      const { statistics } = action.payload;

      return { status: Status.success, errorMsg: null, statistics };
    }
    default:
      return state;
  }
};
