import LockIcon from '@material-ui/icons/Lock';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setPremiumModalVisibility } from '../../actions';
import {
  getApplicationModeState,
  getPremiumModalContentLocKey,
  getPremiumModalVisibility,
} from '../../selectors';
import { classHandler, useModalKeyboardShortcuts } from '../../utils';
import { Modal } from '../Modal';
import { SaveButtonsWrapper } from '../SaveButtonsWrapper';
import './PremiumFeatureModal.scss';

export const PremiumFeatureModal: FunctionComponent<{}> = () => {
  const applicationMode = useSelector(getApplicationModeState);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isVisible = useSelector(getPremiumModalVisibility);
  const contentLocKey = useSelector(getPremiumModalContentLocKey) || 'premiumModalGeneralContent';
  const handleClose = () => {
    dispatch(setPremiumModalVisibility(false));
  };

  useModalKeyboardShortcuts(isVisible, handleClose, handleClose);

  return (
    <Modal isVisible={isVisible} width="fit-content" height="fit-content" handleClose={handleClose}>
      <div className={classHandler('PremiumFeatureModal', applicationMode)}>
        <div className="PremiumFeatureModal__textIconWrapper">
          <LockIcon className="PremiumFeatureModal__icon" fontSize="large" />
          <div>
            <p>{t(contentLocKey)}</p>
            <p>{t('premiumModalGeneralFooter')}</p>
          </div>
        </div>
        <SaveButtonsWrapper
          primaryButton={{
            label: t('premiumFeatureLearnMore'),
            externalLink: 'https://www.refereerecorder.com/',
          }}
          secondaryButton={{
            label: t('premiumFeatureDismiss'),
            onClick: handleClose,
          }}
          style={{
            margin: '10px 0 0 0',
            padding: '1.2em 0 0 0',
          }}
        />
      </div>
    </Modal>
  );
};
