import { Status, UserState } from '../types';

import { AnyAction } from 'redux';
import { SET_USER_ERROR, SET_USER_REQUESTING, SET_USER_SUCCESS } from '../constants';

const initUserState: UserState = { status: Status.idle, errorMsg: null, user: null };

/**
 * User reducer
 *
 * @params state State of user
 */
export const userReducer = (state: UserState = initUserState, action: AnyAction): UserState => {
  switch (action.type) {
    case SET_USER_REQUESTING: {
      return { status: Status.requesting, errorMsg: null, user: null };
    }
    case SET_USER_ERROR: {
      const { errorMsg } = action.payload;

      return { status: Status.error, errorMsg, user: null };
    }
    case SET_USER_SUCCESS: {
      const { user } = action.payload;

      return { status: Status.success, errorMsg: null, user };
    }
    default:
      return state;
  }
};
