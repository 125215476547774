import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAccountingData } from '../../actions';
import { getAccountingFilter, getAccountingsData, getApplicationCurrency } from '../../selectors';

import { StatisticsCard } from '../StatisticsCard';
import './AccountingYearTab.scss';

export const COMMUNITY_TAB_ID = 1;

type AccountingYearTabProps = {
  year: string;
  link: string;
};

export const AccountingYearTab: FunctionComponent<AccountingYearTabProps> = ({ year, link }) => {
  const dispatch = useDispatch();
  const data = useSelector(getAccountingsData);
  const currency = ' ' + useSelector(getApplicationCurrency);
  const filter = useSelector(getAccountingFilter);

  useEffect(() => {
    dispatch(setAccountingData(link, filter));
  }, [dispatch, link, year, filter]);

  return (
    <div className="AccountingCard__container">
      {/* Income Taxable */}
      {!!data.taxable && (
        <StatisticsCard
          data={data.taxable}
          label="Income Taxable"
          firstColumn={link === 'all' ? 'season' : 'month'}
          secondColumn="amount"
          bottomText="Total"
          postFix={currency}
          explanation="rewards & workday bonuses"
          lock={true}
        />
      )}
      {/* Income Non-taxable */}
      {!!data.nonTaxable && (
        <StatisticsCard
          data={data.nonTaxable}
          label="Income Non-taxable"
          firstColumn={link === 'all' ? 'season' : 'month'}
          secondColumn="amount"
          bottomText="Total"
          postFix={currency}
          explanation="food & travel costs"
          lock={true}
        />
      )}
      {/* Income Total */}
      {!!data.total && (
        <StatisticsCard
          data={data.total}
          label="Income TOTAL"
          firstColumn={link === 'all' ? 'season' : 'month'}
          secondColumn="amount"
          bottomText="Total"
          postFix={currency}
          explanation=""
          lock={true}
        />
      )}
      {/* Income Total - Competitions overview */}
      {!!data.competitions && (
        <StatisticsCard
          data={data.competitions}
          label="Income TOTAL - Competitions overview"
          firstColumn="name"
          secondColumn="amount"
          bottomText="Total"
          postFix={currency}
          explanation=""
          lock={true}
        />
      )}
    </div>
  );
};
