import { Accounting, AccountingData, AccountingFilter, RootState, Status } from '../types';

export const getAccountingsStatus = (state: RootState): Status => state.accounting.status;

export const getAccountingsErrorMsg = (state: RootState): string | null =>
  state.accounting.errorMsg;

// export const getAccountingsTotalCount = (state: RootState): number => state.accounting.totalCount;

export const getAccountings = (state: RootState): Accounting[] => state.accounting.accounting;

export const getAccountingsData = (state: RootState): AccountingData =>
  state.accountingData.accountingData;

export const getAccountingFilter = (state: RootState): AccountingFilter => state.accounting.filter;
