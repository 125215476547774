import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { Icon, IconType } from '../Icon';
import { classHandler } from '../../utils';
import { getApplicationModeState } from '../../selectors';

import './ScrollButton.scss';

export enum ScrollButtonType {
  left = 'left',
  right = 'right',
}

type ScrollButtonProps = {
  type: ScrollButtonType;
  handleClick: React.MouseEventHandler<HTMLDivElement>;
  isInvisible: boolean;
};

export const ScrollButton: FunctionComponent<ScrollButtonProps> = ({
  type,
  handleClick,
  isInvisible,
}) => {
  const applicationMode = useSelector(getApplicationModeState);

  const customStyles: any = {};
  if (isInvisible) {
    customStyles.backgroundColor = 'transparent';
    customStyles.cursor = 'auto';
  }
  if (type === ScrollButtonType.right) customStyles.margin = '0 0 0 -80px';

  return (
    <div
      style={customStyles}
      onClick={handleClick}
      className={classHandler('ScrollButton', applicationMode)}
    >
      {!isInvisible && (
        <Icon
          name={type === ScrollButtonType.left ? 'arrowBack' : 'arrowForward'}
          type={IconType.default}
          size="15px"
        />
      )}
    </div>
  );
};
