import { Gender, Teams } from './';
import { Match, VideoStatus } from './match';
import { Status } from './state';

export interface MyClip {
  id: number;
  name: string;
  note: string;
  publicNote: string;
  categories: ClipCategory[];
  imageUrl: string;
}

export interface MyClipGroup {
  id: number; // matchId
  date: Date;
  competition: string;
  gender: Gender;
  category: string;
  teams: Teams;
  clips: MyClip[];
}

export interface CommunityClip {
  id: number;
  name: string;
  note: string;
  publicNote: string;
  categories: ClipCategory[];
  matchDate: Date;
  location: string;
  competition: string;
  gender: Gender;
  category: string;
  teams: Teams;
  imageUrl: string;
}

export interface CommunityClipGroup {
  id: number;
  title: string;
  clips: CommunityClip[];
  count: number;
  status: Status;
}

export interface ClosedCommunityClip {
  id: number;
  name: string;
  note: string;
  publicNote: string;
  categories: ClipCategory[];
  matchDate: Date;
  location: string;
  competition: string;
  gender: Gender;
  category: string;
  teams: Teams;
  imageUrl: string;
}

export interface ClosedCommunityClipGroup {
  id: number;
  title: string;
  clips: ClosedCommunityClip[];
  count: number;
  status: Status;
}

export interface FavouriteClip {
  id: number;
  name: string;
  note: string;
  publicNote: string;
  categories: ClipCategory[];
  matchDate: Date;
  location: string;
  teams: Teams;
  gender: Gender;
  imageUrl: string;
}

export interface FavouriteClipGroup {
  id: number;
  title: string;
  clips: FavouriteClip[];
  count: number;
  status: Status;
}

export interface ClipFilters {
  clipCategories: ClipCategory[];
}

export type ClipGroup =
  | MyClipGroup
  | CommunityClipGroup
  | FavouriteClipGroup
  | ClosedCommunityClipGroup;

export type Clip = MyClip | CommunityClip | FavouriteClip;

export enum ClipType {
  myVideo = 'myVideo',
  community = 'community',
  favourite = 'favourite',
  closedCommunity = 'closedCommunity',
}

export interface ClipDetail {
  id: number;
  name: string;
  isPrivate: boolean;
  isFavorite: boolean;
  syncTime: number;
  time: number;
  startTime: number;
  endTime: number;
  videoUrl: string;
  videoStatus: VideoStatus;
  note: string;
  publicNote: string;
  clipCategories: ClipCategory[];
  sport: number;
  videoClipUrl: string;
  videoClipName: string;
  ratings: Ratings;
  totalRating: number;
  reportDisabled?: boolean;
  closedCommunityName?: string;
  isClosedCommunityShared?: boolean;
}

export interface Ratings {
  liked: number;
  disliked: number;
  canUserReact?: boolean;
  userReaction: 'liked' | 'disliked' | null;
}

export interface ClipDetailWithMatch extends ClipDetail {
  match: Match;
  ownerId: number;
}

export interface ClipCategory {
  id: number;
  type: string;
  value: string;
  shortCut: string;
}

export interface ClipCategoryGrouped {
  type: string;
  values: ClipCategory[];
}

export interface Quality {
  label: string;
  value: number;
}

export interface PlaybackSpeed {
  label: string;
  value: number;
}

export interface DownloadClipResponse {
  videoClipName: string;
  videoClipUrl: string;
}

export interface DownloadAllClipsResponse {
  ready: boolean;
  error: boolean;
}

export enum ClipReaction {
  like = 'like',
  dislike = 'dislike',
  unreact = 'unreact',
}
