import {
  RootState,
  Status,
  MyClipGroup,
  CommunityClipGroup,
  FavouriteClipGroup,
  ClipFilters,
  ClosedCommunityClipGroup,
} from '../types';

export const getClipsStatus = (state: RootState): Status => state.clips.status;

export const getClipsErrorMsg = (state: RootState): string | null => state.clips.errorMsg;

export const getMyClipGroups = (state: RootState): MyClipGroup[] => state.clips.myClipGroups;

export const getCommunityClipGroups = (state: RootState): CommunityClipGroup[] =>
  state.clips.communityClipGroups;

export const getClosedCommunityClipGroups = (state: RootState): ClosedCommunityClipGroup[] =>
  state.clips.closedCommunityClipGroups;

export const getClosedCommunityClipGroupCount = (state: RootState): number =>
  state.clips.closedCommunityClipGroupCount;

export const getClosedCommunityName = (state: RootState): string | null =>
  state.clips.closedCommunityName;

export const getFavouriteClipGroups = (state: RootState): FavouriteClipGroup[] =>
  state.clips.favouriteClipGroups;

export const getMyClipGroupCount = (state: RootState): number => state.clips.myClipGroupCount;

export const getCommunityClipGroupCount = (state: RootState): number =>
  state.clips.communityClipGroupCount;

export const getFavouriteClipGroupCount = (state: RootState): number =>
  state.clips.favouriteClipGroupCount;

export const getClipFilters = (state: RootState): ClipFilters => state.clips.filters;

export const getSharedMatchClipGroup = (state: RootState): MyClipGroup | undefined =>
  state.sharedMatch.sharedMatchClipGroup;

export const getSharedMatchInfo = (
  state: RootState
): { status: Status; sport: number | null; errorMsg: string | null } => state.sharedMatch;
