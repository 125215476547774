import { Dispatch } from 'redux';

import { SET_USER_ERROR, SET_USER_REQUESTING, SET_USER_SUCCESS } from '../constants';
import { Credentials, ErrorResponse, Month, Settings, User, UserResponse } from '../types';
import { getIsPremiumUntil, getUser, postLogin } from '../api';
import { isErrorResponse, removeJwtFromLocalStorage, setJwtToLocalStorage } from '../utils';
import { resetLanguage, setSettingsSuccess } from './settingsActions';

export const setUserRequesting = () => ({ type: SET_USER_REQUESTING });

export const setUserError = (errorMsg: string) => {
  return {
    type: SET_USER_ERROR,
    payload: { errorMsg },
  };
};

export const setUserSuccess = (user: User | null) => {
  return {
    type: SET_USER_SUCCESS,
    payload: { user },
  };
};

export const setUserPremium = (user: User | null) => {
  if (user) user.isPremium = true;

  return {
    type: SET_USER_SUCCESS,
    payload: { user },
  };
};

export const loginUser = (credentials: Credentials) => (dispatch: Dispatch) => {
  dispatch(setUserRequesting());

  postLogin(credentials).then((response: UserResponse | ErrorResponse) => {
    if (isErrorResponse(response)) {
      const errorResponse = response as ErrorResponse;

      dispatch(setUserError(errorResponse.message[0].messages[0].message));
    } else {
      const userResponse = response as UserResponse;

      const transformedUser: User = {
        id: userResponse.user.id || 0,
        firstName: userResponse.user.firstName,
        lastName: userResponse.user.lastName,
        isPremium: userResponse.user.isPremium,
        photoUrl: userResponse.user.avatarUrl,
        matchesCount: userResponse.user.matchesCount,
        level: userResponse.user.level || 0,
      };

      const transformedSettings: Settings = {
        country: userResponse.settings.country,
        sports: userResponse.settings.sports,
        language: userResponse.settings.appLanguage,
        darkMode: userResponse.settings.darkMode,
        defaultVideoStartingTime: userResponse.settings.videoDefaultSpareTime || 0,
        defaultVideoEndingTime: userResponse.settings.videoDefaultEndingSpareTime || 0,
        statisticsFrom: userResponse.settings.statisticsFrom || Month.January,
        statisticsTo: userResponse.settings.statisticsTo || Month.December,
      };

      setJwtToLocalStorage(userResponse.jwt);
      dispatch(setUserSuccess(transformedUser));
      dispatch(setSettingsSuccess(transformedSettings));
    }
  });
};

export const logoutUser = () => (dispatch: Dispatch) => {
  removeJwtFromLocalStorage();
  dispatch(setUserSuccess(null));
  dispatch(resetLanguage());
};

export const setUserWithJwt = (jwt: string) => (dispatch: Dispatch) => {
  dispatch(setUserRequesting());

  getUser(jwt).then((response: UserResponse | ErrorResponse) => {
    if (isErrorResponse(response)) {
      const errorResponse = response as ErrorResponse;

      dispatch(setUserError(errorResponse.message[0].messages[0].message));
    } else {
      const userResponse = response as UserResponse;

      const transformedUser: User = {
        id: userResponse.user.id || 0,
        firstName: userResponse.user.firstName,
        lastName: userResponse.user.lastName,
        isPremium: userResponse.user.isPremium,
        photoUrl: userResponse.user.avatarUrl,
        matchesCount: userResponse.user.matchesCount,
        level: userResponse.user.level || 0,
      };

      const transformedSettings: Settings = {
        country: userResponse.settings.country,
        sports: userResponse.settings.sports,
        language: userResponse.settings.appLanguage,
        darkMode: userResponse.settings.darkMode,
        defaultVideoStartingTime: userResponse.settings.videoDefaultSpareTime || 0,
        defaultVideoEndingTime: userResponse.settings.videoDefaultEndingSpareTime || 0,
        statisticsFrom: userResponse.settings.statisticsFrom || Month.January,
        statisticsTo: userResponse.settings.statisticsTo || Month.December,
      };

      setJwtToLocalStorage(userResponse.jwt);
      dispatch(setUserSuccess(transformedUser));
      dispatch(setSettingsSuccess(transformedSettings));
    }
  });
};

export const setIsPremiumUntil = (jwt: string | null, user: User | null) => (
  dispatch: Dispatch
) => {
  if (!jwt || !user || !user.id) {
    return;
  }
  getIsPremiumUntil(jwt, user.id).then(response => {
    if (!isErrorResponse(response) && new Date(response.isPremiumUntil) > new Date()) {
      dispatch(setUserPremium(user));
    }
  });
};
