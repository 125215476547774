export enum ApplicationMode {
  dark = 'dark',
  light = 'light',
}

export enum ApplicationBreakpoint {
  desktop = 'desktop',
  tablet = 'tablet',
  mobile = 'mobile',
}

export interface ApplicationState {
  applicationMode: ApplicationMode;
  applicationBreakpoint: ApplicationBreakpoint;
  filterPanelVisibility: boolean;
  sidePanelVisibility: boolean;
  premiumModalVisibility: boolean;
  premiumModalContentLocKey: string | null;
  loginRequiredModalVisibility: boolean;
  country: string;
  currency: string;
  sport: string;
  language: string;
  defaultStartTime: number;
  defaultEndTime: number;
  infoModalVisibility: boolean;
  infoModalText: string;
}

export enum Platform {
  web = 'web',
  iOSWeb = 'iOSWeb',
  iOSApp = 'iOSApp',
  androidApp = 'androidApp',
  androidWeb = 'androidWeb',
}
