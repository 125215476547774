export default () => {
  // eslint-disable-next-line
  self.addEventListener('message', (e: MessageEvent) => {
    if (!e.data) {
      return;
    }
    // console.log(e.data)

    const reader = new FileReaderSync();
    const { file: _file, chunkIndex } = e.data;
    const blob = _file as File;
    const BYTES_PER_CHUNK = 10 * 1024 * 1024; // 10MiB chunk sizes.
    const realFileSize = blob.size;
    const totalChunks = Math.ceil(realFileSize / BYTES_PER_CHUNK);
    const _chunk = blob.slice(chunkIndex * BYTES_PER_CHUNK, BYTES_PER_CHUNK * (chunkIndex + 1));
    const chunk = reader.readAsDataURL(_chunk);
    postMessage({ chunk, totalChunks });
  });
};
