import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MatchDetail, TimeStamp } from '../../types';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import './MatchDetailResyncClipsModal.scss';
import { MatchDetailVideoResyncTimeModal } from '../MatchDetailVideoResyncTimeModal';

import { timePresentation } from '../../utils/calculateTime';
import { useModalKeyboardShortcuts } from '../../utils';

type MatchDetailResyncClipsModalProps = {
  newMatch: MatchDetail;
  syncEntry: number;
  setProxy: (videoUrl: string) => string;
  setClipResync: any;
  isVisible: boolean;
  syncMap: Map<number, number>;
  setSyncMap: (id: number, syncTime: number) => void;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MatchDetailResyncClipsModal: FunctionComponent<MatchDetailResyncClipsModalProps> = ({
  newMatch,
  syncEntry,
  setProxy,
  setClipResync,
  isVisible,
  setModalVisible,
  syncMap,
  setSyncMap,
}) => {
  const { t } = useTranslation();

  const [isSecondModalVisible, setSecondModalVisible] = useState(false);
  const [indexVisibleVideoResyncModal, setIndexVisibleVideoResyncModal] = useState(-1);

  const handleModalVisibilityChange = (e: React.SyntheticEvent) => {
    setModalVisible(!isVisible);
    if (e && e.stopPropagation) e.stopPropagation();
  };

  const handleSecondModalVisibilityChange = () => {
    if (isSecondModalVisible) setIndexVisibleVideoResyncModal(-1);
    setSecondModalVisible(!isSecondModalVisible);
  };

  const changeModals = (e: React.SyntheticEvent) => {
    handleModalVisibilityChange(e);
    handleSecondModalVisibilityChange();
  };

  const renderSyncTimes = newMatch.clips.map((sync: TimeStamp, index: number) => {
    return (
      <React.Fragment key={index}>
        {sync.id && (
          <TableRow hover={true} key={index} onClick={() => setIndexVisibleVideoResyncModal(index)}>
            <TableCell>
              {sync.name}
              {!!sync.syncTime && sync.syncTime !== 0 && (
                <span
                  style={{
                    color: 'white',
                    background: 'black',
                    marginLeft: '1em',
                    display: 'inline-block',
                    padding: '2px 7px',
                    borderRadius: '4px',
                  }}
                >
                  {sync.syncTime >= 0
                    ? `+ ${timePresentation(sync.syncTime, false, true)}`
                    : `- ${timePresentation(-sync.syncTime, false, true)}`}
                </span>
              )}
            </TableCell>
            <TableCell align="right">
              <MatchDetailVideoResyncTimeModal
                newMatch={newMatch}
                sync={sync}
                setClipResync={setClipResync}
                syncEntry={syncEntry}
                setProxy={setProxy}
                syncMap={syncMap}
                setSyncMap={setSyncMap}
                type="time"
                showConfirmation={false}
                isOpenedByDefault={indexVisibleVideoResyncModal === index}
                handleOpenedByDefault={() => setIndexVisibleVideoResyncModal(-1)}
              />
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  });

  const timestampsTable = newMatch.clips.length > 0 && (
    <Table>
      <TableBody>{renderSyncTimes}</TableBody>
    </Table>
  );

  useModalKeyboardShortcuts(
    isVisible,
    () => setModalVisible(prev => !prev),
    () => {
      setModalVisible(prev => !prev);
      handleSecondModalVisibilityChange();
    }
  );

  useModalKeyboardShortcuts(
    isSecondModalVisible,
    handleSecondModalVisibilityChange,
    handleSecondModalVisibilityChange
  );

  return (
    <div style={{ position: 'relative' }}>
      <Modal
        isVisible={isVisible}
        width="600px"
        height="fit-content"
        handleClose={handleModalVisibilityChange}
      >
        <div className="MatchDetailResyncClipsModal__confirmation-modal">
          <div>{t('resync-explanation')}</div>
        </div>

        <div className=" MatchDetailResyncClipsModal__buttons">
          <Button label={t('cancel')} onClick={handleModalVisibilityChange} type="secondary" />

          <Button label={t('resync')} onClick={changeModals} type="primary" />
        </div>
      </Modal>

      <Modal
        isVisible={isSecondModalVisible}
        width="800px"
        height="fit-content"
        className={'ResynchronizationModal'}
        handleClose={handleSecondModalVisibilityChange}
      >
        <div className="MatchDetailVideoContent__fileInputModalHeader">
          {t('resynchronization')}
        </div>
        <div className="MatchDetailVideoContent__fileInputModalText">
          <div>{t('resync-tooltip-1')}</div>
          <div>{t('resync-tooltip-2')}</div>
          <div>{t('resync-tooltip-3')}</div>
          <div className="DigiRecorderStopwatch__label">
            {t('liveRecordingHelpLinkPrefix', '')}
            &nbsp;
            <a href={t('liveRecordingHelpLink')} target="_blank" rel="noopener noreferrer">
              {t('liveRecordingHelpLinkSuffix', '')}
            </a>
            &nbsp;
          </div>
          <div
            className="MatchDetailVideoContent__fileInputModalText"
            style={{ fontWeight: 'bold' }}
          >
            {t('clipToResync')}
          </div>
          <div className="ResynchronizationModal__timestampsWrapperModal">{timestampsTable}</div>
        </div>
        <div className="MatchDetailVideoContent__centered">
          <Button label={t('back')} onClick={handleSecondModalVisibilityChange} type="primary" />
        </div>
      </Modal>
    </div>
  );
};
