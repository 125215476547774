import React, { Fragment, FunctionComponent, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTips } from '../../actions/tipsActions';
import { getApplicationModeState, getTips } from '../../selectors';
import { Tip } from '../../types';
import { classHandler } from '../../utils';
import './TipBar.scss';

export const TipBar: FunctionComponent<{}> = () => {
  const applicationMode = useSelector(getApplicationModeState);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const timer = 8000;
  const tips = useSelector(getTips);

  const [currentTipIndex, setCurrentTipIndex] = useState<number>(0);
  const currentTip: Tip | undefined = tips[currentTipIndex];

  const nextTip = useCallback(() => {
    if (!tips || tips.length === 0) return;
    setCurrentTipIndex((currentTipIndex + 1) % tips.length);
  }, [tips, currentTipIndex]);

  useEffect(() => {
    dispatch(setTips());
  }, [dispatch]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      nextTip();
    }, timer);
    return () => {
      clearTimeout(timeout);
    };
  }, [nextTip]);

  const renderDots = tips.map((tip: Tip, index) => {
    const isActive = currentTip?.id === tip.id;

    return <div key={index} className={classHandler('TipBar__dot', applicationMode, isActive)} />;
  });

  return (
    <div className={classHandler('TipBar', applicationMode)} onClick={nextTip}>
      {currentTip && (
        <Fragment>
          <p className="boldText">{t(currentTip.heading)}</p>
          <p>{t(currentTip.text)}</p>
        </Fragment>
      )}
      <div className="TipBar__dots">{renderDots}</div>
    </div>
  );
};
