import { ClipCategory, RootState, Status } from '../types';

export const getClipCategoriesStatus = (state: RootState): Status => state.clipCategories.status;

export const getClipCategoriesErrorMsg = (state: RootState): string | null =>
  state.clipCategories.errorMsg;

export const getClipCategories = (state: RootState): ClipCategory[] =>
  state.clipCategories.clipCategories;

export const getClipCategoryTypes = (state: RootState): string[] =>
  state.clipCategories.clipCategoryTypes;
