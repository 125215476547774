import {
  ClickAwayListener,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getApplicationModeState } from '../../selectors';
import { MatchDetail, SaveButtonState, TimeStamp, VideoStatus } from '../../types';
import { classHandler, iconTypeHandler, timePresentation } from '../../utils';
import DownloadAllClips from '../DownloadAllClips/DownloadAllClips';
import { Icon, IconType } from '../Icon';
import { MatchDetailResyncClipsModal } from '../MatchDetailResyncClipsModal';
import { MatchDetailVideoAddTimestampModal } from '../MatchDetailVideoAddTimestampModal';
import { MatchDetailVideoTransferTimestampsModal } from '../MatchDetailVideoTransferTimestampsModal';
import { ShareMatch } from '../ShareMatch';
import { TimeInput } from '../TimeInput';

import '../MatchDetailVideoContent/MatchDetailVideoContent.scss';
import './MatchDetailSituationsContent.scss';
import useIsNewMatch from '../../utils/useIsNewMatch';

import setProxy from '../../utils/setProxy';
import DigiRecorderButton from '../DigiRecorderButton/DigiRecorderButton';

interface MatchDetailSituationsContentProps {
  newMatch: MatchDetail | null;
  handleNewMatch: any;
  handleSave: any;
  syncEntry: number;
  previousSyncEntry: number;
  setSyncEntry: any;
  setPreviousSyncEntry: any;
  setAddSrcModalVisible: any;
  syncMap: Map<number, number>;
  setSyncMap: any;
  setSaving: React.Dispatch<React.SetStateAction<SaveButtonState>>;
}

const MatchDetailSituationsContent: React.FC<MatchDetailSituationsContentProps> = ({
  newMatch,
  handleNewMatch,
  handleSave,
  syncEntry,
  setAddSrcModalVisible,
  setSyncMap,
  syncMap,
  setSaving,
}) => {
  const { t } = useTranslation();
  const moreButtonRef = useRef<HTMLDivElement>(null);
  const [tooltipVisibility, setTooltipVisible] = useState<boolean>(false);
  const [modalVisibility, setModalVisible] = useState<boolean>(false);
  const applicationMode = useSelector(getApplicationModeState);
  const isNewMatch = useIsNewMatch();

  const handleTooltipVisibility = useCallback(() => {
    setTooltipVisible(prev => !prev);
  }, [setTooltipVisible]);

  const openModal = useCallback(() => {
    handleTooltipVisibility();
    setModalVisible(prev => !prev);
  }, [handleTooltipVisibility, setModalVisible]);

  const setClipResync = (syncTime: number, id: number) => {
    const clips = newMatch?.clips.map((timeStamp: TimeStamp) => {
      const clip = { ...timeStamp };
      if (clip.id === id) clip.syncTime = syncTime;

      return clip;
    });

    /* ? */
    if (!clips || !newMatch?.id) {
      return;
    }

    handleNewMatch({
      ...newMatch,
      syncTime: syncEntry,
      clips,
    });
  };

  const renderSyncTimes = useCallback(
    () =>
      newMatch?.clips.map((sync: TimeStamp, index: number) => {
        const redirectAvailable = sync?.id;
        const _handleChange = (event: any) => {
          const entryName = event.target.name;
          const entryValue = event.target.value;

          const changedTimeStamps: TimeStamp[] = newMatch.clips.map(
            (timeStamp: TimeStamp, _index: number) => {
              if (_index === index) {
                return entryName !== 'name'
                  ? {
                      ...timeStamp,
                      time: entryName === 'time' ? parseInt(entryValue, 10) : timeStamp.time,
                    }
                  : { ...timeStamp, name: entryValue };
              }

              return timeStamp;
            }
          );

          handleNewMatch({ ...newMatch, clips: changedTimeStamps });
        };

        const goToClipDetail = (event: any) => {
          if (redirectAvailable) handleSave(event, `/video-detail?id=${sync?.id}`, false);
        };

        return (
          <TableRow hover={true} style={{ cursor: 'pointer' }} key={index} onClick={goToClipDetail}>
            <TableCell>
              {sync.name}
              {!!sync.syncTime && sync.syncTime !== 0 && (
                <span
                  style={{
                    color: 'white',
                    background: 'black',
                    marginLeft: '1em',
                    display: 'inline-block',
                    padding: '2px 7px',
                    borderRadius: '4px',
                  }}
                >
                  {sync.syncTime >= 0
                    ? `+ ${timePresentation(sync.syncTime, false, true)}`
                    : `- ${timePresentation(-sync.syncTime, false, true)}`}
                </span>
              )}
            </TableCell>
            <TableCell align="right">
              <div style={{ display: 'flex', flexDirection: 'row', float: 'right' }}>
                <TimeInput
                  time={sync.time + (syncMap.get(sync.id ?? 0) ?? 0)}
                  handleChange={_handleChange}
                  passive
                />
                <div
                  onClick={goToClipDetail}
                  style={{
                    opacity: redirectAvailable ? 1 : 0.2,
                    textAlign: 'right',
                    marginLeft: '1em',
                    userSelect: 'none',
                  }}
                >
                  <Icon size="18px" name="arrowForward" type={IconType.red} />
                </div>
              </div>
            </TableCell>
          </TableRow>
        );
      }),
    [handleNewMatch, handleSave, newMatch, syncMap]
  );

  const renderTimestampsTable = useCallback(() => {
    const l = newMatch?.clips.length;
    if (l && l > 0) {
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('clipName')}</TableCell>
              <TableCell style={{ textAlign: 'right' }}>{t('timeInOrigVideo')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderSyncTimes()}</TableBody>
        </Table>
      );
    }
  }, [t, newMatch, renderSyncTimes]);

  const renderMoreButton = useCallback(() => {
    if (isNewMatch || !newMatch || newMatch.clips.every(clip => !clip.id)) {
      return null;
    }
    return (
      <div
        style={{
          position: 'absolute',
          top: '2.2em',
          right: '1.2em',
        }}
      >
        <div
          onClick={handleTooltipVisibility}
          className="MatchDetailVideoContent__container__moreButton"
          ref={moreButtonRef}
        >
          <Icon size="16px" name="more" type={iconTypeHandler(applicationMode, IconType.white)} />
          <Popper
            open={tooltipVisibility}
            anchorEl={moreButtonRef.current}
            role={undefined}
            placement="left-start"
            onClick={handleTooltipVisibility}
            modifiers={{
              computeStyle: {
                gpuAcceleration: false,
                adaptive: false,
              },
            }}
          >
            <div
              className={classHandler('PopperShadow', applicationMode)}
              style={{ maxWidth: 'calc(100vw - 1.5em)', marginRight: '5px' }}
            >
              {
                <ClickAwayListener onClickAway={handleTooltipVisibility}>
                  <div
                    className={classHandler(
                      'ClipController__moreTooltip',
                      applicationMode,
                      false,
                      tooltipVisibility
                    )}
                  >
                    <ShareMatch matchId={newMatch?.id || 0} />
                    {newMatch?.videoStatus === VideoStatus.done && (
                      <DownloadAllClips matchId={newMatch?.id || 0} />
                    )}
                    <div className="ClipController__tooltipButton" onClick={openModal}>
                      <Icon
                        size="20px"
                        type={iconTypeHandler(applicationMode, IconType.white)}
                        name="synchronization"
                      />
                      <div className="ClipController__tooltipButtonLabel">
                        {t('solveCutInVideoCapture')}
                      </div>
                    </div>
                  </div>
                </ClickAwayListener>
              }
            </div>
          </Popper>
        </div>
      </div>
    );
  }, [
    applicationMode,
    handleTooltipVisibility,
    isNewMatch,
    newMatch,
    openModal,
    t,
    tooltipVisibility,
  ]);

  if (!newMatch) {
    return null;
  }

  return (
    <div
      className={
        classHandler('MatchDetailVideoContent', applicationMode) +
        ' ' +
        classHandler('MatchDetailSituationsContent', applicationMode)
      }
    >
      <MatchDetailResyncClipsModal
        newMatch={newMatch}
        syncEntry={syncEntry}
        setProxy={setProxy}
        setClipResync={setClipResync}
        isVisible={modalVisibility}
        syncMap={syncMap}
        setSyncMap={setSyncMap}
        setModalVisible={setModalVisible}
      />

      <div className="MatchDetailVideoContent__container">
        <div className="MatchDetailVideoContent__containerHeading">{t('addSituations')}</div>
        <DigiRecorderButton match={newMatch} setSaving={setSaving} />
        <MatchDetailVideoTransferTimestampsModal
          newMatch={newMatch}
          handleNewMatch={handleNewMatch}
        />
        <MatchDetailVideoAddTimestampModal
          newMatch={newMatch}
          handleNewMatch={handleNewMatch}
          syncEntry={syncEntry}
          setProxy={setProxy}
          handleNoSourceVideoModalVisibility={() => setAddSrcModalVisible(true)}
        />
      </div>
      {newMatch.clips.length > 0 && (
        <div className="MatchDetailVideoContent__container">
          <div className="MatchDetailVideoContent__containerHeading">
            {t('listOfSituations')}

            {/* Dropdown */}
            {renderMoreButton()}
          </div>
          <div className="MatchDetailVideoContent__fileInputWrapper">{/* empty */}</div>
          {renderTimestampsTable()}
        </div>
      )}
    </div>
  );
};

export default MatchDetailSituationsContent;
