import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { MatchTab, Tab } from '../types';

const setJwtToLocalStorageFromCookie = (appKey: string): void => {
  // tslint:disable-next-line
  console.log(`replace ${getJwtFromLocalStorage()} by ${appKey}`);
  setJwtToLocalStorage(appKey);
  setLatestAppKeyFromLocalStorage(appKey);
};

const checkJwtAndCookie = (): void => {
  const newAppKey = Cookies.get('app_key');
  const oldAppKey = getLatestAppKeyFromLocalStorage();

  if (!newAppKey) {
    // tslint:disable-next-line
    return;
  }

  if (!oldAppKey) {
    // tslint:disable-next-line
    console.log('no latest app key defined');
    return setJwtToLocalStorageFromCookie(newAppKey);
  }

  type TokenBody = {
    id: number;
    iat: number;
    exp: number;
  };

  try {
    const newIssuedAt = (jwt_decode(newAppKey) as TokenBody).iat;
    const oldIssuedAt = (jwt_decode(oldAppKey) as TokenBody).iat;

    if (newIssuedAt > oldIssuedAt) {
      // tslint:disable-next-line
      console.log('app_key cookie is younger than latest app key');
      return setJwtToLocalStorageFromCookie(newAppKey);
    }
    // tslint:disable-next-line
    console.log('app_key cookie is older than latest app key');
  } catch (e) {
    // tslint:disable-next-line
    console.log('token is invalid');
  }
};

export const getJwtFromLocalStorage = (checkCookie?: boolean): string | null => {
  if (checkCookie) {
    checkJwtAndCookie();
  }
  return window.localStorage.getItem('jwt');
};

export const setJwtToLocalStorage = (jwt: string): void => {
  window.localStorage.setItem('jwt', jwt);
};

export const getLatestAppKeyFromLocalStorage = (): string | null => {
  return window.localStorage.getItem('latest_app_key');
};

export const setLatestAppKeyFromLocalStorage = (latestAppKey: string): void => {
  window.localStorage.setItem('latest_app_key', latestAppKey);
};

export const removeJwtFromLocalStorage = (): void => {
  window.localStorage.removeItem('jwt');
};

export const setVolumeToLocalStorage = (volume: number): void => {
  window.localStorage.setItem('volume', volume.toString());
};

export const getVolumeFromLocalStorage = (): number => {
  const volume = window.localStorage.getItem('volume');
  if (volume === null) {
    return 50;
  }
  return Number(volume);
};

const setTabToLocalStorage = <T extends { id: number }>(key: string, tab: T): void => {
  localStorage.setItem(key, tab.id.toString());
};

const getTabFromLocalStorage = <T extends { id: number }>(key: string, tabs: T[]): T | null => {
  const tabIndex = localStorage.getItem(key);
  if (tabIndex === null) {
    return null;
  }
  const tab = tabs[Number(tabIndex)];
  return tab;
};

export const setMatchDetailTabToLocalStorage = (tab: Tab): void => {
  return setTabToLocalStorage('matchDetailTab', tab);
};

export const getMatchDetailTabFromLocalStorage = (tabs: Tab[]): Tab | null => {
  return getTabFromLocalStorage('matchDetailTab', tabs);
};

export const setMatchTabToLocalStorage = (tab: MatchTab): void => {
  return setTabToLocalStorage('matchTab', tab);
};

export const getMatchTabFromLocalStorage = (tabs: MatchTab[]): MatchTab | null => {
  return getTabFromLocalStorage('matchTab', tabs);
};

export const setVideoTabToLocalStorage = (tab: Tab): void => {
  return setTabToLocalStorage('videoTab', tab);
};

export const getVideoTabFromLocalStorage = (tabs: Tab[]): Tab | null => {
  return getTabFromLocalStorage('videoTab', tabs);
};

export const setNoteTabToLocalStorage = (tab: Tab): void => {
  return setTabToLocalStorage('noteTab', tab);
};

export const getNoteTabFromLocalStorage = (tabs: Tab[]): Tab | null => {
  return getTabFromLocalStorage('noteTab', tabs);
};
