import { IconButton, Snackbar, SnackbarCloseReason } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import './Notification.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getNotification } from '../../selectors/notification';
import { hideNotification } from '../../actions/notificationActions';
import { classHandler } from '../../utils';
import { getApplicationModeState } from '../../selectors';

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const Notification: FunctionComponent = () => {
  const dispatch = useDispatch();

  const applicationMode = useSelector(getApplicationModeState);
  const notification = useSelector(getNotification);

  const handleClose = (_: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    if (reason === 'timeout') {
      closeNotification();
    }
  };

  const closeNotification = () => {
    dispatch(hideNotification());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={notification.open}
      onClose={handleClose}
      message={notification.message}
      autoHideDuration={notification.hideAfter}
      action={
        <React.Fragment>
          <IconButton
            href=""
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeNotification}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    >
      <Alert
        severity={notification.severity}
        onClose={closeNotification}
        className={
          notification.severity === 'info'
            ? classHandler('Notification__alertInfo', applicationMode)
            : undefined
        }
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
};
