import React, { FunctionComponent, Fragment, ChangeEvent, useMemo } from 'react';
import { TextField } from '@material-ui/core';

import { calculateTime } from '../../utils';

import './TimeInput.scss';

type TimeInputTextFieldProps = {
  label: string;
  withLabel?: boolean;
  name: string;
  value: any;
  min: number;
  max?: number;
  onChange: any;
  onFocus: any;
};

const TimeInputTextField: FunctionComponent<TimeInputTextFieldProps> = ({
  label,
  withLabel,
  name,
  value,
  min,
  max,
  onChange,
  onFocus,
}) => {
  if (withLabel) {
    return (
      <TextField
        className="MatchDetailVideoContent__timeInput"
        label={label}
        name={name}
        type="number"
        placeholder="00"
        value={value}
        inputProps={{ min, max, style: { textAlign: 'center' } }}
        autoComplete="off"
        onChange={onChange}
        onFocus={onFocus}
      />
    );
  }

  return (
    <TextField
      className="MatchDetailVideoContent__timeInput"
      name={name}
      type="number"
      placeholder="00"
      value={value}
      inputProps={{ min, max, style: { textAlign: 'center' } }}
      autoComplete="off"
      onChange={onChange}
      style={{ width: '60px' }}
      onFocus={onFocus}
    />
  );
};

type TimeInputProps = {
  time: number;
  handleChange?: (param?: any) => void;
  passive?: boolean;
  withLabels?: boolean;
};

export const TimeInput: FunctionComponent<TimeInputProps> = ({
  time,
  handleChange,
  passive,
  withLabels,
}) => {
  const { hours, minutes, seconds } = useMemo(() => {
    return calculateTime(time);
  }, [time]);

  const onChange = (h: number, m: number, s: number) => {
    const value = h * 3600 + m * 60 + s;
    const event = {
      target: {
        name: 'time',
        value: value >= 0 ? value : 0,
      },
    };
    if (handleChange) handleChange(event);
  };

  const onChangeHours = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(parseInt(event.target.value, 10), Number(minutes), Number(seconds));
  };

  const onChangeMinutes = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(Number(hours), parseInt(event.target.value, 10), Number(seconds));
  };

  const onChangeSeconds = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(Number(hours), Number(minutes), parseInt(event.target.value, 10));
  };

  const onFocus = (event: FocusEvent) => {
    const el = event.target as HTMLInputElement;

    setTimeout(() => {
      const value = el.value;
      el.value = '';
      el.value = value;
    });
  };

  return (
    <div style={{ minWidth: passive ? '90px' : '165px' }}>
      <div
        style={{
          lineHeight: '25px',
        }}
      >
        {passive && (
          <div className="TimeInput__passiveTimeInput">
            <div>
              <span className="TimeInput__passiveTimeInputNumber">{hours}</span>
              <span className="TimeInput__passiveTimeInputDivider">:</span>
              <span className="TimeInput__passiveTimeInputNumber">{minutes}</span>
              <span className="TimeInput__passiveTimeInputDivider">:</span>
              <span className="TimeInput__passiveTimeInputNumber">{seconds}</span>
            </div>
          </div>
        )}
        {!passive && (
          <Fragment>
            <TimeInputTextField
              label="HH"
              withLabel={withLabels}
              name="hours"
              value={hours}
              min={0}
              onChange={onChangeHours}
              onFocus={onFocus}
            />
            <div
              className={`TimeInput__activeTimeInputDivider ${
                withLabels ? 'TimeInput__activeTimeInputDivider__withLabels' : ''
              }`}
            >
              :
            </div>
            <TimeInputTextField
              label="MM"
              withLabel={withLabels}
              name="minutes"
              value={minutes}
              min={-1}
              max={60}
              onChange={onChangeMinutes}
              onFocus={onFocus}
            />
            <div
              className={`TimeInput__activeTimeInputDivider ${
                withLabels ? 'TimeInput__activeTimeInputDivider__withLabels' : ''
              }`}
            >
              :
            </div>
            <TimeInputTextField
              label="SS"
              withLabel={withLabels}
              name="seconds"
              value={seconds}
              min={-1}
              max={60}
              onChange={onChangeSeconds}
              onFocus={onFocus}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};
