import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { Icon, IconType } from '../Icon';
import { getApplicationModeState } from '../../selectors';
import { iconTypeHandler } from '../../utils';

import './MatchCardItem.scss';

type MatchCardItemProps = {
  label: string | number;
  iconName: string;
  iconMargin?: string;
  bolder?: boolean;
};

export const MatchCardItem: FunctionComponent<MatchCardItemProps> = ({
  label,
  iconName,
  iconMargin,
  bolder,
}) => {
  const applicationMode = useSelector(getApplicationModeState);

  return (
    <div className="MatchCardItem" style={{ fontWeight: bolder ? 'bolder' : undefined }}>
      <div className="MatchCardItem__iconWrapper" style={{ margin: iconMargin }}>
        <Icon type={iconTypeHandler(applicationMode, IconType.white)} size="16px" name={iconName} />
      </div>
      {label}
    </div>
  );
};
