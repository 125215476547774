import WarningIcon from '@material-ui/icons/Warning';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setInfoModalText, setInfoModalVisibility } from '../../actions';
import { getApplicationModeState, getInfoModalText, getInfoModalVisibility } from '../../selectors';
import { classHandler, useModalKeyboardShortcuts } from '../../utils';
import { Modal } from '../Modal';
import { SaveButtonsWrapper } from '../SaveButtonsWrapper';
import './InfoModal.scss';

export const InfoModal: FunctionComponent<{}> = () => {
  const applicationMode = useSelector(getApplicationModeState);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isVisible = useSelector(getInfoModalVisibility);
  const text = useSelector(getInfoModalText);

  const handleClose = () => {
    dispatch(setInfoModalVisibility(false));
    dispatch(setInfoModalText(''));
  };

  useModalKeyboardShortcuts(isVisible, handleClose, handleClose);

  return (
    <Modal isVisible={isVisible} width="fit-content" height="fit-content" handleClose={handleClose}>
      <div className={classHandler('PremiumFeatureModal', applicationMode)}>
        <div className="PremiumFeatureModal__textIconWrapper">
          <WarningIcon className="PremiumFeatureModal__icon" fontSize="large" />
          <p>{t(text)}</p>
        </div>
        <SaveButtonsWrapper
          primaryButton={{
            label: t('premiumFeatureDismiss'),
            onClick: handleClose,
          }}
        />
      </div>
    </Modal>
  );
};
