import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { InputLabel, MenuItem, Select, Switch, FormControl } from '@material-ui/core';
import { useOpenBrowserHandler } from '../../utils';

import {
  getApplicationModeState,
  getCountries,
  getLanguages,
  getSports,
  getSettings,
  getSettingsStatus,
  getApplicationBreakpointState,
  getIsPremium,
} from '../../selectors';
import {
  ApplicationBreakpoint,
  ApplicationMode,
  Country,
  Language,
  Month,
  Settings,
  Sport,
  Status,
} from '../../types';
import { Title } from '../../components/Title';
import {
  setCountries,
  setSettingsSuccess,
  setSports,
  setLanguages,
  setPremiumModalVisibility,
  setInfoModalText,
  setInfoModalVisibility,
} from '../../actions';
import { putSettings } from '../../api';
import { MobileTitle } from '../../components/MobileTItle';
import { Icon, IconType } from '../../components/Icon';

import './SettingsPage.scss';
import { parseTime } from '../../utils';
import { useHistory } from 'react-router-dom';
import { SaveButtonsWrapper } from '../../components/SaveButtonsWrapper';
import LockIcon from '@material-ui/icons/Lock';
import { Button } from '../../components/Button';
import { isIOSApp } from '../../utils/getPlatform';

export const SettingsPage: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const iOS = isIOSApp();

  useEffect(() => {
    dispatch(setSports());
    dispatch(setCountries());
    dispatch(setLanguages());
  }, [dispatch]);

  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const settings = useSelector(getSettings);
  const settingsStatus = useSelector(getSettingsStatus);
  const sports = useSelector(getSports);
  const countries = useSelector(getCountries);
  const languages = useSelector(getLanguages);
  const isPremium = useSelector(getIsPremium);

  const times = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

  const settingsPageClasses = ['SettingsPage'];
  if (applicationMode === ApplicationMode.dark) settingsPageClasses.push('SettingsPage--dark');

  const [newSettings, setNewSettings] = useState<Settings | null>(settings);

  const openPremiumModal = () => dispatch(setPremiumModalVisibility(true));

  const handleChange = (event: any) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === 'darkMode') {
      value = event.target.checked;
    }
    if (name === 'country') {
      value = countries.find((country: Country) => value === country.id);
    }
    if (name === 'sports') {
      value = sports.find((sport: Sport) => value === sport.id);
    }
    if (name === 'language') {
      value = languages.find((language: Language) => value === language.id);
    }

    if (newSettings) {
      setNewSettings(prev => (prev ? { ...prev, [name]: value } : null));
    }
  };

  const handleClick = () => {
    if (iOS) history.push(`/premium`);
    else window.open('https://www.refereerecorder.com/my-account/', '_blank');
  };

  /* opening browser iOS */
  const iOSOpenBrowserHandler = useOpenBrowserHandler;

  const handleIosOpenGeneral = (url: string) => {
    if (!iOS) {
      return;
    }

    iOSOpenBrowserHandler(url, (result: any) => {
      if (result.status === 1) {
        dispatch(setInfoModalText(result.error));
        dispatch(setInfoModalVisibility(true));
      }
    });
  };

  const openMyAccount = () => {
    handleIosOpenGeneral('https://www.refereerecorder.com/my-account');
  };

  const openDeleteAccount = () => {
    handleIosOpenGeneral('https://www.refereerecorder.com/delete-account/');
  };

  const openMessagePreferences = () => {
    handleIosOpenGeneral('https://www.refereerecorder.com/messaging-preferences/');
  };

  const handleSave = () => {
    if (newSettings) {
      dispatch(setSettingsSuccess(newSettings));
      putSettings(newSettings);
    }
    history.push('/');
  };

  const canSelectTime = (multiplicator: number, value: number) =>
    value !== 0 ||
    (multiplicator < 0
      ? newSettings?.defaultVideoEndingTime !== 0
      : newSettings?.defaultVideoStartingTime !== 0);

  const renderTimes = (multiplicator: number) =>
    times.map((time: number, index: number) => {
      const value = multiplicator * time;
      return (
        <MenuItem key={index} value={value} disabled={!canSelectTime(multiplicator, value)}>
          {`${parseTime(value)} ${t('seconds')}`}
        </MenuItem>
      );
    });

  const renderMonths = Object.keys(Month).map((month: string, index: number) => {
    return (
      <MenuItem key={index} value={month}>
        {t(`${month}`)}
      </MenuItem>
    );
  });

  const renderSports = sports.map((sport: Sport) => {
    return (
      <MenuItem key={sport.id} value={sport.id}>
        {t(sport.name)}
      </MenuItem>
    );
  });

  const renderCountries = countries.map((country: Country) => {
    return (
      <MenuItem key={country.id} value={country.id}>
        {t(country.name)} ({t(country.currency)})
      </MenuItem>
    );
  });

  const renderLanguages = languages.map((language: Language) => {
    return (
      <MenuItem key={language.id} value={language.id}>
        {t(language.name)}
      </MenuItem>
    );
  });

  const renderMyAccountLink = (url: string, name: string, handleOpen: () => void) => {
    return (
      <>
        {!iOS && (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="SettingsPage__myAccountLink"
          >
            <span>{t(name)}</span>
            <Icon size="18px" name="arrowForward" type={IconType.red} />
          </a>
        )}

        {iOS && (
          <span className="SettingsPage__myAccountLink" onClick={handleOpen}>
            <span>{t(name)}</span>
            <Icon size="18px" name="arrowForward" type={IconType.red} />
          </span>
        )}
      </>
    );
  };

  return (
    <div className={settingsPageClasses.join(' ')}>
      {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
        <Title title={t('settings')} />
      ) : (
        <MobileTitle title={t('settings')} />
      )}
      {settingsStatus === Status.success && newSettings && (
        <div className="SettingsPage__scrollWrapper">
          <div className="SettingsPage__content">
            <div className="SettingsPage__container">
              <div className="SettingsPage__containerHeading">{t('general')}</div>
              <FormControl style={{ width: '100%', margin: '5px' }}>
                <InputLabel id="country-label">
                  {t('country')} & {t('currency')}
                </InputLabel>
                <Select
                  style={{ width: '100%' }}
                  labelId="country-label"
                  className="textField"
                  name="country"
                  value={newSettings.country.id}
                  onChange={handleChange}
                >
                  {renderCountries}
                </Select>
              </FormControl>
              <FormControl style={{ width: '100%', margin: '5px' }}>
                <InputLabel id="sport-label">{t('sport')}</InputLabel>
                <Select
                  style={{ width: '100%' }}
                  labelId="sport-label"
                  className="textField"
                  name="sports"
                  value={newSettings?.sports.id}
                  onChange={handleChange}
                >
                  {renderSports}
                </Select>
              </FormControl>
              <FormControl style={{ width: '100%', margin: '5px' }}>
                <InputLabel id="language-label">{t('language')}</InputLabel>
                <Select
                  style={{ width: '100%' }}
                  labelId="language-label"
                  className="textField"
                  name="language"
                  value={newSettings?.language.id}
                  onChange={handleChange}
                >
                  {renderLanguages}
                </Select>
              </FormControl>
              <div
                style={{
                  width: '100%',
                  margin: '5px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <InputLabel
                  style={{
                    color: applicationMode === ApplicationMode.dark ? '#fff' : '#000',
                    fontWeight: 500,
                  }}
                  id="language-label"
                >
                  {t('darkMode')}
                </InputLabel>
                <Switch
                  color="primary"
                  checked={newSettings.darkMode}
                  onChange={handleChange}
                  name="darkMode"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled={!isPremium}
                />
              </div>
              {!isPremium && (
                <div className="SettingsPage__premium" onClick={openPremiumModal}>
                  <LockIcon className="SettingsPage__premium__icon" />
                  {t('premiumFeatureAvailable')}
                </div>
              )}
            </div>
            <div className="SettingsPage__container">
              <div className="SettingsPage__containerHeading">{t('video')}</div>
              <FormControl style={{ width: '100%', margin: '5px' }}>
                <InputLabel id="defaultVideoStartingTime-label">{t('defaultStartTime')}</InputLabel>
                <Select
                  style={{ width: '100%' }}
                  labelId="defaultVideoStartingTime-label"
                  className="textField"
                  name="defaultVideoStartingTime"
                  value={newSettings?.defaultVideoStartingTime}
                  onChange={handleChange}
                >
                  {renderTimes(-1)}
                </Select>
              </FormControl>
              <FormControl style={{ width: '100%', margin: '5px' }}>
                <InputLabel id="defaultVideoEndingTime-label">{t('defaultEndTime')}</InputLabel>
                <Select
                  style={{ width: '100%' }}
                  labelId="defaultVideoEndingTime-label"
                  className="textField"
                  name="defaultVideoEndingTime"
                  value={newSettings?.defaultVideoEndingTime}
                  onChange={handleChange}
                >
                  {renderTimes(1)}
                </Select>
              </FormControl>
            </div>
            <div className="SettingsPage__container">
              <div className="SettingsPage__containerHeading">{t('statistics')}</div>
              <FormControl style={{ width: '100%', margin: '5px' }}>
                <InputLabel id="statisticsFrom-label">{t('statFrom')}</InputLabel>
                <Select
                  style={{ width: '100%' }}
                  labelId="statisticsFrom-label"
                  className="textField"
                  name="statisticsFrom"
                  value={newSettings?.statisticsFrom}
                  onChange={handleChange}
                >
                  {renderMonths}
                </Select>
              </FormControl>
              <FormControl style={{ width: '100%', margin: '5px' }}>
                <InputLabel id="statisticsTo-label">{t('statTo')}</InputLabel>
                <Select
                  style={{ width: '100%' }}
                  labelId="statisticsTo-label"
                  className="textField"
                  name="statisticsTo"
                  value={newSettings?.statisticsTo}
                  onChange={handleChange}
                >
                  {renderMonths}
                </Select>
              </FormControl>
            </div>
            <div className="SettingsPage__container">
              <div className="SettingsPage__containerHeading">{t('myAccount')}</div>
              {renderMyAccountLink(
                'https://www.refereerecorder.com/my-account',
                'manageMyAccount',
                openMyAccount
              )}

              {renderMyAccountLink(
                'https://www.refereerecorder.com/messaging-preferences/',
                'messagePreferences',
                openMessagePreferences
              )}

              {renderMyAccountLink(
                'https://www.refereerecorder.com/delete-account/',
                'deleteAccount',
                openDeleteAccount
              )}

              {!isPremium && (
                // <div className="SettingsPage__getPremium" onClick={handleClick}>
                //   {t('getPremium')}
                // </div>

                <Button onClick={handleClick} label={t('getPremium')} type="secondary" />
              )}
            </div>

            {/* { iOS && !isPremium && 
            <div className="SettingsPage__container">
              <div className="SettingsPage__containerHeading">{t('Premium')}</div>
              {
                <span className="SettingsPage__myAccountLink" onClick={handleClick}>
                  <span>{t('getPremium')}</span>
                  <Icon size="18px" name="arrowForward" type={IconType.red} />
                </span>
              }
            </div> } */}
          </div>
        </div>
      )}
      <SaveButtonsWrapper
        primaryButton={{
          onClick: handleSave,
          label: t('save'),
        }}
      />
    </div>
  );
};
