import { getJwtFromLocalStorage, request } from '../utils';
import { ErrorResponse, MatchNotesResponse, UserResponse } from '../types';
import { GET_MATCH_NOTES_URL, PUT_USER_URL } from './routes';

export const putUserNotepad = async (
  userId: number,
  notepad: string
): Promise<UserResponse | ErrorResponse> => {
  const response = await request(PUT_USER_URL(userId), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
    body: JSON.stringify({
      notepad,
    }),
  });

  return response.json();
};

export const getMatchNotes = async (): Promise<MatchNotesResponse | ErrorResponse> => {
  const response = await request(GET_MATCH_NOTES_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });

  return response.json();
};
