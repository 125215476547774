import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { Match } from '../../types';
import { getApplicationModeState } from '../../selectors';
import { MatchCardItem } from '../MatchCardItem';
import { classHandler, dateToString } from '../../utils';

import './MatchCard.scss';
import { useTranslation } from 'react-i18next';

export enum MatchCardType {
  previous = 'previous',
  upcoming = 'upcoming',
}

type MatchCardProps = {
  match: Match;
  type: MatchCardType;
  redirectToMatch: () => void;
};

export const MatchCard: FunctionComponent<MatchCardProps> = ({ match, type, redirectToMatch }) => {
  const applicationMode = useSelector(getApplicationModeState);
  const { t } = useTranslation();

  const detailRedirect = () => redirectToMatch();

  return (
    <div onClick={detailRedirect} className={classHandler('MatchCard', applicationMode)}>
      <div className="MatchCard__itemWrapper">
        <MatchCardItem
          label={dateToString(match.date, type === MatchCardType.upcoming, match.time)}
          iconName="calendar"
          bolder={type === MatchCardType.upcoming}
        />
        {type === MatchCardType.previous && (
          <div className="MatchCard__itemWrapper">
            {match.videoCount > 0 && (
              <MatchCardItem iconMargin="0 5px" label={match.videoCount} iconName="camera" />
            )}
            {match.noteCount > 0 && (
              <MatchCardItem iconMargin="0 5px 0 20px" label={match.noteCount} iconName="note" />
            )}
          </div>
        )}
      </div>
      {match.location && (
        <MatchCardItem
          label={match.location}
          iconName="location"
          bolder={type === MatchCardType.upcoming}
        />
      )}
      <MatchCardItem
        label={`${match.competition ? t(match.gender) + ' •' : t(match.gender) || ''} ${
          match.category ? match.competition + ' •' : match.competition || ''
        } ${match.category || ''}`}
        iconName="cup"
      />
      {(match.teams.firstTeam || match.teams.secondTeam) && (
        <MatchCardItem
          label={`${match.teams.firstTeam || '?'} vs. ${match.teams.secondTeam || '?'}`}
          iconName="team"
        />
      )}
      {!isEmpty(match.referees) && (
        <MatchCardItem label={match.referees.join(', ')} iconName="handshake" />
      )}
      {match.refereeCoach && <MatchCardItem label={match.refereeCoach} iconName="eye" />}
    </div>
  );
};
