import {
  InputAdornment,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getApplicationCurrency, getApplicationModeState } from '../../selectors';
import { MatchAccounting, MatchDetail, MatchAccountingReceivedAs } from '../../types';
import { classHandler, containsOnlyNumbers } from '../../utils';
import useCategoryReward from '../../utils/useCategoryReward';
import { InputWrapper } from '../InputWrapper';
import './MatchDetailAccountingContent.scss';
import { Position } from '../../constants';

type MatchDetailAccountingContentProps = {
  newMatch: MatchDetail;
  handleNewMatch: React.Dispatch<React.SetStateAction<MatchDetail>>; // TODO-refactor: nahradit raději dispatchem v komponentě
};

export const MatchDetailAccountingContent: FunctionComponent<MatchDetailAccountingContentProps> = ({
  newMatch,
  handleNewMatch,
}) => {
  const { t } = useTranslation();
  const applicationMode = useSelector(getApplicationModeState);
  const currency = useSelector(getApplicationCurrency);

  const categoryReward = useCategoryReward(newMatch);
  const [rewardChanged, setRewardChanged] = useState(false);
  const [sum, setSum] = useState(0);

  useEffect(() => {
    if (
      categoryReward !== null &&
      !rewardChanged &&
      categoryReward !== 0 &&
      categoryReward !== newMatch?.matchAccounting.reward
    ) {
      const reward = categoryReward;
      handleNewMatch({ ...newMatch, matchAccounting: { ...newMatch?.matchAccounting, reward } });
    }
  }, [newMatch, handleNewMatch, categoryReward, rewardChanged]);

  useEffect(() => {
    if (newMatch) {
      const actualSum =
        newMatch.matchAccounting.reward +
        newMatch.matchAccounting.workdayBonus +
        newMatch.matchAccounting.dietary +
        newMatch.matchAccounting.travelCost;
      setSum(actualSum);
    }
  }, [setSum, newMatch]);

  const handleInputChange = (e: any) => {
    const name: keyof MatchAccounting = e.target.name;
    const value = e.target.value;
    const newEarnings: MatchAccounting = newMatch.matchAccounting;

    if (name === 'reward') {
      setRewardChanged(true);
    }

    if (name === 'receivedAs') {
      newEarnings[name] = value;
    } else {
      if (containsOnlyNumbers(value)) {
        newEarnings[name] = parseInt(value, 0);

        if (value === '') newEarnings[name] = 0;
      }
    }

    handleNewMatch({ ...newMatch, matchAccounting: newEarnings });
  };

  const inputProps = {
    endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
  };

  return (
    <div className={classHandler('MatchDetailAccountingContent', applicationMode)}>
      <div className="MatchDetailAccountingContent__container">
        <div className="MatchDetailAccountingContent__containerHeading">{t('earnings')}</div>
        <InputWrapper iconName="reward" iconSize="22px" iconAlignSelf={Position.end}>
          <TextField
            name="reward"
            label={t('reward')}
            className="MatchDetailInfoContent__textFiled"
            value={newMatch.matchAccounting.reward || ''}
            onChange={handleInputChange}
            autoComplete="off"
            InputProps={inputProps}
          />
        </InputWrapper>
        <InputWrapper iconName="workdayBonus" iconSize="22px" iconAlignSelf={Position.end}>
          <TextField
            name="workdayBonus"
            label={t('workdayBonus')}
            className="MatchDetailInfoContent__textFiled"
            value={newMatch.matchAccounting.workdayBonus || ''}
            onChange={handleInputChange}
            autoComplete="off"
            InputProps={inputProps}
          />
        </InputWrapper>
        <InputWrapper iconName="travelCost" iconSize="22px" iconAlignSelf={Position.end}>
          <TextField
            name="travelCost"
            label={t('travelCosts')}
            className="MatchDetailInfoContent__textFiled"
            value={newMatch.matchAccounting.travelCost || ''}
            onChange={handleInputChange}
            autoComplete="off"
            InputProps={inputProps}
          />
        </InputWrapper>
        <InputWrapper iconName="foodCost" iconSize="22px" iconAlignSelf={Position.end}>
          <TextField
            name="dietary"
            label={t('foodCosts')}
            className="MatchDetailInfoContent__textFiled"
            value={newMatch.matchAccounting.dietary || ''}
            onChange={handleInputChange}
            autoComplete="off"
            InputProps={inputProps}
          />
        </InputWrapper>
        <div className="MatchDetailAccountingContent__sumInput">
          <InputWrapper iconName="sum" iconSize="22px">
            <TextField
              className="MatchDetailInfoContent__textFiled"
              disabled={true}
              value={sum}
              label={t('totalAmount')}
              autoComplete="off"
              InputProps={{ ...inputProps, style: { fontWeight: 700 } }}
              variant="outlined"
            />
          </InputWrapper>
        </div>
        <div className="MatchDetailAccountingContent_receivedAs">
          <InputWrapper iconName="receivedAs" iconSize="22px">
            <FormControl component="fieldset">
              <FormLabel component="legend">{t('receivedAs')}</FormLabel>
              <RadioGroup
                row
                aria-label="receivedAs"
                name="receivedAs"
                defaultValue={MatchAccountingReceivedAs.bankAccount}
                value={newMatch.matchAccounting.receivedAs}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value={MatchAccountingReceivedAs.bankAccount}
                  control={<Radio color="primary" />}
                  label={t(MatchAccountingReceivedAs.bankAccount)}
                />
                <FormControlLabel
                  value={MatchAccountingReceivedAs.cash}
                  control={<Radio color="primary" />}
                  label={t(MatchAccountingReceivedAs.cash)}
                />
              </RadioGroup>
            </FormControl>
          </InputWrapper>
        </div>
      </div>
    </div>
  );
};
