import React, { FunctionComponent } from 'react';
import { MatchTable, MatchTableType } from '../MatchTable';
import { useTranslation } from 'react-i18next';

import './NoMatches.scss';
import { useSelector } from 'react-redux';
import { getApplicationModeState } from '../../selectors';
import { classHandler } from '../../utils';
import { Button } from '../Button';

type NoMatchesProps = {
  type: MatchTableType;
  redirectToNewMatch: React.MouseEventHandler<HTMLDivElement>;
  withMatchtable?: boolean;
};

export const NoMatches: FunctionComponent<NoMatchesProps> = ({
  type,
  redirectToNewMatch,
  withMatchtable,
}) => {
  const { t } = useTranslation();

  const applicationMode = useSelector(getApplicationModeState);

  const getMessageKey = (messageType: MatchTableType): string => {
    switch (messageType) {
      case MatchTableType.previous:
        return 'noPreviousMatches';
      case MatchTableType.upcoming:
        return 'noUpcomingMatches';
      case MatchTableType.noData:
        return 'noMatches';
      default:
        return '';
    }
  };

  return (
    <div className={classHandler('NoMatches', applicationMode)}>
      {withMatchtable && <MatchTable type={type} matches={[]} style={{ height: '400px' }} />}
      <div className={withMatchtable ? 'NoMatches__matchtableWrapper' : 'NoMatches__wrapper'}>
        <img
          alt="no matches"
          className="NoMatches__image"
          src={`${process.env.PUBLIC_URL}/no_matches.png`}
        />
        <span className="NoMatches__text">{t(getMessageKey(type))}</span>

        {/* <div onClick={redirectToNewMatch} className="NoMatches__button">
          {t('addNewMatch')}
        </div> */}

        <Button
          label={t('addNewMatch')}
          type="primary"
          onClick={redirectToNewMatch}
          adaptable={true}
        />
      </div>
    </div>
  );
};
