import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import './NoVideos.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationModeState } from '../../selectors';
import { classHandler } from '../../utils';
import { useHistory } from 'react-router-dom';
import { setFilterPanelVisibility } from '../../actions';

export enum NoVideosType {
  default = 'default',
  community = 'community',
  favourite = 'favourite',
  sharedMatch = 'sharedMatch',
  sharedClip = 'sharedClip',
  closedCommunity = 'closedCommunity',
}

type NoVideosProps = {
  type?: NoVideosType;
};

export const NoVideos: FunctionComponent<NoVideosProps> = ({ type }) => {
  const applicationMode = useSelector(getApplicationModeState);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const redirectToMatches = () => {
    history.push(`/`);
  };

  const openFilterPanel = () => dispatch(setFilterPanelVisibility(true));

  if (!type) {
    type = NoVideosType.default;
  }

  const showDefaultMessage = type === NoVideosType.default;
  const showCommunityMessage =
    type === NoVideosType.community || type === NoVideosType.closedCommunity;
  const showFavouriteMessage = type === NoVideosType.favourite;
  const showSharedMatchMessage = type === NoVideosType.sharedMatch;
  const showSharedClipMessage = type === NoVideosType.sharedClip;

  const showDefaultAddButton = type === NoVideosType.default;
  const showCommunityOpenFilter = type === NoVideosType.community;

  return (
    <div className={classHandler('NoVideos', applicationMode)}>
      <img
        alt="no videos"
        className="NoVideos__image"
        src={`${process.env.PUBLIC_URL}/no_videos.png`}
      />
      <span className="NoVideos__text">
        {showDefaultMessage && (
          <Trans i18nKey="noVideosMessage">
            You have <strong>{{ videos: t('noVideos').toLowerCase() }}</strong>. Add some either
            using RECORDER or create some manually.
          </Trans>
        )}
        {showCommunityMessage && (
          <Trans i18nKey="noVideosMessageCommunity">
            There are <strong>{{ videos: t('noVideos').toLowerCase() }}</strong>. Change filtering
            to see some videos.
          </Trans>
        )}
        {showFavouriteMessage && (
          <Trans i18nKey="noVideosMessageFavourite">
            There are <strong>{{ videos: t('noVideos').toLowerCase() }}</strong>. Mark either yours
            or other ref's video as a favourite.
          </Trans>
        )}
        {showSharedMatchMessage && <Trans i18nKey="deletedMatchMessage" />}
        {showSharedClipMessage && <Trans i18nKey="deletedVideoMessage" />}
      </span>
      {showDefaultAddButton && (
        <div onClick={redirectToMatches} className="NoVideos__button">
          {t('addNotesEmpty')}
        </div>
      )}
      {showCommunityOpenFilter && (
        <div onClick={openFilterPanel} className="NoVideos__button">
          {t('openFilter')}
        </div>
      )}
    </div>
  );
};
