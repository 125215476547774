import { Dispatch } from 'redux';

import { SET_LANGUAGES_ERROR, SET_LANGUAGES_REQUESTING, SET_LANGUAGES_SUCCESS } from '../constants';
import { ErrorResponse, Language, LanguagesResponse } from '../types';
import { getLanguages } from '../api';
import { isErrorResponse } from '../utils';

export const setLanguagesRequesting = () => ({ type: SET_LANGUAGES_REQUESTING });

export const setLanguagesError = (errorMsg: string) => ({
  type: SET_LANGUAGES_ERROR,
  payload: { errorMsg },
});

export const setLanguagesSuccess = (languages: Language[]) => ({
  type: SET_LANGUAGES_SUCCESS,
  payload: { languages },
});

export const setLanguages = () => (dispatch: Dispatch) => {
  dispatch(setLanguagesRequesting());

  getLanguages().then((response: LanguagesResponse | ErrorResponse) => {
    if (isErrorResponse(response)) {
      const errorResponse = response as ErrorResponse;

      dispatch(setLanguagesError(errorResponse.message[0].messages[0].message));
    } else {
      const languagesResponse = response as LanguagesResponse;

      dispatch(setLanguagesSuccess(languagesResponse.languages));
    }
  });
};
