import React, { Fragment, createRef, useCallback, useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClipCategoryTypes,
  getClipFilters,
  getClipsStatus,
  getClosedCommunityClipGroups,
  getSettings,
  getUser,
} from '../../selectors';
import { ClipType, ClosedCommunityClipGroup, Status, VideoTabLocationState } from '../../types';
import { useLocation } from 'react-router-dom';
import { setClosedCommunityClips } from '../../actions';
import { setClipCategories } from '../../actions/clipCategoriesActions';
import { VideoSwimLine } from '../VideoSwimLine';
import { ContentLoader } from '../ContentLoader';
import { NoVideos, NoVideosType } from '../NoVideos';

export const CLOSED_COMMUNITY_TAB_ID = 4;

export type VideoListClosedCommunityTabProps = {
  redirectToVideoDetail: (path: string, verticalScroll: number, horizontalScroll: number) => void;
  redirectToMatchDetail: (path: string, verticalScroll: number) => void;
};

export const VideoListClosedCommunityTab: FunctionComponent<VideoListClosedCommunityTabProps> = ({
  redirectToVideoDetail,
  redirectToMatchDetail,
}) => {
  const closedCommunityClipGroups = useSelector(getClosedCommunityClipGroups);
  const clipsStatus = useSelector(getClipsStatus);
  const settings = useSelector(getSettings);
  const location = useLocation<VideoTabLocationState>();
  const clipCategoryTypes = useSelector(getClipCategoryTypes);
  const dispatch = useDispatch();
  const clipFilters = useSelector(getClipFilters);
  const user = useSelector(getUser);

  const [startedFetching, setStartedFetching] = useState(false);

  const clipGroups = clipCategoryTypes.map(
    (title: string, id: number): ClosedCommunityClipGroup => ({
      id,
      title,
      clips: [],
      count: 0,
      status: Status.idle,
    })
  );

  const getInitialClipGroupsLimit = useCallback(
    (index: number) => {
      if (index !== location.state?.verticalScroll) {
        return 10;
      }
      if (location.state?.horizontalScroll !== undefined && location.state?.horizontalScroll > 6) {
        return location.state.horizontalScroll + 5;
      }
      return 10;
    },
    [location.state]
  );

  const allClipGroupsFetched =
    clipsStatus === Status.success &&
    startedFetching &&
    clipGroups.length &&
    clipGroups.length === closedCommunityClipGroups.length;

  const clipsCount = closedCommunityClipGroups
    .map(clipGroup => clipGroup.count)
    .reduce((prev, cur) => prev + cur, 0);

  const clipLineRefs = Array.from({
    length: clipGroups.length,
  }).map(() => createRef<HTMLDivElement>());

  const ownerId = user?.id;

  useEffect(() => {
    setStartedFetching(false);
    dispatch(setClipCategories(settings?.sports.id || 0));
  }, [dispatch, settings]);

  useEffect(() => {
    clipGroups.forEach((clipGroup, index: number) => {
      const start = 0;
      const limit = getInitialClipGroupsLimit(index);
      dispatch(setClosedCommunityClips(start, limit, { ...clipGroup }, clipFilters, ownerId));
    });
    setStartedFetching(true);
  }, [dispatch, clipGroups.length, ownerId, clipFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderVideoSwimLines = closedCommunityClipGroups.map(
    (clipGroup: ClosedCommunityClipGroup, index: number) => {
      const _redirectToVideoDetail = (path: string, horizontalScroll: number) => {
        const verticalScroll = index;
        redirectToVideoDetail(path, verticalScroll, horizontalScroll);
      };

      const _redirectToMatchDetail = (path: string) => {
        const verticalScroll = index;
        redirectToMatchDetail(path, verticalScroll);
      };

      const loadMoreClips = () => {
        const start = clipGroup.clips.length;
        const totalCount = clipGroup.count;
        if (start < totalCount && clipGroup.status !== Status.requestingMore) {
          dispatch(setClosedCommunityClips(start, 10, { ...clipGroup }, clipFilters, ownerId));
        }
      };

      const clipLineIndex = location.state?.verticalScroll;
      const shouldScroll =
        location.state?.videoTab === CLOSED_COMMUNITY_TAB_ID &&
        !!allClipGroupsFetched &&
        clipLineIndex === index &&
        !!clipLineRefs[clipLineIndex];

      return (
        <VideoSwimLine
          key={index}
          clipGroup={clipGroup}
          clipType={ClipType.closedCommunity}
          redirectToMatchDetail={_redirectToMatchDetail}
          redirectToVideoDetail={_redirectToVideoDetail}
          loadMore={loadMoreClips}
          clipLineRef={clipLineRefs[index]}
          shouldScroll={shouldScroll}
          seePrivateNote={false}
        />
      );
    }
  );

  return (
    <Fragment>
      {allClipGroupsFetched ? (
        <div className="VideoListPage__swimLines">
          {clipsCount > 0 && renderVideoSwimLines}
          {clipsCount === 0 && <NoVideos type={NoVideosType.closedCommunity} />}
        </div>
      ) : (
        <ContentLoader />
      )}
    </Fragment>
  );
};
