import { AnyAction } from 'redux';

import { RefereeTeamTypesState, Status } from '../types';
import {
  SET_REFEREE_TEAM_TYPES_ERROR,
  SET_REFEREE_TEAM_TYPES_REQUESTING,
  SET_REFEREE_TEAM_TYPES_SUCCESS,
} from '../constants';

const initRefereeTeamTypesState: RefereeTeamTypesState = {
  status: Status.idle,
  errorMsg: null,
  refereeTeamTypes: [],
};

export const refereeTeamTypesReducer = (
  state: RefereeTeamTypesState = initRefereeTeamTypesState,
  action: AnyAction
): RefereeTeamTypesState => {
  switch (action.type) {
    case SET_REFEREE_TEAM_TYPES_REQUESTING: {
      return {
        status: Status.requesting,
        errorMsg: null,
        refereeTeamTypes: [],
      };
    }
    case SET_REFEREE_TEAM_TYPES_ERROR: {
      const { errorMsg } = action.payload;

      return {
        status: Status.error,
        errorMsg,
        refereeTeamTypes: [],
      };
    }
    case SET_REFEREE_TEAM_TYPES_SUCCESS: {
      const { refereeTeamTypes } = action.payload;

      return {
        status: Status.error,
        errorMsg: null,
        refereeTeamTypes,
      };
    }
    default:
      return state;
  }
};
