import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, IconType } from '../Icon';
import { logoutUser, setSidePanelVisibility } from '../../actions';
import { getApplicationModeState } from '../../selectors';
import { iconTypeHandler } from '../../utils';
import { ApplicationBreakpoint } from '../../types';
import { getApplicationBreakpointState } from '../../selectors';

import './TopSegment.scss';

type TopSegmentProps = {
  isOpened: boolean;
  isLoggedIn: boolean;
  handleClose?: React.MouseEventHandler<HTMLDivElement>;
};

export const TopSegment: FunctionComponent<TopSegmentProps> = ({
  isOpened,
  handleClose,
  isLoggedIn,
}) => {
  const dispatch = useDispatch();

  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);

  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch(setSidePanelVisibility(false));
  };

  return (
    <div className="TopSegment">
      {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
        <div style={{ width: '25px' }} />
      ) : (
        <div className="TopSegment__iconWrapper" onClick={handleClose}>
          <Icon
            type={iconTypeHandler(applicationMode, IconType.white)}
            name={isOpened ? 'close' : 'menu'}
            size="16px"
          />
        </div>
      )}
      <div className="TopSegment__logoWrapper">
        <Icon type={iconTypeHandler(applicationMode, IconType.white)} name="logo" size="98px" />
      </div>
      <div className="TopSegment__placeHolder">
        {isLoggedIn && (
          <div onClick={handleLogout} className="TopSegment__iconWrapper">
            <Icon
              type={iconTypeHandler(applicationMode, IconType.white)}
              name="logout"
              size="18px"
            />
          </div>
        )}
      </div>
    </div>
  );
};
