import React, { useState } from 'react';
import { Modal } from '../Modal';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, FormLabel, TextField } from '@material-ui/core';
import { REPORT_REASONS } from '../../constants';
import './ReportVideoModal.scss';
import { SaveButtonsWrapper } from '../SaveButtonsWrapper';
import { reportClip } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { getClipDetail } from '../../selectors';
import { setReportedClip, showNotification } from '../../actions';

interface ReportVideoModalProps {
  handleClose: (value: any) => void;
  isVisible: boolean;
}

const ReportVideoModal: React.FC<ReportVideoModalProps> = ({ handleClose, isVisible }) => {
  const { t } = useTranslation();

  const clip = useSelector(getClipDetail);
  const [reportReason, setReportReason] = useState<string>('');
  const [reasonDescription, setReasonDescription] = useState<string>('');
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!clip) return;
    reportClip(clip.id, reportReason, reasonDescription)
      .then(() => {
        dispatch(setReportedClip());
        dispatch(showNotification(t('videoclipReportedSuccesfully'), 'success'));
        handleClose(true);
      })
      .catch(e => {
        dispatch(showNotification(t(e.message), 'error'));
        handleClose(false);
      });
  };

  return (
    <Modal
      width="auto"
      height="auto"
      handleClose={handleClose}
      isVisible={isVisible}
      className="ReportVideoModal"
    >
      <form className="ReportVideoModal__form" onSubmit={e => e.preventDefault()}>
        <div className="ReportVideoModal__formGroup">
          <FormLabel htmlFor="select-reason">{t('selectReportReason')}</FormLabel>
          <Select
            id="select-reason"
            value={reportReason}
            onChange={e => {
              const value = (e.target as any).value;
              setReportReason(value);
            }}
          >
            <MenuItem value={''}>-</MenuItem>
            {REPORT_REASONS.map(reason => (
              <MenuItem value={reason} key={reason}>
                {t(reason)}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className="ReportVideoModal__formGroup">
          <FormLabel htmlFor="reason-description">{t('reportReasonDescription')}</FormLabel>
          <TextField
            value={reasonDescription}
            onChange={e => setReasonDescription((e.target as any).value)}
            onKeyDown={e => {
              e.stopPropagation();
              // ignore fullscreen shortcut
            }}
            multiline
          />
        </div>

        <SaveButtonsWrapper
          primaryButton={{
            label: t('reportVideo'),
            onClick: handleSubmit,
          }}
          secondaryButton={{ label: t('cancel'), onClick: () => handleClose(false) }}
        />
      </form>
    </Modal>
  );
};

export default ReportVideoModal;
