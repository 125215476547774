import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { getApplicationModeState } from '../../selectors';
import { classHandler } from '../../utils';

import './ContentLoader.scss';

export const ContentLoader: FunctionComponent<{}> = () => {
  const applicationMode = useSelector(getApplicationModeState);

  return (
    <div className={classHandler('ContentLoader', applicationMode)}>
      <div className="ContentLoader__spinner" />
    </div>
  );
};
