import { Dispatch } from 'redux';

import { SET_COUNTRIES_ERROR, SET_COUNTRIES_REQUESTING, SET_COUNTRIES_SUCCESS } from '../constants';
import { Country } from '../types';
import { getCountries } from '../api';

export const setCountriesRequesting = () => ({ type: SET_COUNTRIES_REQUESTING });

export const setCountriesError = (errorMsg: string) => ({
  type: SET_COUNTRIES_ERROR,
  payload: { errorMsg },
});

export const setCountriesSuccess = (countries: Country[]) => ({
  type: SET_COUNTRIES_SUCCESS,
  payload: { countries },
});

export const setCountries = () => (dispatch: Dispatch) => {
  dispatch(setCountriesRequesting());

  getCountries().then((response: any) => {
    dispatch(setCountriesSuccess(response));
  });
};
