import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MobileTitle } from '../../components/MobileTItle';
import { Title } from '../../components/Title';
import { getApplicationBreakpointState, getApplicationModeState, getUser } from '../../selectors';
import { ApplicationBreakpoint, ApplicationMode } from '../../types';
import { isErrorResponse, useIAPRestoreAllHandler, useIAPSubscribeHandler } from '../../utils';
import { Offer } from '../../components/Offer';

import './PremiumPage.scss';
import { FeatureToggle } from '../../components/FeatureToggle';
import { postReceipt } from '../../api/premium';
import { ContentLoader } from '../../components/ContentLoader';
import { useHistory } from 'react-router-dom';
import { store } from '../..';
import { setInfoModalText, setInfoModalVisibility, setUserPremium } from '../../actions';
import { Button } from '../../components/Button';
// import { Modal } from '../../components/Modal';

export const PremiumPage: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  // const iOSListHandler = useIAPListHandler;
  const iOSSubscribeHandler = useIAPSubscribeHandler;
  const iOSRestoreAllHandler = useIAPRestoreAllHandler;
  const user = useSelector(getUser);
  // const iOS = (window as any).webkit;
  const history = useHistory();

  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const PremiumPageClasses = ['PremiumPage'];
  if (applicationMode === ApplicationMode.dark) PremiumPageClasses.push('PremiumPage--dark');
  const [selectedOffer, setSelectedOffer] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  /* tslint:disable */
  const handleRestoreAll = () => {
    setLoading(true);
    iOSRestoreAllHandler((response: any) => {
      if (response.status === 1) {
        setLoading(false);
        dispatch(setInfoModalText(response.error));
        dispatch(setInfoModalVisibility(true));
        return;
      }

      const encodedString = response.data;
      postReceipt(encodedString).then((response: any) => {
        if (!isErrorResponse(response) && response.isPremium) {
          store.dispatch(setUserPremium(user));
          setLoading(false);
          // if (response) history.push('/');
          if (response) {
            dispatch(setInfoModalText('premiumActivatedTextSuccess'));
            dispatch(setInfoModalVisibility(true));
            history.push('/');
          }
        } else {
          dispatch(setInfoModalText('onReceiptFailedRestoreAll'));
          dispatch(setInfoModalVisibility(true));
        }
      });
    });
  };

  const handleSubscribe = () => {
    setLoading(true);
    let parameter =
      selectedOffer === 0
        ? process.env.REACT_APP_PREMIUM_MONTH_ID ?? 'premium_month'
        : process.env.REACT_APP_PREMIUM_YEAR_ID ?? 'premium_year';

    iOSSubscribeHandler(parameter, (response: any) => {
      if (response.status === 1) {
        setLoading(false);
        dispatch(setInfoModalText(response.error));
        dispatch(setInfoModalVisibility(true));
        return;
      }

      const encodedString = response.data;

      postReceipt(encodedString).then((response: any) => {
        if (!isErrorResponse(response)) {
          store.dispatch(setUserPremium(user));
          setLoading(false);
          // if (response) history.push('/');
          if (response) {
            dispatch(setInfoModalText('premiumActivatedTextSuccess'));
            dispatch(setInfoModalVisibility(true));
            history.push('/');
          }
        } else {
          dispatch(setInfoModalText('premiumActivatedTextFail'));
          dispatch(setInfoModalVisibility(true));
        }
      });
    });
  };
  /* tslint:enable */

  const selectMonthly = () => {
    setSelectedOffer(0);
  };

  const selectYearly = () => {
    setSelectedOffer(1);
  };

  return (
    <div className={PremiumPageClasses.join(' ')}>
      {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
        !loading && <Title title={t('premium')} />
      ) : (
        <MobileTitle title={t('premium')} />
      )}

      {loading && applicationBreakpoint === ApplicationBreakpoint.desktop && <ContentLoader />}

      {loading && applicationBreakpoint !== ApplicationBreakpoint.desktop && (
        <div className="PremiumPage__loadingContainer">
          <ContentLoader />
        </div>
      )}

      {!loading && (
        <div className="PremiumPage__container">
          <div className="PremiumPage__content PremiumPage__scrollable">
            <h3>{t('premiumFeature')}</h3>
            <h4>{t('premiumBenefits')}</h4>
            {applicationBreakpoint === ApplicationBreakpoint.desktop && (
              <table>
                <tbody>
                  <tr>
                    <td>{t('matches')}</td>
                    <td>
                      {t('premiumMatchesText1')}
                      <span />
                      {t('premiumMatchesText2')}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('video')}</td>
                    <td>
                      {t('premiumVideosText1')}
                      <span />
                      {t('premiumVideosText2')}
                      <span />
                      {t('premiumVideosText3')}
                      <span />
                      {t('premiumVideosText4')}
                      <span />
                      {t('premiumVideosText5')}
                      <span />
                      {t('premiumVideosText6')}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('notes')}</td>
                    <td>{t('premiumNotesText1')}</td>
                  </tr>
                  <tr>
                    <td>{t('accounting')}</td>
                    <td>{t('premiumAccountingText1')}</td>
                  </tr>
                  <tr>
                    <td>{t('statistics')}</td>
                    <td>
                      {t('premiumStatisticsText1')}
                      <span />
                      {t('premiumStatisticsText2')}
                      <span />
                      {t('premiumStatisticsText3')}
                      <span />
                      {t('premiumStatisticsText4')}
                      <span />
                      {t('premiumStatisticsText5')}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('importExport')}</td>
                    <td>
                      {t('premiumImportExportText1')}
                      <span />
                      {t('premiumImportExportText2')}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('getReward')}</td>
                    <td>
                      {t('premiumGetRewardText1')}
                      <span />
                      {t('premiumGetRewardText2')}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            {applicationBreakpoint !== ApplicationBreakpoint.desktop && (
              <div>
                <FeatureToggle
                  title={t('matches')}
                  content={[t('premiumMatchesText1'), t('premiumMatchesText2')]}
                />
                <FeatureToggle
                  title={t('video')}
                  content={[
                    t('premiumVideosText1'),
                    t('premiumVideosText2'),
                    t('premiumVideosText3'),
                    t('premiumVideosText4'),
                    t('premiumVideosText5'),
                    t('premiumVideosText6'),
                  ]}
                />
                <FeatureToggle title={t('notes')} content={[t('premiumNotesText1')]} />
                <FeatureToggle title={t('accounting')} content={[t('premiumAccountingText1')]} />
                <FeatureToggle
                  title={t('statistics')}
                  content={[
                    t('premiumStatisticsText1'),
                    t('premiumStatisticsText2'),
                    t('premiumStatisticsText3'),
                    t('premiumStatisticsText4'),
                    t('premiumStatisticsText5'),
                  ]}
                />
                <FeatureToggle
                  title={t('importExport')}
                  content={[t('premiumImportExportText1'), t('premiumImportExportText2')]}
                />
                <FeatureToggle
                  title={t('getReward')}
                  content={[t('premiumGetRewardText1'), t('premiumGetRewardText2')]}
                />
              </div>
            )}

            <hr />

            <p>{t('selectPlan14daysTrial')}</p>
            <div className="offers">
              <Offer
                frequency="monthly"
                price="12,99 €"
                bonus={t('cancelAnyTime')}
                equal={t('equalsTo') + '156 €/' + t('year')}
                selected={selectedOffer === 0}
                handleClick={selectMonthly}
              />

              <Offer
                frequency="yearly"
                price="99,99 €"
                bonus={t('cancelAnyTime')}
                equal={t('equalsTo') + '8,3 €/' + t('month')}
                selected={selectedOffer === 1}
                handleClick={selectYearly}
                trial={true}
              />
            </div>

            {/* <div className="tmp-class" onClick={onList}>
          List
        </div>
        <div className="tmp-class" onClick={onSubcribe}>
          Subscribe
        </div> */}

            <Button
              type={'primary'}
              className="PremiumPage__subscribeButton"
              onClick={handleSubscribe}
            >
              {t('subscribeFreeTrial')}
            </Button>

            <p className="restoreAll" onClick={handleRestoreAll}>
              {t('restoreAll')}
            </p>

            <p>{t('paymentDescription')}</p>
            <p>{t('termsForPaidServices')}</p>
          </div>
        </div>
      )}
    </div>
  );
};
