import { AnyAction } from 'redux';
import { HIDE_NOTIFICATION, SHOW_NOTIFICATION, UPDATE_NOTIFICATION } from '../constants';
import { NotificationState } from '../types';

const initNotificationState: NotificationState = {
  notification: { open: false, message: '', severity: 'info' },
};

export const notificationReducer = (
  state: NotificationState = initNotificationState,
  action: AnyAction
): NotificationState => {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      const { message, severity, hideAfter } = action.payload;
      return {
        ...state,
        notification: {
          ...state.notification,
          open: true,
          message,
          severity,
          hideAfter,
        },
      };
    }
    case UPDATE_NOTIFICATION: {
      return {
        ...state,
        notification: {
          ...state.notification,
          ...action.payload,
        },
      };
    }
    case HIDE_NOTIFICATION: {
      return {
        ...state,
        notification: {
          ...state.notification,
          message: '',
          open: false,
          severity: 'info',
          hideAfter: undefined,
        },
      };
    }
    default:
      return state;
  }
};
