import { Statistics, StatisticsData, RootState, Status } from '../types';

export const getStatisticsStatus = (state: RootState): Status => state.statistics.status;

export const getStatisticsErrorMsg = (state: RootState): string | null => state.statistics.errorMsg;

// export const getStatisticsTotalCount = (state: RootState): number => state.statistics.totalCount;

export const getStatistics = (state: RootState): Statistics[] => state.statistics.statistics;

export const getStatisticsData = (state: RootState): StatisticsData =>
  state.statisticsData.statisticsData;
