import { SettingsState, Status } from '../types';
import { AnyAction } from 'redux';
import {
  RESET_LANGUAGE,
  SET_SETTINGS_ERROR,
  SET_SETTINGS_REQUESTING,
  SET_SETTINGS_SUCCESS,
} from '../constants';

const initSettingsState: SettingsState = { status: Status.idle, errorMsg: null, settings: null };

export const settingsReducer = (state: SettingsState = initSettingsState, action: AnyAction) => {
  switch (action.type) {
    case SET_SETTINGS_REQUESTING: {
      return { status: Status.requesting, errorMsg: null, settings: null };
    }
    case SET_SETTINGS_ERROR: {
      const { errorMsg } = action.payload;

      return { status: Status.error, errorMsg, settings: null };
    }
    case SET_SETTINGS_SUCCESS: {
      const { settings } = action.payload;

      return {
        status: Status.success,
        errorMsg: null,
        settings,
      };
    }
    case RESET_LANGUAGE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          language: {
            id: -1,
            name: '',
            value: '',
          },
        },
      };
    }
    default:
      return state;
  }
};
