import React, { FunctionComponent, MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';

import { getApplicationModeState } from '../../selectors';
import { classHandler, iconTypeHandler } from '../../utils';
import { Icon, IconType } from '../Icon';

import './TimeStampController.scss';

type TimeStampControllerProps = {
  label: string;
  time: string;
  handlePlus: MouseEventHandler<HTMLDivElement>;
  handleMinus: MouseEventHandler<HTMLDivElement>;
  plusDisabled: boolean;
  minusDisabled: boolean;
};

export const TimeStampController: FunctionComponent<TimeStampControllerProps> = ({
  label,
  time,
  handlePlus,
  handleMinus,
  plusDisabled,
  minusDisabled,
}) => {
  const applicationMode = useSelector(getApplicationModeState);

  const plusButtonClasses = ['TimeStampController__controlsButton'];
  if (plusDisabled) {
    plusButtonClasses.push('TimeStampController__controlsButton__disabled');
  }

  const minusButtonClasses = ['TimeStampController__controlsButton'];
  if (minusDisabled) {
    minusButtonClasses.push('TimeStampController__controlsButton__disabled');
  }

  const onPlusClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!plusDisabled) {
      handlePlus(e);
    }
  };

  const onMinusClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!minusDisabled) {
      handleMinus(e);
    }
  };

  return (
    <div className={classHandler('TimeStampController', applicationMode)}>
      <div className="TimeStampController__head">
        <Icon size="13px" name="flag" type={iconTypeHandler(applicationMode, IconType.white)} />
        <div className="TimeStampController__label">{label}</div>
      </div>
      <div className="TimeStampController__controls">
        <div onClick={onMinusClick} className={minusButtonClasses.join(' ')}>
          -
        </div>
        {time}
        <div onClick={onPlusClick} className={plusButtonClasses.join(' ')}>
          +
        </div>
      </div>
    </div>
  );
};
