import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './Offer.scss';

type OfferProps = {
  frequency: string;
  price: string;
  oldPrice?: string;
  bonus: string;
  equal: string;
  selected: boolean;
  handleClick: React.MouseEventHandler<HTMLDivElement>;
  trial?: boolean;
};

export const Offer: FunctionComponent<OfferProps> = ({
  frequency,
  price,
  oldPrice,
  bonus,
  equal,
  selected,
  handleClick,
  trial,
}) => {
  const { t } = useTranslation();

  return (
    <section
      className={!selected ? 'PremiumPage__offer , unactive_offer' : 'PremiumPage__offer'}
      onClick={handleClick}
    >
      {trial && <h4>{t('try14daysForFree')}</h4>}
      <div>
        <label className="container">
          <input type="radio" checked={selected} name="radio" readOnly />
          <span style={selected ? { border: '2px solid #f47777' } : {}} className="checkmark" />
        </label>
        <p>
          <span>{t(frequency)}</span>
          <span>{t(price)}</span>
          {oldPrice && (
            <span className="PremiumPage__offer__oldPrice">
              <span>{t(oldPrice)}</span>
            </span>
          )}
        </p>

        <p>
          <span>{t(bonus)}</span>
          <span>{t(equal)}</span>
        </p>

        {/* <span>
                      <div>{t(frequency)}</div>
                      <div>{t(bonus)}</div>
                  </span>
                  <span>
                      <div>{price} <span>{oldPrice}</span></div>
                      <div>{t(equal)}</div>
                  </span> */}
      </div>
    </section>
  );
};
