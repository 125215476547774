import React from 'react';
import { Icon, IconType } from '../Icon';
import { iconTypeHandler, isErrorResponse, useShareLinkHandler } from '../../utils';
import { setInfoModalText, setInfoModalVisibility, showNotification } from '../../actions';
import { useTranslation } from 'react-i18next';
import { getApplicationModeState } from '../../selectors';
import { useSelector, useDispatch } from 'react-redux';
import { shareMatch } from '../../api/match';
import { ShareContentType, ShareTokenResponse } from '../../types';
import { getUser } from '../../selectors';
import './ShareMatch.scss';
import { isAndroidApp, isIOSApp } from '../../utils/getPlatform';
import copy from 'copy-to-clipboard';

type Props = {
  matchId: number;
};

export const ShareMatch: React.FC<Props> = ({ matchId }: Props) => {
  const { t } = useTranslation();
  const applicationMode = useSelector(getApplicationModeState);
  const shareLinkHandler = useShareLinkHandler;
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isWeb = !isIOSApp() && !isAndroidApp();

  const handleShare = () => {
    if (!user) return;

    shareMatch(matchId).then((response: ShareTokenResponse) => {
      if (!isErrorResponse(response)) {
        const sharePath = `/match-shared?id=${matchId}&share=${response.shareToken}`;
        const shareUrl = `${window.location.origin}${sharePath}`;

        if (isWeb) {
          copy(shareUrl);
          dispatch(showNotification(t('videoLinkCopiedToClipboard'), 'success', 3000));
        } else {
          shareLinkHandler(
            shareUrl,
            ShareContentType.plainText,
            (result: any) => {
              if (result.status === 1) {
                dispatch(setInfoModalText(result.error));
                dispatch(setInfoModalVisibility(true));
              }
            },
            () => {
              dispatch(showNotification(t('videoLinkCopiedToClipboard'), 'success', 3000));
            }
          );
        }
      } else {
        dispatch(showNotification(t('videoLinkCopiedToClipboardError'), 'error', 3000));
      }
    });
  };
  const classNames = ['ClipController__tooltipButton'];
  if (!user) {
    classNames.push('ShareMatch__premium');
  }
  return (
    <div className={classNames.join(' ')} onClick={handleShare}>
      <Icon size="20px" name="share" type={iconTypeHandler(applicationMode, IconType.white)} />
      <div className="ClipController__tooltipButtonLabel">{t('shareAllVideosOfMatch')}</div>
    </div>
  );
};
