import {
  ClipDetail,
  ClipDetailResponse,
  ClipsResponse,
  ErrorResponse,
  SetClipsType,
  CommunityClipGroup,
  ClipFilters,
  ClipCategory,
  DownloadClipResponse,
  DownloadAllClipsResponse,
  ClipReaction,
} from '../types';
import {
  GET_CLIP_DETAIL_URL,
  GET_MY_FAVOURITE_CLIPS_URL,
  GET_COMMUNITY_CLIPS_URL,
  GET_OTHERS_FAVOURITE_CLIPS_URL,
  GET_DOWNLOAD_ALL_CLIPS_URL,
} from './index';
import { getJwtFromLocalStorage, request } from '../utils';
import { Pagination } from '../types/Pagination';
import {
  GET_CLIP_DOWNLOAD_URL,
  GET_CLOSED_COMMUNITY_CLIPS_URL,
  GET_CLOSED_COMMUNITY_NAME_URL,
  GET_MY_CLIPS_URL,
  GET_REACT_TO_CLIP_URL,
  GET_SHARED_CLIP_URL,
  POST_REPORT_CLIP_URL,
  PUT_SHARE_CLIP_URL,
  SHARE_CLOSED_COMMUNITY_URL,
} from './routes';

const getFilters = (type: SetClipsType, clipFilters: ClipFilters): string => {
  const clipCategoriesFilters: string = clipFilters.clipCategories
    .map(({ id }: ClipCategory) => {
      if (type === SetClipsType.default) {
        return `&clips.clipCategories.id=${id}`;
      }
      return `&clipCategories.id=${id}`;
    })
    .join('');

  return `${clipCategoriesFilters}`;
};

const getClipsUrl = (
  pagination: Pagination,
  type: SetClipsType,
  clipFilters: ClipFilters,
  ownerId?: number,
  clipGroup?: CommunityClipGroup
) => {
  const filters = getFilters(type, clipFilters);
  switch (type) {
    case SetClipsType.community:
      return GET_COMMUNITY_CLIPS_URL(pagination, clipGroup?.title || '', filters);
    case SetClipsType.myFavourites:
      return GET_MY_FAVOURITE_CLIPS_URL(pagination, filters, ownerId);
    case SetClipsType.otherFavourites:
      return GET_OTHERS_FAVOURITE_CLIPS_URL(pagination, filters, ownerId);
    case SetClipsType.closedCommunity:
      return GET_CLOSED_COMMUNITY_CLIPS_URL(pagination, clipGroup?.title || '', filters, ownerId);
    default:
      return GET_MY_CLIPS_URL(pagination, filters);
  }
};

const setClipShared = async (clipId: number, share: boolean) => {
  const response = await request(PUT_SHARE_CLIP_URL(clipId), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
    body: JSON.stringify({
      share,
    }),
  });

  return response.json();
};

export const _getClips = async (
  pagination: Pagination,
  type: SetClipsType,
  clipFilters: ClipFilters,
  ownerId?: number,
  clipGroup?: CommunityClipGroup
): Promise<ClipsResponse | ErrorResponse> => {
  const url = getClipsUrl(pagination, type, clipFilters, ownerId, clipGroup);

  const response = await request(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });

  return response.json();
};

export const _getClipDetail = async (
  clipId: number
): Promise<ClipDetailResponse | ErrorResponse> => {
  const response = await request(GET_CLIP_DETAIL_URL(clipId), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });

  return response.json();
};

export const postClipDetail = async (clipDetail: ClipDetail) => {
  const response = await request(GET_CLIP_DETAIL_URL(clipDetail.id), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
    body: JSON.stringify({
      id: clipDetail.id,
      name: clipDetail.name,
      publicNote: clipDetail.publicNote,
      isPrivate: clipDetail.isPrivate,
      isFavorite: clipDetail.isFavorite,
      syncTime: clipDetail.syncTime,
      time: clipDetail.time,
      startTime: clipDetail.startTime,
      endTime: clipDetail.endTime,
      videoUrl: clipDetail.videoUrl,
      videoStatus: clipDetail.videoStatus,
      note: clipDetail.note,
      clipCategories: clipDetail.clipCategories,
      sport: clipDetail.sport,
    }),
  });

  return response.json();
};

export const shareToClosedCommunity = async (
  clipId: number,
  isShared: boolean,
  userId?: number
) => {
  const response = await request(SHARE_CLOSED_COMMUNITY_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
    body: JSON.stringify({ clipId, isShared, userId }),
  });

  return response.json();
};

export const getClosedCommunityName = async (userId: number) => {
  const response = await request(GET_CLOSED_COMMUNITY_NAME_URL(userId), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });

  return response.json();
};

export const postClipDetailFavourite = async (clipDetail: { id: number; isFavorite: boolean }) => {
  const response = await request(GET_CLIP_DETAIL_URL(clipDetail.id), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
    body: JSON.stringify({
      isFavorite: clipDetail.isFavorite,
    }),
  });

  return response.json();
};

export const postClipDetailResync = async (clipDetail: { id: number; syncTime: number }) => {
  const response = await request(GET_CLIP_DETAIL_URL(clipDetail.id), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
    body: JSON.stringify({
      syncTime: clipDetail.syncTime,
    }),
  });

  return response.json();
};

export const deleteClipDetail = async (clipId: number) => {
  const response = await request(GET_CLIP_DETAIL_URL(clipId), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });

  return response.json();
};

export const downloadClip = async (
  clipId: number,
  startTime: number,
  time: number,
  endTime: number,
  name: string
): Promise<DownloadClipResponse> => {
  const response = await request(GET_CLIP_DOWNLOAD_URL(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
    body: JSON.stringify({
      id: clipId,
      startTime,
      time,
      endTime,
      name,
    }),
  });

  return response.json();
};

export const shareClip = async (clipId: number) => {
  return setClipShared(clipId, true);
};

export const cancelShareClip = async (clipId: number) => {
  return setClipShared(clipId, false);
};

export const getSharedClip = async (clipId: number, shareToken: string) => {
  const response = await request(GET_SHARED_CLIP_URL(clipId, shareToken), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.json();
};

export const postReactToClip = async (action: ClipReaction, clipId: number) => {
  await request(GET_REACT_TO_CLIP_URL(action, clipId), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });
};

export const tryDownloadAllClips = async (matchId: number): Promise<DownloadAllClipsResponse> => {
  try {
    const response = await request(GET_DOWNLOAD_ALL_CLIPS_URL(matchId), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getJwtFromLocalStorage()}`,
      },
    });

    // 200 = preparing . . .
    if (response.status === 200) {
      return { ready: false, error: false };
    }

    if (!response.ok) {
      return { ready: false, error: true };
    }

    // 201 = ready for download
    return {
      ready: true,
      error: false,
    };
  } catch (e) {
    return { ready: false, error: true };
  }
};

export const reportClip = async (
  clipId: number,
  reason: string,
  description: string | undefined
) => {
  const headers: any = { 'Content-Type': 'application/json' };
  const token = getJwtFromLocalStorage();
  if (token) headers.Authorization = `Bearer ${token}`;
  return new Promise((res, rej) => {
    request(POST_REPORT_CLIP_URL(clipId), {
      method: 'POST',
      headers,
      body: JSON.stringify({
        description,
        reason,
      }),
    })
      .then(response => {
        if (response.ok) {
          res({ ok: true });
        } else {
          response.json().then(data => {
            rej({ message: data.message.message });
          });
        }
      })
      .catch(e => {
        rej({ message: e });
      });
  });
};
