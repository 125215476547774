export enum JobType {
  VideoUploadJob,
  AnyJob,
}

export interface VideoUploadJob {
  id: string;
  type: JobType.VideoUploadJob;
  metadata: {
    matchId: number;
    percentUploaded: number;
  };
}

export interface AnyJob {
  id: string;
  type: JobType.AnyJob;
  metadata: any;
}

export type Job = VideoUploadJob | AnyJob;
