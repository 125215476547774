import { Dispatch } from 'redux';

import { SET_SPORTS_REQUESTING, SET_SPORTS_ERROR, SET_SPORTS_SUCCESS } from '../constants';
import { Sport } from '../types';
import { getSports } from '../api';

/**
 * Action for sports setting (REQUESTING)
 *
 */
export const setSportsRequesting = () => ({ type: SET_SPORTS_REQUESTING });

/**
 * Action for sports setting (ERROR)
 *
 * @param errorMsg Error message
 */
export const setSportsError = (errorMsg: string) => ({
  type: SET_SPORTS_ERROR,
  payload: { errorMsg },
});

/**
 * Action for sports setting (SUCCESS)
 *
 * @param sports array of sports
 */
export const setSportsSuccess = (sports: Sport[]) => ({
  type: SET_SPORTS_SUCCESS,
  payload: { sports },
});

/**
 * Action for sports setting
 *
 */
export const setSports = () => (dispatch: Dispatch) => {
  dispatch(setSportsRequesting());

  getSports().then((response: any) => {
    dispatch(setSportsSuccess(response));
  });
};
