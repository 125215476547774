import React, { FunctionComponent } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { ClipCategory, ClipDetail, ClipCategoryGrouped } from '../../types';

import './AddCategoryContent.scss';
import { getClipCategoryLabel, sortClipCategoryByType } from '../../utils';

const checkBoxStyles = () => ({
  root: {
    '&$checked': {
      fill: '#FFFFFF',
      color: '#de1919',
    },
  },
  checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

type AddCategoryContentProps = {
  newClipDetail: ClipDetail;
  handleNewClipDetail: any;
  defaultClipCategories: ClipCategory[];
};

export const AddCategoryContent: FunctionComponent<AddCategoryContentProps> = ({
  newClipDetail,
  handleNewClipDetail,
  defaultClipCategories,
}) => {
  const { t } = useTranslation();
  const grouped = _.chain(defaultClipCategories.sort(sortClipCategoryByType))
    .groupBy('type')
    .map(category => {
      return {
        type: category[0].type,
        values: _.map(category),
      };
    })
    .value();
  const renderDefaultClipCategories = grouped.map(
    (clipCategoryGrouped: ClipCategoryGrouped, index: number) => {
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
        if (event.target.checked) {
          const currentClip = clipCategoryGrouped.values.find(item => item.id === id);
          handleNewClipDetail({
            ...newClipDetail,
            clipCategories: [...newClipDetail.clipCategories, currentClip],
          });
        } else {
          const filteredClipCategories = newClipDetail.clipCategories.filter(
            (_clipCategory: ClipCategory) => _clipCategory.id !== id
          );

          handleNewClipDetail({ ...newClipDetail, clipCategories: filteredClipCategories });
        }
      };

      const isChecked = (id: number) =>
        newClipDetail.clipCategories.find((item: ClipCategory) => item.id === id) !== undefined;

      return (
        <div key={index} className="AddCategoryContent__wrapper">
          {clipCategoryGrouped.values.length > 0 && <h3>{t(clipCategoryGrouped.type)}</h3>}
          {clipCategoryGrouped.values?.map((item: any) => {
            const _item = { ...item, value: t(item.value) };
            return (
              <FormControlLabel
                key={item.id}
                control={
                  <CustomCheckbox
                    checked={isChecked(item.id)}
                    // tslint:disable-next-line
                    onChange={e => handleChange(e, item.id)}
                  />
                }
                label={getClipCategoryLabel(_item)}
              />
            );
          })}
        </div>
      );
    }
  );

  return <div className="AddCategoryContent">{renderDefaultClipCategories}</div>;
};
