import { Dispatch } from 'redux';

import { SET_TIPS_REQUESTING, SET_TIPS_ERROR, SET_TIPS_SUCCESS } from '../constants';
import { Tip } from '../types';
import { getTips } from '../api';
import { isErrorResponse } from '../utils';

/**
 * Action for tips setting (REQUESTING)
 *
 */
export const setTipsRequesting = () => ({ type: SET_TIPS_REQUESTING });

/**
 * Action for tips setting (ERROR)
 *
 * @param errorMsg Error message
 */
export const setTipsError = (errorMsg: string) => ({
  type: SET_TIPS_ERROR,
  payload: { errorMsg },
});

/**
 * Action for tips setting (SUCCESS)
 *
 * @param tips array of tips
 */
export const setTipsSuccess = (tips: Tip[]) => ({
  type: SET_TIPS_SUCCESS,
  payload: { tips },
});

/**
 * Action for tips setting
 *
 */
export const setTips = () => (dispatch: Dispatch) => {
  dispatch(setTipsRequesting());

  getTips().then((response: any) => {
    if (!isErrorResponse(response)) {
      dispatch(setTipsSuccess(response.TipsResponse));
    }
  });
};
