import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { getApplicationModeState } from '../../selectors';
import { classHandler } from '../../utils';
import { getUser } from '../../selectors/user';

import './ProfilePicture.scss';

export const ProfilePicture: FunctionComponent<{}> = () => {
  const applicationMode = useSelector(getApplicationModeState);
  const user = useSelector(getUser);

  const fallbackImage = `${process.env.PUBLIC_URL}/profile-placeholder.gif`;

  const setFallbackImage = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = fallbackImage;
  };

  return (
    <div className={classHandler('ProfilePicture', applicationMode)}>
      <div className="ProfilePicture__wrapper">
        <img
          className="ProfilePicture__picture"
          src={user?.photoUrl ? user?.photoUrl : fallbackImage}
          alt="profile"
          onError={setFallbackImage}
        />
      </div>
    </div>
  );
};
