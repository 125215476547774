import { AnyAction } from 'redux';

import { ApplicationBreakpoint, ApplicationMode, ApplicationState } from '../types';
import {
  SET_APPLICATION_MODE,
  SET_APPLICATION_BREAKPOINT,
  SET_FILTER_PANEL_VISIBILITY,
  SET_SIDE_PANEL_VISIBILITY,
  SET_PREMIUM_MODAL_VISIBILITY,
  SET_APPLICATION_CURRENCY,
  SET_INFO_MODAL_VISIBILITY,
  SET_INFO_MODAL_TEXT,
  SET_LOGIN_REQUIRED_MODAL_VISIBILITY,
} from '../constants';

const initApplicationState: ApplicationState = {
  applicationMode: ApplicationMode.light,
  applicationBreakpoint: ApplicationBreakpoint.desktop,
  filterPanelVisibility: false,
  sidePanelVisibility: false,
  premiumModalVisibility: false,
  premiumModalContentLocKey: null,
  loginRequiredModalVisibility: false,
  country: 'Czech Republic',
  currency: 'Kč',
  sport: 'Basketball',
  language: 'English',
  defaultStartTime: 5,
  defaultEndTime: 30,
  infoModalText: '',
  infoModalVisibility: false,
};

/**
 * Application reducer
 *
 * @params state State of application
 */
export const applicationReducer = (
  state: ApplicationState = initApplicationState,
  action: AnyAction
): ApplicationState => {
  switch (action.type) {
    case SET_APPLICATION_MODE: {
      const { applicationMode } = action.payload;

      return { ...state, applicationMode };
    }
    case SET_APPLICATION_BREAKPOINT: {
      const { applicationBreakpoint } = action.payload;

      return { ...state, applicationBreakpoint };
    }
    case SET_FILTER_PANEL_VISIBILITY: {
      const { filterPanelVisibility } = action.payload;

      return { ...state, filterPanelVisibility };
    }
    case SET_SIDE_PANEL_VISIBILITY: {
      const { sidePanelVisibility } = action.payload;

      return { ...state, sidePanelVisibility };
    }

    case SET_PREMIUM_MODAL_VISIBILITY: {
      const { premiumModalVisibility, premiumModalContentLocKey } = action.payload;

      return {
        ...state,
        premiumModalVisibility,
        premiumModalContentLocKey,
      };
    }
    case SET_LOGIN_REQUIRED_MODAL_VISIBILITY: {
      const { loginRequiredModalVisibility } = action.payload;

      return { ...state, loginRequiredModalVisibility };
    }
    case SET_INFO_MODAL_VISIBILITY: {
      const { infoModalVisibility } = action.payload;

      return { ...state, infoModalVisibility };
    }
    case SET_INFO_MODAL_TEXT: {
      const { infoModalText } = action.payload;

      return { ...state, infoModalText };
    }
    case SET_APPLICATION_CURRENCY: {
      const { currency } = action.payload;

      return { ...state, currency };
    }
    default:
      return state;
  }
};
