import { AnyAction } from 'redux';

import {
  SET_COMPETITIONS_REQUESTING,
  SET_COMPETITIONS_ERROR,
  SET_COMPETITIONS_SUCCESS,
} from '../constants';
import { CompetitionsState, Status } from '../types';
import { sortCompetitionCategories } from '../utils';

const initCompetitionsState = { status: Status.idle, errorMsg: null, competitions: [] };

/**
 * Competitions reducer
 *
 * @params state State of competitions
 */
export const competitionsReducer = (
  state: CompetitionsState = initCompetitionsState,
  action: AnyAction
): CompetitionsState => {
  switch (action.type) {
    case SET_COMPETITIONS_REQUESTING: {
      return { status: Status.requesting, errorMsg: null, competitions: [] };
    }
    case SET_COMPETITIONS_ERROR: {
      const { errorMsg } = action.payload;

      return { status: Status.error, errorMsg, competitions: [] };
    }
    case SET_COMPETITIONS_SUCCESS: {
      const { competitions } = action.payload;

      return {
        status: Status.success,
        errorMsg: null,
        competitions: sortCompetitionCategories(competitions),
      };
    }
    default:
      return state;
  }
};
