import { AnyAction } from 'redux';
import {
  ADD_RECORDER_SITUATION,
  CLEAR_RECORDER_SITUATIONS,
  REMOVE_RECORDER_SITUATION,
  SET_MATCH_ID_FOR_SITUATIONS,
  SET_RECORDER_SITUATIONS,
  UPDATE_RECORDER_SITUATION,
} from '../constants';
import { MatchSituation } from '../types';

export const setRecorderSituations = (situations: MatchSituation[]): AnyAction => {
  return {
    type: SET_RECORDER_SITUATIONS,
    payload: situations,
  };
};

export const updateRecorderSituation = (
  situationId: string,
  data: Partial<Omit<MatchSituation, 'id'>>
): AnyAction => {
  return {
    type: UPDATE_RECORDER_SITUATION,
    payload: {
      id: situationId,
      data,
    },
  };
};

export const removeRecorderSituation = (situationId: string): AnyAction => {
  return {
    type: REMOVE_RECORDER_SITUATION,
    payload: {
      id: situationId,
    },
  };
};

export const addRecorderSituation = (timeSeconds: number): AnyAction => {
  return {
    type: ADD_RECORDER_SITUATION,
    payload: {
      timeSeconds,
    },
  };
};

export const clearRecorderSituations = (): AnyAction => ({ type: CLEAR_RECORDER_SITUATIONS });
export const setMatchIdForSituations = (id: number | null): AnyAction => ({
  type: SET_MATCH_ID_FOR_SITUATIONS,
  payload: { id },
});
