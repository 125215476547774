import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationModeState, getUser } from '../../selectors';
import { classHandler, getJwtFromLocalStorage } from '../../utils';
import { BottomMenuItemProps, BottomMenuItem } from '../BottomMenuItem';

import './BottomMenu.scss';
import { useHistory } from 'react-router-dom';
import { setIsPremiumUntil } from '../../actions';

export const BottomMenu: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const applicationMode = useSelector(getApplicationModeState);
  const dispatch = useDispatch();
  const history = useHistory();
  const jwt = getJwtFromLocalStorage(true);
  const user = useSelector(getUser);

  const items: BottomMenuItemProps[] = [
    { label: t('matches'), iconName: 'calendar', to: '/' },
    { label: t('videos'), iconName: 'camera', to: '/videos' },
    { label: t('notes'), iconName: 'note', to: '/notes' },
    { label: t('accounting'), iconName: 'bank', to: '/accounting' },
    { label: t('statistics'), iconName: 'stat', to: '/statistics' },
  ];

  const onClick = (to?: string) => {
    dispatch(setIsPremiumUntil(jwt, user));
    if (to) {
      history.push(to);
    }
  };

  const renderBottomMenuItems = items.map((item: BottomMenuItemProps, index: number) => {
    return (
      <BottomMenuItem
        key={index}
        label={item.label}
        iconName={item.iconName}
        to={item.to}
        onClick={() => onClick(item.to)}
      />
    );
  });

  return <div className={classHandler('BottomMenu', applicationMode)}>{renderBottomMenuItems}</div>;
};
