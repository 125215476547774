import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Credentials, Status } from '../../types';
import { getApplicationModeState, getUser, getUserErrorMsg, getUserStatus } from '../../selectors';
import { loginUser, setInfoModalText, setInfoModalVisibility } from '../../actions';
import { UserAuthLayout, UserAuthLayoutType } from '../../components/UserAuthLayout';
import { getApplicationBreakpointState } from '../../selectors';
import { ApplicationBreakpoint } from '../../types';

import './LoginPage.scss';
import { Button } from '../../components/Button';
import { getAppPlatform, useGetAppVersion, useOpenBrowserHandler } from '../../utils';
import { isAndroidApp, isIOSApp } from '../../utils';

type LoginErrors = {
  email: string | null;
  password: string | null;
};

type LoginPageProps = {
  location: any;
};

export const LoginPage: FunctionComponent<LoginPageProps> = ({ location }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const iOS = isIOSApp();
  const android = isAndroidApp();
  const iOSOpenBrowserHandler = useOpenBrowserHandler;
  const getAppVersion = useGetAppVersion;

  const user = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);
  const userErrorMsg = useSelector(getUserErrorMsg);

  const [credentials, setCredentials] = useState<Credentials>({
    email: '',
    password: '',
    platform: undefined,
    appVersion: '',
  });
  const [errors] = useState<LoginErrors>({ email: null, password: null });

  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const applicationMode = useSelector(getApplicationModeState);

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    // if (value === '') setErrors({ ...errors, [name]: `${name} has to be filled` });
    // else setErrors({ ...errors, [name]: null });

    setCredentials({ ...credentials, [name]: value });
  };

  const handleLogin = () => {
    if (credentials.email.length > 0 && credentials.password.length > 0)
      dispatch(loginUser(credentials));
  };

  const handleRegistration = () => {
    if (android) {
      window.open('https://www.refereerecorder.com/my-account/?fromapp=true&fromandroid=true');
      return;
    }

    if (iOS) {
      iOSOpenBrowserHandler(
        'https://www.refereerecorder.com/my-account/?fromapp=true',
        (result: any) => {
          if (result.status === 1) {
            dispatch(setInfoModalText(result.error));
            dispatch(setInfoModalVisibility(true));
          }
        }
      );

      return;
    }

    window.open('https://www.refereerecorder.com/my-account/');
  };

  const handleForgottenPassword = () => {
    if (!iOS) {
      window.open('https://www.refereerecorder.com/my-account/lost-password/');
    } else {
      if (iOS)
        iOSOpenBrowserHandler(
          'https://www.refereerecorder.com/my-account/lost-password/',
          (result: any) => {
            if (result.status === 1) {
              dispatch(setInfoModalText(result.error));
              dispatch(setInfoModalVisibility(true));
            }
          }
        );
    }
  };

  useEffect(() => {
    if (!user) return;

    location.state
      ? history.replace(location.state.from.pathname + location.state.from.search)
      : history.replace('/');
  }, [user, history, location]);

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  useEffect(() => {
    if (android || iOS) {
      getAppVersion((data: any) => {
        setCredentials({ ...credentials, platform: getAppPlatform(), appVersion: data.data });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAppVersion, getAppPlatform, setCredentials]);

  return (
    <UserAuthLayout type={UserAuthLayoutType.login}>
      <TextField
        error={errors.email !== null}
        helperText={t(errors.email || '')}
        required={true}
        className="LoginPage__textField"
        label="Email"
        name="email"
        type="email"
        value={credentials.email}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        autoComplete="off"
      />
      <TextField
        error={errors.password !== null}
        helperText={t(errors.password || '')}
        required={true}
        className="LoginPage__textField"
        label={t('password')}
        name="password"
        type="password"
        value={credentials.password}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        autoComplete="off"
      />
      {userErrorMsg && <span className="LoginPage__errorMessage">{t(userErrorMsg)}</span>}
      {userStatus === Status.requesting ? (
        <div className="LoginPage__spinner" />
      ) : (
        <div className="LoginPage__placeholder" />
      )}

      <Button
        onClick={handleLogin}
        type="primary"
        label={t('login')}
        disabled={credentials.email.length < 1 || credentials.password.length < 1}
        stretch={true}
        theme={applicationMode}
      />

      {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
        <a
          href="https://www.refereerecorder.com/my-account/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('createNewAcc')}
        </a>
      ) : (
        <span className="LoginPage__mobile-link" onClick={handleRegistration}>
          {t('createNewAcc')}
        </span>
      )}

      {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
        <a
          href="https://www.refereerecorder.com/my-account/lost-password/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('forgottenPassword')}
        </a>
      ) : (
        <span className="LoginPage__mobile-link" onClick={handleForgottenPassword}>
          {t('forgottenPassword')}
        </span>
      )}
    </UserAuthLayout>
  );
};
