import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { getApplicationModeState } from '../../selectors';
import { MatchDetail } from '../../types';
import { classHandler } from '../../utils';
// import { MatchDetailVideoAddTimestampModal } from '../MatchDetailVideoAddTimestampModal';
import { MatchDetailVideoPlayVideoModal } from '../MatchDetailVideoPlayVideoModal';
import { MatchDetailVideoSyncTimeModal } from '../MatchDetailVideoSyncTimeModal';
// import { MatchDetailVideoTransferTimestampsModal } from '../MatchDetailVideoTransferTimestampsModal';
import { MatchDetailVideoVideoSrcModal } from '../MatchDetailVideoVideoSrcModal';
import './MatchDetailVideoContent.scss';
import setProxy from '../../utils/setProxy';

type MatchDetailVideoContentProps = {
  newMatch: MatchDetail | null;
  handleNewMatch: any;
  syncEntry: number;
  previousSyncEntry: number;
  setSyncEntry: any;
  setPreviousSyncEntry: any;
  setAddSrcModalVisible: any;
};

export const MatchDetailVideoContent: FunctionComponent<MatchDetailVideoContentProps> = ({
  newMatch,
  handleNewMatch,
  syncEntry,
  setSyncEntry,
  setPreviousSyncEntry,
  setAddSrcModalVisible,
}) => {
  const applicationMode = useSelector(getApplicationModeState);

  if (!newMatch) return null;

  return (
    <div className={classHandler('MatchDetailVideoContent', applicationMode)}>
      <div className="MatchDetailVideoContent__container">
        <MatchDetailVideoPlayVideoModal newMatch={newMatch} />
        <MatchDetailVideoVideoSrcModal newMatch={newMatch} handleNewMatch={handleNewMatch} />
        <MatchDetailVideoSyncTimeModal
          newMatch={newMatch}
          syncEntry={syncEntry}
          setSyncEntry={setSyncEntry}
          setPreviousSyncEntry={setPreviousSyncEntry}
          setProxy={setProxy}
          handleNoVideoSource={() => setAddSrcModalVisible(true)}
        />
      </div>
    </div>
  );
};
