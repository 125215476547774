import React, { FunctionComponent } from 'react';

import './AlertModal.scss';
import { classHandler, useModalKeyboardShortcuts } from '../../utils';
import { useSelector } from 'react-redux';
import { getApplicationModeState } from '../../selectors';
import { useTranslation } from 'react-i18next';
import { SaveButtonsWrapper } from '../SaveButtonsWrapper';

type ModalProps = {
  isVisible: boolean;
  width?: string;
  height?: string;
  handleClose: any;
  text: string;
  confirmBtn: boolean;
  cancelBtn: boolean;
  confirmText?: string;
  cancelText?: string;
};

export const AlertModal: FunctionComponent<ModalProps> = ({
  isVisible,
  width = 'min(600px, 90vw)',
  height = 'auto',
  handleClose,
  confirmBtn,
  cancelBtn,
  text,
  confirmText,
  cancelText,
}) => {
  const applicationMode = useSelector(getApplicationModeState);
  const { t } = useTranslation();

  useModalKeyboardShortcuts(
    isVisible,
    () => handleClose(false),
    () => handleClose(true)
  );

  if (!isVisible) return null;

  const handleCloseClick = (event: any) => {
    if (event.target.className === 'Modal__overlay') {
      handleClose(false);
    }
  };

  const style = {
    margin: '10px 0 0 0',
    padding: '1.2em 0 0 0',
  };

  let buttons: any = (
    <SaveButtonsWrapper
      secondaryButton={{
        label: cancelText ? t(cancelText) : t('cancel'),
        onClick: () => handleClose(false),
      }}
      primaryButton={{
        label: confirmText ? t(confirmText) : t('confirm'),
        onClick: () => handleClose(true),
      }}
      style={style}
    />
  );

  if (confirmBtn && !cancelBtn) {
    buttons = (
      <SaveButtonsWrapper
        primaryButton={{
          label: t('confirm'),
          onClick: () => handleClose(true),
        }}
        style={style}
      />
    );
  } else if (cancelBtn && !confirmBtn) {
    buttons = (
      <SaveButtonsWrapper
        primaryButton={{
          label: t('cancel'),
          onClick: () => handleClose(false),
        }}
        style={style}
      />
    );
  } else if (!cancelBtn && !confirmBtn) {
    buttons = null;
  }

  return (
    <div className="Modal__overlay" onClick={handleCloseClick}>
      <div
        style={{ width, height }}
        className={classHandler('Modal', applicationMode, undefined, undefined, 'AlertModal')}
      >
        {/* Text */}
        <div className="AlertModal__text">{text}</div>
        {/* Buttons */}
        {buttons}

        {/*
        <div className="Alert__buttons">
          { cancelBtn && <div className="cancelButton MatchDetailVideoContent__fileInputButton MatchDetailVideoContent__playVideoButton"
            onClick={() => { handleClose(false) }}>
                {t('cancel')}
          </div> }
          { confirmBtn && <div className="confirmButton MatchDetailVideoContent__fileInputButton MatchDetailVideoContent__playVideoButtonMarginLeft"
            onClick={() => { handleClose(true) }}>
              {t('confirm')}
          </div>  }
          </div>
        */}
      </div>
    </div>
  );
};
