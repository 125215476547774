import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getApplicationModeState, getUser } from '../../selectors';
import { classHandler } from '../../utils';
import { Button } from '../Button';
import './GetPremiumButton.scss';

export const GetPremiumButton: FunctionComponent<{}> = () => {
  const applicationMode = useSelector(getApplicationModeState);
  const user = useSelector(getUser);
  const { t } = useTranslation();

  const url = 'https://www.refereerecorder.com/';
  const label = user ? t('getPremium') : t('visitRefereeRecorder');

  return (
    <div className={classHandler('GetPremiumButton', applicationMode)}>
      <div className="GetPremiumButton__buttonWrapper">
        <Button label={label} externalLink={url} type="primary" />
      </div>
    </div>
  );
};
