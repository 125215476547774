import { Dispatch } from 'redux';

import {
  SET_MATCH_DETAIL_ASYNC_REQUESTING,
  SET_MATCH_DETAIL_ERROR,
  SET_MATCH_DETAIL_REQUESTING,
  SET_MATCH_DETAIL_SUCCESS,
  getDefaultNewMatch,
} from '../constants';
import { ErrorResponse, MatchDetail, MatchDetailResponse, VideoStatus, RootState } from '../types';
import { _getMatchDetail } from '../api';
import { isErrorResponse, transformMatchDetail } from '../utils';
import { updateNotification } from './notificationActions';
import i18next from 'i18next';
import { Job } from '../types/job';

/**
 * Action for match detail setting (REQUESTING)
 *
 */
export const setMatchDetailRequesting = () => ({ type: SET_MATCH_DETAIL_REQUESTING });

/**
 * Action for match detail setting (ASYNC REQUESTING)
 *
 */
export const setMatchDetailAsyncRequesting = () => ({ type: SET_MATCH_DETAIL_ASYNC_REQUESTING });

/**
 * Action for match detail setting (ERROR)
 *
 * @param errorMsg Error message
 */
export const setMatchDetailError = (errorMsg: string) => ({
  type: SET_MATCH_DETAIL_ERROR,
  payload: { errorMsg },
});

/**
 * Action for match detail setting (SUCCESS)
 *
 * @param matchDetail match detail
 */
export const setMatchDetailSuccess = (matchDetail: MatchDetail) => ({
  type: SET_MATCH_DETAIL_SUCCESS,
  payload: { matchDetail },
});

/**
 * Action for match detail setting
 *
 * @param matchId match id
 */
export const setMatchDetail = (matchId: number) => (dispatch: Dispatch, getState: any) => {
  dispatch(setMatchDetailRequesting());

  _getMatchDetail(matchId).then((response: MatchDetailResponse | ErrorResponse) => {
    if (isErrorResponse(response)) {
      const errorResponse = response as ErrorResponse;

      dispatch(setMatchDetailError(errorResponse.message[0].messages[0].message));
    } else {
      const matchDetailResponse = response as MatchDetailResponse;

      const transformedMatchDetail: MatchDetail = transformMatchDetail(matchDetailResponse);
      // console.log(matchDetailResponse.matchDetail.syncTime);

      dispatch(setMatchDetailSuccess(transformedMatchDetail));
      switch (transformedMatchDetail.videoStatus) {
        case VideoStatus.uploadPostProcessing:
        case VideoStatus.uploading:
          waitForMatchToProcess(matchId)(dispatch, getState);
          break;
      }
    }
  });
};

export const videoUploadedForMatch = (matchId: number) => (dispatch: Dispatch, getState: any) => {
  const state: RootState = getState();

  if (state.matchDetail.matchDetail?.id === +matchId) {
    setMatchDetail(matchId)(dispatch, getState);
  }
};

/**
 * if match video status is either uploadPostProcess or uploading (but from other device), refetch the information until
 * video is either loaded or upload ended with error.
 *
 * @param matchId
 * @returns void
 */
export const waitForMatchToProcess = (matchId: number) => (dispatch: Dispatch, getState: any) => {
  const state: RootState = getState();

  const isProcessing =
    state.matchDetail.matchDetail?.videoStatus === VideoStatus.uploadPostProcessing;

  // if match is uploading from current device, no need to refetch status,
  // uploader method handles this.
  const isUploadingFromOtherDevice = state.job.jobs.every(
    (job: Job) => parseInt(job.metadata.matchId, 10) !== matchId
  );

  if (isUploadingFromOtherDevice || isProcessing) {
    // fetch match data until is done or error
    setTimeout(() => {
      _getMatchDetail(matchId).then((response: MatchDetailResponse | ErrorResponse) => {
        if (isErrorResponse(response)) {
          const errorResponse = response as ErrorResponse;
          dispatch(setMatchDetailError(errorResponse.message[0].messages[0].message));
        } else {
          const matchDetailResponse = response as MatchDetailResponse;
          const transformedMatchDetail = transformMatchDetail(matchDetailResponse);
          switch (matchDetailResponse.matchDetail.videoStatus) {
            case VideoStatus.uploadPostProcessing:
              if (!isProcessing) {
                // update match status uploading -> processing once
                if (getState().matchDetail.matchDetail?.id === +matchId) {
                  dispatch(setMatchDetailSuccess(transformedMatchDetail));
                }
              }
            // intentionally missing return/break.
            // eslint-disable-next-line
            case VideoStatus.uploading:
              waitForMatchToProcess(matchId)(dispatch, getState);
              return;

            case VideoStatus.done:
              updateMatchDetail(matchId, transformedMatchDetail)(dispatch, getState);
              dispatch(updateNotification(i18next.t('videoSuccess'), 'success'));
              return;

            case VideoStatus.error:
              updateMatchDetail(matchId, transformedMatchDetail)(dispatch, getState);
              dispatch(updateNotification(i18next.t('videoError'), 'error'));
              return;

            case VideoStatus.cancelled:
              updateMatchDetail(matchId, transformedMatchDetail)(dispatch, getState);
              dispatch(updateNotification('The upload was cancelled.', 'error'));
              return;
          }
        }
      });
    }, 5000);
  }
};

/**
 * updates match status, only if the match is the one being displayed by user (ie this method *cannot* change matchID)
 * @param targetMatchId match id to be updated
 * @param newMatchDetail new match status
 * @returns
 */
export const updateMatchDetail = (targetMatchId: number, newMatchDetail: MatchDetail) => (
  dispatch: Dispatch,
  getState: any
) => {
  if (getState().matchDetail.matchDetail?.id === +targetMatchId) {
    dispatch(setMatchDetailSuccess(newMatchDetail));
  }
};

/**
 * Action for new match detail setting
 *
 * @param matchId match id
 */
export const setNewMatchDetail = () => (dispatch: Dispatch) => {
  dispatch(setMatchDetailRequesting());

  dispatch(setMatchDetailSuccess(getDefaultNewMatch()));
};
