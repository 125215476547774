import { AnyAction } from 'redux';

import {
  SET_CLIP_CATEGORIES_REQUESTING,
  SET_CLIP_CATEGORIES_ERROR,
  SET_CLIP_CATEGORIES_SUCCESS,
} from '../constants';
import { ClipCategoriesState, Status, ClipCategory } from '../types';

const initClipCategoriesState = {
  status: Status.idle,
  errorMsg: null,
  clipCategories: [],
  clipCategoryTypes: [],
};

const getClipCategoryTypes = (clipCategories: ClipCategory[]): string[] => {
  const onlyUnique = (value: string, index: number, self: string[]) =>
    self.indexOf(value) === index;
  return clipCategories.map((category: ClipCategory) => category.type).filter(onlyUnique);
};

/**
 * ClipCategories reducer
 *
 * @params state State of clipCategories
 */
export const clipCategoriesReducer = (
  state: ClipCategoriesState = initClipCategoriesState,
  action: AnyAction
): ClipCategoriesState => {
  switch (action.type) {
    case SET_CLIP_CATEGORIES_REQUESTING: {
      return {
        status: Status.requesting,
        errorMsg: null,
        clipCategories: [],
        clipCategoryTypes: [],
      };
    }
    case SET_CLIP_CATEGORIES_ERROR: {
      const { errorMsg } = action.payload;

      return { status: Status.error, errorMsg, clipCategories: [], clipCategoryTypes: [] };
    }
    case SET_CLIP_CATEGORIES_SUCCESS: {
      const { clipCategories } = action.payload;

      return {
        status: Status.success,
        errorMsg: null,
        clipCategories,
        clipCategoryTypes: getClipCategoryTypes(clipCategories),
      };
    }
    default:
      return state;
  }
};
