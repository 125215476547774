import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getApplicationModeState, getApplicationBreakpointState } from '../../selectors';
import { ApplicationBreakpoint, ApplicationMode, Tab } from '../../types';
import { Title } from '../../components/Title';
import { MobileTitle } from '../../components/MobileTItle';

import './NoteListPage.scss';
import { getNoteTabFromLocalStorage, setNoteTabToLocalStorage } from '../../utils';
import { NotesByMatchesTab } from '../../components/NotesByMatchesTab';
import { GeneralNotepadTab } from '../../components/GeneralNotepadTab';

export const NoteListPage: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);

  const noteListPageClasses = ['NoteListPage'];
  if (applicationMode === ApplicationMode.dark) noteListPageClasses.push('NoteListPage--dark');

  const tabs: Tab[] = [
    { id: 0, name: t('notesFrom') },
    { id: 1, name: t('genNotes') },
  ];

  const [activeTab, setActiveTab] = useState<Tab>(getNoteTabFromLocalStorage(tabs) || tabs[0]);

  useEffect(() => {
    setNoteTabToLocalStorage(activeTab);
  }, [activeTab]);

  return (
    <div className={noteListPageClasses.join(' ')}>
      {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
        <Title title={t('notes')} tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      ) : (
        <MobileTitle
          title={t('notes')}
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}
      <div className="NoteListPage__container">
        <div className="NoteListPage__content">
          {activeTab.id === 0 && <NotesByMatchesTab />}
          {activeTab.id === 1 && <GeneralNotepadTab />}
        </div>
      </div>
    </div>
  );
};
