export const addZero = (num: number) => {
  if (num <= 0) {
    return '00';
  }
  if (num < 10) {
    return `0${num}`;
  }
  return `${num}`;
};

export const calculateTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds / 60) % 60;
  seconds = Math.floor((seconds % 3600) % 60);

  return {
    hours: addZero(hours),
    minutes: addZero(minutes),
    seconds: addZero(seconds),
  };
};

const timePresentationFromSeconds = (_seconds: number, forceShowHours = false) => {
  const { hours, minutes, seconds } = calculateTime(_seconds);
  if (hours !== '00' || forceShowHours) {
    return `${hours}:${minutes}:${seconds}`;
  }
  return `${minutes}:${seconds}`;
};

export const timePresentation = (_seconds: number, parens?: boolean, forceShowHours = false) => {
  const time = timePresentationFromSeconds(_seconds, forceShowHours);
  if (parens) {
    return `(${time})`;
  }
  return time;
};

export const convertTransformedStringToTime = (
  hours: string | undefined,
  minutes: string | undefined,
  seconds: string | undefined
): number => {
  return (
    parseInt(hours || '0', 10) * 3600 +
    parseInt(minutes || '0', 10) * 60 +
    parseInt(seconds || '0', 10)
  );
};

export const parseTime = (time: number) => {
  if (time < 0) {
    return `- ${Math.abs(time)}`;
  }
  if (time === 0) {
    return `${time}`;
  }
  return `+ ${time}`;
};
