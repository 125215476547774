import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import {
  getApplicationBreakpointState,
  getApplicationModeState,
  getClipFilters,
  getClosedCommunityName,
  getUser,
} from '../../selectors';
import { ApplicationBreakpoint, ApplicationMode, Tab } from '../../types';
import { Title } from '../../components/Title';
import { MobileTitle } from '../../components/MobileTItle';
import { VideoListMyVideosTab, MY_VIDEOS_TAB_ID } from '../../components/VideoListMyVideosTab';

import './VideoListPage.scss';
import { VideoListCommunityTab, COMMUNITY_TAB_ID } from '../../components/VideoListCommunityTab';
import { VideoListFavouriteTab, FAVOURITE_TAB_ID } from '../../components/VideoListFavouriteTab';
import { VideoTabLocationState } from '../../types';
import { getVideoTabFromLocalStorage, setVideoTabToLocalStorage } from '../../utils';
import { setClosedCommunityName, setFilterPanelVisibility } from '../../actions';
import {
  CLOSED_COMMUNITY_TAB_ID,
  VideoListClosedCommunityTab,
} from '../../components/VideoListClosedCommunityTab';

export const VideoListPage: FunctionComponent<{}> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation<VideoTabLocationState>();
  const dispatch = useDispatch();

  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const { clipCategories } = useSelector(getClipFilters);
  const closedCommunityName = useSelector(getClosedCommunityName);
  const user = useSelector(getUser);

  const [tabs, setTabs] = useState<Tab[]>([
    {
      id: MY_VIDEOS_TAB_ID,
      name: t('myVideos'),
    },
    {
      id: COMMUNITY_TAB_ID,
      name: t('otherRefsVideos'),
    },
    {
      id: FAVOURITE_TAB_ID,
      name: t('myFavouriteVideos'),
    },
  ]);

  const swimlinesRef = useRef<HTMLDivElement>(null);
  const [myRef, setmyRef] = useState<HTMLDivElement | null>(swimlinesRef.current);

  useEffect(() => {
    if (user?.id) {
      dispatch(setClosedCommunityName(user?.id));
    }
  });

  useEffect(() => {
    setmyRef(swimlinesRef.current);
  }, [swimlinesRef]);

  useEffect(() => {
    if (closedCommunityName) {
      setTabs([
        tabs[0],
        { id: CLOSED_COMMUNITY_TAB_ID, name: closedCommunityName },
        ...tabs.slice(1),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closedCommunityName]);

  const [activeTab, setActiveTab] = useState(getVideoTabFromLocalStorage(tabs) || tabs[0]);

  const redirectToVideoDetail = (
    path: string,
    verticalScroll: number,
    horizontalScroll: number
  ) => {
    history.replace(location.pathname, {
      videoTab: activeTab.id,
      verticalScroll,
      horizontalScroll,
    });
    history.push(path);
  };

  const redirectToMatchDetail = (path: string, verticalScroll: number) => {
    history.replace(location.pathname, {
      videoTab: activeTab.id,
      verticalScroll,
      horizontalScroll: 0,
    });
    history.push(path);
  };

  useEffect(() => {
    const closeFilterPanel = () => dispatch(setFilterPanelVisibility(false));

    return () => {
      closeFilterPanel();
    };
  }, [dispatch]);

  useEffect(() => {
    if (location.state?.videoTab) {
      const currentTab = tabs.filter(tab => tab.id === location.state?.videoTab);
      if (currentTab.length > 0) {
        setActiveTab(currentTab[0]);
      }
    }
  }, [location.state, tabs]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setVideoTabToLocalStorage(activeTab);
  }, [activeTab]);

  const videoListPageClasses = ['VideoListPage'];
  if (applicationMode === ApplicationMode.dark) videoListPageClasses.push('VideoListPage--dark');

  const showActiveTabComponent = () => {
    if (activeTab.id === MY_VIDEOS_TAB_ID) {
      return (
        <VideoListMyVideosTab
          redirectToVideoDetail={redirectToVideoDetail}
          redirectToMatchDetail={redirectToMatchDetail}
          scrollRef={myRef}
        />
      );
    }

    if (activeTab.id === COMMUNITY_TAB_ID) {
      return (
        <VideoListCommunityTab
          redirectToVideoDetail={redirectToVideoDetail}
          redirectToMatchDetail={redirectToMatchDetail}
        />
      );
    }

    if (activeTab.id === FAVOURITE_TAB_ID) {
      return (
        <VideoListFavouriteTab
          redirectToVideoDetail={redirectToVideoDetail}
          redirectToMatchDetail={redirectToMatchDetail}
        />
      );
    }

    if (activeTab.id === CLOSED_COMMUNITY_TAB_ID) {
      return (
        <VideoListClosedCommunityTab
          redirectToVideoDetail={redirectToVideoDetail}
          redirectToMatchDetail={redirectToMatchDetail}
        />
      );
    }
  };

  return (
    <div className={videoListPageClasses.join(' ')}>
      {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
        <Title
          title={t('videos')}
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          activeFilters={clipCategories.length}
          withFilter
        />
      ) : (
        <MobileTitle
          title={t('videos')}
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          activeFilters={clipCategories.length}
          withFilters
        />
      )}
      <div className="VideoListPage__swimLinesWrapper" ref={swimlinesRef}>
        {showActiveTabComponent()}
      </div>
    </div>
  );
};
