import { GET_COUNTRIES_URL, GET_SPORTS_URL, GET_TIPS_URL } from './index';
import { getJwtFromLocalStorage, request } from '../utils';
import { AccountingFilter, ErrorResponse, RefereeTeamTypesResponse } from '../types';
import {
  // GET_STATISTICS_SEASONS_URL,
  // GET_STATISTICS_SEASON_URL,
  GET_REFEREE_TEAM_TYPES_BY_SPORT,
  GET_ACCOUNTING_SEASONS_URL,
  GET_ACCOUNTING_SEASON_URL,
  GET_STATISTICS_SEASONS_URL,
  GET_STATISTICS_SEASON_URL,
} from './routes';

export const getCountries = async (): Promise<any> => {
  const response = await request(GET_COUNTRIES_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.json();
};

export const getSports = async (): Promise<any> => {
  const response = await request(GET_SPORTS_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.json();
};

export const getRefereeTeamTypes = async (
  sportId: number
): Promise<RefereeTeamTypesResponse | ErrorResponse> => {
  const response = await request(GET_REFEREE_TEAM_TYPES_BY_SPORT(sportId), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });

  return response.json();
};

export const getTips = async (): Promise<any> => {
  const response = await request(GET_TIPS_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.json();
};

export const getAccounting = async (): Promise<any> => {
  const response = await request(GET_ACCOUNTING_SEASONS_URL, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });
  return response.json();
};

export const getAccountingData = async (
  address: string,
  filter: AccountingFilter
): Promise<any> => {
  const response = await request(GET_ACCOUNTING_SEASON_URL(address, filter), {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });
  return response;
};

export const getStatistics = async (): Promise<any> => {
  const response = await request(GET_STATISTICS_SEASONS_URL, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });
  return response.json();
};

export const getStatisticsData = async (address: string): Promise<any> => {
  const response = await request(GET_STATISTICS_SEASON_URL(address), {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });
  return response.json();
};
