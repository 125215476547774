import React, {
  Fragment,
  FunctionComponent,
  useEffect,
  createRef,
  useState,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setCommunityClips, setMoreCommunityClips } from '../../actions/clipsActions';
import {
  getClipsStatus,
  getSettings,
  getClipCategoryTypes,
  getUser,
  getCommunityClipGroups,
  getClipFilters,
} from '../../selectors';
import { Status, ClipType, CommunityClipGroup, VideoTabLocationState } from '../../types';
import { ContentLoader } from '../ContentLoader';
import { VideoSwimLine } from '../VideoSwimLine';
import './VideoListCommunityTab.scss';
import { setClipCategories } from '../../actions/clipCategoriesActions';
import { NoVideos, NoVideosType } from '../NoVideos';

export const COMMUNITY_TAB_ID = 1;

type VideoListCommunityTabProps = {
  redirectToVideoDetail: (path: string, verticalScroll: number, horizontalScroll: number) => void;
  redirectToMatchDetail: (path: string, verticalScroll: number) => void;
};

export const VideoListCommunityTab: FunctionComponent<VideoListCommunityTabProps> = ({
  redirectToVideoDetail,
  redirectToMatchDetail,
}) => {
  const dispatch = useDispatch();
  const location = useLocation<VideoTabLocationState>();

  const clipsStatus = useSelector(getClipsStatus);
  const settings = useSelector(getSettings);
  const user = useSelector(getUser);
  const clipCategoryTypes = useSelector(getClipCategoryTypes);
  const communityClipGroups = useSelector(getCommunityClipGroups);
  const clipFilters = useSelector(getClipFilters);

  const [startedFetching, setStartedFetching] = useState(false);

  const clipGroups = clipCategoryTypes.map(
    (title: string, id: number): CommunityClipGroup => ({
      id,
      title,
      clips: [],
      count: 0,
      status: Status.idle,
    })
  );

  const getInitialClipGroupsLimit = useCallback(
    (index: number) => {
      if (index !== location.state?.verticalScroll) {
        return 10;
      }
      if (location.state?.horizontalScroll !== undefined && location.state?.horizontalScroll > 6) {
        return location.state.horizontalScroll + 5;
      }
      return 10;
    },
    [location.state]
  );

  const allClipGroupsFetched =
    clipsStatus === Status.success &&
    startedFetching &&
    clipGroups.length &&
    clipGroups.length === communityClipGroups.length;

  const clipsCount = communityClipGroups
    .map(clipGroup => clipGroup.count)
    .reduce((prev, cur) => prev + cur, 0);

  const clipLineRefs = Array.from({
    length: clipGroups.length,
  }).map(() => createRef<HTMLDivElement>());

  const ownerId = user?.id;

  useEffect(() => {
    setStartedFetching(false);
    dispatch(setClipCategories(settings?.sports.id || 0));
  }, [dispatch, settings]);

  useEffect(() => {
    clipGroups.forEach((clipGroup, index: number) => {
      const start = 0;
      const limit = getInitialClipGroupsLimit(index);
      dispatch(setCommunityClips(start, limit, { ...clipGroup }, clipFilters, ownerId));
    });
    setStartedFetching(true);
  }, [dispatch, clipGroups.length, ownerId, clipFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderVideoSwimLines = communityClipGroups.map(
    (clipGroup: CommunityClipGroup, index: number) => {
      const _redirectToVideoDetail = (path: string, horizontalScroll: number) => {
        const verticalScroll = index;
        redirectToVideoDetail(path, verticalScroll, horizontalScroll);
      };

      const _redirectToMatchDetail = (path: string) => {
        const verticalScroll = index;
        redirectToMatchDetail(path, verticalScroll);
      };

      const loadMoreClips = () => {
        const start = clipGroup.clips.length;
        const totalCount = clipGroup.count;
        if (start < totalCount && clipGroup.status !== Status.requestingMore)
          dispatch(setMoreCommunityClips(start, clipGroup, clipFilters, ownerId));
      };

      const clipLineIndex = location.state?.verticalScroll;
      const shouldScroll =
        location.state?.videoTab === COMMUNITY_TAB_ID &&
        !!allClipGroupsFetched &&
        clipLineIndex === index &&
        !!clipLineRefs[clipLineIndex];

      return (
        <VideoSwimLine
          key={index}
          clipGroup={clipGroup}
          clipType={ClipType.community}
          redirectToMatchDetail={_redirectToMatchDetail}
          redirectToVideoDetail={_redirectToVideoDetail}
          loadMore={loadMoreClips}
          clipLineRef={clipLineRefs[index]}
          shouldScroll={shouldScroll}
          seePrivateNote={false}
        />
      );
    }
  );

  return (
    <Fragment>
      {allClipGroupsFetched ? (
        <div className="VideoListPage__swimLines">
          {clipsCount > 0 && renderVideoSwimLines}
          {clipsCount === 0 && <NoVideos type={NoVideosType.community} />}
        </div>
      ) : (
        <ContentLoader />
      )}
    </Fragment>
  );
};
