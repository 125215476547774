import { AnyAction } from 'redux';
import {
  SET_SHARED_MATCH_REQUESTING,
  SET_SHARED_MATCH_SUCCESS,
  SET_SHARED_MATCH_ERROR,
} from '../constants';
import { Status } from '../types';
import { SharedMatchState } from '../types';
import { transformGender } from '../utils';

const initialState: SharedMatchState = {
  status: Status.idle,
  sport: null,
  errorMsg: null,
  sharedMatchClipGroup: undefined,
};

export const sharedMatchReducer = (state: SharedMatchState = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_SHARED_MATCH_SUCCESS: {
      if (!action.payload) {
        return { ...state, sharedMatchClipGroup: { ...state.sharedMatchClipGroup, clips: [] } };
      }

      return {
        ...state,
        status: Status.success,
        sport: action.payload.sport,
        sharedMatchClipGroup: {
          matchId: action.payload.matchId,
          id: action.payload.matchId,
          clips: action.payload.clips.map((clip: any) => ({ ...clip, imageUrl: clip.photoUrl })),
          date: new Date(action.payload.date),
          gender: transformGender(action.payload.gender),
          teams: {
            firstTeam: action.payload.homeTeam,
            secondTeam: action.payload.guestTeam,
          },
          competition: action.payload.competition,
          category: action.payload.category,
        },
      };
    }
    case SET_SHARED_MATCH_ERROR: {
      return { ...state, status: Status.error, errorMsg: action.payload };
    }
    case SET_SHARED_MATCH_REQUESTING: {
      return { ...state, status: Status.requesting };
    }
    default:
      return state;
  }
};
