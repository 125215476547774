import { AnyAction } from 'redux';

import {
  SET_MY_CLIPS_REQUESTING,
  SET_MY_CLIPS_ERROR,
  SET_MY_CLIPS_SUCCESS,
  SET_COMMUNITY_CLIPS_REQUESTING,
  SET_COMMUNITY_CLIPS_ERROR,
  SET_COMMUNITY_CLIPS_SUCCESS,
  SET_MORE_COMMUNITY_CLIPS_REQUESTING,
  SET_MORE_COMMUNITY_CLIPS_SUCCESS,
  SET_FAVOURITE_CLIPS_REQUESTING,
  SET_FAVOURITE_CLIPS_ERROR,
  SET_MY_FAVOURITE_CLIPS_SUCCESS,
  SET_OTHER_FAVOURITE_CLIPS_SUCCESS,
  SET_MORE_FAVOURITE_CLIPS_REQUESTING,
  SET_MORE_MY_FAVOURITE_CLIPS_SUCCESS,
  SET_MORE_OTHER_FAVOURITE_CLIPS_SUCCESS,
  OTHER_FAVOURITE_VIDEOS_TITLE,
  MY_FAVOURITE_VIDEOS_TITLE,
  SET_MORE_MY_CLIPS_REQUESTING,
  SET_CLIP_FILTERS,
  REMOVE_MY_CLIPS_GROUP,
  SET_CLOSED_COMMUNITY_CLIPS_REQUESTING,
  SET_CLOSED_COMMUNITY_CLIPS_SUCCESS,
  SET_CLOSED_COMMUNITY_CLIPS_ERROR,
  SET_CLOSED_COMMUNITY_NAME,
  SET_MORE_CLOSED_COMMUNITY_CLIPS_REQUESTING,
  SET_MORE_CLOSED_COMMUNITY_CLIPS_SUCCESS,
} from '../constants';
import {
  ClipsState,
  Status,
  CommunityClipGroup,
  FavouriteClipGroup,
  SetClipsType,
  ClosedCommunityClipGroup,
} from '../types';
import { sortCommunityClipGroupsByTitle } from '../utils';

const initClipsState: ClipsState = {
  status: Status.idle,
  errorMsg: null,
  myClipGroups: [],
  myClipGroupCount: 0,
  communityClipGroups: [],
  communityClipGroupCount: 0,
  favouriteClipGroups: [
    {
      id: 1,
      title: MY_FAVOURITE_VIDEOS_TITLE,
      clips: [],
      count: 0,
      status: Status.idle,
    },
    {
      id: 2,
      title: OTHER_FAVOURITE_VIDEOS_TITLE,
      clips: [],
      count: 0,
      status: Status.idle,
    },
  ],
  favouriteClipGroupCount: 2,
  closedCommunityClipGroupCount: 0,
  closedCommunityClipGroups: [],
  closedCommunityName: '',
  filters: {
    clipCategories: [],
  },
};

/**
 * Clips reducer
 *
 * @params state State of clips
 */
export const clipsReducer = (state: ClipsState = initClipsState, action: AnyAction): ClipsState => {
  switch (action.type) {
    case SET_MY_CLIPS_REQUESTING: {
      return {
        ...state,
        status: Status.requesting,
        errorMsg: null,
        myClipGroups: [],
        myClipGroupCount: 0,
      };
    }

    case SET_MORE_MY_CLIPS_REQUESTING: {
      return { ...state, status: Status.requestingMore };
    }

    case SET_COMMUNITY_CLIPS_REQUESTING: {
      return {
        ...state,
        status: Status.requesting,
        errorMsg: null,
        communityClipGroups: [],
        communityClipGroupCount: 0,
      };
    }

    case SET_CLOSED_COMMUNITY_CLIPS_REQUESTING: {
      return {
        ...state,
        status: Status.requesting,
        errorMsg: null,
        closedCommunityClipGroupCount: 0,
        closedCommunityClipGroups: [],
      };
    }

    case SET_FAVOURITE_CLIPS_REQUESTING: {
      return {
        ...state,
        status: Status.requesting,
        errorMsg: null,
        favouriteClipGroups: [
          {
            id: 1,
            title: MY_FAVOURITE_VIDEOS_TITLE,
            clips: [],
            count: 0,
            status: Status.idle,
          },
          {
            id: 2,
            title: OTHER_FAVOURITE_VIDEOS_TITLE,
            clips: [],
            count: 0,
            status: Status.idle,
          },
        ],
        favouriteClipGroupCount: 2,
      };
    }

    case SET_MY_CLIPS_ERROR: {
      const { errorMsg } = action.payload;

      return { ...state, status: Status.error, errorMsg, myClipGroups: [], myClipGroupCount: 0 };
    }

    case SET_COMMUNITY_CLIPS_ERROR: {
      const { errorMsg } = action.payload;

      return {
        ...state,
        status: Status.error,
        errorMsg,
        communityClipGroups: [],
        communityClipGroupCount: 0,
      };
    }

    case SET_CLOSED_COMMUNITY_CLIPS_ERROR: {
      return {
        ...state,
        status: Status.error,
        errorMsg: action.errorMsg,
        communityClipGroups: [],
        closedCommunityClipGroupCount: 0,
      };
    }

    case SET_FAVOURITE_CLIPS_ERROR: {
      const { errorMsg } = action.payload;

      return {
        ...state,
        status: Status.error,
        errorMsg,
        favouriteClipGroups: [
          {
            id: 1,
            title: MY_FAVOURITE_VIDEOS_TITLE,
            clips: [],
            count: 0,
            status: Status.idle,
          },
          {
            id: 2,
            title: OTHER_FAVOURITE_VIDEOS_TITLE,
            clips: [],
            count: 0,
            status: Status.idle,
          },
        ],
        favouriteClipGroupCount: 2,
      };
    }

    case SET_MY_CLIPS_SUCCESS: {
      const { myClipGroups, myClipGroupCount } = action.payload;

      return { ...state, status: Status.success, errorMsg: null, myClipGroups, myClipGroupCount };
    }

    case SET_CLOSED_COMMUNITY_CLIPS_SUCCESS: {
      const { closedCommunityClips, closedCommunityClipGroup } = action.payload;

      const clipGroups = state.closedCommunityClipGroups;

      const newClipGroup: ClosedCommunityClipGroup = {
        ...closedCommunityClipGroup,
        clips: closedCommunityClips,
      };

      if (clipGroups.find(clipGroup => clipGroup.id === closedCommunityClipGroup.id)) {
        const newClosedCommunityClipGroups = clipGroups.map(
          (clipGroup: ClosedCommunityClipGroup) => {
            if (clipGroup.id === closedCommunityClipGroup.id) {
              return newClipGroup;
            }
            return clipGroup;
          }
        );
        return {
          ...state,
          status: Status.success,
          closedCommunityClipGroups: newClosedCommunityClipGroups.sort(
            sortCommunityClipGroupsByTitle
          ),
          closedCommunityClipGroupCount: newClosedCommunityClipGroups.length,
        };
      } else {
        return {
          ...state,
          status: Status.success,
          closedCommunityClipGroups: [...clipGroups, newClipGroup].sort(
            sortCommunityClipGroupsByTitle
          ),
          closedCommunityClipGroupCount: clipGroups.length + 1,
        };
      }
    }

    case SET_CLOSED_COMMUNITY_NAME: {
      const { name } = action.payload.name;
      return {
        ...state,
        closedCommunityName: name,
      };
    }

    case SET_COMMUNITY_CLIPS_SUCCESS: {
      const { communityClips, communityClipGroup } = action.payload;

      const clipGroups = state.communityClipGroups;

      const newClipGroup: CommunityClipGroup = {
        ...communityClipGroup,
        clips: communityClips,
      };

      if (clipGroups.find(clipGroup => clipGroup.id === communityClipGroup.id)) {
        const newCommunityClipGroups = clipGroups.map((clipGroup: CommunityClipGroup) => {
          if (clipGroup.id === communityClipGroup.id) {
            return newClipGroup;
          }
          return clipGroup;
        });
        return {
          ...state,
          status: Status.success,
          communityClipGroups: newCommunityClipGroups.sort(sortCommunityClipGroupsByTitle),
          communityClipGroupCount: newCommunityClipGroups.length,
        };
      } else {
        return {
          ...state,
          status: Status.success,
          communityClipGroups: [...clipGroups, newClipGroup].sort(sortCommunityClipGroupsByTitle),
          communityClipGroupCount: clipGroups.length + 1,
        };
      }
    }

    case SET_MORE_CLOSED_COMMUNITY_CLIPS_REQUESTING: {
      const { closedCommunityClipGroup } = action.payload;

      return {
        ...state,
        closedCommunityClipGroups: state.closedCommunityClipGroups.map(
          (clipGroup: ClosedCommunityClipGroup) => {
            if (clipGroup.id === closedCommunityClipGroup.id) {
              return { ...clipGroup, status: Status.requestingMore };
            }
            return clipGroup;
          }
        ),
      };
    }

    case SET_MORE_COMMUNITY_CLIPS_REQUESTING: {
      const { communityClipGroup } = action.payload;

      return {
        ...state,
        communityClipGroups: state.communityClipGroups.map((clipGroup: CommunityClipGroup) => {
          if (clipGroup.id === communityClipGroup.id) {
            return { ...clipGroup, status: Status.requestingMore };
          }
          return clipGroup;
        }),
      };
    }

    case SET_MORE_COMMUNITY_CLIPS_SUCCESS: {
      const { moreCommunityClips, communityClipGroup } = action.payload;
      const clipGroups = state.communityClipGroups;

      return {
        ...state,
        communityClipGroups: clipGroups.map((clipGroup: CommunityClipGroup) => {
          if (clipGroup.id === communityClipGroup.id) {
            return {
              ...clipGroup,
              clips: [...clipGroup.clips, ...moreCommunityClips],
              status: Status.success,
            };
          }
          return clipGroup;
        }),
      };
    }

    case SET_MORE_CLOSED_COMMUNITY_CLIPS_SUCCESS: {
      const { moreClosedCommunityClips, closedCommunityClipGroup } = action.payload;
      const clipGroups = state.closedCommunityClipGroups;

      return {
        ...state,
        closedCommunityClipGroups: clipGroups.map((clipGroup: ClosedCommunityClipGroup) => {
          if (clipGroup.id === closedCommunityClipGroup.id) {
            return {
              ...clipGroup,
              clips: [...clipGroup.clips, ...moreClosedCommunityClips],
              status: Status.success,
            };
          }
          return clipGroup;
        }),
      };
    }

    case SET_MY_FAVOURITE_CLIPS_SUCCESS: {
      const { myFavouriteClips, myFavouriteClipsCount } = action.payload;

      const newClipGroup: FavouriteClipGroup = {
        id: 1,
        title: MY_FAVOURITE_VIDEOS_TITLE,
        clips: myFavouriteClips,
        count: myFavouriteClipsCount,
        status: Status.success,
      };

      const newFavouriteClipGroups = [newClipGroup, state.favouriteClipGroups[1]];

      return {
        ...state,
        status: Status.success,
        favouriteClipGroups: newFavouriteClipGroups,
        favouriteClipGroupCount: newFavouriteClipGroups.length,
      };
    }

    case SET_OTHER_FAVOURITE_CLIPS_SUCCESS: {
      const { otherFavouriteClips, otherFavouriteClipsCount } = action.payload;

      const newClipGroup: FavouriteClipGroup = {
        id: 2,
        title: OTHER_FAVOURITE_VIDEOS_TITLE,
        clips: otherFavouriteClips,
        count: otherFavouriteClipsCount,
        status: Status.success,
      };

      const newFavouriteClipGroups = [state.favouriteClipGroups[0], newClipGroup];

      return {
        ...state,
        status: Status.success,
        favouriteClipGroups: newFavouriteClipGroups,
        favouriteClipGroupCount: newFavouriteClipGroups.length,
      };
    }

    case SET_MORE_FAVOURITE_CLIPS_REQUESTING: {
      const { type } = action.payload;

      if (type === SetClipsType.myFavourites) {
        const oldClipGroup = state.favouriteClipGroups[0];
        const newClipGroup = {
          ...oldClipGroup,
          status: Status.requestingMore,
        };

        const favouriteClipGroups = [newClipGroup, state.favouriteClipGroups[1]];

        return { ...state, favouriteClipGroups };
      } else {
        const oldClipGroup = state.favouriteClipGroups[1];
        const newClipGroup = {
          ...oldClipGroup,
          status: Status.requestingMore,
        };

        const favouriteClipGroups = [state.favouriteClipGroups[0], newClipGroup];

        return { ...state, favouriteClipGroups };
      }
    }

    case SET_MORE_MY_FAVOURITE_CLIPS_SUCCESS: {
      const { moreMyFavouriteClips, myFavouriteClipsCount } = action.payload;

      const oldClipGroup = state.favouriteClipGroups[0];
      const newClipGroup = {
        ...oldClipGroup,
        clips: [...oldClipGroup.clips, ...moreMyFavouriteClips],
        count: myFavouriteClipsCount,
        status: Status.success,
      };

      const newFavouriteClipGroups = [newClipGroup, state.favouriteClipGroups[1]];

      return {
        ...state,
        status: Status.success,
        favouriteClipGroups: newFavouriteClipGroups,
        favouriteClipGroupCount: newFavouriteClipGroups.length,
      };
    }

    case SET_MORE_OTHER_FAVOURITE_CLIPS_SUCCESS: {
      const { moreOtherFavouriteClips, otherFavouriteClipsCount } = action.payload;

      const oldClipGroup = state.favouriteClipGroups[1];
      const newClipGroup = {
        ...oldClipGroup,
        clips: [...oldClipGroup.clips, ...moreOtherFavouriteClips],
        count: otherFavouriteClipsCount,
        status: Status.success,
      };

      const newFavouriteClipGroups = [state.favouriteClipGroups[0], newClipGroup];

      return {
        ...state,
        status: Status.success,
        favouriteClipGroups: newFavouriteClipGroups,
        favouriteClipGroupCount: newFavouriteClipGroups.length,
      };
    }

    case SET_CLIP_FILTERS: {
      const { filters } = action.payload;
      return {
        ...state,
        filters,
      };
    }

    case REMOVE_MY_CLIPS_GROUP: {
      return {
        ...state,
        myClipGroups: state.myClipGroups.filter(group => group.id !== action.payload.clipsGroupId),
      };
    }

    default:
      return state;
  }
};
