import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
  useCallback,
  ChangeEvent,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isSafari } from 'react-device-detect';
import isTouchDevice from 'is-touch-device';

import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import VolumeUp from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import SettingsIcon from '@material-ui/icons/Settings';
import SpeedIcon from '@material-ui/icons/Speed';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { ContentLoader } from '../ContentLoader';
import {
  classHandler,
  timePresentation,
  getVolumeFromLocalStorage,
  setVolumeToLocalStorage,
  useFullscreenHandler,
} from '../../utils';
import { ApplicationBreakpoint, ApplicationMode, Quality, PlaybackSpeed } from '../../types';
import { getApplicationBreakpointState } from '../../selectors';

import './VideoPlayer.scss';
import { UnavailableVideoNotification } from '../UnavailableVideoNotification';
import {
  QUALITY_LIST,
  DEFAULT_QUALITY,
  DEFAULT_PLAYBACK_SPEED,
  SLOWEST_PLAYBACK_SPEED,
  FASTEST_PLAYBACK_SPEED,
  PLAYBACK_SPEED_SLOWER,
  PLAYBACK_SPEED_FASTER,
  PLAYBACK_SPEED_LIST,
} from '../../constants';
import { setInfoModalText, setInfoModalVisibility } from '../../actions';
import { isIOSApp, isIOSWeb } from '../../utils/getPlatform';

type TimeSegment = {
  start: number;
  end: number;
};

type VideoPlayerProps = {
  videoSrc: string;
  videoOwnerId?: number;
  timeSegment?: TimeSegment;
  keyboardShortcutDisabled?: boolean;
  setProgressTime?: (time: number) => void;
  startPlayingAt?: number;
  forceStopPlaying?: boolean;
};

export const VideoPlayer: FunctionComponent<VideoPlayerProps> = ({
  videoSrc,
  videoOwnerId,
  timeSegment,
  keyboardShortcutDisabled,
  setProgressTime,
  startPlayingAt,
  forceStopPlaying,
}) => {
  const videoRef = useRef<any>(null);
  const videoPlayerContainer = useRef<HTMLDivElement | null>(null);

  const [isPlaying, setPlaying] = useState<boolean>(false);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [originalDuration, setOriginalDuration] = useState<number | null>(null);
  const [isAvailable, setAvailable] = useState<boolean>(true);
  const [isLoadingError, setIsLoadingError] = useState(false);

  const [wasAvailable, setWasAvailable] = useState<boolean>(true);
  const [areControlsVisible, setControlsVisible] = useState<boolean>(false);
  const [prevTimeSegment, setPrevTimeSegment] = useState<TimeSegment | undefined>(timeSegment);

  const [playbackSpeedOpen, setPlaybackSpeedOpen] = useState<any>(false);
  const [qualityOpen, setQualityOpen] = useState<any>(false);

  const isQualityImplemented = false;
  const [progress, setProgress] = useState(0);
  const progressRange = 100000;

  const getInitialVolume = () => {
    if (isSafari) {
      return 0;
    }
    return getVolumeFromLocalStorage();
  };

  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState<number>(getInitialVolume());

  const [playbackSpeed, setPlaybackSpeed] = useState<PlaybackSpeed>(DEFAULT_PLAYBACK_SPEED);

  const [quality, setQuality] = useState<Quality>(DEFAULT_QUALITY);

  const applicationBreakpoint = useSelector(getApplicationBreakpointState);

  const playbackSpeedRef = useRef<any>(null);
  const qualityRef = useRef<any>(null);

  const prevQualityOpen = useRef<any>(qualityOpen);

  const iOS = isIOSApp();
  const iOSWeb = isIOSWeb();
  const iOSFullscreenHandler = useFullscreenHandler;
  const dispatch = useDispatch();

  const getTimeSegment = useCallback((): TimeSegment => {
    if (timeSegment) {
      return timeSegment;
    }
    return {
      start: 0,
      end: originalDuration || 0,
    };
  }, [timeSegment, originalDuration]);

  const getStartPlayingAt = (): number => {
    if (startPlayingAt) {
      return startPlayingAt;
    }
    return getTimeSegment().start;
  };

  const maybeDuration = getTimeSegment().end - getTimeSegment().start;
  const duration = maybeDuration < 0 ? 0 : maybeDuration;

  const play = useCallback(() => {
    const video = videoRef.current;
    if (video && video.paused && !isPlaying) video.play();
  }, [videoRef, isPlaying]);

  const pause = useCallback(() => {
    const video = videoRef.current;
    if (video && !video.paused && isPlaying) video.pause();
  }, [videoRef, isPlaying]);

  const getVolume = useCallback(() => {
    if (muted) return 0;
    return volume;
  }, [muted, volume]);

  useEffect(() => {
    if (!isPlaying || !videoRef.current || isNaN(videoRef.current.duration)) {
      return;
    }
    if (originalDuration === null) {
      setOriginalDuration(videoRef.current.duration);
    }
  }, [isPlaying, videoRef, originalDuration]);

  useEffect(() => {
    const video = videoRef.current;
    if (video && !video.paused && forceStopPlaying) video.pause();
  }, [forceStopPlaying, videoRef]);

  useEffect(() => {
    setWasAvailable(isAvailable);
    setAvailable(true);
    if (originalDuration === null) return;
    const originalStart = 0;
    const originalEnd = originalDuration;
    const videoStartsEarly = getTimeSegment().start < originalStart;
    const videoEndsLate = getTimeSegment().end > originalEnd;
    const videoIsTooShort = getTimeSegment().end - getTimeSegment().start < 1;
    const videoIsUnavailable = videoStartsEarly || videoEndsLate || videoIsTooShort;

    setWasAvailable(isAvailable);
    setAvailable(!videoIsUnavailable);
  }, [originalDuration, getTimeSegment, isAvailable]);

  useEffect(() => {
    if (isAvailable && !wasAvailable) {
      play();
    }
    if (!isAvailable && wasAvailable) {
      pause();
    }
  }, [isAvailable, wasAvailable, play, pause]);

  useEffect(() => {
    if (prevQualityOpen.current === true && qualityOpen === false) {
      qualityRef.current?.focus();
    }

    prevQualityOpen.current = qualityOpen;
  }, [qualityOpen]);

  useEffect(() => {
    if (videoRef.current) videoRef.current.volume = getVolume() / 100;
  }, [videoRef.current, getVolume]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (videoRef.current) videoRef.current.playbackRate = playbackSpeed.value;
  }, [videoRef, playbackSpeed]);

  useEffect(() => {
    setVolumeToLocalStorage(volume);
  }, [volume]);

  useEffect(() => {
    if (!areControlsVisible) {
      setQualityOpen(false);
      setPlaybackSpeedOpen(false);
    }
  }, [areControlsVisible]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardShortcut);

    return () => {
      window.removeEventListener('keydown', handleKeyboardShortcut);
    };
  });

  // Add listener to video load error
  useEffect(() => {
    if (videoRef.current) {
      const handleError = () => setIsLoadingError(true);
      videoRef.current.addEventListener('error', handleError, true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      return () => videoRef.current.removeEventListener('error', handleError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef.current]);

  const handleCanPlay = () => {
    setLoading(false);
    if (!iOSWeb && !iOS) {
      play();
    }
  };

  const setMaxProgress = () => {
    videoRef.current.currentTime = getStartPlayingAt();
  };

  const getTimeFromStart = () => {
    if (videoRef && videoRef.current) return videoRef.current.currentTime - getTimeSegment().start;
    return 0;
  };

  /**
   * From duration and videoRef.current get percentage
   * @returns number 0 - progressRange
   */
  const calculateProgressFromTime = () => {
    const timeFromStart = getTimeFromStart();
    const percentage = timeFromStart / duration;
    return Math.floor(percentage * progressRange);
  };

  /**
   * From percentage get currentTime
   * @returns number
   */
  const calculateTimeFromProgress = (_progress: number) => {
    const percentage = _progress / progressRange;
    const timeFromStart = percentage * duration;
    return timeFromStart + getTimeSegment().start;
  };

  const setCurrentProgress = () => {
    if (duration === 0) {
      return;
    }
    if (videoRef.current.currentTime > getTimeSegment().end + 0.2) playAgain();
    setProgress(calculateProgressFromTime());
    if (setProgressTime) setProgressTime(Math.floor(videoRef.current.currentTime));
  };

  const onChangeProgress = (e: ChangeEvent<HTMLInputElement>) => {
    setProgress(Number(e.target.value));
    const currentTime = calculateTimeFromProgress(Number(e.target.value));
    videoRef.current.currentTime = currentTime;

    if (setProgressTime) setProgressTime(Math.floor(currentTime));
  };

  // @ts-ignore
  const onPlaying = () => {
    setPlaying(true);
  };

  const onPause = () => {
    setPlaying(false);
  };

  const onEnded = () => {
    videoRef.current.currentTime = getTimeSegment().start;
  };

  const playPause = () => {
    if (videoRef.current.paused) {
      play();
    } else {
      pause();
    }
  };

  const handleVideoClick = () => {
    if (applicationBreakpoint === ApplicationBreakpoint.desktop) playPause();
  };

  const stop = () => {
    pause();
    videoRef.current.currentTime = getTimeSegment().start;
  };

  useEffect(() => {
    const timeSegmentChanged =
      timeSegment?.start !== prevTimeSegment?.start || timeSegment?.end !== prevTimeSegment?.end;
    if (timeSegmentChanged) {
      if (videoRef.current) videoRef.current.currentTime = getTimeSegment().start;
      play();
    }
    setPrevTimeSegment(timeSegment);
  }, [timeSegment, prevTimeSegment, setPrevTimeSegment, play, videoRef, getTimeSegment]);

  const playAgain = () => {
    stop();
    playPause();
  };

  const handleMouseEnter = () => {
    setControlsVisible(true);
  };

  const handleMouseLeave = () => {
    setControlsVisible(false);
  };

  const handleTouchStart = () => {
    setControlsVisible(true);
    setTimeout(() => {
      setControlsVisible(false);
    }, 3000);
  };

  const soundOn = () => {
    setMuted(false);
    if (volume === 0) setVolume(50);
  };

  const soundOff = () => {
    setMuted(true);
    setVolumeToLocalStorage(0);
  };

  const onChangeVolume = (e: ChangeEvent<HTMLInputElement>) => {
    setVolume(Number(e.target.value));
    setMuted(Number(e.target.value) === 0);
  };

  const toggleOpenPlaybackSpeed = () => {
    setQualityOpen(false);
    setPlaybackSpeedOpen(!playbackSpeedOpen);
  };

  const toggleOpenQuality = () => {
    setPlaybackSpeedOpen(false);
    setQualityOpen(!qualityOpen);
  };

  const handleClosePlaybackSpeed = (event: React.MouseEvent<EventTarget>) => {
    if (
      playbackSpeedRef.current &&
      playbackSpeedRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setPlaybackSpeedOpen(false);
  };

  const handleCloseQuality = (event: React.MouseEvent<EventTarget>) => {
    if (qualityRef.current && qualityRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setQualityOpen(false);
  };

  const handleChoosePlaybackSpeed = (_playbackSpeed: PlaybackSpeed) => {
    setPlaybackSpeed(_playbackSpeed);
    setPlaybackSpeedOpen(false);
  };

  const handleChooseQuality = (_quality: Quality) => {
    setQuality(_quality);
  };

  const enterFullscreen = () => {
    const container = videoPlayerContainer.current;
    if (!container) return;

    // iOS doesn't support Fullscreen API
    if ((container as any).webkitRequestFullscreen) {
      (container as any).webkitRequestFullscreen();
      return;
    } else if (iOS) {
      iOSFullscreenHandler(
        videoSrc,
        getStartPlayingAt(),
        parseInt(getTimeSegment().end.toString(), 0),
        parseInt(videoRef.current.currentTime, 10),
        (result: any) => {
          if (result.status === 1) {
            dispatch(setInfoModalText(result.error));
            dispatch(setInfoModalVisibility(true));
          }
        },
        (data: any) => {
          videoRef.current.currentTime = data.data;
        }
      );
      pause();
      return;
    }

    container.requestFullscreen();
  };

  const exitFullscreen = () => {
    // iOS doesn't support Fullscreen API
    if ((document as any).webkitExitFullscreen) {
      (document as any).webkitExitFullscreen();
      return;
    } else if (iOS) {
      iOSFullscreenHandler(
        videoSrc,
        getStartPlayingAt(),
        getTimeSegment().end,
        parseInt(videoRef.current.currentTime, 10),
        (result: any) => {
          if (result.status === 1) {
            dispatch(setInfoModalText(result.error));
            dispatch(setInfoModalVisibility(true));
          }
        },
        (data: any) => {
          videoRef.current.currentTime = data.data;
        }
      );
      return;
    }

    document.exitFullscreen();
  };

  const toggleFullscreen = () => {
    // iOS doesn't support Fullscreen API
    if (document.fullscreenElement || (document as any).webkitFullscreenElement) {
      exitFullscreen();
    } else {
      enterFullscreen();
    }
  };

  const fasterPlaybackSpeed = () => {
    if (playbackSpeed !== FASTEST_PLAYBACK_SPEED) {
      const currentIndex = PLAYBACK_SPEED_LIST.findIndex(
        (speed: PlaybackSpeed) => speed === playbackSpeed
      );
      setPlaybackSpeed(PLAYBACK_SPEED_LIST[currentIndex + PLAYBACK_SPEED_FASTER]);
    }
  };

  const slowerPlaybackSpeed = () => {
    if (playbackSpeed !== SLOWEST_PLAYBACK_SPEED) {
      const currentIndex = PLAYBACK_SPEED_LIST.findIndex(
        (speed: PlaybackSpeed) => speed === playbackSpeed
      );
      setPlaybackSpeed(PLAYBACK_SPEED_LIST[currentIndex + PLAYBACK_SPEED_SLOWER]);
    }
  };

  const goForward = (seconds: number) => {
    const wantToGoHere = videoRef.current.currentTime + seconds;
    const maximum = getTimeSegment().end;
    if (wantToGoHere <= maximum) {
      videoRef.current.currentTime = wantToGoHere;
    } else {
      videoRef.current.currentTime = maximum;
    }
  };

  const goBackward = (seconds: number) => {
    const wantToGoHere = videoRef.current.currentTime - seconds;
    const minimum = getTimeSegment().start;
    if (wantToGoHere >= minimum) {
      videoRef.current.currentTime = wantToGoHere;
    } else {
      videoRef.current.currentTime = minimum;
    }
  };

  const handleKeyboardShortcut = (event: KeyboardEvent) => {
    if (keyboardShortcutDisabled) return;
    let preventDefault = true;
    const { keyCode } = event;
    const space = 32;
    const f = 70;
    const m = 77;
    const arrowDown = 40;
    const arrowUp = 38;
    const arrowLeft = 37;
    const arrowRight = 39;
    if (!forceStopPlaying) {
      switch (keyCode) {
        case space:
          playPause();
          break;
        case f:
          toggleFullscreen();
          break;
        case arrowUp:
          fasterPlaybackSpeed();
          break;
        case arrowDown:
          slowerPlaybackSpeed();
          break;
        case arrowLeft:
          goBackward(5);
          break;
        case arrowRight:
          goForward(5);
          break;
        case m:
          setMuted(!muted);
          break;
        default:
          preventDefault = false;
          break;
      }
    }

    if (preventDefault) event.preventDefault();
  };

  if (!videoSrc.length) return null;

  const formattedTimeFromStart = timePresentation(getTimeFromStart());
  const formattedDuration = timePresentation(duration);

  return (
    <React.Fragment>
      {(!isAvailable || isLoadingError) && (
        <UnavailableVideoNotification
          timeOverlap={!isAvailable}
          isLoadingError={isLoadingError}
          videoOwnerId={videoOwnerId}
        />
      )}
      <div
        onTouchStart={handleTouchStart}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="VideoPlayer"
        hidden={!isAvailable || isLoadingError}
        ref={videoPlayerContainer}
      >
        <div
          className="VideoPlayer__loaderWrapper"
          style={{
            display: isLoading ? 'block' : 'none',
          }}
        >
          <div className="VideoPlayer__loader">
            <ContentLoader />
          </div>
        </div>

        <video
          onCanPlay={handleCanPlay}
          ref={videoRef}
          src={videoSrc}
          onTimeUpdate={setCurrentProgress}
          onLoadedMetadata={setMaxProgress}
          onClick={handleVideoClick}
          onDoubleClick={toggleFullscreen}
          onPlaying={onPlaying}
          onPause={onPause}
          onEnded={onEnded}
          playsInline
          autoPlay
          controls={false}
        />
        <div
          className={classHandler(
            'VideoPlayer__controls',
            ApplicationMode.light,
            false,
            areControlsVisible || isTouchDevice()
          )}
        >
          <input
            className="VideoPlayer__progressBar"
            type="range"
            id="progress"
            name="progress"
            min="0"
            max={progressRange}
            value={progress}
            onChange={onChangeProgress}
            style={{
              background: `linear-gradient(to right, #de1919 0%, #de1919 ${(progress /
                progressRange) *
                100}%, #c8c8c8 ${(progress / progressRange) * 100}%, #c8c8c8 100%)`,
            }}
          />
          <div className="VideoPlayer__controlBar">
            <div className="VideoPlayer__controlsLeft">
              <div className="VideoPlayer__playerButton" onClick={playPause}>
                {isPlaying ? (
                  <PauseIcon className="VideoPlayer__playPauseIcon" />
                ) : (
                  <PlayArrowIcon className="VideoPlayer__playPauseIcon" />
                )}
              </div>
              <div
                className="VideoPlayer__playerButton"
                style={{
                  display:
                    applicationBreakpoint === ApplicationBreakpoint.desktop ? 'block' : 'none',
                }}
              >
                {!muted ? (
                  <VolumeUp className="VideoPlayer__whiteIcon" onClick={soundOff} />
                ) : (
                  <VolumeOffIcon className="VideoPlayer__whiteIcon" onClick={soundOn} />
                )}
              </div>
              <input
                className="VideoPlayer__inputVolume"
                type="range"
                id="volume"
                name="volume"
                min="0"
                max="100"
                value={getVolume()}
                onChange={onChangeVolume}
                style={{
                  background: `linear-gradient(to right, #fff 0%, #fff ${getVolume()}%, #7d7470 ${getVolume()}%, #7d7470 100%)`,
                  display:
                    applicationBreakpoint === ApplicationBreakpoint.desktop
                      ? 'inline-block'
                      : 'none',
                }}
              />
              <div style={{ color: '#FFF' }}>
                {formattedTimeFromStart} / {formattedDuration}
              </div>
            </div>
            <div className="VideoPlayer__controlsRight">
              <div
                ref={playbackSpeedRef}
                aria-controls={playbackSpeedOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={toggleOpenPlaybackSpeed}
                style={{ margin: '0 14px' }}
              >
                <SpeedIcon className="VideoPlayer__whiteIcon" />
              </div>
              <div
                ref={qualityRef}
                aria-controls={qualityOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={toggleOpenQuality}
                style={{ margin: '0 7px', display: isQualityImplemented ? undefined : 'none' }}
              >
                <SettingsIcon className="VideoPlayer__whiteIcon" />
              </div>
              {!iOSWeb && (
                <div onMouseDown={toggleFullscreen}>
                  <FullscreenIcon className="VideoPlayer__screenIcon" />
                </div>
              )}
            </div>
          </div>
          <Popper
            open={playbackSpeedOpen}
            anchorEl={playbackSpeedRef.current}
            role={undefined}
            transition={true}
            disablePortal={true}
            placement="top"
          >
            {({ TransitionProps }) => (
              <Grow style={{ backgroundColor: '#2B333F' }} {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClosePlaybackSpeed}>
                    <MenuList
                      autoFocusItem={playbackSpeedOpen}
                      id="menu-list-grow"
                      style={{ color: '#FFF' }}
                    >
                      {PLAYBACK_SPEED_LIST.map((_playbackSpeed: PlaybackSpeed) => {
                        const onClick = (_: React.MouseEvent<EventTarget>) =>
                          handleChoosePlaybackSpeed(_playbackSpeed);
                        return (
                          <MenuItem
                            key={_playbackSpeed.value}
                            className="VideoPlayer__menuItem"
                            onClick={onClick}
                            selected={_playbackSpeed.value === playbackSpeed.value}
                          >
                            {_playbackSpeed.label}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <Popper
            open={qualityOpen && isQualityImplemented}
            anchorEl={qualityRef.current}
            role={undefined}
            transition={true}
            disablePortal={true}
            placement="top"
          >
            {({ TransitionProps }) => (
              <Grow style={{ backgroundColor: '#2B333F' }} {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseQuality}>
                    <MenuList
                      autoFocusItem={qualityOpen}
                      id="menu-list-grow"
                      style={{ color: '#FFF' }}
                    >
                      {QUALITY_LIST.map((_quality: Quality) => {
                        const onClick = (_: React.MouseEvent<EventTarget>) =>
                          handleChooseQuality(_quality);
                        return (
                          <MenuItem
                            key={_quality.value}
                            className="VideoPlayer__menuItem"
                            onClick={onClick}
                            selected={_quality.value === quality.value}
                          >
                            {_quality.label}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    </React.Fragment>
  );
};
