import { Dispatch } from 'redux';

import {
  SET_SETTINGS_REQUESTING,
  SET_SETTINGS_ERROR,
  SET_SETTINGS_SUCCESS,
  mockedSettings,
  RESET_LANGUAGE,
} from '../constants';
import { Settings } from '../types';

/**
 * Action for settings setting (REQUESTING)
 *
 */
export const setSettingsRequesting = () => ({ type: SET_SETTINGS_REQUESTING });

/**
 * Action for settings setting (ERROR)
 *
 * @param errorMsg Error message
 */
export const setSettingsError = (errorMsg: string) => ({
  type: SET_SETTINGS_ERROR,
  payload: { errorMsg },
});

/**
 * Action for settings setting (SUCCESS)
 *
 * @param settings settings
 */
export const setSettingsSuccess = (settings: Settings) => ({
  type: SET_SETTINGS_SUCCESS,
  payload: { settings },
});

export const resetLanguage = () => ({
  type: RESET_LANGUAGE,
});

/**
 * Action for settings setting
 *
 */
export const setSettings = () => (dispatch: Dispatch) => {
  dispatch(setSettingsRequesting());

  dispatch(setSettingsSuccess(mockedSettings));
};
