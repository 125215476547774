import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUser } from '../../selectors';

import {
  getApplicationBreakpointState,
  getApplicationModeState,
  getFilterPanelVisibility,
  getSidePanelVisibility,
} from '../../selectors/application';
import { ApplicationBreakpoint } from '../../types';
import { classHandler } from '../../utils';
import { BottomMenu } from '../BottomMenu';
import { VideoFilterPanel } from '../VideoFilterPanel';
import { SidePanel } from '../SidePanel';
import '../DesktopLayout/DesktopLayout.scss';
import '../MobileLayout/MobileLayout.scss';
import { AccountingFilterPanel } from '../AccountingFilterPanel';

export const Layout: FunctionComponent<{}> = ({ children }) => {
  const { pathname } = useLocation();

  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const applicationMode = useSelector(getApplicationModeState);
  const filterPanelVisibility = useSelector(getFilterPanelVisibility);
  const isSidePanelVisible = useSelector(getSidePanelVisibility);
  const user = useSelector(getUser);

  const isVideoDetailRoute = pathname === '/video-detail';
  const isMatchDetailRoute = pathname === '/match-detail';
  const isDigirecorderRoute = pathname === '/digirecorder';
  const isSharedMatch = pathname === '/match-shared';

  const isDesktop = applicationBreakpoint === ApplicationBreakpoint.desktop;

  const renderFilterPanel = () => {
    switch (pathname) {
      case '/match-shared':
      case '/videos': {
        return <VideoFilterPanel isVisible={filterPanelVisibility} />;
      }
      case '/accounting': {
        return <AccountingFilterPanel isVisible={filterPanelVisibility} />;
      }
      default:
        return '';
    }
  };

  return (
    <div className={isDesktop ? classHandler('DesktopLayout', applicationMode) : 'MobileLayout'}>
      {isDesktop && <SidePanel />}
      <div className={isDesktop ? 'DesktopLayout__middleContent' : 'MobileLayout__middleContent'}>
        {children}
      </div>
      {isDesktop && (user || isSharedMatch) && renderFilterPanel()}
      {!isDesktop &&
        (user || isSharedMatch) &&
        !isVideoDetailRoute &&
        !isMatchDetailRoute &&
        !isDigirecorderRoute && <BottomMenu />}
      {!isDesktop && renderFilterPanel()}
      {!isDesktop && <SidePanel isVisible={isSidePanelVisible} />}
    </div>
  );
};
