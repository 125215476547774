import React, { FunctionComponent } from 'react';
import { Icon, IconType } from '../Icon';
import { useSelector } from 'react-redux';
import { getApplicationModeState } from '../../selectors';
import { classHandler, iconTypeHandler } from '../../utils';

import './InputWrapper.scss';
import { FormControl } from '@material-ui/core';
import { Position } from '../../constants';

type InputWrapperProps = {
  iconName?: string;
  iconSize?: string;
  iconAlignSelf?: Position;
  withDelete?: boolean;
  handleDelete?: React.MouseEventHandler<HTMLDivElement>;
  spacing?: string;
  formControl?: boolean;
};

export const InputWrapper: FunctionComponent<InputWrapperProps> = ({
  iconName,
  iconSize,
  iconAlignSelf = Position.center,
  withDelete,
  handleDelete,
  children,
  spacing,
  formControl,
}) => {
  const applicationMode = useSelector(getApplicationModeState);

  return (
    <div style={{ margin: spacing }} className={classHandler('InputWrapper', applicationMode)}>
      {iconName && (
        <div className="InputWrapper__iconWrapper" style={{ alignSelf: iconAlignSelf }}>
          <Icon
            name={iconName}
            type={iconTypeHandler(applicationMode, IconType.white)}
            size={iconSize || '18px'}
          />
        </div>
      )}
      {formControl && <FormControl className="InputWrapper__content">{children}</FormControl>}
      {!formControl && <div className="InputWrapper__content">{children}</div>}
      {withDelete ? (
        <div onClick={handleDelete} className="InputWrapper__trashIconWrapper">
          <Icon name="trash" type={iconTypeHandler(applicationMode, IconType.white)} size="18px" />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
