import React, { Fragment, FunctionComponent, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInfoModalText, setInfoModalVisibility } from '../../actions';
import { useOpenBrowserHandler } from '../../utils';
import { Icon, IconType } from '../Icon';
import './Button.scss';
import { isIOSApp } from '../../utils/getPlatform';
import { getApplicationModeState } from '../../selectors';

export interface ButtonProps {
  label?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  hidden?: boolean;
  loading?: boolean;
  disabled?: boolean;
  externalLink?: string;
  className?: string;
  type?: 'primary' | 'secondary' | 'basic';
  iconName?: string;
  iconType?: IconType;
  iconPosition?: 'left' | 'right';
  premiumFeature?: boolean;
  theme?: string;
  adaptable?: boolean;
  stretch?: boolean;
  children?: any;
  rightIcon?: ReactNode;
}

export const Button: FunctionComponent<ButtonProps> = ({
  label,
  onClick,
  hidden,
  loading,
  disabled,
  externalLink,
  className,
  type = 'basic',
  iconName,
  iconType,
  iconPosition,
  premiumFeature,
  theme,
  adaptable,
  stretch,
  children,
  rightIcon,
}) => {
  // different behavior for iOS
  const iOS = isIOSApp();
  theme = theme ?? useSelector(getApplicationModeState);
  const iOSOpenBrowserHandler = useOpenBrowserHandler;
  const dispatch = useDispatch();

  const handleLinkOpen = () => {
    if (iOS && externalLink)
      iOSOpenBrowserHandler(externalLink, (result: any) => {
        if (result.status === 1) {
          dispatch(setInfoModalText(result.error));
          dispatch(setInfoModalVisibility(true));
        }
      });
  };

  const linkOpener =
    iOS && externalLink ? (
      <span onClick={handleLinkOpen}>{label}</span>
    ) : (
      <a href={externalLink} target="_blank" rel="noopener noreferrer">
        {label}
      </a>
    );

  // const linkOpener = <span onClick={handleLinkOpen}>{label}</span>

  return (
    <Fragment>
      {!hidden && (
        <div
          onClick={disabled ? (premiumFeature ? onClick : undefined) : onClick}
          /*
          className={`${type === 'primary' ? 'Button__primary' : ''}
            ${type === 'primary' && disabled ? 'Button__primary--disabled' : ''}
            ${type === 'secondary' ? 'Button__secondary' : ''}
            ${type === 'basic' ? 'Button__basic' : ''}
            ${type === 'basic' && disabled ? 'Button__basic--disabled' : ''}
            ${className ? className : ''} ${disabled ? `${className}--disabled` : ''}
            ${disabled && className ? `${className}--disabled` : ''}
            ${theme === 'dark' && disabled ? 'Button__basic--dark--disabled' : ''}
            ${theme === 'dark' && type === 'basic' ? 'Button__basic--dark' : ''}
            ${theme === 'dark' ? 'Button--dark' : ''}
            ${adaptable ? 'Button--adaptable' : ''}
            ${stretch ? 'Button--stretch' : ''}
            `}
          */
          className={`
          Button__${type} Button__${type}${theme === 'dark' ? '--dark' : ''}${
            disabled ? '--disabled' : ''
          } 
          Button${adaptable ? '--adaptable' : ''}
          Button${stretch ? '--stretch' : ''} 
          ${className ? className : ''}`}
        >
          {iconPosition !== 'right' && iconName && (
            <span className="left-icon">
              <Icon size="16px" name={iconName} type={iconType || IconType.default} />
            </span>
          )}

          {loading ? (
            <div className="SaveButtonsWrapper__spinner" />
          ) : (
            <Fragment>
              {externalLink ? linkOpener : label}
              {children}
            </Fragment>
          )}

          {iconPosition === 'right' && (iconName || rightIcon) && (
            <span className="right-icon">
              {!rightIcon && iconName && (
                <Icon size="16px" name={iconName} type={iconType || IconType.default} />
              )}
              {rightIcon}
            </span>
          )}
        </div>
      )}
    </Fragment>
  );
};
