import React, { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Icon, IconType } from '../Icon';
import { getApplicationModeState } from '../../selectors';
import { classHandler, iconTypeHandler } from '../../utils';

import './BottomMenuItem.scss';

export type BottomMenuItemProps = {
  label: string;
  iconName: string;
  to?: string;
  onClick?: () => void;
};

export const BottomMenuItem: FunctionComponent<BottomMenuItemProps> = ({
  label,
  iconName,
  to,
  onClick,
}) => {
  const { pathname } = useLocation();

  const applicationMode = useSelector(getApplicationModeState);
  const isActive = pathname === to;

  return (
    <Link onClick={onClick} to={to || ''} className="BottomMenuItem__link">
      <div className={classHandler('BottomMenuItem', applicationMode, isActive)}>
        <Icon
          type={iconTypeHandler(applicationMode, IconType.light, IconType.red, isActive)}
          name={iconName}
        />
        {applicationMode === 'dark' && isActive ? (
          <span style={{ color: 'white' }}>{label}</span>
        ) : (
          label
        )}
      </div>
    </Link>
  );
};
