import { AnyAction } from 'redux';
import {
  SET_NOTEPAD,
  SET_NOTEPAD_REQUESTING,
  SET_NOTES_BY_MATCHES,
  SET_NOTES_BY_MATCHES_REQUESTING,
} from '../constants';
import { NotesState, Status } from '../types';

const initNotesState: NotesState = {
  status: Status.idle,
  notepad: '',
  notesByMatches: [],
};

export const notesReducer = (state: NotesState = initNotesState, action: AnyAction): NotesState => {
  switch (action.type) {
    case SET_NOTEPAD_REQUESTING:
    case SET_NOTES_BY_MATCHES_REQUESTING: {
      return {
        ...state,
        status: Status.requesting,
      };
    }

    case SET_NOTEPAD: {
      const { notepad } = action.payload;

      return {
        ...state,
        notepad,
        status: Status.success,
      };
    }
    case SET_NOTES_BY_MATCHES: {
      const { notesByMatches } = action.payload;

      return {
        ...state,
        notesByMatches,
        status: Status.success,
      };
    }
    default:
      return state;
  }
};
