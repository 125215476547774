import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { classHandler, isErrorResponse, useModalKeyboardShortcuts } from '../../utils';
import {
  getApplicationBreakpointState,
  getApplicationModeState,
  getCompetitions,
  getUser,
  getSettings,
} from '../../selectors';
import { Title } from '../../components/Title';
import { setCompetitions } from '../../actions';
import { ApplicationBreakpoint, Competition, Gender } from '../../types';
import { FloatingAddButton } from '../../components/FloatingAddButton';
import { Modal } from '../../components/Modal';
import { defaultNewCompetition } from '../../constants';
import { postCompetition } from '../../api';
import { CompetitionEditContent } from '../../components/CompetitionEditContent';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { MobileTitle } from '../../components/MobileTItle';

import './CompetitionsPage.scss';

export enum ModalType {
  edit = 'edit',
  new = 'new',
}

export const CompetitionsPage: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [newCompetition, setNewCompetition] = useState<Competition>(defaultNewCompetition);

  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const settings = useSelector(getSettings);
  const user = useSelector(getUser);
  const menCompetitions = useSelector(getCompetitions(Gender.men));
  const womenCompetitions = useSelector(getCompetitions(Gender.women));

  const saveNewCompetition = () => {
    const currentSport = settings?.sports.id;
    const currentCountry = settings?.country.id;
    postCompetition(newCompetition, currentSport, currentCountry).then((response: any) => {
      if (!isErrorResponse(response)) {
        if (user) dispatch(setCompetitions(user?.id));

        hideModal();
        setNewCompetition({ ...newCompetition, name: '' });
      }
    });
  };

  const handleNewCompetitionChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    setNewCompetition({ ...newCompetition, [name]: value });
  };

  const openCreateModal = () => {
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    if (user) dispatch(setCompetitions(user.id));
  }, [dispatch, user]);

  const renderCompetitions = (competitions: Competition[]) => {
    return competitions
      .filter((com: any) => com.sport === settings?.sports.id)
      .map((competition: Competition) => {
        const handleClick = () => {
          history.push(`/competition-detail?id=${competition.id}`);
        };

        return (
          <TableRow
            hover={true}
            style={{ cursor: 'pointer' }}
            key={competition.id}
            onClick={handleClick}
          >
            <TableCell>{t(competition.name)}</TableCell>
            <TableCell align="right">{competition.categories.length}</TableCell>
          </TableRow>
        );
      });
  };

  useModalKeyboardShortcuts(isModalVisible, hideModal, saveNewCompetition);

  return (
    <div className={classHandler('CompetitionsPage', applicationMode)}>
      {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
        <Title title={t('competitionsAndCategories')} />
      ) : (
        <MobileTitle title={t('competitionsAndCategories')} />
      )}
      <div className="CompetitionsPage__scrollWrapper">
        <div className="CompetitionsPage__contentWrapper">
          <div className="CompetitionsPage__container">
            <h3>{t('menCompetitions')}</h3>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="CompetitionsPage__headerText">{t('name')}</TableCell>
                  <TableCell className="CompetitionsPage__headerText" align="right">
                    {t('categories')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderCompetitions(menCompetitions)}</TableBody>
            </Table>
          </div>
          <div className="CompetitionsPage__container">
            <h3>{t('womenCompetitions')}</h3>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="CompetitionsPage__headerText">{t('name')}</TableCell>
                  <TableCell className="CompetitionsPage__headerText" align="right">
                    {t('categories')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderCompetitions(womenCompetitions)}</TableBody>
            </Table>
          </div>
        </div>
      </div>
      <FloatingAddButton handleClick={openCreateModal} />
      <Modal isVisible={isModalVisible} width="500px" height="fit-content" handleClose={hideModal}>
        <CompetitionEditContent
          competition={newCompetition}
          handleCompetition={handleNewCompetitionChange}
          handleSave={saveNewCompetition}
          modalType={ModalType.new}
        />
      </Modal>
    </div>
  );
};
