import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  getApplicationBreakpointState,
  getApplicationModeState,
  getCompetitionsStatus,
  getMatchDetailStatus,
  getSettings,
} from '../../selectors';
import {
  ApplicationBreakpoint,
  ApplicationMode,
  ErrorResponse,
  MatchDetail,
  SaveButtonState,
  Status,
  Tab,
} from '../../types';
import { Title } from '../../components/Title';
import {
  getMatchDetailTabFromLocalStorage,
  isErrorResponse,
  setMatchDetailTabToLocalStorage,
  useQuery,
} from '../../utils';
import { ContentLoader } from '../../components/ContentLoader';
import { cancelUpload, postMatchDetail, postNewMatchDetail, removeMatchDetail } from '../../api';
import { MobileTitle } from '../../components/MobileTItle';

import './MatchDetailPage.scss';
import { SaveButtonsWrapper } from '../../components/SaveButtonsWrapper';
import { AlertModal } from '../../components/AlertModal';
import MatchDetailRoutes from '../MatchDetailRoutes/MatchDetailRoutes';
import useNewMatch from '../../utils/useNewMatch';
import {
  setMatchDetailAsyncRequesting,
  setMatchDetailError,
  setMatchDetailSuccess,
} from '../../actions';
import useIsNewMatch from '../../utils/useIsNewMatch';

export const MatchDetailPage: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false);

  const matchId = parseInt(useQuery().get('id') || '0', 0);
  const isNew = useIsNewMatch();

  const { newMatch, setNewMatch } = useNewMatch();

  const handleConfirmVisibilityChange = () => {
    setConfirmVisible(!isConfirmVisible);
  };

  const handleDelete = (result: boolean) => {
    handleConfirmVisibilityChange();
    // const result = window.confirm(t('deleteMatch'));
    if (result) {
      removeMatchDetail(matchId).then((response: any) => {
        if (!isErrorResponse(response)) {
          history.goBack();
        }
      });
    }
  };

  const handleSave = (_: any, redirect: string = '') => {
    autoSaveHandler();
    history.push(redirect);
  };

  const nextTab = () => {
    if (activeTab.name !== t('notes')) {
      tabs.forEach((tab: Tab, index: number) => {
        if (activeTab.name === tab.name) {
          setActiveTab(tabs[index + 1]);
        }
      });
    }
    autoSaveHandler();
  };

  const previousTab = () => {
    if (activeTab.name !== t('info')) {
      tabs.forEach((tab: Tab, index: number) => {
        if (activeTab.name === tab.name) {
          setActiveTab(tabs[index - 1]);
        }
      });
    }
    autoSaveHandler();
  };

  const autoSaveHandler = async () => {
    if (isNew && newMatch?.id === 0) {
      const response = await postNewMatchDetail(newMatch, settings?.sports.id);
      if (!isErrorResponse(response)) {
        const matchDetail = response as MatchDetail;
        const search = [`id=${matchDetail.id}`];
        if (isNew) {
          search.push('isNew=true');
        }
        dispatch(setMatchDetailAsyncRequesting());
        window.history.replaceState(null, 'REFEREE RECORDER', `/match-detail?${search.join('&')}`);
        dispatch(setMatchDetailSuccess(matchDetail));
      } else {
        dispatch(setMatchDetailError((response as ErrorResponse).message.join(' ')));
      }
    } else if (newMatch) {
      dispatch(setMatchDetailAsyncRequesting());
      const response = await postMatchDetail(newMatch, settings?.sports.id);
      if (!isErrorResponse(response)) {
        dispatch(setMatchDetailSuccess(newMatch));
      }
    }
  };

  const tabs: Tab[] = [
    { id: 0, name: t('info'), key: 'info' },
    { id: 1, name: t('tabSituations'), key: 'situations' },
    { id: 2, name: t('video'), key: 'video' },
    { id: 3, name: t('tabOther'), key: 'tabOther' },
  ];

  useEffect(() => {
    return () => {
      if (history.location.pathname === '/video-detail') return;
      setMatchDetailTabToLocalStorage(tabs[0]);
    };
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const tabKey = params.get('tab');

    if (tabKey) {
      const currentTab = tabs.find(x => x.key === tabKey);
      if (currentTab) {
        setActiveTab(currentTab);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  const [activeTab, setActiveTab] = useState<Tab>(
    getMatchDetailTabFromLocalStorage(tabs) || tabs[0]
  );

  const [saveButtonState, setSaveButtonState] = useState<SaveButtonState>(SaveButtonState.default);
  const [isLeaveConfirmModalVisible, setLeaveConfirmModalVisible] = useState(false);

  const [addSrcModalvisible, setAddSrcModalVisible] = useState<boolean>(false);

  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const matchDetailStatus = useSelector(getMatchDetailStatus);
  const competitionsStatus = useSelector(getCompetitionsStatus);
  const settings = useSelector(getSettings);

  useEffect(() => {
    setMatchDetailTabToLocalStorage(activeTab);
  }, [activeTab]);

  const title = useMemo(() => {
    return isNew ? t('newMatch') : t('matchDetail');
  }, [isNew, t]);

  const matchDetailClasses = ['MatchDetail'];
  if (applicationMode === ApplicationMode.dark) matchDetailClasses.push('MatchDetail--dark');

  const goBackToPrevTab = () => {
    history.push('');
  };

  const handleGoBack = () => {
    setLeaveConfirmModalVisible(true);
  };

  const handleLeaveMatch = (leave: boolean) => {
    if (leave) {
      if (newMatch.id && isNew) {
        if (newMatch.videoLink) {
          cancelUpload(newMatch.id);
        }
        removeMatchDetail(newMatch.id).then(() => goBackToPrevTab());
      } else {
        goBackToPrevTab();
      }
      return;
    }

    setLeaveConfirmModalVisible(false);
  };

  return (
    <div className={matchDetailClasses.join(' ')}>
      <AlertModal
        isVisible={isConfirmVisible}
        handleClose={handleDelete}
        confirmBtn={true}
        cancelBtn={true}
        text={t('deleteMatch')}
        confirmText={t('delete')}
      />

      <AlertModal
        isVisible={isLeaveConfirmModalVisible}
        handleClose={handleLeaveMatch}
        confirmBtn={true}
        cancelBtn={true}
        text={t('sureToLeave')}
        confirmText="YesLeave"
        cancelText="NoStay"
      />
      <AlertModal
        isVisible={addSrcModalvisible}
        handleClose={() => setAddSrcModalVisible(false)}
        text={t('AddSourceVideoFirst')}
        confirmBtn
        cancelBtn={false}
      />

      {(matchDetailStatus === Status.success && competitionsStatus === Status.success) ||
      matchDetailStatus === Status.asyncRequesting ? (
        <React.Fragment>
          {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
            <Title
              title={title}
              tabs={tabs}
              activeTab={activeTab}
              withIcons={[
                { onClick: handleConfirmVisibilityChange, name: 'trash', visible: !isNew },
              ]}
              backFunction={true}
              setActiveTab={(tab: Tab) => {
                setActiveTab(tab);
                autoSaveHandler();
              }}
              withPrevTab={true}
              goBackTab={handleGoBack}
              isNewMatch={isNew}
            />
          ) : (
            <MobileTitle
              title={title}
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={(tab: Tab) => {
                setActiveTab(tab);
                autoSaveHandler();
              }}
              withIcons={[
                { onClick: handleConfirmVisibilityChange, name: 'trash', visible: !isNew },
              ]}
              withPrevTab={true}
              goBackTab={handleGoBack}
              backFunction={true}
              isNewMatch={isNew}
            />
          )}
          <div className="MatchDetail__container">
            <div className="MatchDetail__content">
              <MatchDetailRoutes
                setSaving={setSaveButtonState}
                tabName={activeTab.name}
                handleSave={handleSave}
                newMatch={newMatch}
                setNewMatch={setNewMatch}
              />
            </div>
          </div>

          <SaveButtonsWrapper
            primaryButton={{
              onClick: isNew && activeTab.name !== t('tabOther') ? nextTab : handleSave,
              label: isNew && activeTab.name !== t('tabOther') ? t('next') : t('save'),
              loading: saveButtonState === SaveButtonState.loading,
            }}
            secondaryButton={{
              onClick: previousTab,
              label: t('previous'),
              hidden: !isNew || activeTab.name === t('info'),
            }}
          />
        </React.Fragment>
      ) : (
        <ContentLoader />
      )}
    </div>
  );
};
