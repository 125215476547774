import { Dispatch } from 'redux';

import {
  SET_MY_CLIPS_REQUESTING,
  SET_MORE_MY_CLIPS_REQUESTING,
  SET_MY_CLIPS_ERROR,
  SET_MY_CLIPS_SUCCESS,
  SET_COMMUNITY_CLIPS_REQUESTING,
  SET_COMMUNITY_CLIPS_ERROR,
  SET_COMMUNITY_CLIPS_SUCCESS,
  SET_FAVOURITE_CLIPS_REQUESTING,
  SET_FAVOURITE_CLIPS_ERROR,
  SET_MY_FAVOURITE_CLIPS_SUCCESS,
  SET_OTHER_FAVOURITE_CLIPS_SUCCESS,
  SET_MORE_COMMUNITY_CLIPS_SUCCESS,
  SET_MORE_MY_FAVOURITE_CLIPS_SUCCESS,
  SET_MORE_OTHER_FAVOURITE_CLIPS_SUCCESS,
  SET_MORE_COMMUNITY_CLIPS_REQUESTING,
  SET_MORE_FAVOURITE_CLIPS_REQUESTING,
  SET_CLIP_FILTERS,
  REMOVE_MY_CLIPS_GROUP,
  SET_REPORTED_CLIP,
  SET_CLOSED_COMMUNITY_CLIPS_REQUESTING,
  SET_MORE_CLOSED_COMMUNITY_CLIPS_REQUESTING,
  SET_CLOSED_COMMUNITY_CLIPS_SUCCESS,
  SET_CLOSED_COMMUNITY_CLIPS_ERROR,
  SET_CLOSED_COMMUNITY_NAME,
  SET_MORE_CLOSED_COMMUNITY_CLIPS_SUCCESS,
} from '../constants';
import {
  MyClipsResponse,
  ErrorResponse,
  Gender,
  SetClipsType,
  MyClipGroup,
  MyClip,
  ClipsResponse,
  CommunityClipGroup,
  CommunityClipsResponse,
  CommunityClip,
  FavouriteClipsResponse,
  FavouriteClip,
  ClipFilters,
  ClosedCommunityClipGroup,
  ClosedCommunityClipsResponse,
  ClosedCommunityClip,
} from '../types';
import { _getClips, getClosedCommunityName } from '../api';
import { isErrorResponse } from '../utils';
import { store } from '../index';

export const setReportedClip = () => ({ type: SET_REPORTED_CLIP });

export const setMyClipsRequesting = () => ({ type: SET_MY_CLIPS_REQUESTING });

export const setMoreMyClipsRequesting = () => ({ type: SET_MORE_MY_CLIPS_REQUESTING });

export const setCommunityClipsRequesting = () => ({ type: SET_COMMUNITY_CLIPS_REQUESTING });

export const setMoreCommunityClipsRequesting = (communityClipGroup: CommunityClipGroup) => ({
  type: SET_MORE_COMMUNITY_CLIPS_REQUESTING,
  payload: { communityClipGroup },
});

export const setFavouriteClipsRequesting = () => ({ type: SET_FAVOURITE_CLIPS_REQUESTING });

export const setMoreFavouriteClipsRequesting = (
  type: SetClipsType.myFavourites | SetClipsType.otherFavourites
) => ({
  type: SET_MORE_FAVOURITE_CLIPS_REQUESTING,
  payload: { type },
});

export const setMyClipsError = (errorMsg: string) => ({
  type: SET_MY_CLIPS_ERROR,
  payload: { errorMsg },
});

export const setCommunityClipsError = (errorMsg: string) => ({
  type: SET_COMMUNITY_CLIPS_ERROR,
  payload: { errorMsg },
});

export const setFavouriteClipsError = (errorMsg: string) => ({
  type: SET_FAVOURITE_CLIPS_ERROR,
  payload: { errorMsg },
});

export const setMyClipsSuccess = (myClipGroups: MyClipGroup[], myClipGroupCount: number) => ({
  type: SET_MY_CLIPS_SUCCESS,
  payload: { myClipGroups, myClipGroupCount },
});

export const createRemoveClipsGroupAction = (clipsGroupId: number) => ({
  type: REMOVE_MY_CLIPS_GROUP,
  payload: { clipsGroupId },
});

export const setCommunityClipsSuccess = (
  communityClips: CommunityClip[],
  communityClipGroup: CommunityClipGroup
) => ({
  type: SET_COMMUNITY_CLIPS_SUCCESS,
  payload: { communityClips, communityClipGroup },
});

export const setMoreCommunityClipsSuccess = (
  moreCommunityClips: CommunityClip[],
  communityClipGroup: CommunityClipGroup
) => ({
  type: SET_MORE_COMMUNITY_CLIPS_SUCCESS,
  payload: { moreCommunityClips, communityClipGroup },
});

export const setFavouriteClipsSuccess = (
  favouriteClips: FavouriteClip[],
  favouriteClipsCount: number,
  type: SetClipsType.myFavourites | SetClipsType.otherFavourites
) => {
  if (type === SetClipsType.myFavourites) {
    return {
      type: SET_MY_FAVOURITE_CLIPS_SUCCESS,
      payload: { myFavouriteClips: favouriteClips, myFavouriteClipsCount: favouriteClipsCount },
    };
  } else {
    return {
      type: SET_OTHER_FAVOURITE_CLIPS_SUCCESS,
      payload: {
        otherFavouriteClips: favouriteClips,
        otherFavouriteClipsCount: favouriteClipsCount,
      },
    };
  }
};

export const setMoreClosedCommunityClipsSuccess = (
  moreClosedCommunityClips: ClosedCommunityClip[],
  closedCommunityClipGroup: ClosedCommunityClipGroup
) => ({
  type: SET_MORE_CLOSED_COMMUNITY_CLIPS_SUCCESS,
  payload: { moreClosedCommunityClips, closedCommunityClipGroup },
});

export const setMoreFavouriteClipsSuccess = (
  moreFavouriteClips: FavouriteClip[],
  favouriteClipsCount: number,
  type: SetClipsType.myFavourites | SetClipsType.otherFavourites
) => {
  if (type === SetClipsType.myFavourites) {
    return {
      type: SET_MORE_MY_FAVOURITE_CLIPS_SUCCESS,
      payload: {
        moreMyFavouriteClips: moreFavouriteClips,
        myFavouriteClipsCount: favouriteClipsCount,
      },
    };
  } else {
    return {
      type: SET_MORE_OTHER_FAVOURITE_CLIPS_SUCCESS,
      payload: {
        moreOtherFavouriteClips: moreFavouriteClips,
        otherFavouriteClipsCount: favouriteClipsCount,
      },
    };
  }
};

export const setMyClips = (
  start: number,
  clipFilters: ClipFilters,
  limit: number | undefined = 10
) => (dispatch: Dispatch) => {
  if (start === 0) {
    dispatch(setMyClipsRequesting());
  } else {
    dispatch(setMoreMyClipsRequesting());
  }

  _getClips({ start, limit }, SetClipsType.default, clipFilters).then(
    (response: ClipsResponse | ErrorResponse) => {
      if (isErrorResponse(response)) {
        setMyClipsError('Failed');
      } else {
        const clipsResponse = response as MyClipsResponse;

        if (start === 0) {
          dispatch(setMyClipsSuccess(transformMyClipGroups(clipsResponse), clipsResponse.count));
        } else {
          dispatch(
            setMyClipsSuccess(
              [...store.getState().clips.myClipGroups, ...transformMyClipGroups(clipsResponse)],
              clipsResponse.count
            )
          );
        }
      }
    }
  );
};

export const setCommunityClips = (
  start: number,
  limit: number,
  clipGroup: CommunityClipGroup,
  clipFilters: ClipFilters,
  ownerId?: number
) => (dispatch: Dispatch) => {
  if (start === 0) dispatch(setCommunityClipsRequesting());

  _getClips({ start, limit }, SetClipsType.community, clipFilters, ownerId, clipGroup).then(
    (response: ClipsResponse | ErrorResponse) => {
      if (isErrorResponse(response)) {
        setCommunityClipsError('Failed');
      } else {
        const { clips, count } = response as CommunityClipsResponse;

        dispatch(setCommunityClipsSuccess(transformCommunityClips(clips), { ...clipGroup, count }));
      }
    }
  );
};

export const setMoreCommunityClips = (
  start: number,
  clipGroup: CommunityClipGroup,
  clipFilters: ClipFilters,
  ownerId?: number
) => (dispatch: Dispatch) => {
  dispatch(setMoreCommunityClipsRequesting(clipGroup));

  _getClips({ start, limit: 10 }, SetClipsType.community, clipFilters, ownerId, clipGroup).then(
    (response: ClipsResponse | ErrorResponse) => {
      if (isErrorResponse(response)) {
        setCommunityClipsError('Failed');
      } else {
        const { clips, count } = response as CommunityClipsResponse;

        dispatch(
          setMoreCommunityClipsSuccess(transformCommunityClips(clips), { ...clipGroup, count })
        );
      }
    }
  );
};

export const setFavouriteClips = (
  start: number,
  limit: number,
  clipFilters: ClipFilters,
  ownerId?: number
) => (dispatch: Dispatch) => {
  if (start === 0) dispatch(setFavouriteClipsRequesting());

  _getClips({ start, limit }, SetClipsType.myFavourites, clipFilters, ownerId).then(
    (response: ClipsResponse | ErrorResponse) => {
      if (isErrorResponse(response)) {
        setFavouriteClipsError('Failed');
      } else {
        const { clips, count } = response as FavouriteClipsResponse;

        dispatch(
          setFavouriteClipsSuccess(transformFavouriteClips(clips), count, SetClipsType.myFavourites)
        );
      }
    }
  );

  _getClips({ start, limit }, SetClipsType.otherFavourites, clipFilters, ownerId).then(
    (response: ClipsResponse | ErrorResponse) => {
      if (isErrorResponse(response)) {
        setFavouriteClipsError('Failed');
      } else {
        const { clips, count } = response as FavouriteClipsResponse;

        dispatch(
          setFavouriteClipsSuccess(
            transformFavouriteClips(clips),
            count,
            SetClipsType.otherFavourites
          )
        );
      }
    }
  );
};

export const setMoreFavouriteClips = (
  start: number,
  type: SetClipsType.myFavourites | SetClipsType.otherFavourites,
  clipFilters: ClipFilters,
  ownerId?: number
) => (dispatch: Dispatch) => {
  dispatch(setMoreFavouriteClipsRequesting(type));

  _getClips({ start, limit: 10 }, type, clipFilters, ownerId).then(
    (response: ClipsResponse | ErrorResponse) => {
      if (isErrorResponse(response)) {
        setFavouriteClipsError('Failed');
      } else {
        const { clips, count } = response as FavouriteClipsResponse;

        dispatch(setMoreFavouriteClipsSuccess(transformFavouriteClips(clips), count, type));
      }
    }
  );
};

export const setClipFilters = (filters: ClipFilters) => ({
  type: SET_CLIP_FILTERS,
  payload: { filters },
});

const transformMyClipGroups = (clipsResponse: MyClipsResponse): MyClipGroup[] => {
  const myClipGroups = clipsResponse.clipGroups as any[];
  return myClipGroups.map(
    (clipGroup: any): MyClipGroup => ({
      id: clipGroup.matchId,
      date: new Date(clipGroup.date),
      competition: clipGroup.competition,
      gender: clipGroup.gender === 'male' ? Gender.men : Gender.women,
      category: clipGroup.category,
      teams: { firstTeam: clipGroup.homeTeam, secondTeam: clipGroup.guestTeam },
      clips: clipGroup.clips.map(
        (clip: any): MyClip => ({
          id: clip.id,
          name: clip.name,
          note: clip.note,
          publicNote: clip.publicNote,
          categories: clip.categories,
          imageUrl: clip.photoUrl || `${process.env.PUBLIC_URL}/preview.png`,
        })
      ),
    })
  );
};

const transformCommunityClips = (communityClips: any[]): CommunityClip[] => {
  return communityClips.map(
    (clip: any): CommunityClip => ({
      id: clip.id,
      name: clip.name,
      note: clip.note,
      publicNote: clip.publicNote,
      imageUrl: clip.photoUrl || `${process.env.PUBLIC_URL}/preview.png`,
      categories: clip.clipCategories,
      matchDate: new Date(clip.matchDate),
      location: clip.location,
      teams: { firstTeam: clip.homeTeam, secondTeam: clip.guestTeam },
      competition: clip.competition,
      gender: clip.gender === 'male' ? Gender.men : Gender.women,
      category: clip.category,
    })
  );
};

const transformClosedCommunityClips = (closedCommunityClips: any[]): ClosedCommunityClip[] => {
  return closedCommunityClips.map(
    (clip: any): ClosedCommunityClip => ({
      id: clip.id,
      name: clip.name,
      note: clip.note,
      publicNote: clip.publicNote,
      imageUrl: clip.photoUrl || `${process.env.PUBLIC_URL}/preview.png`,
      categories: clip.clipCategories,
      matchDate: new Date(clip.matchDate),
      location: clip.location,
      teams: { firstTeam: clip.homeTeam, secondTeam: clip.guestTeam },
      competition: clip.competition,
      gender: clip.gender === 'male' ? Gender.men : Gender.women,
      category: clip.category,
    })
  );
};

const transformFavouriteClips = (favouriteClips: any[]): FavouriteClip[] => {
  return favouriteClips.map(
    (clip: any): FavouriteClip => ({
      id: clip.id,
      name: clip.name,
      note: clip.note,
      publicNote: clip.publicNote,
      imageUrl: clip.photoUrl || `${process.env.PUBLIC_URL}/preview.png`,
      categories: clip.clipCategories,
      matchDate: new Date(clip.matchDate),
      location: clip.location,
      teams: { firstTeam: clip.homeTeam, secondTeam: clip.guestTeam },
      gender: clip.gender === 'male' ? Gender.men : Gender.women,
    })
  );
};

export const setClosedCommunityNameToStore = (name: string) => ({
  type: SET_CLOSED_COMMUNITY_NAME,
  payload: { name },
});

export const setClosedCommunityClipsRequesting = () => ({
  type: SET_CLOSED_COMMUNITY_CLIPS_REQUESTING,
});

export const setMoreClosedCommunityClipsRequesting = (
  closedCommunityClipGroup: ClosedCommunityClipGroup
) => ({
  type: SET_MORE_CLOSED_COMMUNITY_CLIPS_REQUESTING,
  payload: { closedCommunityClipGroup },
});

export const setClosedCommunityClipsSuccess = (
  closedCommunityClips: ClosedCommunityClip[],
  closedCommunityClipGroup: ClosedCommunityClipGroup
) => ({
  type: SET_CLOSED_COMMUNITY_CLIPS_SUCCESS,
  payload: { closedCommunityClips, closedCommunityClipGroup },
});

export const setClosedCommunityClipsError = (errorMsg: string) => ({
  type: SET_CLOSED_COMMUNITY_CLIPS_ERROR,
  payload: { errorMsg },
});

export const setupClosedCommunityName = (name: string) => ({
  type: SET_CLOSED_COMMUNITY_NAME,
  payload: { name },
});

export const setClosedCommunityClips = (
  start: number,
  limit: number,
  clipGroup: CommunityClipGroup,
  clipFilters: ClipFilters,
  ownerId?: number
) => (dispatch: Dispatch) => {
  if (start === 0) {
    dispatch(setClosedCommunityClipsRequesting());
  } else {
    dispatch(setMoreClosedCommunityClipsRequesting(clipGroup));
  }

  _getClips({ start, limit }, SetClipsType.closedCommunity, clipFilters, ownerId, clipGroup).then(
    (response: ClipsResponse | ErrorResponse) => {
      if (isErrorResponse(response)) {
        setClosedCommunityClipsError((response as ErrorResponse).error);
      } else {
        const { clips, count } = response as ClosedCommunityClipsResponse;
        const currentClips = transformClosedCommunityClips(clips);
        if (start === 0) {
          dispatch(setClosedCommunityClipsSuccess(currentClips, { ...clipGroup, count }));
        } else {
          dispatch(setMoreClosedCommunityClipsSuccess(currentClips, { ...clipGroup, count }));
        }
      }
    }
  );
};

export const setClosedCommunityName = (userId: number) => async (dispatch: Dispatch) => {
  const name = await getClosedCommunityName(userId);

  if (name) {
    dispatch(setClosedCommunityNameToStore(name));
  }
};
