import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getApplicationModeState, getIsPremium } from '../../selectors';
import { classHandler } from '../../utils';
import LockIcon from '@material-ui/icons/Lock';

import './StatisticsCard.scss';

import { setPremiumModalVisibility } from '../../actions';

type StatisticsProps = {
  data: any;
  label: string;
  firstColumn: string;
  secondColumn: string;
  bottomText: string;
  postFix: string;
  explanation: string;
  lock?: boolean;
};

export const StatisticsCard: FunctionComponent<StatisticsProps> = ({
  data,
  label,
  firstColumn,
  secondColumn,
  bottomText,
  postFix,
  explanation,
  lock,
}) => {
  const applicationMode = useSelector(getApplicationModeState);
  const userIsPremium = useSelector(getIsPremium);

  const barSize = 100;
  let sum = 0;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const rows: any = [];

  const openPremiumModal = () => dispatch(setPremiumModalVisibility(true));

  if (data && data.forEach) {
    const max = Math.max(...data.map((o: any) => o[secondColumn]), 0);
    data.forEach((d: any, index: number) => {
      const width = d[secondColumn] ? (barSize * d[secondColumn]) / max : 0;
      sum += d[secondColumn];
      rows.push(
        <tr key={index}>
          <td>
            {firstColumn === 'month' && d[firstColumn] && d[firstColumn].length === 8
              ? '0' + d[firstColumn]
              : d[firstColumn] !== undefined
              ? t(d[firstColumn])
              : 'N/A'}
          </td>
          <td>
            <div
              className={classHandler('StatisticsBar', applicationMode)}
              style={{ width: width + '%' }}
            />
          </td>
          {lock && !userIsPremium ? (
            <td>
              <span className="card-premium" onClick={openPremiumModal}>
                <LockIcon className="card__premium__icon" />
                Premium
              </span>
            </td>
          ) : (
            <td>{(Math.round(d[secondColumn] * 100) / 100).toLocaleString(t('en')) + postFix}</td>
          )}
        </tr>
      );
    });
  }

  const visibleExplanation =
    explanation && t(explanation).length > 30
      ? t(explanation).substr(0, 28) + '...'
      : t(explanation);
  const visibleLabel = label && t(label).length > 36 ? t(label).substr(0, 33) + '...' : t(label);

  return (
    <div className={classHandler('StatisticsCard', applicationMode)}>
      <h3>{visibleLabel}</h3>
      <span className="card-explanation">
        {visibleExplanation ? `(${visibleExplanation})` : ''}
      </span>
      <table>
        <tbody>
          {rows}
          {bottomText !== '' && (
            <tr className="card-sum">
              <td>{t(bottomText)}</td>
              <td colSpan={2}>{(Math.round(sum * 100) / 100).toLocaleString(t('en')) + postFix}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
