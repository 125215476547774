import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { getApplicationModeState } from '../../selectors';
import { classHandler } from '../../utils';
import './SaveButtonsWrapper.scss';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Button, ButtonProps } from '../Button';

interface SaveButtonsWrapperProps {
  primaryButton: ButtonProps;
  secondaryButton?: ButtonProps;
  style?: CSSProperties;
  className?: string;
}

export const SaveButtonsWrapper: FunctionComponent<SaveButtonsWrapperProps> = ({
  primaryButton,
  secondaryButton,
  style,
  className,
}) => {
  const applicationMode = useSelector(getApplicationModeState);

  return (
    <div
      className={`${classHandler('SaveButtonsWrapper', applicationMode)} ${className}`}
      style={{ ...style }}
    >
      {secondaryButton && <Button type="secondary" {...secondaryButton} />}
      <Button type="primary" {...primaryButton} />
    </div>
  );
};
