import { RefereeTeamType, RootState, Status } from '../types';

export const getRefereeTeamTypesStatus = (state: RootState): Status =>
  state.refereeTeamTypes.status;

export const getRefereeTeamTypesErrorMsg = (state: RootState): string | null =>
  state.refereeTeamTypes.errorMsg;

export const getRefereeTeamTypes = (state: RootState): RefereeTeamType[] =>
  state.refereeTeamTypes.refereeTeamTypes;

export const getRoles = (currentRefereeTeamTypeId: number) => (state: RootState): string[] => {
  return (
    state.refereeTeamTypes.refereeTeamTypes.find(
      (refereeTeamType: RefereeTeamType) => refereeTeamType.id === currentRefereeTeamTypeId
    )?.roles || []
  );
};

export const getOtherRoles = (currentRefereeTeamTypeId: number, myRole: string) => (
  state: RootState
): string[] => {
  return (
    state.refereeTeamTypes.refereeTeamTypes
      .find((refereeTeamType: RefereeTeamType) => refereeTeamType.id === currentRefereeTeamTypeId)
      ?.roles.filter((role: string) => role !== myRole) || []
  );
};
