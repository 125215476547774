import React, { Fragment, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { getApplicationModeState } from '../../selectors';
import { ApplicationMode, ClipType } from '../../types';
import { ContentLoader } from '../ContentLoader';
import { Icon, IconType } from '../Icon';
import './ClipCardLoader.scss';

type ClipCardLoaderProps = {
  clipType: ClipType;
};

export const ClipCardLoader: FunctionComponent<ClipCardLoaderProps> = ({ clipType }) => {
  const applicationMode = useSelector(getApplicationModeState);

  const iconType = applicationMode === ApplicationMode.light ? IconType.default : IconType.white;
  const clipCardClasses = ['ClipCard'];
  if (applicationMode === ApplicationMode.dark) clipCardClasses.push('ClipCardLoader--dark');

  const myClipCardInfo = () => (
    <Fragment>
      <div className="ClipCard__label">
        <Icon size="12px" name="pen" type={iconType} />
        <div className="ClipCard__labelText ClipCardLoader__labelText">
          {'aaaaaaaaaaaaaaaaaaaaaa'}
        </div>
      </div>
      <div className="ClipCard__label">
        <Icon size="12px" name="folder" type={iconType} />
        <div className="ClipCard__labelText ClipCardLoader__labelText">{'aaaaaaaaaaa'}</div>
      </div>
      <div className="ClipCard__label">
        <Icon size="12px" name="comment" type={iconType} />
        <div className="ClipCard__labelText ClipCardLoader__labelText">
          {'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa'}
        </div>
      </div>
    </Fragment>
  );

  const communityClipCardInfo = () => {
    return myClipCardInfo();
  };

  const favouriteClipCardInfo = () => {
    return myClipCardInfo();
  };

  const getClipCardInfo = () => {
    if (clipType === ClipType.myVideo) {
      return myClipCardInfo();
    }
    if (clipType === ClipType.community) {
      return communityClipCardInfo();
    }
    if (clipType === ClipType.favourite) {
      return favouriteClipCardInfo();
    }
  };

  return (
    <div className={clipCardClasses.join(' ')}>
      <div className="ClipCard__thumbnail ClipCardLoader__thumbnail">
        <ContentLoader />
      </div>
      <div className="ClipCard__info">{getClipCardInfo()}</div>
    </div>
  );
};
