import { AnyAction } from 'redux';

import { SportsState, Status } from '../types';
import { SET_SPORTS_ERROR, SET_SPORTS_REQUESTING, SET_SPORTS_SUCCESS } from '../constants';

const initSportsState: SportsState = { status: Status.idle, errorMsg: null, sports: [] };

export const sportsReducer = (
  state: SportsState = initSportsState,
  action: AnyAction
): SportsState => {
  switch (action.type) {
    case SET_SPORTS_REQUESTING: {
      return { status: Status.requesting, errorMsg: null, sports: [] };
    }
    case SET_SPORTS_ERROR: {
      const { errorMsg } = action.payload;

      return { status: Status.error, errorMsg, sports: [] };
    }
    case SET_SPORTS_SUCCESS: {
      const { sports } = action.payload;

      return { status: Status.success, errorMsg: null, sports };
    }
    default:
      return state;
  }
};
