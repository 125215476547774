import { Country, RootState, Status } from '../types';

export const getCountriesStatus = (state: RootState): Status => state.countries.status;

export const getCountriesErrorMsg = (state: RootState): string | null => state.countries.errorMsg;

export const getCountries = (state: RootState): Country[] => state.countries.countries;

export const getCountriesNames = (state: RootState): string[] =>
  state.countries.countries.map((country: Country) => country.name);

export const getCountryCurrency = (countryId: number) => (state: RootState): string | undefined =>
  state.countries.countries.find((country: Country) => country.id === countryId)?.currency;
