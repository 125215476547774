import { ClipCategory } from './clip';
import { Note } from './notes';

export enum Gender {
  men = 'Men',
  women = 'Women',
}

export interface Teams {
  firstTeam: string;
  secondTeam: string;
}

export enum VideoStatus {
  uploading = 'uploading',
  uploadPostProcessing = 'uploadPostProcessing',
  error = 'error',
  done = 'done',
  cancelled = 'cancelled',
  noVideo = '',
}

export interface Earnings {
  reward: number;
  workdayBonus: number;
  travelCosts: number;
  foodCosts: number;
  currency: string;
  receivedAs: string;
}

export interface Match {
  id: number;
  date: Date;
  time: string;
  location: string;
  gender: Gender;
  competition: string;
  category: string;
  teams: Teams;
  referees: string[];
  refereeCoach: string;
  videoCount: number;
  noteCount: number;
}

export interface MatchDetail {
  id: number;
  date: Date;
  time: Date;
  location: string;
  gender: Gender;
  competition: number;
  category: number;
  teams: Teams;
  homeTeam?: number;
  guestTeam?: number;
  refereeTeamType: number;
  referees: Referee[];
  myRole: string;
  sport: number;
  matchAccounting: MatchAccounting;
  videoUrl: string;
  videoLink?: string;
  fileName?: string;
  videoStatus?: VideoStatus;
  syncTime: number;
  clips: TimeStamp[];
  notes: Note[];
  chiefReferee: string;
}

export interface TimeStamp {
  id?: number;
  name: string;
  time: number;
  startTime: number;
  endTime: number;
  syncTime?: number;
  clipCategories?: number[];
}

export interface SyncTime {
  name: string;
  time: number;
}

export interface Competition {
  id: number;
  name: string;
  sport: number;
  country: number;
  gender: Gender;
  categories: Category[];
}

export interface CategoryRewards {
  type4AR: number | null;
  typeAAR: number | null;
  typeAR: number | null;
  typeD: number | null;
  typeR: number | null;
}

export interface Category {
  id: number;
  name: string;
  rewards: CategoryRewards;
}

export interface Referee {
  id: number;
  name: string;
  role: string;
}

export enum MatchAccountingReceivedAs {
  cash = 'cash',
  bankAccount = 'bankAccount',
}

export interface MatchAccounting {
  id: number;
  reward: number;
  workdayBonus: number;
  travelCost: number;
  dietary: number;
  receivedAs: MatchAccountingReceivedAs;
}

export interface RefereeTeamType {
  id: number;
  name: string;
  roles: string[];
}

export interface MatchSituation {
  id: string;
  videoName: string;
  timeSeconds: number;
  categories: ClipCategory[];
}

export enum SaveButtonState {
  default = '',
  loading = 'loading',
}
