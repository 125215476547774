import { Platform } from '../types';

export const getPlatform = (): Platform => {
  let platform = Platform.web;
  const iOS =
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform
    ) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  const Android = navigator.userAgent.toLowerCase().indexOf('android') > -1;
  const iOSApp = (window as any)?.webkit !== undefined && !navigator.userAgent.match('CriOS');
  const AndroidApp = (window as any)?.JSBridge !== undefined;

  if (iOS) {
    platform = Platform.iOSWeb;
  }

  if (iOS && iOSApp) {
    platform = Platform.iOSApp;
  }

  if (Android) {
    platform = Platform.androidWeb;
  }

  if (Android && AndroidApp) {
    platform = Platform.androidApp;
  }

  return platform;
};

export const getAppPlatform = (): Platform | undefined => {
  const platform = getPlatform();
  if (platform === Platform.androidApp || platform === Platform.iOSApp) {
    return platform;
  }
  return undefined;
};

export const isIOSApp = (): boolean => {
  return getPlatform() === Platform.iOSApp;
};

export const isIOSWeb = (): boolean => {
  return getPlatform() === Platform.iOSWeb;
};

export const isWeb = (): boolean => {
  return getPlatform() === Platform.web;
};

export const isAndroidApp = (): boolean => {
  return getPlatform() === Platform.androidApp;
};

export const isAndroidWeb = (): boolean => {
  return getPlatform() === Platform.androidWeb;
};

export const isMobileEdge = (): boolean => /(Edge|EdgA)\//i.test(navigator.userAgent);
