import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { classHandler, iconTypeHandler } from '../../utils';
import { Icon, IconType } from '../Icon';
import { getApplicationModeState, getApplicationBreakpointState } from '../../selectors';
import { ApplicationBreakpoint } from '../../types';

import './UserAuthLayout.scss';

export enum UserAuthLayoutType {
  register = 'register',
  login = 'login',
}

type UserAuthLayoutProps = {
  type: UserAuthLayoutType;
};

export const UserAuthLayout: FunctionComponent<UserAuthLayoutProps> = ({ children, type }) => {
  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);

  return (
    <div className={classHandler('UserAuthLayout', applicationMode)}>
      <Icon
        name="logoFullText"
        type={iconTypeHandler(applicationMode, IconType.white)}
        size="200px"
      />
      <div
        className="UserAuthLayout__container"
        style={
          type === UserAuthLayoutType.register
            ? applicationBreakpoint !== ApplicationBreakpoint.mobile
              ? { width: '760px' }
              : {}
            : {}
        }
      >
        {children}
      </div>
    </div>
  );
};
