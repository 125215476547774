import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getApplicationModeState } from '../../selectors';
import { MatchDetail } from '../../types';
import { useModalKeyboardShortcuts } from '../../utils';
import { Button } from '../Button';
import { MatchButton } from '../MatchButton';
import { Modal } from '../Modal';
import { TimeInput } from '../TimeInput';
import { VideoPlayer } from '../VideoPlayer';
import './MatchDetailVideoSyncTimeModal.scss';

type MatchDetailVideoSyncTimeModalProps = {
  newMatch: MatchDetail;
  syncEntry: number;
  setSyncEntry: any;
  setPreviousSyncEntry: any;
  setProxy: (videoUrl: string) => string;
  handleNoVideoSource?: () => void;
};

export const MatchDetailVideoSyncTimeModal: FunctionComponent<MatchDetailVideoSyncTimeModalProps> = ({
  newMatch,
  syncEntry,
  setSyncEntry,
  setPreviousSyncEntry,
  setProxy,
  handleNoVideoSource: handleNoSourceVideoModalVisibility,
}) => {
  const { t } = useTranslation();

  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [videoProgressTime, setVideoProgressTime] = useState<number>(0);
  const applicationMode = useSelector(getApplicationModeState);

  const handleSyncTime = (event: any) => {
    const entryName = event.target.name;
    const entryValue = event.target.value;
    const newValue = parseInt(entryValue, 10) || 0;

    if (entryName === 'time') {
      setPreviousSyncEntry(syncEntry);
      setSyncEntry(newValue);
    }
  };

  const handleModalVisibilityChange = () => {
    setModalVisible(!isModalVisible);
  };

  const setSyncTimeFromVideo = () => {
    setPreviousSyncEntry(syncEntry);
    setSyncEntry(videoProgressTime);
    handleModalVisibilityChange();
  };

  const getContext = () => {
    const synchronizationSet = syncEntry !== 0;
    const noVideoSet = !newMatch.videoUrl;
    if (noVideoSet) {
      return {
        className:
          'MatchDetailVideoContent__fileInputButton MatchDetailVideoContent__fileInputButton--gray',
        label: t('setSyncTime'),
        onClick: handleNoSourceVideoModalVisibility || undefined,
        state: 'unactive',
      };
    }

    if (synchronizationSet) {
      return {
        className:
          'MatchDetailVideoContent__fileInputButton MatchDetailVideoContent__fileInputButton--green',
        label: t('syncTimeSet'),
        onClick: handleModalVisibilityChange,
        state: 'done',
      };
    }

    return {
      className: 'MatchDetailVideoContent__fileInputButton',
      label: t('setSyncTime'),
      onClick: handleModalVisibilityChange,
      state: null,
    };
  };

  const context = getContext();

  useModalKeyboardShortcuts(
    isModalVisible,
    handleModalVisibilityChange,
    (() => {
      if (newMatch.videoUrl) {
        return setSyncTimeFromVideo;
      }
      return () => {
        return;
      };
    })()
  );

  return (
    <div className="MatchDetailVideoContent__fileInputWrapper MatchDetailVideoContent__videoSetupPageButtonRow">
      <div>
        <div className="MatchDetailVideoContent__fileInputLabel">{t('syncTime')}</div>
        <div className="MatchDetailVideoContent__fileInputDescription">
          {t('syncTimeDescription')}
        </div>
      </div>

      {/* <div onClick={context.onClick} className={context.className}>
        <Fragment>
          <Icon
            size="20px"
            name="synchronization"
            type={IconType.white}
            style={{ marginRight: '0.3em' }}
          />{' '}
          {context.label}
        </Fragment>
      </div> */}

      <MatchButton
        onClick={context.onClick}
        type={context.state === 'done' ? 'done' : 'primary'}
        iconStyle={{ marginRight: '0.3em' }}
        label={context.label}
        disabled={context.state === 'unactive'}
        theme={applicationMode}
        premiumFeature
      />

      <Modal
        isVisible={isModalVisible}
        width="fit-content"
        height="fit-content"
        handleClose={handleModalVisibilityChange}
      >
        <div className="MatchDetailVideoContent__playVideoModalHeader">{t('syncTime')}</div>
        <div className="MatchDetailVideoContent__playVideoSyncTimeWrapperModal">
          {newMatch.videoUrl && (
            <VideoPlayer
              videoSrc={setProxy(newMatch.videoUrl)}
              setProgressTime={setVideoProgressTime}
              startPlayingAt={syncEntry}
            />
          )}
        </div>
        <div className="MatchDetailVideoContent__centered">
          <div className="MatchDetailVideoContent__playVideoContainer">
            <div
              className="MatchDetailVideoContent__fileInputWrapper"
              style={{ flexWrap: 'wrap', gap: '0.5em' }}
            >
              <div>{t('syncTimeDescription')}</div>
              {!newMatch.videoUrl && (
                <TimeInput time={syncEntry} handleChange={handleSyncTime} withLabels />
              )}
            </div>
          </div>
          <div className="MatchDetailVideoContent__saveCancelButonsWrapper">
            {/* <div
              onClick={handleModalVisibilityChange}
              className="MatchDetailVideoContent__fileInputButton MatchDetailVideoContent__playVideoButton"
            >
              {t('back')}
            </div> */}

            <Button label={t('back')} onClick={handleModalVisibilityChange} type="secondary" />

            {newMatch.videoUrl && (
              // <div
              //   onClick={setSyncTimeFromVideo}
              //   className="MatchDetailVideoContent__fileInputButton MatchDetailVideoContent__playVideoButtonMarginLeft"
              // >
              //   {`${t('setSyncTimeNow')} ${timePresentation(videoProgressTime, true)}`}
              // </div>
              <Button
                label={`${t('setSyncTimeNow')}`}
                type="primary"
                onClick={setSyncTimeFromVideo}
                adaptable={true}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
