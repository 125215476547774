import { AnyAction } from 'redux';

import { CountriesState, Status } from '../types';
import { SET_COUNTRIES_ERROR, SET_COUNTRIES_REQUESTING, SET_COUNTRIES_SUCCESS } from '../constants';

const initCountriesState: CountriesState = { status: Status.idle, errorMsg: null, countries: [] };

export const countriesReducer = (
  state: CountriesState = initCountriesState,
  action: AnyAction
): CountriesState => {
  switch (action.type) {
    case SET_COUNTRIES_REQUESTING: {
      return { status: Status.requesting, errorMsg: null, countries: [] };
    }
    case SET_COUNTRIES_ERROR: {
      const { errorMsg } = action.payload;

      return { status: Status.error, errorMsg, countries: [] };
    }
    case SET_COUNTRIES_SUCCESS: {
      const { countries } = action.payload;

      return { status: Status.success, errorMsg: null, countries };
    }
    default:
      return state;
  }
};
