import { AnyAction } from 'redux';

import { LanguageState, Status } from '../types';
import { SET_LANGUAGES_ERROR, SET_LANGUAGES_REQUESTING, SET_LANGUAGES_SUCCESS } from '../constants';

const initLanguagesState: LanguageState = { status: Status.idle, errorMsg: null, languages: [] };

export const languagesReducer = (
  state: LanguageState = initLanguagesState,
  action: AnyAction
): LanguageState => {
  switch (action.type) {
    case SET_LANGUAGES_REQUESTING: {
      return { status: Status.requesting, errorMsg: null, languages: [] };
    }
    case SET_LANGUAGES_ERROR: {
      const { errorMsg } = action.payload;

      return { status: Status.error, errorMsg, languages: [] };
    }
    case SET_LANGUAGES_SUCCESS: {
      const { languages } = action.payload;

      return { status: Status.error, errorMsg: null, languages };
    }
    default:
      return state;
  }
};
