import { AnyAction } from 'redux';

import { MatchesState, Status } from '../types';
import {
  SET_MATCHES_REQUESTING,
  SET_MATCHES_ERROR,
  SET_MATCHES_SUCCESS,
  SET_MATCHES_REQUESTING_MORE,
} from '../constants';

const initMatchesState = { status: Status.idle, errorMsg: null, totalCount: 0, matches: [] };

/**
 * Matches reducer
 *
 * @params state State of matches
 */
export const matchesReducer = (
  state: MatchesState = initMatchesState,
  action: AnyAction
): MatchesState => {
  switch (action.type) {
    case SET_MATCHES_REQUESTING: {
      return { status: Status.requesting, errorMsg: null, totalCount: 0, matches: [] };
    }
    case SET_MATCHES_REQUESTING_MORE: {
      return { ...state, status: Status.requestingMore, errorMsg: null };
    }
    case SET_MATCHES_ERROR: {
      const { errorMsg } = action.payload;

      return { status: Status.error, errorMsg, totalCount: 0, matches: [] };
    }
    case SET_MATCHES_SUCCESS: {
      const { matches, totalCount } = action.payload;

      return { status: Status.success, errorMsg: null, totalCount, matches };
    }
    default:
      return state;
  }
};
