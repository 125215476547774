import React from 'react';
import { Icon, IconType } from '../Icon';
import { iconTypeHandler } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationModeState, getUser } from '../../selectors';
import useDownloadAllClips from '../../utils/useDownloadAllClips';
import { setPremiumModalVisibility } from '../../actions';
// import FileSaver from 'file-saver';

interface DownloadAllClipsProps {
  matchId: number;
}
const DownloadAllClips: React.FC<DownloadAllClipsProps> = ({ matchId }) => {
  const { t } = useTranslation();
  const applicationMode = useSelector(getApplicationModeState);
  const { handleDownloadArchive } = useDownloadAllClips(matchId);
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  return (
    <div
      className={`ClipController__tooltipButton${
        user?.isPremium ? '' : ' ClipController__tooltipButton--opaque'
      }`}
      onClick={() => {
        if (!user?.isPremium) {
          dispatch(setPremiumModalVisibility(true));
          return;
        }

        // FileSaver.saveAs(new Blob(['Hello world'], { type: 'application/zip' }), 'abc.txt');
        // console.log(handleDownloadArchive);
        handleDownloadArchive();
      }}
    >
      <Icon size="20px" name="download" type={iconTypeHandler(applicationMode, IconType.white)} />
      <div className="ClipController__tooltipButtonLabel">{t('downloadAllVideosOfMatch')}</div>
    </div>
  );
};

export default DownloadAllClips;
