import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Gender, ClipDetailWithMatch, ApplicationBreakpoint } from '../../types';
import {
  getApplicationBreakpointState,
  getApplicationModeState,
  getClipCategories,
} from '../../selectors';
import { classHandler } from '../../utils';
import { InputWrapper } from '../InputWrapper';
import { ClipCategoriesContent } from '../ClipCategoriesContent';
import ThumbsUpDown from '../ThumbsUpDown/ThumbsUpDown';

import './ClipInformation.scss';
type ClipInformationProps = {
  clipDetail: ClipDetailWithMatch | null;
  handleFavourite?: () => void;
  handleShare?: () => void;
  clipShared?: boolean;
};

export const ClipInformation: FunctionComponent<ClipInformationProps> = ({ clipDetail }) => {
  const { t } = useTranslation();

  const applicationMode = useSelector(getApplicationModeState);
  const defaultClipCategories = useSelector(getClipCategories);
  const applicationBreakPoint = useSelector(getApplicationBreakpointState);

  if (!clipDetail) return null;

  const renderThumbs = (variant: 'first' | 'last') => (
    <div
      className={
        classHandler('ClipInformation__subInformation', applicationMode) +
        ` ClipInformation__ratings ClipInformation__ratings--${variant}`
      }
    >
      <ThumbsUpDown />
    </div>
  );

  return (
    <div>
      {applicationBreakPoint !== ApplicationBreakpoint.desktop && renderThumbs('first')}
      <div className={classHandler('ClipInformation', applicationMode)}>
        <div className="ClipInformation__head">
          <h4>{t('clipDetail')}</h4>
        </div>
        {clipDetail.name && (
          <InputWrapper spacing="15px 0" iconName="pen">
            <h3>{clipDetail.name}</h3>
          </InputWrapper>
        )}
        {clipDetail.match.date && (
          <InputWrapper spacing="0 0" iconName="calendar">
            <span>{moment(clipDetail.match.date).format('DD. MM. yyyy')}</span>
          </InputWrapper>
        )}
        {clipDetail.match.location && (
          <InputWrapper spacing="30px 0" iconName="location">
            <span>{clipDetail.match.location}</span>
          </InputWrapper>
        )}
        {clipDetail.match.competition && (
          <InputWrapper spacing="30px 0" iconName="cup">
            <span>{clipDetail.match.competition}</span>
          </InputWrapper>
        )}
        <InputWrapper spacing="30px 0" iconName="gender">
          <span>{t(clipDetail.match.gender === Gender.men ? Gender.men : Gender.women)}</span>
        </InputWrapper>
        {clipDetail.match.category && (
          <InputWrapper spacing="30px 0" iconName="category">
            <span>{clipDetail.match.category}</span>
          </InputWrapper>
        )}
        {clipDetail.match.teams.firstTeam && clipDetail.match.teams.secondTeam && (
          <InputWrapper spacing="30px 0" iconName="team">
            <span>{`${clipDetail.match.teams.firstTeam} vs. ${clipDetail.match.teams.secondTeam}`}</span>
          </InputWrapper>
        )}
      </div>
      <div className={classHandler('ClipInformation__subInformation', applicationMode)}>
        <div className="ClipInformation__head">
          <h4>{t('clipCategories')}</h4>
        </div>
        <ClipCategoriesContent
          newClipDetail={clipDetail}
          defaultClipCategories={defaultClipCategories}
          deleteHidden
          plusHidden
        />
      </div>

      <div className={classHandler('ClipInformation__subInformation', applicationMode)}>
        <div className="ClipInformation__head">
          <h4>{t('clipNotePublic')}</h4>
        </div>
        <InputWrapper iconName="comment">
          {clipDetail.publicNote ? (
            <div className="ClipInformation__publicNote" style={{ width: '100%' }}>
              {clipDetail.publicNote}
            </div>
          ) : (
            <div>-</div>
          )}
        </InputWrapper>
      </div>

      {applicationBreakPoint === ApplicationBreakpoint.desktop && renderThumbs('last')}
    </div>
  );
};
