import { GetAppVersionResult, ShareContentType } from '../types';

const android = (window as any).JSBridge;
const iOS = (window as any).webkit;
(window as any).callbacks = (window as any).callbacks ? (window as any).callbacks : {};

(window as any).callbacks = {
  onTimestamps: (window as any).callbacks.onTimestamps || null,
  onReceipt: (window as any).callbacks.onReceipt || null,
  onDownloadVideo: (window as any).callbacks.onDownloadVideo || null,
  onDownloadZip: (window as any).callbacks.onDownloadZip || null,
  onFullscreenVideo: (window as any).callbacks.onFullscreenVideo || null,
  onFullscreenVideoExit: (window as any).callbacks.onFullscreenVideoExit || null,
  onShare: (window as any).callbacks.onShare || null,
  onOpenBrowser: (window as any).callbacks.onOpenBrowser || null,
  onProducts: (window as any).callbacks.onProducts || null,
  onOpenMail: (window as any).callbacks.onOpenMail || null,
  onGetAppVersion: (window as any).callbacks.onGetAppVersion || null,
};

const callbacks = (window as any).callbacks;

const bridgeMessagesHandler = (message: any) => {
  if (message.messageType === 'onTimestamps') {
    if (callbacks.onTimestamps) callbacks.onTimestamps(message);
  } else if (message.messageType === 'onReceipt') {
    if (callbacks.onReceipt) callbacks.onReceipt(message);
  } else if (message.messageType === 'onDownloadVideo') {
    if (callbacks.onDownloadVideo) callbacks.onDownloadVideo(message);
  } else if (message.messageType === 'onFullScreenVideo') {
    if (callbacks.onFullScreenVideo) callbacks.onFullScreenVideo(message);
  } else if (message.messageType === 'onShare') {
    if (callbacks.onShare) callbacks.onShare(message);
  } else if (message.messageType === 'onOpenBrowser') {
    if (callbacks.onOpenBrowser) callbacks.onOpenBrowser(message);
  } else if (message.messageType === 'onProducts') {
    if (callbacks.onProducts) callbacks.onProducts(message);
  } else if (message.messageType === 'onOpenMail') {
    if (callbacks.onOpenMail) callbacks.onOpenMail(message);
  } else if (message.messageType === 'onDownloadZip') {
    if (callbacks.onDownloadZip) callbacks.onDownloadZip(message);
  } else if (message.messageType === 'onFullScreenVideoExit') {
    if (callbacks.onFullScreenVideoExit) callbacks.onFullScreenVideoExit(message);
  } else if (message.messageType === 'onGetAppVersion') {
    if (callbacks.onGetAppVersion) callbacks.onGetAppVersion(message);
  }
};

export const useNFCHandler = (callback: (data: any) => void): void => {
  if (!iOS) return;

  iOS.messageHandlers.ReactObserver.postMessage({ action: 'timestamps' });

  callbacks.onTimestamps = callback;
  // @ts-ignore
  window.onBridgeMessage = bridgeMessagesHandler;
};

export const useIAPListHandler = (callback: (data: any) => void): void => {
  if (!iOS) return;

  iOS.messageHandlers.ReactObserver.postMessage({ action: 'products' });

  callbacks.onProducts = callback;
  // @ts-ignore
  window.onBridgeMessage = bridgeMessagesHandler;
};

export const useIAPSubscribeHandler = (productId: string, callback: (data: any) => void): void => {
  if (!iOS) return;

  iOS.messageHandlers.ReactObserver.postMessage({ action: 'subscribe', product_id: productId });

  callbacks.onReceipt = callback;
  // @ts-ignore
  window.onBridgeMessage = bridgeMessagesHandler;
};

export const useIAPRestoreAllHandler = (callback: (data: any) => void): void => {
  if (!iOS) return;

  iOS.messageHandlers.ReactObserver.postMessage({ action: 'restoreAll' });

  callbacks.onReceipt = callback;
  // @ts-ignore
  window.onBridgeMessage = bridgeMessagesHandler;
};

export const useShareLinkHandler = (
  url: string,
  contentType: ShareContentType,
  callback: (data: any) => void,
  errorCallback: (error: any) => void
): void => {
  if (!iOS && !android) return;
  if (iOS) {
    iOS.messageHandlers.ReactObserver?.postMessage({ action: 'share', url });
  } else {
    try {
      android.share(url, contentType);
    } catch (e) {
      errorCallback(e);
    }
  }
  callbacks.onShare = callback;
  // @ts-ignore
  window.onBridgeMessage = bridgeMessagesHandler;
};

export const useOpenBrowserHandler = (url: string, callback: (data: any) => void): void => {
  if (!iOS) return;
  if (iOS?.messageHandlers?.ReactObserver?.postMessage) {
    iOS.messageHandlers.ReactObserver.postMessage({ action: 'openBrowser', url });
  } else {
    window.open(url);
  }

  callbacks.onOpenBrowser = callback;
  // @ts-ignore
  window.onBridgeMessage = bridgeMessagesHandler;
};

export const useFullscreenHandler = (
  url: string,
  start: number,
  end: number,
  currentTime: number,
  callback: (data: any) => void,
  onFullscreenVideoExit: (currentTime: number) => void
): void => {
  if (!iOS) return;

  iOS.messageHandlers.ReactObserver.postMessage({
    action: 'fullScreenVideo',
    url,
    start,
    end,
    currentTime,
  });
  callbacks.onFullScreenVideo = callback;
  callbacks.onFullScreenVideoExit = onFullscreenVideoExit;
  // @ts-ignore
  window.onBridgeMessage = bridgeMessagesHandler;
};

export const useDownloadVideoHandler = (
  url: string,
  filename: string,
  callback: (data: any) => void
): void => {
  if (iOS) {
    iOS.messageHandlers.ReactObserver.postMessage({ action: 'downloadVideo', url, filename });
  } else if (android) {
    android.downloadVideo(url, filename);
  }

  callbacks.onDownloadVideo = callback;
  // @ts-ignore
  window.onBridgeMessage = bridgeMessagesHandler;
};

export const useOpenMailHandler = (email: string, callback: (data: any) => void): void => {
  if (iOS) {
    iOS.messageHandlers.ReactObserver.postMessage({ action: 'openMail', email });
  }

  callbacks.onOpenMail = callback;
  // @ts-ignore
  window.onBridgeMessage = bridgeMessagesHandler;
};

export const useDownloadZipHandler = (
  url: string,
  filename: string,
  callback: (data: any) => void
): void => {
  if (iOS) {
    iOS.messageHandlers.ReactObserver.postMessage({ action: 'downloadZip', url, filename });
  } else if (android) {
    android.downloadZip(url, filename);
  }

  callbacks.onDownloadZip = callback;
  // @ts-ignore
  window.onBridgeMessage = bridgeMessagesHandler;
};

export const vibrateDevice = (duration: number): void => {
  if (iOS) {
    iOS.messageHandlers.ReactObserver.postMessage({ action: 'vibrate', duration });
  } else if (android && android.vibrateDevice) {
    android.vibrateDevice(duration);
  }
};

export const useGetAppVersion = (callback: (data: GetAppVersionResult) => void): void => {
  if (iOS) {
    iOS.messageHandlers.ReactObserver.postMessage({ action: 'getAppVersion' });
  } else if (android) {
    try {
      android.getAppVersion();
    } catch (e) {
      // TODO: handle error
    }
  }

  callbacks.onGetAppVersion = callback;
  // @ts-ignore
  window.onBridgeMessage = bridgeMessagesHandler;
};
