import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { getApplicationModeState } from '../../selectors';
import { classHandler } from '../../utils';

import './Divider.scss';

export const Divider: FunctionComponent<{}> = () => {
  const applicationMode = useSelector(getApplicationModeState);

  return <div className={classHandler('Divider', applicationMode)} />;
};
