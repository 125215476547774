import {
  SET_APPLICATION_MODE,
  SET_APPLICATION_BREAKPOINT,
  SET_FILTER_PANEL_VISIBILITY,
  SET_SIDE_PANEL_VISIBILITY,
  SET_PREMIUM_MODAL_VISIBILITY,
  SET_APPLICATION_CURRENCY,
  SET_INFO_MODAL_VISIBILITY,
  SET_INFO_MODAL_TEXT,
  SET_LOGIN_REQUIRED_MODAL_VISIBILITY,
} from '../constants';
import { ApplicationBreakpoint, ApplicationMode } from '../types';

/**
 * Action for setting application mode
 *
 * @params applicationMode Application Mode
 */
export const setApplicationMode = (applicationMode: ApplicationMode) => ({
  type: SET_APPLICATION_MODE,
  payload: { applicationMode },
});

/**
 * Action for setting application inner width
 *
 * @params applicationMode Application Mode
 */
export const setApplicationBreakpoint = (applicationBreakpoint: ApplicationBreakpoint) => ({
  type: SET_APPLICATION_BREAKPOINT,
  payload: { applicationBreakpoint },
});

/**
 * Action for setting filter panel visibility
 *
 * @params filterPanelVisibility Filter panel visibility
 */
export const setFilterPanelVisibility = (filterPanelVisibility: boolean) => ({
  type: SET_FILTER_PANEL_VISIBILITY,
  payload: { filterPanelVisibility },
});

/**
 * Action for setting side panel visibility
 *
 * @params sidePanelVisibility Side panel visibility
 */
export const setSidePanelVisibility = (sidePanelVisibility: boolean) => ({
  type: SET_SIDE_PANEL_VISIBILITY,
  payload: { sidePanelVisibility },
});

/**
 * Action for setting premium modal visibility
 *
 * @params premiumModalVisibility Premium modal visibility
 * @params premiumModalText Premium modal text
 */
export const setPremiumModalVisibility = (
  premiumModalVisibility: boolean,
  premiumModalContentLocKey: string | null = null
) => ({
  type: SET_PREMIUM_MODAL_VISIBILITY,
  payload: { premiumModalVisibility, premiumModalContentLocKey },
});

/**
 * Action for set login required modal visibility
 * @param loginRequiredModalVisibility login required modal visibility
 */
export const setLoginRequiredModalVisibility = (loginRequiredModalVisibility: boolean) => ({
  type: SET_LOGIN_REQUIRED_MODAL_VISIBILITY,
  payload: { loginRequiredModalVisibility },
});

/**
 * Action for setting currency
 *
 * @params currency Currency
 */
export const setCurrency = (currency: string) => ({
  type: SET_APPLICATION_CURRENCY,
  payload: { currency },
});

/**
 * Action for setting information modal visibility
 *
 * @params infoModalVisibility information modal visibility
 */
export const setInfoModalVisibility = (infoModalVisibility: boolean) => ({
  type: SET_INFO_MODAL_VISIBILITY,
  payload: { infoModalVisibility },
});

/**
 * Action for setting information modal text
 *
 * @params infoModalText text
 */
export const setInfoModalText = (infoModalText: string) => ({
  type: SET_INFO_MODAL_TEXT,
  payload: { infoModalText },
});
