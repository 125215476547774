import { useCallback, useEffect, useState } from 'react';
import { MatchDetail } from '../types';

export default (newMatch: MatchDetail | null) => {
  const [syncMap, _setSyncMap] = useState(new Map<number, number>());

  useEffect(() => {
    if (newMatch && newMatch.clips) {
      const next = new Map<number, number>();

      newMatch.clips.forEach(clip =>
        // @ts-ignore
        next.set(clip.id!, 0)
      );

      _setSyncMap(next);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMatch?.id]);

  const setSyncMap = useCallback(
    (id: number, syncTime: number) => {
      const clipFromId = newMatch?.clips?.filter(clip => clip.id === id)[0];
      _setSyncMap(prev => {
        const previous = prev?.get(id);
        const nextMap = new Map<number, number>([...prev]);

        prev.forEach((value, key) => {
          const currentClip = newMatch?.clips?.filter(clip => clip.id === key)[0];

          if (currentClip?.time! > clipFromId?.time!) {
            const v = value + syncTime - (previous ?? 0);
            nextMap.set(key, v);
          }
        });

        return nextMap;
      });

      _setSyncMap(prev => {
        return new Map(prev.set(id, syncTime));
      });
    },
    [newMatch]
  );
  return { syncMap, setSyncMap };
};
