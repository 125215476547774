import { Dispatch } from 'redux';

import {
  SET_CLIP_DETAIL_REQUESTING,
  SET_CLIP_DETAIL_ERROR,
  SET_CLIP_DETAIL_SUCCESS,
  SET_CLIP_REACTION,
} from '../constants';
import {
  ClipDetail,
  ClipDetailResponse,
  ErrorResponse,
  ClipDetailWithMatch,
  Gender,
  VideoStatus,
  ClipReaction,
} from '../types';
import { getSharedClip, _getClipDetail, postReactToClip } from '../api';
import { isErrorResponse } from '../utils';
import { showNotification } from './notificationActions';

/**
 * Action for clip detail setting (REQUESTING)
 *
 */
export const setClipDetailRequesting = () => ({ type: SET_CLIP_DETAIL_REQUESTING });

/**
 * Action for clip detail setting (ERROR)
 *
 * @param errorMsg Error message
 */
export const setClipDetailError = (errorMsg: string) => ({
  type: SET_CLIP_DETAIL_ERROR,
  payload: { errorMsg },
});

/**
 * Action for clip detail setting (SUCCESS)
 *
 * @param matchDetail match detail
 */
export const setClipDetailSuccess = (clipDetail: ClipDetail) => ({
  type: SET_CLIP_DETAIL_SUCCESS,
  payload: { clipDetail },
});

/**
 * Action for clip detail setting
 *
 * @param clipId match id
 */
export const setClipDetail = (clipId: number) => (dispatch: Dispatch) => {
  dispatch(setClipDetailRequesting());

  _getClipDetail(clipId).then((response: ClipDetailResponse | ErrorResponse) => {
    if (isErrorResponse(response)) {
      dispatch(setClipDetailError('Failed'));
    } else {
      const clipDetailResponse = response as ClipDetailResponse;
      dispatch(setClipDetailSuccess(transformClipDetail(clipDetailResponse.clipDetail)));
    }
  });
};

export const setClipReaction = (reaction: ClipReaction) => ({
  type: SET_CLIP_REACTION,
  payload: { reaction },
});

export const setSharedClipDetail = (clipId: number, shareToken: string) => (dispatch: Dispatch) => {
  dispatch(setClipDetailRequesting());

  getSharedClip(clipId, shareToken).then((response: ClipDetailResponse | ErrorResponse) => {
    if (isErrorResponse(response)) {
      dispatch(setClipDetailError('Failed'));
    } else {
      const clipDetail = response as ClipDetailResponse;
      dispatch(setClipDetailSuccess(transformClipDetail(clipDetail)));
    }
  });
};

export const reactToClip = (clipId: number, reaction: ClipReaction) => (
  dispatch: Dispatch,
  getState: any
) => {
  if (!getState().user.user) return;
  dispatch(setClipReaction(reaction));
  postReactToClip(reaction, clipId).catch(err => dispatch(showNotification(err, 'error')));
};

const transformClipDetail = (clipDetail: any): ClipDetailWithMatch => {
  return {
    id: clipDetail.id || 0,
    name: clipDetail.name || '',
    isPrivate: clipDetail.isPrivate,
    isFavorite: clipDetail.isFavorite,
    syncTime: clipDetail.syncTime,
    time: clipDetail.time || 0,
    match: {
      ...clipDetail.match,
      id: clipDetail.matchId,
      gender: clipDetail.match.gender === 'male' ? Gender.men : Gender.women,
      competition: clipDetail.match.competition?.name,
      category: clipDetail.match.categorie?.name,
      teams: {
        firstTeam: clipDetail.match.homeTeam,
        secondTeam: clipDetail.match.guestTeam,
      },
    },
    ownerId: clipDetail.ownerId,
    startTime: clipDetail.startTime || 0,
    endTime: clipDetail.endTime || 0,
    videoUrl: clipDetail.videoUrl || '',
    videoStatus: clipDetail.videoStatus || VideoStatus.noVideo,
    note: clipDetail.note || '',
    publicNote: clipDetail.publicNote || '',
    clipCategories: clipDetail.clipCategories || [],
    sport: clipDetail.sport,
    videoClipUrl: clipDetail.videoClipUrl,
    videoClipName: clipDetail.videoClipName,
    reportDisabled: clipDetail.reportDisabled,
    ratings: {
      ...clipDetail.ratings,
    },
    closedCommunityName: clipDetail.closedCommunityName,
    isClosedCommunityShared: clipDetail.isClosedCommunityShared,
    totalRating: clipDetail.rating,
  };
};
