import { IconType } from '../components/Icon';
import { ApplicationMode } from '../types';

export const iconTypeHandler = (
  applicationMode: ApplicationMode,
  darkType: IconType,
  activeType?: IconType,
  isActive?: boolean
): IconType => {
  let resultType = IconType.default;

  if (applicationMode === ApplicationMode.dark) resultType = darkType;
  if (isActive) resultType = activeType || IconType.default;

  return resultType;
};
