import React, { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Icon, IconType } from '../Icon';
import { getApplicationModeState, getUser } from '../../selectors';
import { classHandler, iconTypeHandler } from '../../utils';

import './MenuItem.scss';

export type MenuItemWrapperProps = {
  disabled: boolean;
  to: string;
  onClick?: any;
  className: string;
};

const MenuItemWrapper: FunctionComponent<MenuItemWrapperProps> = ({
  children,
  disabled,
  to,
  onClick,
  className,
}) => {
  if (disabled) {
    return <div className={`${className} MenuItem__disabled`}>{children}</div>;
  }

  return (
    <div onClick={onClick}>
      <Link to={to}>
        <div className={className}>{children}</div>
      </Link>
    </div>
  );
};

export type MenuItemProps = {
  label: string;
  iconName: string;
  to: string;
  onClick?: any;
  active?: boolean;
};

export const MenuItem: FunctionComponent<MenuItemProps> = ({
  label,
  iconName,
  to,
  onClick,
  active,
}) => {
  const { pathname } = useLocation();

  const applicationMode = useSelector(getApplicationModeState);
  const user = useSelector(getUser);
  const disabled = !user;
  const isActive = Boolean(pathname === to || active) && !disabled;

  const iconType = iconTypeHandler(applicationMode, IconType.light, IconType.red, isActive);

  return (
    <MenuItemWrapper
      onClick={onClick}
      to={to}
      className={classHandler('MenuItem', applicationMode, isActive)}
      disabled={disabled}
    >
      <div className="MenuItem__iconWrapper">
        <Icon type={iconType} name={iconName} />
      </div>
      <p>{label}</p>
    </MenuItemWrapper>
  );
};
