import React from 'react';
import { useTranslation } from 'react-i18next';
import { timePresentation } from '../../utils';

import './DigiRecorderStopwatch.scss';

interface DigiRecorderStopwatchProps {
  timeSeconds: number;
  showLabel: boolean;
}

const DigiRecorderStopwatch: React.FC<DigiRecorderStopwatchProps> = ({
  timeSeconds,
  showLabel,
}) => {
  const { t } = useTranslation();
  const getDotClass = () => {
    const base = 'DigiRecorderStopwatch__dot';
    const classes = [base];
    if (!(timeSeconds % 2)) {
      classes.push(`${base}--off`);
    }
    return classes.join(' ');
  };

  return (
    <div className="DigiRecorderStopwatch">
      <div className={getDotClass()} />
      <div className="DigiRecorderStopwatch__time">
        {timePresentation(timeSeconds, false, true)}
      </div>
      {showLabel && (
        <>
          <div className="DigiRecorderStopwatch__label">{t('recordingMatchStartHint')}</div>
          <div className="DigiRecorderStopwatch__label">
            {t('liveRecordingHelpLinkPrefix', '')}
            &nbsp;
            <a href={t('liveRecordingHelpLink')} target="_blank" rel="noopener noreferrer">
              {t('liveRecordingHelpLinkSuffix', '')}
            </a>
            &nbsp;
          </div>
        </>
      )}
    </div>
  );
};

export default DigiRecorderStopwatch;
