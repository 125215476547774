import { AnyAction } from 'redux';

import { AccountingFilter, AccountingState, Status } from '../types';
import {
  SET_ACCOUNTING_ERROR,
  SET_ACCOUNTING_REQUESTING,
  SET_ACCOUNTING_SUCCESS,
  SET_ACCOUNTING_FILTER,
} from '../constants';

export const initAccountingFilter: AccountingFilter = {
  dateFrom: null,
  dateTo: null,
  type: '',
};

const initAccountingState: AccountingState = {
  status: Status.idle,
  errorMsg: null,
  accounting: [],
  filter: initAccountingFilter,
};

export const accountingReducer = (
  state: AccountingState = initAccountingState,
  action: AnyAction
): AccountingState => {
  switch (action.type) {
    case SET_ACCOUNTING_REQUESTING: {
      return { ...state, status: Status.requesting, errorMsg: null, accounting: [] };
    }
    case SET_ACCOUNTING_ERROR: {
      const { errorMsg } = action.payload;

      return { ...state, status: Status.error, errorMsg, accounting: [] };
    }
    case SET_ACCOUNTING_SUCCESS: {
      const { accounting } = action.payload;

      return { ...state, status: Status.success, errorMsg: null, accounting };
    }
    case SET_ACCOUNTING_FILTER: {
      const { filter } = action.payload;

      return { ...state, filter };
    }
    default:
      return state;
  }
};
