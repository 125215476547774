import { ClipCategory, Teams } from '../types';

export const getClipCategoryLabel = (clipCategory: ClipCategory) => {
  const { value, shortCut } = clipCategory;
  if (!shortCut) {
    return value;
  }
  return `${value} (${shortCut})`;
};

export const printTeams = (teams: Teams) => {
  return [teams.firstTeam, teams.secondTeam].filter((item: any) => !!item).join(' vs. ');
};

export const joinWithDot = (...args: string[]) => {
  return args.filter((item: string) => !!item).join(' • ');
};

export const applyVideoProxy = (url: string) => {
  const storageUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://storage.googleapis.com/prod-refrec-storage/video/'
      : 'https://storage.googleapis.com/dev-refrec-storage/video/';
  const proxyUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://app.refereerecorder.com/download/video/'
      : 'https://refrec.koala42.com/download/dev/video/';

  return url.replace(storageUrl, proxyUrl);
};

export const getFileWithoutExtension = (fileUrl: string) => {
  return fileUrl.split('.')[0];
};

export const transformGender = (sex: string): string => {
  if (sex === 'male') {
    return 'Men';
  }

  if (sex === 'female') {
    return 'Women';
  }

  return sex;
};
