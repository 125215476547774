import React, { FunctionComponent } from 'react';

import './Modal.scss';
import { classHandler } from '../../utils';
import { useSelector } from 'react-redux';
import { getApplicationModeState } from '../../selectors';

type ModalProps = {
  isVisible: boolean;
  width: string;
  className?: string;
  height: string;
  handleClose: any;
};

export const Modal: FunctionComponent<ModalProps> = ({
  isVisible,
  width,
  height,
  className,
  children,
  handleClose,
}) => {
  const applicationMode = useSelector(getApplicationModeState);

  if (!isVisible) return null;

  const handleCloseClick = (event: any) => {
    if (event.target.className === 'Modal__overlay') {
      handleClose(false);
    }
  };

  return (
    <div className={`Modal__overlay`} onClick={handleCloseClick}>
      <div
        style={{ width, height }}
        className={classHandler('Modal', applicationMode, undefined, undefined, className)}
      >
        {children}
      </div>
    </div>
  );
};
