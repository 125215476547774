import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GET_ALL_CLIPS_FILE_URL, tryDownloadAllClips } from '../api';
import { showNotification } from '../actions';
// import { saveAs } from 'file-saver';
import WebSocketProvider from '../WebSocket';
import { useCallback } from 'react';
import { isAndroidApp, isIOSApp } from './getPlatform';
import { useDownloadZipHandler } from './bridgeHandler';
import { getUser } from '../selectors';
import FileSaver from 'file-saver';
// import FileSaver from 'file-saver';

const useDownloadAllClips = (matchId: number): { handleDownloadArchive: () => void } => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const iOS = isIOSApp();
  const android = isAndroidApp();
  const user = useSelector(getUser);
  const handleAppDownload = useDownloadZipHandler;

  const handleDownloadError = useCallback(() => {
    dispatch(showNotification(t('allClipsDownloadError'), 'error'));
  }, [dispatch, t]);

  const handleDownloadReady = useCallback(() => {
    const name = 'videos-of-match-' + matchId;
    const url = GET_ALL_CLIPS_FILE_URL(matchId, user!.id);
    if (iOS || android) {
      handleAppDownload(url, name, (response: any) => {
        if (response.status === 0) {
          dispatch(showNotification(t('allClipsDownloadSuccess'), 'success', 5000));
        } else if (response.status === 1) {
          dispatch(showNotification(t('allClipsDownloadError'), 'error'));
        }
      });

      return;
    }

    FileSaver.saveAs(url, name);
    dispatch(showNotification(t('allClipsDownloadSuccess'), 'success', 5000));
  }, [user, matchId, android, dispatch, handleAppDownload, iOS, t]);

  const handleDownloadArchive = useCallback(() => {
    if (!user) {
      return;
    }
    dispatch(showNotification(t('allClipsDownloadStart'), 'info'));

    tryDownloadAllClips(matchId).then(async result => {
      if (result.error) {
        handleDownloadError();
        return;
      }

      if (result.ready) {
        handleDownloadReady();
        return;
      }

      WebSocketProvider.registerOnArchiveCreationActionOnce(
        matchId,
        handleDownloadReady,
        handleDownloadError
      );
    });
  }, [t, dispatch, matchId, handleDownloadError, user, handleDownloadReady]);

  return { handleDownloadArchive };
};

export default useDownloadAllClips;
