import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  setApplicationBreakpoint,
  setApplicationMode,
  setCountries,
  setCurrency,
  setIsPremiumUntil,
  setUserWithJwt,
} from '../../actions';
import { ApplicationBreakpoint, ApplicationMode, Status } from '../../types';
import {
  getApplicationBreakpointState,
  getApplicationModeState,
  getCountryCurrency,
} from '../../selectors';
import { themeHandler, setBodyBackground } from '../../utils/themeHandler';
import { Layout } from '../Layout';
import { PrivateRoute } from '../PrivateRoute';
import { MatchListPage } from '../../containers/MatchListPage';
import { VideoListPage } from '../../containers/VideoListPage';
import { NoteListPage } from '../../containers/NoteListPage';
import { AccountingPage } from '../../containers/AccountingPage';
import { StatisticsPage } from '../../containers/StatisticsPage';
import { MatchDetailPage } from '../../containers/MatchDetail';
import { SettingsPage } from '../../containers/SettingsPage';
import { ClipDetailPage } from '../../containers/ClipDetailPage';
import { LoginPage } from '../../containers/LoginPage';
import { getAppPlatform, getJwtFromLocalStorage, useGetAppVersion } from '../../utils';
import { getUser, getUserStatus } from '../../selectors';
import { ContentLoader } from '../ContentLoader';
import { getSettings } from '../../selectors';
import { CompetitionsPage } from '../../containers/CompetitionsPage';
import { CompetitionDetailPage } from '../../containers/CompetitionDetailPage';
import { SharedMatchPage } from '../../containers/SharedMatchPage';

import './App.scss';
import { AboutPage } from '../../containers/AboutPage/AboutPage';
import { Notification } from '../Notification';
import { PremiumFeatureModal } from '../PremiumFeatureModal';
import { InfoModal } from '../InfoModal';
import { PremiumPage } from '../../containers/PremiumPage/PremiumPage';
import { IsOffline } from '../IsOffline';
import useOnlineStatus from '../../utils/useOnlineStatus';
import WebSocketProvider from '../../WebSocket';
import DigiRecorderPage from '../../containers/DigiRecorderPage/DigiRecorderPage';
import { postAppInfo } from '../../api';
import { LoginRequiredModal } from '../LoginRequiredModal';

export const App: FunctionComponent<{}> = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const jwt = getJwtFromLocalStorage(true);

  const currentApplicationBreakpoint = useSelector(getApplicationBreakpointState);
  const applicationMode = useSelector(getApplicationModeState);
  const userStatus = useSelector(getUserStatus);
  const user = useSelector(getUser);
  const settings = useSelector(getSettings);
  const currency = useSelector(getCountryCurrency(settings?.country.id as number));
  const isOnline = useOnlineStatus();
  const getAppVersion = useGetAppVersion;

  useEffect(() => {
    if (jwt && !user) dispatch(setUserWithJwt(jwt));
    dispatch(setCountries());
    // if (user) dispatch(setStatistics(user.id));

    if (user && jwt) {
      WebSocketProvider.init(user.id, jwt);
      dispatch(setIsPremiumUntil(jwt, user));
    }

    return () => {
      WebSocketProvider.close();
    };
  }, [jwt, dispatch, user]);

  useEffect(() => {
    const platform = getAppPlatform();
    if (platform && user && user.id && jwt) {
      getAppVersion((data: any) => {
        postAppInfo(user.id, jwt, { platform, appVersion: data.data });
      });
    }
  }, [getAppVersion, user, jwt]);

  useEffect(() => {
    if (currency) dispatch(setCurrency(currency));
  }, [dispatch, currency]);

  useEffect(() => {
    dispatch(setApplicationMode(settings?.darkMode ? ApplicationMode.dark : ApplicationMode.light));
    setBodyBackground(settings?.darkMode ? ApplicationMode.dark : ApplicationMode.light);

    // test for format [cs_CZ] if not found use english instead
    if (settings?.language.value && /[a-z]{2}_[A-Z]{2}/g.test(settings?.language.value)) {
      const language = settings?.language.value.split(/_/g)[0];
      i18n.changeLanguage(language);
    } else if (settings?.language.value === '') {
      localStorage.removeItem('i18nextLng');
      // @ts-ignore
      i18n.changeLanguage();
    }
  }, [settings, dispatch, i18n]);

  useEffect(() => {
    const handleResize = () => {
      let resultApplicationBreakpoint: ApplicationBreakpoint;

      if (window.innerWidth <= 480) {
        resultApplicationBreakpoint = ApplicationBreakpoint.mobile;
      } else if (window.innerWidth >= 1025) {
        resultApplicationBreakpoint = ApplicationBreakpoint.desktop;
      } else {
        resultApplicationBreakpoint = ApplicationBreakpoint.tablet;
      }

      if (currentApplicationBreakpoint !== resultApplicationBreakpoint) {
        dispatch(setApplicationBreakpoint(resultApplicationBreakpoint));
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch, currentApplicationBreakpoint]);

  const requestingUser = userStatus === Status.requesting;
  const loaded = !requestingUser;

  if (!isOnline) {
    return (
      <ThemeProvider theme={themeHandler(applicationMode)}>
        <IsOffline />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={themeHandler(applicationMode)}>
      <Layout>
        <Notification />
        <PremiumFeatureModal />
        <LoginRequiredModal />
        <InfoModal />
        {requestingUser && <ContentLoader />}
        {loaded && (
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/video-detail" component={ClipDetailPage} />
            <Route path="/match-shared" component={SharedMatchPage} />
            <PrivateRoute path="/digirecorder" component={DigiRecorderPage} />
            <PrivateRoute path="/competition-detail" component={CompetitionDetailPage} />
            <PrivateRoute path="/competitions" component={CompetitionsPage} />
            <PrivateRoute path="/match-detail" component={MatchDetailPage} />
            <PrivateRoute path="/videos" component={VideoListPage} />
            <PrivateRoute path="/notes" component={NoteListPage} />
            <PrivateRoute path="/accounting" component={AccountingPage} />
            <PrivateRoute path="/statistics" component={StatisticsPage} />
            <PrivateRoute path="/settings" component={SettingsPage} />
            <PrivateRoute path="/about" component={AboutPage} />
            <PrivateRoute path="/premium" component={PremiumPage} />
            <PrivateRoute path="/" component={MatchListPage} />
          </Switch>
        )}
      </Layout>
    </ThemeProvider>
  );
};
