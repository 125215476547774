import { Dispatch } from 'redux';

import {
  SET_COMPETITIONS_ERROR,
  SET_COMPETITIONS_REQUESTING,
  SET_COMPETITIONS_SUCCESS,
} from '../constants';
import { Category, Competition, CompetitionsResponse, Gender } from '../types';
import { _getCompetitions } from '../api';

/**
 * Action for competitions setting (REQUESTING)
 *
 */
export const setCompetitionsRequesting = () => ({ type: SET_COMPETITIONS_REQUESTING });

/**
 * Action for competitions setting (ERROR)
 *
 * @param errorMsg Error message
 */
export const setCompetitionsError = (errorMsg: string) => ({
  type: SET_COMPETITIONS_ERROR,
  payload: { errorMsg },
});

/**
 * Action for competitions setting (SUCCESS)
 *
 * @param competitions Array of competitions
 */
export const setCompetitionsSuccess = (competitions: Competition[]) => ({
  type: SET_COMPETITIONS_SUCCESS,
  payload: { competitions },
});

/**
 * Action for competitions setting
 *
 */
export const setCompetitions = (userId: number) => (dispatch: Dispatch) => {
  dispatch(setCompetitionsRequesting());

  _getCompetitions(userId).then((response: CompetitionsResponse) => {
    if (response.competitions) {
      const transformedCompetitions: Competition[] = response.competitions.map(
        (competition: any) => {
          return {
            id: competition.id,
            name: competition.name,
            sport: competition.sport,
            country: competition.country,
            gender: competition.gender === 'male' ? Gender.men : Gender.women,
            categories: transformCategories(competition.categories),
          };
        }
      );

      dispatch(setCompetitionsSuccess(transformedCompetitions));
    }
  });
};

const transformCategories = (categories: any[]): Category[] => {
  return categories.map(
    (clip: any): Category => ({
      id: clip.id,
      name: clip.name,
      rewards: {
        type4AR: clip.reward4AR,
        typeAAR: clip.rewardAAR,
        typeAR: clip.rewardAR,
        typeD: clip.rewardD,
        typeR: clip.rewardR,
      },
    })
  );
};
