import { Dispatch } from 'redux';
import {
  SET_ACCOUNTING_DATA_REQUESTING,
  SET_ACCOUNTING_DATA_SUCCESS,
  SET_ACCOUNTING_ERROR,
  SET_ACCOUNTING_FILTER,
  SET_ACCOUNTING_REQUESTING,
  SET_ACCOUNTING_SUCCESS,
} from '../constants';
import { Accounting, AccountingData, AccountingFilter } from '../types';
import { getAccounting, getAccountingData } from '../api';
import { showNotification } from '.';
import i18next from 'i18next';

export const setAccountingRequesting = () => ({ type: SET_ACCOUNTING_REQUESTING });

export const setAccountingError = (errorMsg: string) => ({
  type: SET_ACCOUNTING_ERROR,
  payload: { errorMsg },
});

export const setAccountingSuccess = (accounting: Accounting[]) => ({
  type: SET_ACCOUNTING_SUCCESS,
  payload: { accounting },
});

export const setAccounting = () => (dispatch: Dispatch) => {
  dispatch(setAccountingRequesting());

  getAccounting().then((response: any) => {
    dispatch(setAccountingSuccess(response));
  });
};

export const setAccountingDataRequesting = () => ({ type: SET_ACCOUNTING_DATA_REQUESTING });

export const setAccountingDataSuccess = (accountingData: AccountingData) => ({
  type: SET_ACCOUNTING_DATA_SUCCESS,
  payload: { accountingData },
});

export const setAccountingData = (address: string, filter: AccountingFilter) => (
  dispatch: Dispatch
) => {
  dispatch(setAccountingDataRequesting());
  getAccountingData(address, filter).then(async (response: any) => {
    const body = await response.json();
    switch (response.status) {
      case 200: {
        dispatch(setAccountingDataSuccess(body));
        break;
      }
      case 400: {
        dispatch(showNotification(i18next.t(body.message), 'error', 5000));
        break;
      }
    }
  });
};

export const setAccountingFilter = (filter: AccountingFilter | undefined) => ({
  type: SET_ACCOUNTING_FILTER,
  payload: { filter },
});
