import { TextField } from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getNotepad as getNotepadAction, updateNotepad } from '../../actions';
import { getNotepad, getNotesStatus, getUser } from '../../selectors';
import { Status } from '../../types';
import { ContentLoader } from '../ContentLoader';
import { SaveButtonsWrapper } from '../SaveButtonsWrapper';

import './GeneralNotepadTab.scss';

export const GeneralNotepadTab: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const initialNotepad = useSelector(getNotepad);
  const notesStatus = useSelector(getNotesStatus);
  const dispatch = useDispatch();

  const [notepad, setNotepad] = useState<string>(initialNotepad);

  useEffect(() => {
    dispatch(getNotepadAction());
  }, [dispatch]);

  useEffect(() => {
    setNotepad(initialNotepad);
  }, [initialNotepad]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNotepad(event.target.value);
  };

  const saveNotepad = () => {
    if (user) {
      dispatch(updateNotepad(user.id, notepad));
    }
  };

  return (
    <div className="GeneralNotepadTab">
      <div className="GeneralNotepadTab__container">
        <div className="GeneralNotepadTab__content">
          {notesStatus === Status.success ? (
            <TextField
              className="GeneralNotepadTab__notepad"
              placeholder={t('generalNotepadPlaceholder')}
              multiline
              rows={60}
              rowsMax={Infinity}
              value={notepad}
              InputProps={{ disableUnderline: true }}
              onChange={handleChange}
            />
          ) : (
            <ContentLoader />
          )}
        </div>
      </div>
      <SaveButtonsWrapper
        primaryButton={{
          label: t('save'),
          onClick: saveNotepad,
        }}
      />
    </div>
  );
};
