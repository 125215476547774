import { MatchCardType } from '../components/MatchCard';
import { MatchTableType } from '../components/MatchTable';

export enum SetClipsType {
  default = 'default',
  myFavourites = 'my_favourites',
  otherFavourites = 'other_favourites',
  community = 'community',
  closedCommunity = 'closedCommunity',
}

export interface Tab {
  id: number;
  name: string;
  key?: string;
}

export interface MatchTab extends Tab {
  filter: string;
  sort: 'ASC' | 'DESC';
  matchCardType: MatchCardType;
  matchTableType: MatchTableType;
}

export interface MatchTabLocationState {
  matchTab: number;
}

export interface VideoTabLocationState {
  videoTab: number;
  verticalScroll: number;
  horizontalScroll: number;
}
