/**
 * use category reward
 */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCompetitions } from '../selectors';
import { Gender, MatchDetail } from '../types';
import useIsNewMatch from './useIsNewMatch';

export default (newMatch: MatchDetail | null) => {
  const competitions = useSelector(getCompetitions(newMatch?.gender || Gender.men));
  const isNew = useIsNewMatch();
  const [categoryReward, setCategoryReward] = useState<number | null>(0);

  useEffect(() => {
    if (!newMatch?.competition || !newMatch?.category) {
      return;
    }
    const currentCompetition = competitions.find(
      competition => +competition.id === +newMatch?.competition
    );
    const currentCategory = currentCompetition?.categories.find(
      category => +category.id === +newMatch?.category
    );

    if (currentCategory !== undefined) {
      const reward = currentCategory.rewards.typeR;
      if (isNew) {
        setCategoryReward(reward);
      }
    }
  }, [competitions, newMatch, isNew, setCategoryReward]);

  return categoryReward;
};
