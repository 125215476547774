import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Icon, IconType } from '../Icon';
import { ApplicationMode, Match } from '../../types';
import { getApplicationModeState } from '../../selectors';
import { classHandler, dateToString } from '../../utils';
import { Table, TableBody, TableCell as MTableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './MatchTable.scss';

type Header = {
  label: string;
  iconName: string;
};

export enum MatchTableType {
  previous = 'previous',
  upcoming = 'upcoming',
  noData = 'noData',
}

type MatchTableProps = {
  matches: Match[];
  type: MatchTableType;
  redirectToMatch?: (match: Match) => void;
  style?: React.CSSProperties;
};

export const MatchTable: FunctionComponent<MatchTableProps> = ({
  matches,
  type,
  redirectToMatch,
  style,
}) => {
  const { t } = useTranslation();

  const useStyles = makeStyles({
    root: {
      padding: '16px 8px',
    },
  });

  const applicationMode = useSelector(getApplicationModeState);

  const headers: Header[] = [
    { label: t('date'), iconName: 'calendar' },
    { label: t('location'), iconName: 'location' },
    { label: t('gender'), iconName: 'gender' },
    { label: t('competition'), iconName: 'cup' },
    { label: t('category'), iconName: 'category' },
    { label: t('teams'), iconName: 'team' },
    { label: t('referees'), iconName: 'handshake' },
    { label: t('refCoach'), iconName: 'eye' },
  ];

  if (type === MatchTableType.previous) {
    headers.push({ label: t('videos'), iconName: 'camera' });
    headers.push({ label: t('notes'), iconName: 'pen' });
  }

  const TableCell = (props: any) => {
    const classes = useStyles();
    return <MTableCell classes={classes}>{props.children}</MTableCell>;
  };

  const _renderHeaders = headers.map((header: Header, index: number) => (
    <TableCell size="small" key={index}>
      <div className="MatchTable__header">
        <Icon
          type={applicationMode === ApplicationMode.dark ? IconType.white : IconType.default}
          size="15px"
          name={header.iconName}
        />
        <div className="MatchTable__headerText">{header.label}</div>
      </div>
    </TableCell>
  ));

  const _renderRows = matches.map((match: Match) => {
    const handleClick = () => {
      if (redirectToMatch) {
        redirectToMatch(match);
      }
    };

    return (
      <TableRow hover={true} style={{ cursor: 'pointer' }} key={match.id} onClick={handleClick}>
        <TableCell style={{ fontWeight: type === MatchTableType.upcoming ? 700 : 400 }}>
          {dateToString(match.date, type === MatchTableType.upcoming, match.time)}
        </TableCell>
        <TableCell style={{ fontWeight: type === MatchTableType.upcoming ? 700 : 400 }}>
          {match.location}
        </TableCell>
        <TableCell>{t(match.gender.toLowerCase())}</TableCell>
        <TableCell>{match.competition}</TableCell>
        <TableCell>{match.category}</TableCell>
        {match.teams.firstTeam && match.teams.secondTeam ? (
          <TableCell>
            {match.teams.firstTeam} vs. {match.teams.secondTeam}
          </TableCell>
        ) : (
          <TableCell />
        )}
        <TableCell>{match.referees.join(', ')}</TableCell>
        <TableCell>{match.refereeCoach}</TableCell>
        {type === MatchTableType.previous && (
          <React.Fragment>
            <TableCell>{match.videoCount}</TableCell>
            <TableCell>{match.noteCount}</TableCell>
          </React.Fragment>
        )}
      </TableRow>
    );
  });

  return (
    <div className={classHandler('MatchTable', applicationMode)} style={{ ...style }}>
      <Table>
        <TableHead>
          <TableRow>{_renderHeaders}</TableRow>
        </TableHead>
        <TableBody>{_renderRows}</TableBody>
      </Table>
    </div>
  );
};
