import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './NoNFCBanner.scss';

type NoNFCBannerProps = {
  handleGoogle?: React.MouseEventHandler<HTMLDivElement>;
  handleApple?: React.MouseEventHandler<HTMLDivElement>;
};

export const NoNFCBanner: FunctionComponent<NoNFCBannerProps> = ({ handleGoogle, handleApple }) => {
  const { t } = useTranslation();
  return (
    <div className="NoNFCBanner__wrapper">
      <h3>{t('bannerTextOne')}</h3>
      <div className="NoNFCBanner__text">
        {t('bannerTextTwo')} <br />{' '}
        <b>
          {t('bannerTextThree')} <br />{' '}
        </b>{' '}
        {t('bannerTextFour')} <br /> <b>{t('bannerTextFive')} </b> <br /> {t('bannerTextSix')}.
      </div>
      <div className="NoNFCBanner__btnRow">
        <img
          onClick={handleGoogle}
          className="NoNFCBanner__btn"
          alt="google"
          src={`${process.env.PUBLIC_URL}/google.png`}
        />
        <img
          onClick={handleApple}
          className="NoNFCBanner__btn"
          alt="apple"
          src={`${process.env.PUBLIC_URL}/apple.png`}
        />
      </div>
    </div>
  );
};
