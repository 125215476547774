import React, { FunctionComponent, useEffect, useRef, useState, createRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Clip,
  ClipGroup,
  ClipType,
  MyClipGroup,
  MyClip,
  CommunityClipGroup,
  CommunityClip,
  FavouriteClipGroup,
  FavouriteClip,
  VideoTabLocationState,
  Status,
  ClosedCommunityClipGroup,
} from '../../types';
import { ClipCard } from '../ClipCard';
import useScrollBox from '../../components/ScrollBox/useScrollBox';
import { ShareMatch } from '../ShareMatch';
import { ScrollButton, ScrollButtonType } from '../ScrollButton';
import { getApplicationModeState } from '../../selectors';
import {
  classHandler,
  joinWithDot,
  printTeams,
  iconTypeHandler,
  isErrorResponse,
} from '../../utils';
import { Popper, ClickAwayListener } from '@material-ui/core';
import { Icon, IconType } from '../../components/Icon';

import './VideoSwimLine.scss';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ClipCardLoader } from '../ClipCardLoader';
import DownloadAllClips from '../DownloadAllClips/DownloadAllClips';
import { AlertModal } from '../AlertModal';
import { removeMatchDetail } from '../../api';
import { createRemoveClipsGroupAction } from '../../actions';

type VideoSwimLineProps = {
  clipGroup: ClipGroup;
  clipType: ClipType;
  redirectToVideoDetail: (path: string, horizontalScroll: number) => void;
  redirectToMatchDetail: (path: string) => void;
  loadMore?: () => void;
  clipLineRef: React.RefObject<HTMLDivElement>;
  shouldScroll: boolean;
  uncollapsed?: boolean;
  seePrivateNote: boolean;
};

export const VideoSwimLine: FunctionComponent<VideoSwimLineProps> = ({
  clipGroup,
  clipType,
  redirectToVideoDetail,
  redirectToMatchDetail,
  loadMore,
  clipLineRef,
  shouldScroll,
  uncollapsed = false,
  seePrivateNote,
}) => {
  const { t } = useTranslation();
  const clipsRef = useRef<any>(null);
  const location = useLocation<VideoTabLocationState>();
  const dispatch = useDispatch();
  const applicationMode = useSelector(getApplicationModeState);

  const { isDragging } = useScrollBox(clipsRef);

  const [scrolledMax, setScrolledMax] = useState<boolean>(false);
  const [scrolledMin, setScrolledMin] = useState<boolean>(true);
  const [isScrollable, setScrollable] = useState<boolean>(false);
  const [scrolledIntoView, setScrolledIntoView] = useState<boolean>(false);
  const [tooltipVisibility, setTooltipVisibility] = useState(false);
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState<boolean>(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const clipRefs = Array.from({
    length: clipGroup.clips.length,
  }).map(() => createRef<HTMLDivElement>());

  const scrollClipIndex = location.state?.horizontalScroll;
  const scrollClipRef = clipRefs[scrollClipIndex];

  useEffect(() => {
    const currentClips = clipsRef.current;

    const handleScroll = (event: any) => {
      setScrolledMax(
        event.target.scrollLeft > event.target.scrollWidth - event.target.clientWidth - 50
      );
      setScrolledMin(event.target.scrollLeft === 0);
    };

    currentClips.scrollTo(0, 0);
    setScrollable(currentClips.scrollWidth > currentClips.clientWidth);

    currentClips.addEventListener('scroll', handleScroll);

    return () => currentClips.removeEventListener('scroll', handleScroll);
  }, [clipsRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const clipRef = scrollClipRef && scrollClipRef.current;

    if (!scrolledIntoView && shouldScroll && clipRef) {
      clipRef.scrollIntoView({
        inline: 'center',
        block: 'center',
      });

      setTimeout(() => {
        setScrolledIntoView(true);
      }, 2000);
    }
  }, [scrollClipRef, shouldScroll, scrolledIntoView]);

  const scrollRight = () => {
    const scrollAmount = window.innerWidth - 20;
    clipsRef.current.scrollTo({
      top: 0,
      left: clipsRef.current.scrollLeft + scrollAmount,
      behavior: 'smooth',
    });
  };

  const scrollLeft = () => {
    const scrollAmount = window.innerWidth - 20;
    clipsRef.current.scrollTo({
      top: 0,
      left: clipsRef.current.scrollLeft - scrollAmount,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (scrolledMax && loadMore) {
      loadMore();
    }
  }, [loadMore, scrolledMax]);

  const titleOnClick = () => {
    if (clipType === ClipType.myVideo) {
      goToMatchDetail();
    }
  };

  const goToMatchDetail = () => {
    redirectToMatchDetail(`/match-detail?id=${clipGroup.id}`);
  };

  const renderTitle = () => {
    if (clipType === ClipType.myVideo) {
      const myClipGroup = clipGroup as MyClipGroup;

      const teams = printTeams(myClipGroup.teams);
      const { gender, competition, category, date } = myClipGroup;
      const fullDate = `${date.getDate()}. ${date.getMonth() + 1}. ${date.getFullYear()}`;

      const title = joinWithDot(fullDate, t(gender), competition, category, teams);

      return title;
    }

    if (clipType === ClipType.community) {
      const communityClipGroup = clipGroup as CommunityClipGroup;
      return t(communityClipGroup.title);
    }

    if (clipType === ClipType.favourite) {
      const favouriteClipGroup = clipGroup as FavouriteClipGroup;
      return t(favouriteClipGroup.title);
    }

    if (clipType === ClipType.closedCommunity) {
      const closedCommunityGroup = clipGroup as ClosedCommunityClipGroup;
      return t(closedCommunityGroup.title);
    }
  };

  const renderTooltip = () => {
    return (
      <Popper
        open={tooltipVisibility}
        anchorEl={tooltipRef.current}
        role={undefined}
        placement="left-start"
        onClick={handleTooltipVisibility}
        modifiers={{
          computeStyle: {
            gpuAcceleration: false,
            adaptive: false,
          },
        }}
      >
        <div
          className={classHandler('PopperShadow', applicationMode)}
          style={{ maxWidth: 'calc(100vw - 1.5em)', marginRight: '5px' }}
        >
          <ClickAwayListener onClickAway={handleTooltipVisibility}>
            <div
              className={classHandler(
                'ClipController__moreTooltip',
                applicationMode,
                false,
                tooltipVisibility
              )}
            >
              <ShareMatch matchId={clipGroup.id} />
              <DownloadAllClips matchId={clipGroup.id} />
              <div
                className={`ClipController__tooltipButton`}
                onClick={() => setIsDeleteConfirmVisible(true)}
              >
                <Icon
                  size="20px"
                  name="trash"
                  type={iconTypeHandler(applicationMode, IconType.white)}
                />
                <div className="ClipController__tooltipButtonLabel">{t('deleteMatchButton')}</div>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </Popper>
    );
  };

  const handleDeleteMatch = (result: boolean) => {
    setIsDeleteConfirmVisible(false);
    if (result) {
      removeMatchDetail(clipGroup.id).then((response: any) => {
        if (!isErrorResponse(response)) {
          dispatch(createRemoveClipsGroupAction(clipGroup.id));
        }
      });
    }
  };

  const handleTooltipVisibility = () => {
    setTooltipVisibility(!tooltipVisibility);
  };

  const renderClips = () => {
    if (clipType === ClipType.myVideo) {
      const myClips = clipGroup.clips as MyClip[];
      return myClips.map((clip: Clip, index: number) => {
        const _redirectToVideoDetail = (path: string) => {
          const horizontalScroll = index;
          redirectToVideoDetail(path, horizontalScroll);
        };

        return (
          <div key={index} style={{ pointerEvents: isDragging ? 'none' : undefined }}>
            <ClipCard
              key={index}
              clip={clip}
              isFirst={!uncollapsed && index === 0}
              isLast={!uncollapsed && index === myClips.length - 1}
              clipType={clipType}
              redirectToVideoDetail={_redirectToVideoDetail}
              clipRef={clipRefs[index]}
              seePrivateNote={seePrivateNote}
            />
          </div>
        );
      });
    }

    if (clipType === ClipType.community) {
      const communityClips = clipGroup.clips as CommunityClip[];
      const clips = communityClips.map((clip: Clip, index: number) => {
        const _redirectToVideoDetail = (path: string) => {
          const horizontalScroll = index;
          redirectToVideoDetail(path, horizontalScroll);
        };

        return (
          <div key={index} style={{ pointerEvents: isDragging ? 'none' : undefined }}>
            <ClipCard
              key={index}
              clip={clip}
              clipType={clipType}
              isFirst={!uncollapsed && index === 0}
              isLast={!uncollapsed && index === communityClips.length - 1}
              redirectToVideoDetail={_redirectToVideoDetail}
              clipRef={clipRefs[index]}
              premiumLock
              clipGroupId={clipGroup.id}
              seePrivateNote={seePrivateNote}
            />
          </div>
        );
      });
      return (
        <Fragment>
          {clips}
          {(clipGroup as CommunityClipGroup).status === Status.requestingMore && (
            <ClipCardLoader clipType={clipType} />
          )}
        </Fragment>
      );
    }

    if (clipType === ClipType.closedCommunity) {
      const closedCommunityClips = clipGroup.clips as CommunityClip[];
      const clips = closedCommunityClips.map((clip: Clip, index: number) => {
        const _redirectToVideoDetail = (path: string) => {
          const horizontalScroll = index;
          redirectToVideoDetail(path, horizontalScroll);
        };

        return (
          <div key={index} style={{ pointerEvents: isDragging ? 'none' : undefined }}>
            <ClipCard
              key={index}
              clip={clip}
              clipType={clipType}
              isFirst={!uncollapsed && index === 0}
              isLast={!uncollapsed && index === closedCommunityClips.length - 1}
              redirectToVideoDetail={_redirectToVideoDetail}
              clipRef={clipRefs[index]}
              premiumLock
              clipGroupId={clipGroup.id}
              seePrivateNote={seePrivateNote}
            />
          </div>
        );
      });
      return (
        <Fragment>
          {clips}
          {(clipGroup as CommunityClipGroup).status === Status.requestingMore && (
            <ClipCardLoader clipType={clipType} />
          )}
        </Fragment>
      );
    }

    if (clipType === ClipType.favourite) {
      const favouriteClips = clipGroup.clips as FavouriteClip[];
      const clips = favouriteClips.map((clip: Clip, index: number) => {
        const _redirectToVideoDetail = (path: string) => {
          const horizontalScroll = index;
          redirectToVideoDetail(path, horizontalScroll);
        };

        return (
          <div key={index} style={{ pointerEvents: isDragging ? 'none' : undefined }}>
            <ClipCard
              key={index}
              clip={clip}
              clipType={clipType}
              isFirst={!uncollapsed && index === 0}
              isLast={!uncollapsed && index === favouriteClips.length - 1}
              redirectToVideoDetail={_redirectToVideoDetail}
              clipRef={clipRefs[index]}
              seePrivateNote={seePrivateNote}
            />
          </div>
        );
      });

      return (
        <Fragment>
          {clips}
          {(clipGroup as FavouriteClipGroup).status === Status.requestingMore && (
            <ClipCardLoader clipType={clipType} />
          )}
        </Fragment>
      );
    }
  };

  const clipsEmpty = clipGroup.clips.length === 0;

  return (
    <div
      className="VideoSwimLine__wrapper"
      ref={clipLineRef}
      style={{ display: clipsEmpty ? 'none' : undefined }}
    >
      {!uncollapsed && (
        <ScrollButton
          type={ScrollButtonType.left}
          handleClick={scrollLeft}
          isInvisible={!isScrollable || scrolledMin}
        />
      )}

      <div className={classHandler('VideoSwimLine', applicationMode)}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="VideoSwimLine__title" onClick={titleOnClick}>
            {renderTitle()}
          </div>
          {clipType === ClipType.myVideo && (
            <div
              ref={tooltipRef}
              onClick={handleTooltipVisibility}
              style={{ position: 'relative' }}
            >
              <div
                onClick={handleTooltipVisibility}
                className="ClipController__moreButton"
                style={{ marginRight: '36px' }}
                ref={tooltipRef}
              >
                <Icon
                  size="15px"
                  name="more"
                  type={iconTypeHandler(applicationMode, IconType.white)}
                />
              </div>
              {renderTooltip()}
            </div>
          )}
        </div>
        <div
          className={`VideoSwimLine__clips${
            uncollapsed ? ' VideoSwimLine__clips--uncollapsed' : ''
          }`}
          ref={clipsRef}
        >
          {renderClips()}
        </div>
      </div>
      {!uncollapsed && (
        <ScrollButton
          type={ScrollButtonType.right}
          handleClick={scrollRight}
          isInvisible={!isScrollable || scrolledMax}
        />
      )}
      <AlertModal
        isVisible={isDeleteConfirmVisible}
        handleClose={handleDeleteMatch}
        confirmBtn={true}
        cancelBtn={true}
        text={t('deleteMatch')}
        confirmText={t('delete')}
      />
    </div>
  );
};
