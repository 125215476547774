import { RootState, Sport, Status } from '../types';

export const getSportsStatus = (state: RootState): Status => state.sports.status;

export const getSportsErrorMsg = (state: RootState): string | null => state.sports.errorMsg;

export const getSports = (state: RootState): Sport[] => state.sports.sports;

export const getSportsNames = (state: RootState): string[] =>
  state.sports.sports.map((sport: Sport) => sport.name);
