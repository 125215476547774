import moment from 'moment';
import {
  ErrorResponse,
  Gender,
  MatchDetail,
  MatchDetailResponse,
  MatchesResponse,
  Referee,
  TimeStamp,
  VideoStatus,
} from '../types';
import {
  GET_MATCH_DETAIL_URL,
  GET_MATCHES_URL,
  POST_MATCH_DETAIL_URL,
  POST_NEW_MATCH_DETAIL_URL,
} from './index';
import { getJwtFromLocalStorage, request, transformMatchDetail } from '../utils';
import { Pagination } from '../types/Pagination';
import { Note } from '../types/notes';
import { GET_SHARED_MATCH_CLIPS_URL, GET_SHARED_MATCH_URL, VALIDATE_MATCH_URL_URL } from './routes';

export const getNewMatchDetail = (): any => {
  return null;
};

export const _getMatches = async (
  filter: string,
  pagination: Pagination,
  sort: 'ASC' | 'DESC'
): Promise<MatchesResponse> => {
  const response = await request(GET_MATCHES_URL(filter, pagination, sort), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });

  return response.json();
};

export const shareMatch = async (clipId: number) => {
  return setMatchShared(clipId);
};

export const getMatchClips = async (shareToken: string, filter: string) => {
  const response = await request(GET_SHARED_MATCH_CLIPS_URL(shareToken, filter), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });

  return response.json();
};

const setMatchShared = async (matchId: number) => {
  const response = await request(GET_SHARED_MATCH_URL(matchId), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });

  return response.json();
};

export const _getMatchDetail = async (
  matchId: number
): Promise<MatchDetailResponse | ErrorResponse> => {
  const response = await request(GET_MATCH_DETAIL_URL(matchId), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });

  return response.json();
};

export const postMatchDetail = async (
  matchDetail: MatchDetail,
  currentSport: number | undefined
): Promise<any> => {
  const response = await request(POST_MATCH_DETAIL_URL(matchDetail.id), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
    body: JSON.stringify({
      id: matchDetail.id,
      date: transformDate(matchDetail.date, moment(matchDetail.time).format('HH:mm')),
      location: matchDetail.location,
      gender: matchDetail.gender === Gender.men ? 'male' : 'female',
      competition: matchDetail.competition,
      categorie: matchDetail.category,
      homeTeam: matchDetail?.teams?.firstTeam || '',
      guestTeam: matchDetail?.teams?.secondTeam || '',
      teamOfrefsType: matchDetail.refereeTeamType,
      referees: transformReferees(matchDetail.referees),
      sport: currentSport,
      accounting: matchDetail.matchAccounting,
      // dont override video url when video is already uploading
      videoUrl:
        matchDetail.videoStatus === VideoStatus.uploading ? undefined : matchDetail.videoUrl,
      syncTime: matchDetail.syncTime,
      clips: transformClips(matchDetail.clips),
      myRole: matchDetail.myRole,
      supevisor: matchDetail.chiefReferee,
      notes: transformNotes(matchDetail.notes),
      upload: Boolean(matchDetail.videoLink),
    }),
  });

  return response.json();
};

export const postMatchDetailClipsOnly = async (matchDetail: {
  id: number;
  clips: TimeStamp[];
}): Promise<any> => {
  const response = await request(POST_MATCH_DETAIL_URL(matchDetail.id), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
    body: JSON.stringify({
      id: matchDetail.id,
      clips: transformClips(matchDetail.clips),
    }),
  });

  return response.json();
};

export const postNewMatchDetail = async (
  matchDetail: MatchDetail,
  currentSport: number | undefined
): Promise<MatchDetail | ErrorResponse> => {
  const response = await request(POST_NEW_MATCH_DETAIL_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
    body: JSON.stringify({
      date: transformDate(matchDetail.date, moment(matchDetail.time).format('HH:mm')),
      location: matchDetail.location,
      gender: matchDetail.gender === Gender.men ? 'male' : 'female',
      competition: matchDetail.competition,
      categorie: matchDetail.category,
      homeTeam: matchDetail.teams.firstTeam,
      guestTeam: matchDetail.teams.secondTeam,
      teamOfrefsType: matchDetail.refereeTeamType,
      referees: transformReferees(matchDetail.referees),
      sport: currentSport,
      accounting: matchDetail.matchAccounting,
      videoUrl: matchDetail.videoUrl,
      syncTime: matchDetail.syncTime,
      clips: transformClips(matchDetail.clips),
      myRole: matchDetail.myRole,
      supevisor: matchDetail.chiefReferee,
      notes: transformNotes(matchDetail.notes),
      upload: Boolean(matchDetail.videoLink),
    }),
  });

  return transformMatchDetail(await response.json());
};

export const removeMatchDetail = async (matchId: number): Promise<any> => {
  const response = await request(GET_MATCH_DETAIL_URL(matchId), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });

  return response.json();
};

export const isValidMatchUrl = async (url: string): Promise<boolean> => {
  try {
    const res = await request(VALIDATE_MATCH_URL_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${getJwtFromLocalStorage()}`,
      },
      body: JSON.stringify({ url }),
    });
    return res.status === 200;
  } catch (_) {
    return false;
  }
};

/**
 * Clips transformation from API to Local model
 *
 * @param clips clips
 */
const transformClips = (clips: any): TimeStamp[] => {
  let resultClips: TimeStamp[] = [];

  if (clips) {
    resultClips = clips.map((clip: any) => {
      return {
        id: clip.id,
        name: clip.name,
        time: clip.time,
        startTime: clip.startTime,
        endTime: clip.endTime,
        syncTime: clip.syncTime,
        ...(clip.clipCategories ? { clipCategories: clip.clipCategories } : {}),
      };
    });
  }
  // console.log(resultClips, 'postMatchDetail');
  return resultClips;
};

const transformReferees = (referees: Referee[]) => {
  if (referees.length === 0 || Object.keys(referees).length === 0) {
    return {};
  }
  const refereeRoles = referees?.map((referee: Referee) => referee.role);
  const refereeNames = referees?.map((referee: Referee) => referee.name);

  const resultReferees: any = {};
  refereeRoles.forEach(
    (refereeRole: string, index: number) => (resultReferees[refereeRole] = refereeNames[index])
  );

  return resultReferees;
};

const transformNotes = (notes: Note[]) => {
  const noteTexts = notes.map((note: Note) => ({
    text: note.text,
  }));

  return noteTexts;
};

const transformDate = (date: Date, time: any): Date => {
  const resultDate = new Date(date);
  resultDate.setMinutes(parseInt(time.split(':')[1], 0));
  resultDate.setHours(parseInt(time.split(':')[0], 0));

  return resultDate;
};
