import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  applyVideoProxy,
  getFileWithoutExtension,
  isErrorResponse,
  useDownloadVideoHandler,
} from '.';
import { setLoginRequiredModalVisibility, showNotification } from '../actions';
import { downloadClip } from '../api';
import { getUser } from '../selectors';
import { ClipDetailWithMatch, DownloadClipResponse } from '../types';
import { isAndroidApp, isIOSApp } from './getPlatform';
import { saveAs } from 'file-saver';

type ReturnType = {
  handleDownload: () => Promise<void>;
  isProcessingVideo: boolean;
};

const useDownloadClip = (clipDetail: ClipDetailWithMatch | null): ReturnType => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const iOS = isIOSApp();
  const android = isAndroidApp();
  const user = useSelector(getUser);
  const downloadVideoBridgeHandler = useDownloadVideoHandler;

  const [isProcessingVideo, setProcessingVideo] = useState<boolean>(false);

  const [previousVideoValues, setPreviousVideoValues] = useState<any>({
    startTime: clipDetail?.startTime,
    time: clipDetail?.time,
    endTime: clipDetail?.endTime,
    name: clipDetail?.name,
  });

  const isClipDetailSameAsPreviousDownload =
    previousVideoValues.startTime === clipDetail?.startTime &&
    previousVideoValues.time === clipDetail?.time &&
    previousVideoValues.endTime === clipDetail?.endTime &&
    previousVideoValues.name === clipDetail?.name &&
    clipDetail?.videoClipUrl;

  const downloadVideo = ({ videoClipUrl, videoClipName }: DownloadClipResponse) => {
    if (!videoClipUrl || !videoClipName) {
      // tslint:disable-next-line
      console.error('Video clip url or video clip name missing! Cancelling download...');
      dispatch(showNotification(t('onDownloadVideoFailed'), 'error'));
      return;
    }

    if (iOS || android) {
      dispatch(showNotification(t('downloadStarted'), 'info'));

      downloadVideoBridgeHandler(
        videoClipUrl,
        getFileWithoutExtension(videoClipName),
        (response: any) => {
          if (response.status === 0) {
            dispatch(showNotification(t(response.data), 'success', 3000));
          } else if (response.status === 1) {
            dispatch(showNotification(t(response.error), 'error'));
          }
        }
      );

      return;
    }

    const url = applyVideoProxy(videoClipUrl);
    saveAs(url, videoClipName);
    dispatch(showNotification(t('onDownloadVideoSuccess'), 'success', 3000));

    return;
  };

  const handleDownload = async () => {
    if (!clipDetail) return;
    if (!user) {
      dispatch(setLoginRequiredModalVisibility(true));
      return;
    }
    if (isClipDetailSameAsPreviousDownload) {
      return downloadVideo(clipDetail);
    }

    if (isProcessingVideo) return;

    setProcessingVideo(true);
    dispatch(showNotification(t('processing'), 'info'));

    try {
      const response = await downloadClip(
        clipDetail.id,
        clipDetail.startTime,
        clipDetail.time,
        clipDetail.endTime,
        clipDetail.name
      );

      if (isErrorResponse(response)) {
        throw response;
      }

      downloadVideo(response);

      setPreviousVideoValues({
        startTime: clipDetail.startTime,
        time: clipDetail.time,
        endTime: clipDetail.endTime,
        name: clipDetail.name,
      });
    } catch (_) {
      dispatch(showNotification(t('onDownloadVideoFailed'), 'error'));
    } finally {
      setProcessingVideo(false);
    }
  };

  return {
    handleDownload,
    isProcessingVideo,
  };
};

export default useDownloadClip;
