import React, { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { InputLabel, MenuItem, Select, TextField, FormControl } from '@material-ui/core';

import { Competition, Gender } from '../../types';
import { ModalType } from '../../containers/CompetitionsPage';
import { SaveButtonsWrapper } from '../SaveButtonsWrapper';

type CompetitionEditContentProps = {
  competition: Competition;
  handleCompetition: any;
  handleSave: any;
  modalType: ModalType;
};

export const CompetitionEditContent: FunctionComponent<CompetitionEditContentProps> = ({
  competition,
  handleCompetition,
  handleSave,
  modalType,
}) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="CompetitionsPage__modalHeader">
        {modalType === ModalType.new ? t('createCompetition') : t('editCompetition')}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '0 20px',
        }}
      >
        <TextField
          name="name"
          label={t('compName')}
          value={competition.name}
          onChange={handleCompetition}
          style={{ margin: '10px 0', width: '100%' }}
          autoComplete="off"
        />
        <FormControl style={{ margin: '10px 0', width: '100%' }}>
          <InputLabel id="gender-label">{t('gender')}</InputLabel>
          <Select
            name="gender"
            labelId="gender-label"
            value={competition.gender}
            onChange={handleCompetition}
            style={{ width: '100%' }}
          >
            <MenuItem value={Gender.men}>{t('men')}</MenuItem>
            <MenuItem value={Gender.women}>{t('women')}</MenuItem>
          </Select>
        </FormControl>
      </div>
      <SaveButtonsWrapper
        primaryButton={{
          label: t('save'),
          onClick: handleSave,
        }}
        style={{ paddingBottom: '1em', borderTop: 'none' }}
      />
    </Fragment>
  );
};
