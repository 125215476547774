import { GET_CLIP_CATEGORIES_URL } from './index';
import { getJwtFromLocalStorage, request } from '../utils';
import { ClipCategoriesResponse, ErrorResponse } from '../types';

export const getClipCategories = async (
  sport: number
): Promise<ClipCategoriesResponse | ErrorResponse> => {
  const token = getJwtFromLocalStorage();
  const response = await request(GET_CLIP_CATEGORIES_URL(sport), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  return response.json();
};
