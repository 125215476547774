import React, { FunctionComponent, MouseEventHandler } from 'react';

export enum IconType {
  default = '',
  light = 'Light',
  white = 'White',
  red = 'Red',
  grey = 'Grey',
}

type IconProps = {
  name: string;
  type: IconType;
  size?: string;
  rotation?: string;
  style?: object;
  onClick?: MouseEventHandler;
};

export const Icon: FunctionComponent<IconProps> = ({
  name,
  size,
  rotation,
  type,
  style,
  onClick,
}) => {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/icons/${name}${type}.svg`}
      alt={name}
      width={size}
      style={{ ...style, transform: `rotate(${rotation})` }}
      onClick={onClick}
    />
  );
};
