import React from 'react';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal';
import { useSelector } from 'react-redux';
import { getApplicationBreakpointState, getApplicationModeState } from '../../selectors';
import { classHandler, useModalKeyboardShortcuts } from '../../utils';
import './DeleteAndNextModal.scss';
import { ApplicationBreakpoint } from '../../types';

interface DeleteAndNextModalProps {
  isConfirmVisible: boolean;
  handleDelete: (result: boolean, goToNext?: boolean) => void;
  showNext: boolean;
  handleClose: () => void;
}

export const DeleteAndNextModal: React.FC<DeleteAndNextModalProps> = ({
  isConfirmVisible,
  handleDelete,
  showNext,
  handleClose,
}) => {
  const { t } = useTranslation();
  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  useModalKeyboardShortcuts(isConfirmVisible, handleClose, () => handleDelete(showNext));
  return (
    <Modal
      isVisible={isConfirmVisible}
      width="fit-content"
      height="fit-content"
      handleClose={handleClose}
    >
      <div className="AlertModal__text">{t('deleteClip')}</div>
      <div
        className={`${classHandler(
          'SaveButtonsWrapper',
          applicationMode
        )} DeleteAndNextModal__wrapper${
          applicationBreakpoint !== ApplicationBreakpoint.desktop
            ? ' DeleteAndNextModal__wrapper--mobile'
            : ''
        }`}
        style={{
          marginInline: 0,
          padding: '20px 0 0 0',
        }}
      >
        <Button
          className="DeleteAndNextModal__Button"
          onClick={handleClose}
          label={t('cancel')}
          type="secondary"
        />
        <Button
          className="DeleteAndNextModal__Button"
          onClick={() => {
            handleClose();
            handleDelete(false);
          }}
          label={t('delete')}
          type="secondary"
        />
        {showNext && (
          <Button
            className={`DeleteAndNextModal__Button DeleteAndNextModal__Button--next`}
            onClick={() => {
              handleClose();
              handleDelete(true);
            }}
            label={t('DeleteAndNext')}
            type="primary"
          />
        )}
      </div>
    </Modal>
  );
};
