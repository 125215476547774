import { Dispatch } from 'redux';

import {
  SET_CLIP_CATEGORIES_REQUESTING,
  SET_CLIP_CATEGORIES_ERROR,
  SET_CLIP_CATEGORIES_SUCCESS,
} from '../constants';
import { getClipCategories } from '../api/clipCategories';
import { ClipCategoriesResponse, ClipCategory, ErrorResponse } from '../types';
import { isErrorResponse } from '../utils';

/**
 * Action for clip categories setting (REQUESTING)
 *
 */
export const setClipCategoriesRequesting = () => ({ type: SET_CLIP_CATEGORIES_REQUESTING });

/**
 * Action for clip categories setting (ERROR)
 *
 * @param errorMsg Error message
 */
export const setClipCategoriesError = (errorMsg: string) => ({
  type: SET_CLIP_CATEGORIES_ERROR,
  payload: { errorMsg },
});

/**
 * Action for clip categories setting (SUCCESS)
 *
 * @param clipCategories array of clip categories
 */
export const setClipCategoriesSuccess = (clipCategories: ClipCategory[]) => ({
  type: SET_CLIP_CATEGORIES_SUCCESS,
  payload: { clipCategories },
});

/**
 * Action for clip categories setting
 *
 */
export const setClipCategories = (sport: number) => (dispatch: Dispatch) => {
  dispatch(setClipCategoriesRequesting());

  getClipCategories(sport).then((response: ClipCategoriesResponse | ErrorResponse) => {
    if (isErrorResponse(response)) {
      setClipCategoriesError('Failed');
    } else {
      const clipCategoriesResponse = response as ClipCategoriesResponse;

      dispatch(setClipCategoriesSuccess(clipCategoriesResponse.clipCategories));
    }
  });
};
