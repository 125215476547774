import {
  MatchDetail,
  TimeStamp,
  Competition,
  // Category,
  CommunityClipGroup,
  ClipCategory,
} from '../types';

const sortClipByTime = (a: TimeStamp, b: TimeStamp): number => {
  if (a.time < b.time) {
    return -1;
  }
  if (a.time > b.time) {
    return 1;
  }
  return 0;
};

// const sortCategoryById = (a: Category, b: Category): number => {
//   if (a.id < b.id) {
//     return -1;
//   }
//   if (a.id > b.id) {
//     return 1;
//   }
//   return 0;
// };

export const sortMatchDetailClips = (matchDetail: MatchDetail): MatchDetail => {
  const sortedClips = matchDetail.clips.sort(sortClipByTime);

  return { ...matchDetail, clips: sortedClips };
};

export const sortCompetitionCategories = (competitions: Competition[]): Competition[] => {
  return competitions.map((competition: Competition) => {
    return {
      ...competition,
      // categories: competition.categories.sort(sortCategoryById),
      categories: competition.categories,
    };
  });
};

export const sortCommunityClipGroupsByTitle = (a: CommunityClipGroup, b: CommunityClipGroup) => {
  const titleA = a.title.toUpperCase();
  const titleB = b.title.toUpperCase();
  if (titleA < titleB) {
    return -1;
  }
  if (titleA > titleB) {
    return 1;
  }

  return 0;
};

export const sortClipCategoryByType = (a: ClipCategory, b: ClipCategory) => {
  const typeA = a.type.toUpperCase();
  const typeB = b.type.toUpperCase();
  if (typeA < typeB) {
    return -1;
  }
  if (typeA > typeB) {
    return 1;
  }

  return 0;
};
