import {
  Gender,
  MatchAccountingReceivedAs,
  MatchDetail,
  MatchDetailResponse,
  Referee,
  TimeStamp,
  VideoStatus,
} from '../types';

export const transformMatchDetail = (matchDetailResponse: MatchDetailResponse): MatchDetail => {
  const date = new Date(matchDetailResponse.matchDetail.date);

  return {
    id: matchDetailResponse.matchDetail.id,
    date,
    time: date,
    location: matchDetailResponse.matchDetail.location,
    gender: matchDetailResponse.matchDetail.gender === 'male' ? Gender.men : Gender.women,
    competition: matchDetailResponse.matchDetail.competition,
    category: matchDetailResponse.matchDetail.categorie,
    teams: {
      firstTeam: matchDetailResponse.matchDetail.homeTeam,
      secondTeam: matchDetailResponse.matchDetail.guestTeam,
    },
    refereeTeamType: matchDetailResponse.matchDetail.teamOfrefsType,
    referees: transformReferees(matchDetailResponse.matchDetail.referees),
    myRole: matchDetailResponse.matchDetail.myRole,
    sport: matchDetailResponse.matchDetail.sport,
    matchAccounting: {
      id: matchDetailResponse.matchDetail.accounting?.id || 0,
      reward: matchDetailResponse.matchDetail.accounting?.reward || 0,
      workdayBonus: matchDetailResponse.matchDetail.accounting?.workdayBonus || 0,
      travelCost: matchDetailResponse.matchDetail.accounting?.travelCost || 0,
      dietary: matchDetailResponse.matchDetail.accounting?.dietary || 0,
      receivedAs:
        matchDetailResponse.matchDetail.accounting?.receivedAs ||
        MatchAccountingReceivedAs.bankAccount,
    },
    videoUrl: matchDetailResponse.matchDetail.videoUrl || '',
    videoStatus: matchDetailResponse.matchDetail.videoStatus || VideoStatus.noVideo,
    syncTime: matchDetailResponse.matchDetail.syncTime || 0,
    clips: transformClips(matchDetailResponse.matchDetail.clips),
    notes: matchDetailResponse.matchDetail.notes,
    chiefReferee: matchDetailResponse.matchDetail.supevisor,
  };
};

/**
 * Clips transformation from API to Local model
 *
 * @param clips clips
 */
export const transformClips = (clips: any): TimeStamp[] => {
  let resultClips: TimeStamp[] = [];

  if (clips) {
    resultClips = clips.map((clip: TimeStamp) => {
      return {
        id: clip.id,
        name: clip.name,
        time: clip.time,
        startTime: clip.startTime,
        endTime: clip.endTime,
        syncTime: clip.syncTime,
      };
    });
  }

  return resultClips;
};

/**
 * Referees transformation from API to Local model
 *
 * @param referees object of referees
 */
const transformReferees = (referees: any): Referee[] => {
  let resultReferees: Referee[] = [];

  if (referees) {
    const refereeRoles: string[] = Object.keys(referees);
    const refereeNames: string[] = Object.values(referees);

    resultReferees = refereeRoles.map((refereeRole: string, index: number) => {
      return { id: index, role: refereeRole, name: refereeNames[index] || '' };
    });
  }

  return resultReferees;
};
