import { GET_LANGUAGES_URL } from './';
import { getJwtFromLocalStorage, request } from '../utils';
import { ErrorResponse, LanguagesResponse } from '../types';

export const getLanguages = async (): Promise<LanguagesResponse | ErrorResponse> => {
  const response = await request(GET_LANGUAGES_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });

  return response.json();
};
