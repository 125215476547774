import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MobileTitle } from '../../components/MobileTItle';
import { Title } from '../../components/Title';
import { getApplicationBreakpointState, getApplicationModeState } from '../../selectors';
import { ApplicationBreakpoint, ApplicationMode } from '../../types';
import { Icon, IconType } from '../../components/Icon';
import { useOpenBrowserHandler, useOpenMailHandler } from '../../utils';

import './AboutPage.scss';
import { setInfoModalText, setInfoModalVisibility } from '../../actions';
import { isIOSApp } from '../../utils/getPlatform';

type CardItemProps = {
  text: string;
  link?: string;
  withArrow?: boolean;
  wordBreak?: boolean;
  storeIcons?: boolean;
  openMailClient?: boolean;
  email?: string;
};

const CardItem: FunctionComponent<CardItemProps> = ({
  text,
  link,
  withArrow,
  wordBreak,
  storeIcons,
  children,
  openMailClient,
  email,
}) => {
  const classNames = [];
  if (wordBreak) classNames.push('AboutPage__wordBreak');
  if (storeIcons) classNames.push('AboutPage__storeIcons');
  const content = (
    <span className={classNames.join(' ')}>
      {children}
      {withArrow && (
        <Icon size="18px" name="arrowForward" type={IconType.red} style={{ marginLeft: '0.5em' }} />
      )}
    </span>
  );

  /* opening browser/mail client iOS */
  const iOS = isIOSApp();
  const iOSOpenBrowserHandler = useOpenBrowserHandler;
  const dispatch = useDispatch();
  const iOSOpenMailHandler = useOpenMailHandler;

  const handleClick = () => {
    if (link && iOS) {
      iOSOpenBrowserHandler(link, (result: any) => {
        if (result.status === 1) {
          dispatch(setInfoModalText(result.error));
          dispatch(setInfoModalVisibility(true));
        }
      });
    }
    if (openMailClient && iOS && email) {
      iOSOpenMailHandler(email, (result: any) => {
        if (result.status === 1) {
          dispatch(setInfoModalText(result.error));
          dispatch(setInfoModalVisibility(true));
        }
      });
    }
  };

  const linkOpener = !iOS ? (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {content}
    </a>
  ) : (
    <span onClick={handleClick}>{content}</span>
  );

  return (
    <div
      onClick={handleClick}
      className={`AboutPage__cardItem ${storeIcons ? 'AboutPage__flexWrap' : ''}`}
    >
      <div className="AboutPage__cardItemText">{text}</div>
      <div className="AboutPage__cardItemContent">{link ? linkOpener : content}</div>
    </div>
  );
};

type CardProps = {
  title?: string;
};

const Card: FunctionComponent<CardProps> = ({ title, children }) => (
  <div className="AboutPage__card">
    {title && <div className="AboutPage__cardTitle">{title}</div>}
    {children}
  </div>
);

export const AboutPage: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const iOS = isIOSApp();
  const iOSOpenBrowserHandler = useOpenBrowserHandler;

  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const AboutPageClasses = ['AboutPage'];
  if (applicationMode === ApplicationMode.dark) AboutPageClasses.push('AboutPage--dark');

  const webpagesLink = 'https://www.refereerecorder.com/';
  const faqLink = 'https://www.refereerecorder.com/faq/';
  const email = 'info@refereerecorder.com';
  const needHelpLink = `mailto: ${email}`;
  const eshopLink = 'https://www.refereerecorder.com/shop/';
  const facebookLink = 'https://www.facebook.com/Referee-Recorder-102621422109869';
  const instagramLink = 'https://www.instagram.com/referee.recorder/';
  const termsAndConditionsLink = 'https://www.refereerecorder.com/terms/';
  const privacyPolicyLink = 'https://www.refereerecorder.com/privacy-policy/';
  // const googlePlayLink = webpagesLink;
  // const appStoreLink = webpagesLink;
  const contactLink = 'https://www.refereerecorder.com/contact';
  const dispatch = useDispatch();

  const handleClick = (link: string) => {
    iOSOpenBrowserHandler(link, (result: any) => {
      if (result.status === 1) {
        dispatch(setInfoModalText(result.error));
        dispatch(setInfoModalVisibility(true));
      }
    });
  };

  const FacebookIcon = () => {
    return !iOS ? (
      <a
        href={facebookLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginLeft: '0.5em' }}
      >
        <Icon
          size="35px"
          name="facebook"
          type={applicationMode === ApplicationMode.dark ? IconType.white : IconType.default}
        />
      </a>
    ) : (
      <span onClick={() => handleClick(facebookLink)} style={{ marginLeft: '0.5em' }}>
        <Icon
          size="35px"
          name="facebook"
          type={applicationMode === ApplicationMode.dark ? IconType.white : IconType.default}
        />
      </span>
    );
  };

  const InstagramIcon = () => {
    return !iOS ? (
      <a
        href={instagramLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginLeft: '0.5em' }}
      >
        <Icon
          size="35px"
          name="instagram"
          type={applicationMode === ApplicationMode.dark ? IconType.white : IconType.default}
        />
      </a>
    ) : (
      <span onClick={() => handleClick(instagramLink)} style={{ marginLeft: '0.5em' }}>
        <Icon
          size="35px"
          name="instagram"
          type={applicationMode === ApplicationMode.dark ? IconType.white : IconType.default}
        />
      </span>
    );
  };

  return (
    <div className={AboutPageClasses.join(' ')}>
      {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
        <Title title={t('about')} />
      ) : (
        <MobileTitle title={t('about')} />
      )}
      <div className="AboutPage__container">
        <div className="AboutPage__content">
          <Card title={t('aboutMoreInfo')}>
            <CardItem text={t('aboutWebPages')} link={webpagesLink} withArrow wordBreak>
              RefereeRecorder.com
            </CardItem>
            <CardItem text={t('aboutFAQ')} link={faqLink} withArrow />
            <CardItem text={t('aboutSocialNetworks')}>
              <FacebookIcon />
              <InstagramIcon />
            </CardItem>
            {!iOS && (
              <CardItem text={t('aboutNeedHelp')} link={needHelpLink} withArrow wordBreak>
                {email}
              </CardItem>
            )}
            {iOS && (
              <CardItem text={t('aboutNeedHelp')} email={email} openMailClient>
                {email}
              </CardItem>
            )}
          </Card>
          <Card title={t('aboutEshop')}>
            <CardItem text={t('aboutBuyRecorder')} link={eshopLink} withArrow>
              {t('aboutVisitEshop')}
            </CardItem>
          </Card>
          <Card title={t('aboutApplication')}>
            <CardItem text={t('aboutProvider')}>{t('provider')}</CardItem>
            <CardItem
              text={t('aboutGeneralTermsAndConditions')}
              link={termsAndConditionsLink}
              withArrow
            />
            <CardItem text={t('aboutPrivacyPolicy')} link={privacyPolicyLink} withArrow />
            {/* <CardItem text={t('aboutRateAppNice')} storeIcons>
            { googlePlayIcon }
            { appStoreIcon }
          </CardItem> */}
            <CardItem text={t('aboutRateAppBad')} link={contactLink} withArrow />
          </Card>
        </div>
      </div>
    </div>
  );
};
