import { Settings } from '../types';
import { PUT_SETTINGS_URL } from './index';
import { getJwtFromLocalStorage, request } from '../utils';

export const putSettings = async (settings: Settings) => {
  const response = await request(PUT_SETTINGS_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
    body: JSON.stringify({
      settings: {
        country: settings.country.id,
        sports: settings.sports.id,
        appLanguage: settings.language.id,
        darkMode: settings.darkMode,
        videoDefaultSpareTime: settings.defaultVideoStartingTime,
        videoDefaultEndingSpareTime: settings.defaultVideoEndingTime,
        statisticsFrom: settings.statisticsFrom,
        statisticsTo: settings.statisticsTo,
      },
    }),
  });

  return response.json();
};
