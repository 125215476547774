import { Dispatch } from 'redux';
import {
  SET_STATISTICS_DATA_REQUESTING,
  SET_STATISTICS_DATA_SUCCESS,
  SET_STATISTICS_ERROR,
  SET_STATISTICS_REQUESTING,
  SET_STATISTICS_SUCCESS,
} from '../constants';
import { Statistics, StatisticsData } from '../types';
import { getStatistics, getStatisticsData } from '../api';

export const setStatisticsRequesting = () => ({ type: SET_STATISTICS_REQUESTING });

export const setStatisticsError = (errorMsg: string) => ({
  type: SET_STATISTICS_ERROR,
  payload: { errorMsg },
});

export const setStatisticsSuccess = (statistics: Statistics[]) => ({
  type: SET_STATISTICS_SUCCESS,
  payload: { statistics },
});

export const setStatistics = () => (dispatch: Dispatch) => {
  dispatch(setStatisticsRequesting());

  getStatistics().then((response: any) => {
    dispatch(setStatisticsSuccess(response));
  });
};

export const setStatisticsDataRequesting = () => ({ type: SET_STATISTICS_DATA_REQUESTING });

export const setStatisticsDataSuccess = (statisticsData: StatisticsData) => ({
  type: SET_STATISTICS_DATA_SUCCESS,
  payload: { statisticsData },
});

export const setStatisticsData = (address: string) => (dispatch: Dispatch) => {
  dispatch(setStatisticsDataRequesting());

  getStatisticsData(address).then((response: any) => {
    const allowedKeys = [
      'matches',
      'matchesInCompetitions',
      'colleagues',
      'rolesInTwoPersons',
      'rolesInThreePersons',
      'fromNotes',
      'numOfTechnicalFouls',
      'numOfUnsportsmenlikeFouls',
      'countriesContest',
    ];
    const res: any = {
      others: [],
    };
    Object.keys(response).forEach((key: string) => {
      if (allowedKeys.includes(key)) {
        res[key] = response[key];
      } else {
        const categoryData: any = {};
        categoryData[key] = response[key];
        res.others.push(categoryData);
      }
    });
    dispatch(setStatisticsDataSuccess(res));
  });
};
