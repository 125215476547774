import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClipDetail, getUser } from '../../selectors';
import { ThumbDown, ThumbUp } from '@material-ui/icons';
import { reactToClip } from '../../actions/clipDetailActions';
import './ThumbsUpDown.scss';
import { ClipReaction } from '../../types';
import { useTranslation } from 'react-i18next';
import { setLoginRequiredModalVisibility } from '../../actions';

const ThumbsUpDown = () => {
  const clip = useSelector(getClipDetail);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const [totalRating, setTotalRating] = useState(0);

  const handleReact = (reaction: ClipReaction) => () => {
    if (!clip) return;

    if (!user) {
      dispatch(setLoginRequiredModalVisibility(true));
      return;
    }

    let isUnreact = false;

    if (
      (clip.ratings.userReaction === 'liked' && reaction === ClipReaction.like) ||
      (clip.ratings.userReaction === 'disliked' && reaction === ClipReaction.dislike)
    ) {
      dispatch(reactToClip(clip.id, ClipReaction.unreact));
      isUnreact = true;
    } else {
      dispatch(reactToClip(clip.id, reaction));
    }
    handleTotalRating(reaction, clip.ratings.userReaction, isUnreact);
  };

  const handleTotalRating = (
    reaction: ClipReaction,
    userReaction: 'liked' | 'disliked' | null,
    isUnreact: boolean
  ) => {
    if (userReaction === 'liked' && reaction === ClipReaction.dislike) {
      setTotalRating(prev => prev - 2);
      return;
    }

    if (userReaction === 'disliked' && reaction === ClipReaction.like) {
      setTotalRating(prev => prev + 2);
      return;
    }

    switch (reaction) {
      case ClipReaction.like: {
        if (isUnreact) {
          setTotalRating(prev => prev - 1);
        } else {
          setTotalRating(prev => prev + 1);
        }
        break;
      }

      case ClipReaction.dislike: {
        if (isUnreact) {
          setTotalRating(prev => prev + 1);
        } else {
          setTotalRating(prev => prev - 1);
        }
        break;
      }
    }
  };

  useEffect(() => {
    if (clip?.totalRating) {
      setTotalRating(clip?.totalRating);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ThumbsUpDown">
      <h4>{t('clipReactions')}</h4>
      <div className="ThumbsUpDown__thumbs">
        <div className="ThumbsUpDown__buttons">
          <div
            className={
              'ThumbsUpDown__reaction' +
              (clip?.ratings.userReaction === 'liked' ? ' ThumbsUpDown__reaction--active' : '')
            }
            onClick={handleReact(ClipReaction.like)}
          >
            <ThumbUp /> <p>{clip?.ratings?.liked}</p>
          </div>
          <div
            className={
              'ThumbsUpDown__reaction' +
              (clip?.ratings.userReaction === 'disliked' ? ' ThumbsUpDown__reaction--active' : '')
            }
            onClick={handleReact(ClipReaction.dislike)}
          >
            <ThumbDown /> <p>{clip?.ratings?.disliked}</p>
          </div>
        </div>
        <div className="ThumbsUpDown__totalRating">{totalRating}</div>
      </div>
    </div>
  );
};

export default ThumbsUpDown;
