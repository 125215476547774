import { Quality, PlaybackSpeed } from '../types';

export * from './mockedData';
export * from './styles';

export const SET_APPLICATION_MODE = 'SET_APPLICATION_MODE';
export const SET_APPLICATION_BREAKPOINT = 'SET_APPLICATION_BREAKPOINT';
export const SET_FILTER_PANEL_VISIBILITY = 'SET_FILTER_PANEL_VISIBILITY';
export const SET_SIDE_PANEL_VISIBILITY = 'SET_SIDE_PANEL_VISIBILITY';
export const SET_PREMIUM_MODAL_VISIBILITY = 'SET_PREMIUM_MODAL_VISIBILITY';
export const SET_LOGIN_REQUIRED_MODAL_VISIBILITY = 'SET_LOGIN_REQUIRED_MODAL_VISIBILITY';
export const SET_APPLICATION_CURRENCY = 'SET_APPLICATION_CURRENCY';

export const SET_INFO_MODAL_TEXT = 'SET_INFO_MODAL_TEXT';
export const SET_INFO_MODAL_VISIBILITY = 'SET_INFO_MODAL_VISIBILITY';

export const SET_MATCHES_REQUESTING = 'SET_MATCHES_REQUESTING';
export const SET_MATCHES_REQUESTING_MORE = 'SET_MATCHES_REQUESTING_MORE';
export const SET_MATCHES_ERROR = 'SET_MATCHES_ERROR';
export const SET_MATCHES_SUCCESS = 'SET_MATCHES_SUCCESS';

export const SET_MATCH_DETAIL_REQUESTING = 'SET_MATCH_DETAIL_REQUESTING';
export const SET_MATCH_DETAIL_ASYNC_REQUESTING = 'SET_MATCH_DETAIL_ASYNC_REQUESTING';
export const SET_MATCH_DETAIL_ERROR = 'SET_MATCH_DETAIL_ERROR';
export const SET_MATCH_DETAIL_SUCCESS = 'SET_MATCH_DETAIL_SUCCESS';

export const SET_COMPETITIONS_REQUESTING = 'SET_COMPETITIONS_REQUESTING';
export const SET_COMPETITIONS_ERROR = 'SET_COMPETITIONS_ERROR';
export const SET_COMPETITIONS_SUCCESS = 'SET_COMPETITIONS_SUCCESS';

export const SET_MY_CLIPS_REQUESTING = 'SET_MY_CLIPS_REQUESTING';
export const SET_MORE_MY_CLIPS_REQUESTING = 'SET_MORE_MY_CLIPS_REQUESTING';
export const SET_MY_CLIPS_ERROR = 'SET_MY_CLIPS_ERROR';
export const SET_MY_CLIPS_SUCCESS = 'SET_MY_CLIPS_SUCCESS';
export const REMOVE_MY_CLIPS_GROUP = 'REMOVE_MY_CLIPS_GROUP';

export const SET_COMMUNITY_CLIPS_REQUESTING = 'SET_COMMUNITY_CLIPS_REQUESTING';
export const SET_COMMUNITY_CLIPS_ERROR = 'SET_COMMUNITY_CLIPS_ERROR';
export const SET_COMMUNITY_CLIPS_SUCCESS = 'SET_COMMUNITY_CLIPS_SUCCESS';
export const SET_MORE_COMMUNITY_CLIPS_REQUESTING = 'SET_MORE_COMMUNITY_CLIPS_REQUESTING';
export const SET_MORE_COMMUNITY_CLIPS_SUCCESS = 'SET_MORE_COMMUNITY_CLIPS_SUCCESS';

export const SET_FAVOURITE_CLIPS_REQUESTING = 'SET_FAVOURITE_CLIPS_REQUESTING';
export const SET_FAVOURITE_CLIPS_ERROR = 'SET_FAVOURITE_CLIPS_ERROR';
export const SET_MY_FAVOURITE_CLIPS_SUCCESS = 'SET_MY_FAVOURITE_CLIPS_SUCCESS';
export const SET_OTHER_FAVOURITE_CLIPS_SUCCESS = 'SET_OTHER_FAVOURITE_CLIPS_SUCCESS';
export const SET_MORE_FAVOURITE_CLIPS_REQUESTING = 'SET_MORE_FAVOURITE_CLIPS_REQUESTING';
export const SET_MORE_MY_FAVOURITE_CLIPS_SUCCESS = 'SET_MORE_MY_FAVOURITE_CLIPS_SUCCESS';
export const SET_MORE_OTHER_FAVOURITE_CLIPS_SUCCESS = 'SET_MORE_OTHER_FAVOURITE_CLIPS_SUCCESS';

export const SET_CLOSED_COMMUNITY_CLIPS_REQUESTING = 'SET_CLOSED_COMMUNITY_CLIPS_REQUESTING';
export const SET_MORE_CLOSED_COMMUNITY_CLIPS_REQUESTING =
  'SET_MORE_CLOSED_COMMUNITY_CLIPS_REQUESTING';
export const SET_CLOSED_COMMUNITY_CLIPS_SUCCESS = 'SET_CLOSED_COMMUNITY_CLIPS_SUCCESS';
export const SET_CLOSED_COMMUNITY_CLIPS_ERROR = 'SET_CLOSED_COMMUNITY_CLIPS_ERROR';
export const SET_CLOSED_COMMUNITY_NAME = 'SET_CLOSED_COMMUNITY_NAME';
export const SET_MORE_CLOSED_COMMUNITY_CLIPS_SUCCESS = 'SET_MORE_CLOSED_COMMUNITY_CLIPS_SUCCESS';

export const SET_CLIP_FILTERS = 'SET_CLIP_FILTERS';

export const SET_CLIP_DETAIL_REQUESTING = 'SET_CLIP_DETAIL_REQUESTING';
export const SET_CLIP_DETAIL_ERROR = 'SET_CLIP_DETAIL_ERROR';
export const SET_CLIP_DETAIL_SUCCESS = 'SET_CLIP_DETAIL_SUCCESS';

export const SET_CLIP_CATEGORIES_REQUESTING = 'SET_CLIP_CATEGORIES_REQUESTING';
export const SET_CLIP_CATEGORIES_ERROR = 'SET_CLIP_CATEGORIES_ERROR';
export const SET_CLIP_CATEGORIES_SUCCESS = 'SET_CLIP_CATEGORIES_SUCCESS';

export const SET_USER_REQUESTING = 'SET_USER_REQUESTING';
export const SET_USER_ERROR = 'SET_USER_ERROR';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';

export const SET_JWT_REQUESTING = 'SET_JWT_REQUESTING';
export const SET_JWT_ERROR = 'SET_JWT_ERROR';
export const SET_JWT_SUCCESS = 'SET_JWT_SUCCESS';

export const SET_COUNTRIES_REQUESTING = 'SET_COUNTRIES_REQUESTING';
export const SET_COUNTRIES_ERROR = 'SET_COUNTRIES_ERROR';
export const SET_COUNTRIES_SUCCESS = 'SET_COUNTRIES_SUCCESS';

export const SET_SPORTS_REQUESTING = 'SET_SPORTS_REQUESTING';
export const SET_SPORTS_ERROR = 'SET_SPORTS_ERROR';
export const SET_SPORTS_SUCCESS = 'SET_SPORTS_SUCCESS';

export const SET_SETTINGS_REQUESTING = 'SET_SETTINGS_REQUESTING';
export const SET_SETTINGS_ERROR = 'SET_SETTINGS_ERROR';
export const SET_SETTINGS_SUCCESS = 'SET_SETTINGS_SUCCESS';
export const RESET_LANGUAGE = 'RESET_LANGUAGE';

export const SET_LANGUAGES_REQUESTING = 'SET_LANGUAGES_REQUESTING';
export const SET_LANGUAGES_ERROR = 'SET_LANGUAGES_ERROR';
export const SET_LANGUAGES_SUCCESS = 'SET_LANGUAGES_SUCCESS';

export const SET_REFEREE_TEAM_TYPES_REQUESTING = 'SET_REFEREE_TEAM_TYPES_REQUESTING';
export const SET_REFEREE_TEAM_TYPES_ERROR = 'SET_REFEREE_TEAM_TYPES_ERROR';
export const SET_REFEREE_TEAM_TYPES_SUCCESS = 'SET_REFEREE_TEAM_TYPES_SUCCESS';

export const SET_TIPS_REQUESTING = 'SET_TIPS_REQUESTING';
export const SET_TIPS_ERROR = 'SET_TIPS_ERROR';
export const SET_TIPS_SUCCESS = 'SET_TIPS_SUCCESS';

export const SET_UPLOAD_REQUEST = 'SET_UPLOAD_REQUEST';
export const SET_UPLOAD_ERROR = 'SET_UPLOAD_ERROR';
export const SET_UPLOAD_SUCCESS = 'SET_UPLOAD_SUCCESS';

export const QUALITY_LIST: Quality[] = [
  { label: '1080p', value: 1080 },
  { label: '720p', value: 720 },
  { label: '480p', value: 480 },
  { label: '360p', value: 360 },
  { label: '240p', value: 240 },
];

export const DEFAULT_QUALITY = QUALITY_LIST[0];

export const PLAYBACK_SPEED_LIST: PlaybackSpeed[] = [
  { label: '2x', value: 2 },
  { label: '1.5x', value: 1.5 },
  { label: '1x', value: 1 },
  { label: '0.75x', value: 0.75 },
  { label: '0.5x', value: 0.5 },
  { label: '0.25x', value: 0.25 },
];

export const FASTEST_PLAYBACK_SPEED = PLAYBACK_SPEED_LIST[0];
export const DEFAULT_PLAYBACK_SPEED = PLAYBACK_SPEED_LIST[2];
export const SLOWEST_PLAYBACK_SPEED = PLAYBACK_SPEED_LIST[PLAYBACK_SPEED_LIST.length - 1];
export const PLAYBACK_SPEED_SLOWER = 1;
export const PLAYBACK_SPEED_FASTER = -1;

export const MY_FAVOURITE_VIDEOS_TITLE = 'myFavouriteVideosTitle';
export const OTHER_FAVOURITE_VIDEOS_TITLE = 'otherFavouriteVideosTitle';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const CREATE_JOB = 'CREATE_JOB';
export const UPDATE_JOB = 'UPDATE_JOB';
export const FINISH_JOB = 'FINISH_JOB';

export const SET_NOTEPAD_REQUESTING = 'SET_NOTEPAD_REQUESTING';
export const SET_NOTES_BY_MATCHES_REQUESTING = 'SET_NOTES_BY_MATCHES_REQUESTING';
export const SET_NOTEPAD = 'GET_NOTEPAD';
export const SET_NOTES_BY_MATCHES = 'GET_NOTES_BY_MATCHES';

export const SET_ACCOUNTING_ERROR = 'SET_ACCOUNTING_ERROR';
export const SET_ACCOUNTING_REQUESTING = 'SET_ACCOUNTING_REQUESTING';
export const SET_ACCOUNTING_SUCCESS = 'SET_ACCOUNTING_SUCCESS';
export const SET_ACCOUNTING_DATA_REQUESTING = 'SET_ACCOUNTING_DATA_REQUESTING';
export const SET_ACCOUNTING_DATA_SUCCESS = 'SET_ACCOUNTING_DATA_SUCCESS';
export const SET_ACCOUNTING_DATA_ERROR = 'SET_ACCOUNTING_DATA_ERROR';
export const SET_ACCOUNTING_FILTER = 'SET_ACCOUNTING_FILTER';

export const SET_STATISTICS_ERROR = 'SET_STATISTICS_ERROR';
export const SET_STATISTICS_REQUESTING = 'SET_STATISTICS_REQUESTING';
export const SET_STATISTICS_SUCCESS = 'SET_STATISTICS_SUCCESS';
export const SET_STATISTICS_DATA_REQUESTING = 'SET_STATISTICS_DATA_REQUESTING';
export const SET_STATISTICS_DATA_SUCCESS = 'SET_STATISTICS_DATA_SUCCESS';
export const SET_STATISTICS_DATA_ERROR = 'SET_STATISTICS_DATA_ERROR';

export const SET_SHARED_MATCH_REQUESTING = 'SET_SHARED_MATCH_REQUESTING';
export const SET_SHARED_MATCH_SUCCESS = 'SET_SHARED_MATCH_SUCCESS';
export const SET_SHARED_MATCH_ERROR = 'SET_SHARED_MATCH_ERROR';

export const SET_RECORDER_SITUATIONS = 'SET_RECORDER_SITUATIONS';
export const REMOVE_RECORDER_SITUATION = 'REMOVE_RECORDER_SITUATION';
export const UPDATE_RECORDER_SITUATION = 'UPDATE_RECORDER_SITUATION';
export const ADD_RECORDER_SITUATION = 'ADD_RECORDER_SITUATION';
export const CLEAR_RECORDER_SITUATIONS = 'CLEAR_RECORDER_SITUATIONS';
export const SET_MATCH_ID_FOR_SITUATIONS = 'SET_MATCH_ID_FOR_SITUATIONS';

export const SET_CLIP_REACTION = 'SET_CLIP_REACTION';

export const REPORT_REASONS: string[] = [
  'sexual',
  'offensive',
  'hateful',
  'bullying_harassing',
  'violent',
  'false_information',
  'abuse',
  'terrorism',
  'spam',
  'legal_issue',
];

export const SET_REPORTED_CLIP = 'SET_REPORTED_CLIP';
