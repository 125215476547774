import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { getApplicationModeState } from '../../selectors';
import { classHandler } from '../../utils';

import './IsOffline.scss';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';

export const IsOffline: FunctionComponent = () => {
  const applicationMode = useSelector(getApplicationModeState);
  const { t } = useTranslation();

  const reload = () => {
    window.location.reload();
  };

  return (
    <div className={classHandler('IsOffline', applicationMode)}>
      <span className="IsOffline__text">{t('You are offline')}</span>
      <Button type="primary" onClick={reload}>
        {t('Refresh')}
      </Button>
    </div>
  );
};
