import { debounce } from 'lodash';
import { useCallback } from 'react';
import { isValidMatchUrl } from '../api';
import isUrl from 'is-url';

export const useValidateUrl = (onValid: (_url: string) => void, onInvalid: () => void) =>
  useCallback(
    debounce((url: string) => {
      if (!isUrl(url)) {
        onInvalid();
        return;
      }

      isValidMatchUrl(url)
        .then(res => {
          if (res) {
            onValid(url);
          } else {
            onInvalid();
          }
        })
        .catch(() => {
          onInvalid();
        });
    }, 500),
    []
  );
