import { ClipDetailWithMatch, Ratings, RootState, Status } from '../types';

export const getClipDetailStatus = (state: RootState): Status => state.clipDetail.status;

export const getClipDetailErrorMsg = (state: RootState): string | null => state.clipDetail.errorMsg;

export const getClipDetail = (state: RootState): ClipDetailWithMatch | null =>
  state.clipDetail.clipDetail;

export const getClipRatings = (state: RootState): Ratings | undefined =>
  state.clipDetail.clipDetail?.ratings;
