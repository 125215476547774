import { AnyAction } from 'redux';

import { JwtState, Status } from '../types';
import { SET_JWT_ERROR, SET_JWT_REQUESTING, SET_JWT_SUCCESS } from '../constants';
import { setJwtToLocalStorage } from '../utils';

const initJwtState: JwtState = { status: Status.idle, errorMsg: null, jwt: null };

/**
 * JWT reducer
 *
 * @params state State of jwt
 */
export const jwtReducer = (state: JwtState = initJwtState, action: AnyAction): JwtState => {
  switch (action.type) {
    case SET_JWT_REQUESTING: {
      return { status: Status.requesting, errorMsg: null, jwt: null };
    }
    case SET_JWT_ERROR: {
      const errorMsg = action.payload;

      return { status: Status.error, errorMsg, jwt: null };
    }
    case SET_JWT_SUCCESS: {
      const { jwt } = action.payload;

      setJwtToLocalStorage(jwt);

      return { status: Status.success, errorMsg: null, jwt };
    }
    default:
      return state;
  }
};
