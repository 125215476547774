import { Dispatch } from 'redux';
import { getMatchNotes, getUser, putUserNotepad } from '../api';
import {
  SET_NOTEPAD,
  SET_NOTEPAD_REQUESTING,
  SET_NOTES_BY_MATCHES_REQUESTING,
  SET_NOTES_BY_MATCHES,
} from '../constants';
import { ErrorResponse, Gender, MatchNotesResponse, UserResponse } from '../types';
import { MatchWithNotes, Note } from '../types/notes';
import { getJwtFromLocalStorage, isErrorResponse } from '../utils';

export const setNotepadRequesting = () => ({
  type: SET_NOTEPAD_REQUESTING,
});

export const setNotesByMatchesRequesting = () => ({
  type: SET_NOTES_BY_MATCHES_REQUESTING,
});

export const setNotepad = (notepad: string) => ({
  type: SET_NOTEPAD,
  payload: { notepad },
});

export const setNotesByMatches = (notesByMatches: MatchWithNotes[]) => ({
  type: SET_NOTES_BY_MATCHES,
  payload: { notesByMatches },
});

export const getNotepad = () => (dispatch: Dispatch) => {
  dispatch(setNotepadRequesting());
  getUser(getJwtFromLocalStorage() || '').then((response: UserResponse | ErrorResponse) => {
    if (!isErrorResponse(response)) {
      const userResponse = response as UserResponse;

      dispatch(setNotepad(userResponse.user.notepad || ''));
    }
  });
};

export const updateNotepad = (userId: number, notepad: string) => (dispatch: Dispatch) => {
  dispatch(setNotepadRequesting());
  putUserNotepad(userId, notepad).then((response: UserResponse | ErrorResponse) => {
    if (!isErrorResponse(response)) {
      const userResponse = response as any;
      dispatch(setNotepad(userResponse.notepad)); // TODO: Please change backend
    }
  });
};

export const getNotesByMatches = () => (dispatch: Dispatch) => {
  dispatch(setNotesByMatchesRequesting());
  getMatchNotes().then((response: MatchNotesResponse | ErrorResponse) => {
    if (!isErrorResponse(response)) {
      const notesResponse = response as MatchNotesResponse;

      dispatch(setNotesByMatches(transformMatchNotesResponse(notesResponse)));
    }
  });
};

const transformMatchNotesResponse = (matches: any): MatchWithNotes[] => {
  return matches.map(
    (matchWithNote: any): MatchWithNotes => ({
      matchId: matchWithNote.matchId,
      date: new Date(matchWithNote.date),
      competition: matchWithNote.competition,
      gender: matchWithNote.gender === 'male' ? Gender.men : Gender.women,
      category: matchWithNote.category,
      teams: {
        firstTeam: matchWithNote.homeTeam,
        secondTeam: matchWithNote.guestTeam,
      },
      notes: matchWithNote.notes.map(
        (note: any): Note => ({
          id: note.id,
          text: note.text,
        })
      ),
    })
  );
};
