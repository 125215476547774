import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
  classHandler,
  getClipCategoryLabel,
  iconTypeHandler,
  sortClipCategoryByType,
} from '../../utils';
import {
  getApplicationModeState,
  getSettings,
  getClipCategories,
  getClipFilters,
  getApplicationBreakpointState,
  getIsPremium,
} from '../../selectors';
import { setFilterPanelVisibility, setPremiumModalVisibility } from '../../actions';

import './VideoFilterPanel.scss';
import { useTranslation } from 'react-i18next';
import LockIcon from '@material-ui/icons/Lock';
import { Icon, IconType } from '../Icon';
import { SaveButtonsWrapper } from '../SaveButtonsWrapper';
import { setClipCategories } from '../../actions/clipCategoriesActions';
import { ClipCategoryGrouped, ClipCategory, ClipFilters, ApplicationBreakpoint } from '../../types';
import { FormControlLabel, withStyles, Checkbox } from '@material-ui/core';
import { setClipFilters } from '../../actions/clipsActions';
import { useLocation } from 'react-router-dom';

type FilterPanelProps = {
  isVisible: boolean;
};

const checkBoxStyles = () => ({
  root: {
    '&$checked': {
      fill: '#FFFFFF',
      color: '#de1919',
    },
  },
  checked: {},
});

export const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const emptyFilters = {
  clipCategories: [],
};

const areFiltersTheSame = (a: ClipFilters, b: ClipFilters) => {
  const areClipCategoriesTheSame = _.isEqual(
    _.sortBy(a.clipCategories, ['id']),
    _.sortBy(b.clipCategories, ['id'])
  );
  return areClipCategoriesTheSame;
};

export const VideoFilterPanel: FunctionComponent<FilterPanelProps> = ({ isVisible }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const settings = useSelector(getSettings);
  const clipCategories = useSelector(getClipCategories);
  const initialFilters = useSelector(getClipFilters);
  const isPremium = useSelector(getIsPremium);
  const { pathname } = useLocation();

  const isDesktop = applicationBreakpoint === ApplicationBreakpoint.desktop;
  const isSharedMatch = pathname === '/match-shared';
  const isFilterDisabled = !isPremium && !isSharedMatch;

  const [filters, setFilters] = useState<ClipFilters>(initialFilters);

  useEffect(() => {
    if (settings?.sports.id) dispatch(setClipCategories(settings?.sports.id));
  }, [settings, dispatch]);

  const setInvisible = () => dispatch(setFilterPanelVisibility(false));

  const openPremiumModal = () => dispatch(setPremiumModalVisibility(true));

  const resetFilter = () => {
    setInvisible();
    setFilters(emptyFilters);
    dispatch(setClipFilters(emptyFilters));
  };

  const applyFilter = () => {
    setInvisible();
    dispatch(setClipFilters(filters));
  };

  const groupedClipCategories = _.chain(clipCategories.sort(sortClipCategoryByType))
    .groupBy('type')
    .map(category => {
      return {
        type: category[0].type,
        values: _.map(category),
      };
    })
    .value();

  return (
    <div className={classHandler('FilterPanel', applicationMode, false, isVisible)}>
      <div className="FilterPanel__headingWrapper">
        <div className="FilterPanel__heading">
          {t('filter')}
          {isFilterDisabled && (
            <div className="FilterPanel__heading__premium" onClick={openPremiumModal}>
              <LockIcon className="FilterPanel__heading__premium__icon" />
              {t('premiumFeatureAvailable')}
            </div>
          )}
        </div>

        <div className="FilterPanel__close" onClick={setInvisible}>
          <Icon type={iconTypeHandler(applicationMode, IconType.white)} name="close" size="16px" />
        </div>
      </div>
      <div
        className={`FilterPanel__content ${
          isFilterDisabled ? 'FilterPanel__content__premium' : ''
        }`}
      >
        <div className="FilterPanel__segment">
          <div className="FilterPanel__segmentHeading">{t('clipCategories')}</div>
          {groupedClipCategories.map(({ type, values }: ClipCategoryGrouped) => (
            <div className="FilterPanel__categoryTypeWrapper" key={type}>
              <div className="FilterPanel__categoryTypeHeading">{t(type)}</div>
              <div className="FilterPanel__categories">
                {values.map((clipCategory: ClipCategory) => {
                  const found = filters.clipCategories.find(
                    (checked: ClipCategory) => checked.id === clipCategory.id
                  );
                  const isChecked = () => {
                    return found !== undefined;
                  };

                  clipCategory.value = t(clipCategory.value);

                  const onChecked = () => {
                    if (found) {
                      setFilters({
                        ...filters,
                        clipCategories: filters.clipCategories.filter(
                          (checked: ClipCategory) => checked.id !== clipCategory.id
                        ),
                      });
                    } else {
                      setFilters({
                        ...filters,
                        clipCategories: [...filters.clipCategories, clipCategory],
                      });
                    }
                  };

                  return (
                    <div className="FilterPanel__category" key={clipCategory.id}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            checked={isChecked()}
                            onChange={onChecked}
                            disabled={isFilterDisabled}
                          />
                        }
                        label={getClipCategoryLabel(clipCategory)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
      <SaveButtonsWrapper
        primaryButton={{
          onClick: applyFilter,
          label: t('applyFilter'),
          disabled: areFiltersTheSame(initialFilters, filters) || isFilterDisabled,
        }}
        secondaryButton={{
          onClick: resetFilter,
          label: t('resetFilter'),
          disabled: areFiltersTheSame(emptyFilters, filters) || isFilterDisabled,
        }}
        style={{
          margin: '0 1em',
          padding: isDesktop ? '20px' : '10px',
          borderTop: '1px solid #c5c5c5',
        }}
      />
    </div>
  );
};
