import {
  Competition,
  Match,
  MatchDetail,
  MyClipGroup,
  CommunityClipGroup,
  ApplicationState,
  User,
  Country,
  Sport,
  Tip,
  Settings,
  Language,
  RefereeTeamType,
  ClipCategory,
  Upload,
  Accounting,
  AccountingData,
  Statistics,
  StatisticsData,
  AccountingFilter,
  MatchSituation,
} from './';
import {
  FavouriteClipGroup,
  ClipFilters,
  ClipDetailWithMatch,
  ClosedCommunityClipGroup,
} from './clip';
import { Job } from './job';
import { MatchWithNotes } from './notes';
import { Notification } from './notification';

export enum Status {
  idle = 'idle',
  requesting = 'requesting',
  asyncRequesting = 'asyncRequesting',
  requestingMore = 'requestingMore',
  error = 'error',
  success = 'success',
}

export interface RootState {
  application: ApplicationState;
  matches: MatchesState;
  matchDetail: MatchDetailState;
  competitions: CompetitionsState;
  clips: ClipsState;
  clipDetail: ClipDetailState;
  clipCategories: ClipCategoriesState;
  user: UserState;
  jwt: JwtState;
  countries: CountriesState;
  sports: SportsState;
  tips: TipsState;
  settings: SettingsState;
  languages: LanguageState;
  refereeTeamTypes: RefereeTeamTypesState;
  upload: UploadState;
  notification: NotificationState;
  job: JobState;
  notes: NotesState;
  accounting: AccountingState;
  accountingData: AccountingDataState;
  statistics: StatisticsState;
  statisticsData: StatisticsDataState;
  sharedMatch: SharedMatchState;
  recorderSituations: RecorderSituationsState;
}

export interface RecorderSituationsState {
  situations: MatchSituation[];
  forMatchId: number | null;
}

export interface SharedMatchState {
  status: Status;
  sport: number | null;
  errorMsg: string | null;
  sharedMatchClipGroup?: MyClipGroup;
}

export interface MatchesState {
  status: Status;
  errorMsg: string | null;
  matches: Match[];
  totalCount: number;
}

export interface MatchDetailState {
  status: Status;
  errorMsg: string | null;
  matchDetail: MatchDetail | null;
}

export interface CompetitionsState {
  status: Status;
  errorMsg: string | null;
  competitions: Competition[];
}

export interface ClipsState {
  status: Status;
  errorMsg: string | null;
  myClipGroups: MyClipGroup[];
  myClipGroupCount: number;
  communityClipGroups: CommunityClipGroup[];
  communityClipGroupCount: number;
  favouriteClipGroups: FavouriteClipGroup[];
  favouriteClipGroupCount: number;
  closedCommunityClipGroups: ClosedCommunityClipGroup[];
  closedCommunityClipGroupCount: number;
  closedCommunityName: string | null;
  filters: ClipFilters;
}

export interface ClipDetailState {
  status: Status;
  errorMsg: string | null;
  clipDetail: ClipDetailWithMatch | null;
}

export interface ClipCategoriesState {
  status: Status;
  errorMsg: string | null;
  clipCategories: ClipCategory[];
  clipCategoryTypes: string[];
}

export interface UserState {
  status: Status;
  errorMsg: string | null;
  user: User | null;
}

export interface JwtState {
  status: Status;
  errorMsg: string | null;
  jwt: string | null;
}

export interface CountriesState {
  status: Status;
  errorMsg: string | null;
  countries: Country[];
}

export interface SportsState {
  status: Status;
  errorMsg: string | null;
  sports: Sport[];
}

export interface SettingsState {
  status: Status;
  errorMsg: string | null;
  settings: Settings | null;
}

export interface LanguageState {
  status: Status;
  errorMsg: string | null;
  languages: Language[];
}

export interface RefereeTeamTypesState {
  status: Status;
  errorMsg: string | null;
  refereeTeamTypes: RefereeTeamType[];
}

export interface UploadState {
  status: Status;
  errorMsg: string | null;
  upload: Upload | null;
}

export interface TipsState {
  status: Status;
  errorMsg: string | null;
  tips: Tip[];
}

export interface NotificationState {
  notification: Notification;
}

export interface JobState {
  jobs: Job[];
}

export interface NotesState {
  status: Status;
  notepad: string;
  notesByMatches: MatchWithNotes[];
}

export interface AccountingState {
  status: Status;
  errorMsg: string | null;
  accounting: Accounting[];
  filter: AccountingFilter;
}

export interface AccountingDataState {
  status: Status;
  errorMsg: string | null;
  accountingData: AccountingData;
}

export interface StatisticsState {
  status: Status;
  errorMsg: string | null;
  statistics: Statistics[];
}

export interface StatisticsDataState {
  status: Status;
  errorMsg: string | null;
  statisticsData: StatisticsData;
}
