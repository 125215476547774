import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setStatisticsData } from '../../actions';
import { getStatisticsData } from '../../selectors';

import { StatisticsCard } from '../StatisticsCard';
import './StatisticsYearTab.scss';

export const COMMUNITY_TAB_ID = 1;

type StatisticsYearTabProps = {
  year: string;
  link: string;
};

export const StatisticsYearTab: FunctionComponent<StatisticsYearTabProps> = ({ year, link }) => {
  const dispatch = useDispatch();
  const data = useSelector(getStatisticsData);

  useEffect(() => {
    dispatch(setStatisticsData(link));
  }, [dispatch, link, year]);

  const categories: any = [];
  const { t } = useTranslation();

  (data.others || []).forEach((category, index) => {
    const key = Object.keys(category)[0];
    const value: any = Object.values(category)[0];

    categories.push(
      <StatisticsCard
        data={value}
        label={'"' + t(key) + '" & ' + t('comp')}
        firstColumn="category"
        secondColumn="value"
        bottomText="Matches total"
        key={index}
        postFix=""
        explanation=""
      />
    );
  });

  return (
    <div className="StatisticCard__container">
      {/* matches */}
      {!!data.matches.length && (
        <StatisticsCard
          data={data.matches.sort((a: any, b: any) => (a.value < b.value ? 1 : -1))}
          label="matches"
          firstColumn="season"
          secondColumn="value"
          bottomText="Total"
          postFix=""
          explanation=""
        />
      )}
      {/* matches in competitions */}
      {!!data.matchesInCompetitions.length && (
        <StatisticsCard
          data={data.matchesInCompetitions.sort((a: any, b: any) => (a.value < b.value ? 1 : -1))}
          label="Matches in competitions"
          firstColumn="competition"
          secondColumn="value"
          bottomText="Matches total"
          postFix=""
          explanation=""
        />
      )}
      {categories}
      {/* colleagues */}
      {!!data.colleagues.length && (
        <StatisticsCard
          data={data.colleagues.sort((a: any, b: any) => (a.value < b.value ? 1 : -1))}
          label="Most frequented colleagues"
          firstColumn="name"
          secondColumn="value"
          bottomText="Unique colleagues"
          postFix=""
          explanation=""
        />
      )}
      {/* roles in two persons */}
      {/* {!!data.rolesInTwoPersons.length && (
        <StatisticsCard
          data={data.rolesInTwoPersons}
          label="My role - in 2 persons officiating"
          firstColumn="role"
          secondColumn="value"
          bottomText=""
          postFix=""
          explanation=""
        />
      )} */}
      {/* roles in three persons */}
      {/* {!!data.rolesInThreePersons.length && (
        <StatisticsCard
          data={data.rolesInThreePersons}
          label="My role - in 3 persons officiating"
          firstColumn="role"
          secondColumn="value"
          bottomText=""
          postFix=""
          explanation=""
        />
      )} */}
      {/* statistics from my notes TODO: firstColumn */}
      {/* !!data.fromNotes.length && (
        <StatisticsCard
          data={data.fromNotes}
          label="Statistics from my notes"
          firstColumn="name"
          secondColumn="value"
          bottomText=""
          postFix=""
          explanation=""
        />
      ) */}
      {/* technical fouls - average per match */}
      {/* {!!data.numOfTechnicalFouls && (
        <StatisticsCard
          data={data.numOfTechnicalFouls}
          label="Technical fouls - average per match"
          firstColumn="name"
          secondColumn="value"
          bottomText=""
          postFix=""
          explanation=""
        />
      )} */}
      {/* unsportsmenlike fouls  - average per match */}
      {/* {!!data.numOfUnsportsmenlikeFouls.length && (
        <StatisticsCard
          data={data.numOfUnsportsmenlikeFouls}
          label="Unsportsmenlike fouls  - average per match"
          firstColumn="name"
          secondColumn="value"
          bottomText=""
          postFix=""
          explanation=""
        />
      )} */}
      {/* Countries contest - Matches per 1 ref in average */}
      {/* {!!data.countriesContest.length && (
        <StatisticsCard
          data={data.countriesContest}
          label="Countries contest - Matches per 1 ref in average"
          firstColumn="name"
          secondColumn="value"
          bottomText=""
          postFix=""
          explanation=""
        />
      )} */}
    </div>
  );
};
