import { CHUNKED_FILE_UPLOAD_URL, GET_MATCH_DETAIL_URL } from './routes';
import { getJwtFromLocalStorage } from '../utils';
import { ErrorResponse, FileUploadResponse } from '../types';

import { store } from '../';
import { showNotification, updateNotification } from '../actions';

import i18next from 'i18next';
import { Job, JobType, VideoUploadJob } from '../types/job';
import { uniqueId } from 'lodash';
import { createJob, finishJob, updateJob } from '../actions';
import splitWorker from '../split.worker';
import WebWorker from '../WebWorker';
import axios from 'axios';

import md5 from 'md5';

const getPercentUploadedMessage = (percentUploaded: number) => {
  return i18next.t('videoUploadingNotification') + ` ${percentUploaded} %`;
};

const getPercentUploaded = (chunkIndex: number, totalChunks: number) => {
  return Math.floor((100 * chunkIndex) / totalChunks);
};

const areYouSureYouWantToRefresh = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  event.returnValue = 'Video is still uploading...';
};

const rejectUpload = (reject: (_: any) => void, message: any, snackbarMessage?: string) => {
  store.dispatch(
    showNotification(snackbarMessage ? snackbarMessage : i18next.t('videoError'), 'error')
  );
  reject(message);
};

export const postFile = async (
  matchId: number,
  file: any
): Promise<FileUploadResponse | ErrorResponse> => {
  if (
    store.getState().job.jobs.filter((job: Job) => job.type === JobType.VideoUploadJob).length > 0
  ) {
    return {
      error: 'only one video at a time',
    } as ErrorResponse;
  }

  window.addEventListener('beforeunload', areYouSureYouWantToRefresh);
  if (!file.name) return { error: 'file.name' } as ErrorResponse;

  const uploadJob: VideoUploadJob = {
    id: uniqueId(),
    type: JobType.VideoUploadJob,
    metadata: { matchId, percentUploaded: 0 },
  };
  store.dispatch(createJob(uploadJob));
  store.dispatch(showNotification(getPercentUploadedMessage(0), 'info'));

  const worker = new WebWorker(splitWorker);
  return processChunk(0, worker, matchId, file, uploadJob).finally(() => {
    store.dispatch(finishJob(uploadJob));
    window.removeEventListener('beforeunload', areYouSureYouWantToRefresh);
    worker.terminate();
  });
};

const apiCancelUpload = (matchId: number, fileName: string) => {
  const params = new URLSearchParams();
  params.append('cancelUpload', 'true');
  params.append('name', fileName);
  params.append('matchId', matchId.toString());

  return axios.post(
    `${CHUNKED_FILE_UPLOAD_URL}?${params.toString()}`,
    {},
    {
      headers: {
        authorization: `Bearer ${getJwtFromLocalStorage()}`,
        'Accept-Language': 'en_US',
        'Content-Type': 'application/octet-stream',
      },
    }
  );
};

export const removeVideo = async (videoId: string) => {
  const response = await axios.delete(`${CHUNKED_FILE_UPLOAD_URL}/${videoId}`, {
    headers: {
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
    },
  });
  return response;
};

const processChunk = async (
  chunkIndex: number,
  worker: WebWorker,
  matchId: number,
  file: File,
  uploadJob: Job
) => {
  const { chunk: chunkToBeSent, totalChunks } = await worker.process({ file, chunkIndex });
  // console.log(`processing chunk ${chunkIndex} / ${totalChunks}`);
  const percentUploaded = getPercentUploaded(chunkIndex, totalChunks);
  store.dispatch(updateNotification(getPercentUploadedMessage(percentUploaded), 'info'));
  store.dispatch(
    updateJob({
      ...uploadJob,
      metadata: {
        ...uploadJob.metadata,
        percentUploaded,
      },
    })
  );
  return new Promise<FileUploadResponse | ErrorResponse>((resolve, reject) => {
    // if doesnt exist uploadJob for given match (meaning it was cancelled)
    if (
      !(store.getState().job.jobs as Job[]).some(
        (job: Job) => job.type === JobType.VideoUploadJob && job.metadata.matchId === matchId
      )
    ) {
      // the job is cancelled, abort upload.
      apiCancelUpload(matchId, file.name)
        .then(() => {
          rejectUpload(reject, 'The upload was cancelled.', 'The upload was cancelled.');
        })
        .catch(() => {
          rejectUpload(
            reject,
            "Upload couldn't cancel properly.",
            "Upload couldn't cancel properly."
          );
        });
      return;
    }

    postChunk(chunkToBeSent, matchId, chunkIndex, totalChunks, file.name)
      .then(res => {
        const isLast = chunkIndex === totalChunks - 1;
        if (!store.getState().user.user) {
          rejectUpload(reject, 'User logged out.');
          return;
        }
        if (isLast) {
          store.dispatch(showNotification(i18next.t('processing'), 'info'));
          resolve(res.data);
        } else {
          resolve(processChunk(chunkIndex + 1, worker, matchId, file, uploadJob));
        }
      })
      .catch(({ code, response }) => {
        const status = response?.status;
        const message = response?.data;

        if (code === 'ECONNABORTED') {
          resolve(processChunk(0, worker, matchId, file, uploadJob));
        } else if (status === 500 && message === 'Unable to copy to bucket') {
          rejectUpload(reject, message);
        } else if (status === 415 && message === 'Wrong content type') {
          rejectUpload(reject, message);
        } else if (
          status === 400 &&
          [
            'Wrong upload paramaters',
            'Upload too big',
            'Filetype not supported',
            'Target file is bussy',
            'Invalid token',
            'Unable to create buffer',
            'Chunk index is bigger than number of chunks',
          ].includes(message)
        ) {
          rejectUpload(reject, message);
        } else if ([401, 402, 403, 404].includes(status)) {
          rejectUpload(reject, message);
        } else if (status === 400 && message === 'Previous chunk or chunks are missing') {
          resolve(processChunk(0, worker, matchId, file, uploadJob));
        } else if (status === 400 && message === 'Previous data not found') {
          resolve(processChunk(0, worker, matchId, file, uploadJob));
        } else if (status === 400 && message === 'Chunk corrupted') {
          resolve(processChunk(chunkIndex, worker, matchId, file, uploadJob));
        } else if (message === 'Network Error') {
          // client went offline
          setTimeout(() => {
            resolve(processChunk(chunkIndex, worker, matchId, file, uploadJob));
            // try to resend every 5 secnods until client goes back online
          }, 5000);
        } else {
          // another option here - start from chunkIndex 0?
          resolve(processChunk(chunkIndex, worker, matchId, file, uploadJob));
        }
      });
  });
};

const postChunk = (
  chunk: Buffer,
  matchId: number,
  chunkIndex: number,
  totalChunks: number,
  fileName: string
) => {
  const params = new URLSearchParams();
  params.append('matchId', matchId.toString());
  params.append('hash', md5(chunk));
  params.append('chunkIndex', chunkIndex.toString());
  params.append('numOfChunks', totalChunks.toString());
  params.append('name', fileName);
  return axios.request({
    method: 'POST',
    url: `${CHUNKED_FILE_UPLOAD_URL}?${params.toString()}`,
    headers: {
      authorization: `Bearer ${getJwtFromLocalStorage()}`,
      'Accept-Language': 'en_US',
      'Content-Type': 'application/octet-stream',
    },
    timeout: chunkIndex + 1 === totalChunks ? 1_800_000 : 160_000,
    // 160 seconds timeout limit, for last chunk 30 minutes
    // (need to wait for video to copy and resize)
    data: chunk,
  });
};

export const cancelUpload = (matchId: number) => {
  const job = (store.getState().job.jobs as Job[]).find(
    (_job: Job) => _job.type === JobType.VideoUploadJob && +_job.metadata.matchId === +matchId
  );

  return new Promise<boolean>(resolve => {
    axios
      .put(
        GET_MATCH_DETAIL_URL(matchId),
        { videoStatus: 'cancelled' },
        {
          headers: {
            authorization: `Bearer ${getJwtFromLocalStorage()}`,
          },
        }
      )
      .finally(() => {
        if (job) {
          store.dispatch(finishJob(job));
        }
        // value passed to resolve determines if match needs to be refetched again.
        resolve(!job);
      });
  });
};
